<template>
  <div>
    <div class="text-center" style="color: #469AE0;" v-if="averageDiagosticsLoading">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement du graphique...
      </h2>
    </div>
    <div v-else class="mt-3">
      <line-chart :data="dataAverageDiagnostics" :points=false :library="options"></line-chart>
      <p class="small text-center mt-1 mb-1" style="color: #BFBFBF;">1 = Dénutrition Sévère, 2 = Dénutrition modérée, 3 = A risque élevé, 4 = A risque faible</p>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import { FETCH_AVERAGE_DIAGNOSTICS } from "../../graphql/stats/queries";

export default {
  name: "AverageDiagnosticsChart",
  props: {
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      averageDiagosticsLoading: true,
      dataAverageDiagnostics: [],
      options: {
        scales: {
          xAxes: [{
            type: "time",
            time: {
              displayFormats: {
                day: "DD MMM YYYY",
              },
            },
          }],
          yAxes: [{
            ticks: {
              min: 1,
              max: 4,
              stepSize: 1,
              precision: 0,
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  apollo: {
    averageDiagnostics: {
      query: FETCH_AVERAGE_DIAGNOSTICS,
      variables() {
        this.averageDiagosticsLoading = true;
        return {
          ehpadId: this.currentEhpad.id,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "network-only",
      result() {
        this.averageDiagosticsLoading = false;
      },
      watchQuery: {
        variables(newVariables) {
          return {
            ehpadId: newVariables.currentEhpad.id,
            startDate: newVariables.startDate,
            endDate: newVariables.endDate,
          };
        },
      },
    },
  },
  watch: {
    averageDiagnostics: {
      handler() {
        if (this.averageDiagnostics !== undefined) {
          this.dataAverageDiagnostics = this.buildAverageDiagnostics;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: "ehpad",
    }),
    buildAverageDiagnostics() {
      const datesArray = Object.keys(this.averageDiagnostics);
      const fetchValuesForField = (field) => datesArray.map(date => [date, _.get(this.averageDiagnostics[date], field, 0)]);

      return [
        {
          name: "Moyenne des états nutritionnels",
          data: fetchValuesForField("average").map((el) => [moment(el[0]).format("YYYY/MM/DD"), el[1]]),
          color: "#ff6347",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
