import gql from 'graphql-tag';

export const FETCH_RESIDENT_INGESTA_CONTROL_FOR_CHARTS = gql`
  query FETCH_RESIDENT_INGESTA_CONTROL_FOR_CHARTS($year: Int, $months: Int, $residentId: ID!) {
    residentIngestaControlForCharts(year: $year, months: $months, residentId: $residentId)
  }
`;

export const FETCH_RESIDENT_DIAGNOSTICS_FOR_CHARTS = gql`
  query FETCH_RESIDENT_DIAGNOSTICS_FOR_CHARTS($year: Int, $months: Int, $residentId: ID!) {
    residentDiagnosticsForCharts(year: $year, months: $months, residentId: $residentId)
  }
`;

export const FETCH_RESIDENT_TESTS_FOR_CHARTS = gql`
  query FETCH_RESIDENT_TESTS_FOR_CHARTS($year: Int, $months: Int, $residentId: ID!) {
    residentTestsForCharts(year: $year, months: $months, residentId: $residentId)
  }
`;
