var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"text-center mb-3"},[_vm._v("\n    Residents\n    "),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}}],staticClass:"fas fa-exclamation-triangle",staticStyle:{"color":"red","font-size":"smaller"},attrs:{"title":"Les constantes en rouge doivent être actualisées"}})]),_vm._v(" "),_c('b-form-group',{staticClass:"mb-3",staticStyle:{"width":"40%"},attrs:{"label-size":"sm","label-for":"filterInput"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"type":"search","id":"filterInput","placeholder":"Filtre de recherche"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_vm._v(" "),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v("Effacer")])],1)],1)],1),_vm._v(" "),_c('b-table',{ref:"residentTable",staticClass:"m-0",attrs:{"filter":_vm.filter,"sticky-header":"800px","bordered":"","striped":"","small":"","hover":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items":_vm.residentsWithRoom,"fields":_vm.computedFields,"id":"residentTable"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(lastWeight)",fn:function(data){return [(data.item.lastDiagnostic.state == 'severe_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastWeight,
            _vm.currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_weight_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'moderate_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastWeight,
            _vm.currentEhpad.allSettings.moderate_undernutrition.constants_config.minimum_weight_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'high_risk')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastWeight,
            _vm.currentEhpad.allSettings.high_risk.constants_config.minimum_weight_value_taking_in_days,
          )
        )}}):_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastWeight,
            _vm.currentEhpad.allSettings.default_undernutrition.constants_config.minimum_weight_value_taking_in_days,
          )
        )}})]}},{key:"cell(lastMna)",fn:function(data){return [(data.item.lastDiagnostic.state == 'severe_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastMna,
            _vm.currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_mna_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'moderate_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastMna,
            _vm.currentEhpad.allSettings.moderate_undernutrition.constants_config.minimum_mna_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'high_risk')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastMna,
            _vm.currentEhpad.allSettings.high_risk.constants_config.minimum_mna_value_taking_in_days,
          )
        )}}):_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastMna,
            _vm.currentEhpad.allSettings.default_undernutrition.constants_config.minimum_mna_value_taking_in_days,
          )
        )}})]}},{key:"cell(lastCrp)",fn:function(data){return [(data.item.lastDiagnostic.state == 'severe_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastCrp,
            _vm.currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_crp_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'moderate_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastCrp,
            _vm.currentEhpad.allSettings.moderate_undernutrition.constants_config.minimum_crp_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'high_risk')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastCrp,
            _vm.currentEhpad.allSettings.high_risk.constants_config.minimum_crp_value_taking_in_days,
          )
        )}}):_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastCrp,
            _vm.currentEhpad.allSettings.default_undernutrition.constants_config.minimum_crp_value_taking_in_days,
          )
        )}})]}},{key:"cell(lastAlbumine)",fn:function(data){return [(data.item.lastDiagnostic.state == 'severe_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastAlbumine,
            _vm.currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_albumine_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'moderate_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastAlbumine,
            _vm.currentEhpad.allSettings.moderate_undernutrition
              .constants_config.minimum_albumine_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'high_risk')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastAlbumine,
            _vm.currentEhpad.allSettings.high_risk.constants_config.minimum_albumine_value_taking_in_days,
          )
        )}}):_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastAlbumine,
            _vm.currentEhpad.allSettings.default_undernutrition.constants_config.minimum_albumine_value_taking_in_days,
          )
        )}})]}},{key:"cell(lastDiagnostic)",fn:function(data){return [(data.item.lastDiagnostic.state == 'severe_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDiagHtmlWithDate(
            data.item.lastDiagnostic,
            _vm.currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_diagnostic_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'moderate_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDiagHtmlWithDate(
            data.item.lastDiagnostic,
            _vm.currentEhpad.allSettings.moderate_undernutrition
              .constants_config.minimum_diagnostic_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'high_risk')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDiagHtmlWithDate(
            data.item.lastDiagnostic,
            _vm.currentEhpad.allSettings.high_risk.constants_config.minimum_diagnostic_value_taking_in_days,
          )
        )}}):_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDiagHtmlWithDate(
            data.item.lastDiagnostic,
            _vm.currentEhpad.allSettings.default_undernutrition.constants_config.minimum_diagnostic_value_taking_in_days,
          )
        )}})]}},{key:"cell(room)",fn:function(data){return [(data.item.lastStatus.statusCategory.name == 'Entrée')?_c('span',[_c('i',{staticClass:"fas fa-bed"})]):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s((data.item.room && `N° ${data.item.room.number}`) || 'Aucun lit assigné')+"\n      ")])]}},{key:"cell(sector)",fn:function(data){return [_c('span',{staticStyle:{"font-size":"medium"}},[_vm._v(_vm._s((data.item.sector && `${data.item.sector.name}`) || 'Pas de secteur'))])]}},{key:"head(actions)",fn:function(){return [_vm._v(_vm._s(''))]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary.noninteractive",modifiers:{"hover":true,"v-primary":true,"noninteractive":true}}],attrs:{"variant":"link","size":"md","to":{ path: `/ehpads/${_vm.currentEhpad.shortname}/resident/${data.item.id}/diagnostics` },"title":"Données personnelles"}},[_c('i',{staticClass:"fas fa-chart-line"})]),_vm._v(" "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary.noninteractive",modifiers:{"hover":true,"v-primary":true,"noninteractive":true}}],attrs:{"variant":"link","size":"md","to":{ path: `/ehpads/${_vm.currentEhpad.shortname}/resident/${data.item.id}/nutrition` },"title":"Nutrition"}},[_c('i',{staticClass:"fas fa-utensils"})]),_vm._v(" "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary.noninteractive",modifiers:{"hover":true,"v-primary":true,"noninteractive":true}}],attrs:{"variant":"link","size":"md","to":{
          path: `/ehpads/${_vm.currentEhpad.shortname}/resident/${data.item.id}/menus/${_vm.currentYear}/${_vm.currentWeek}`,
        },"title":"Menus du résident"}},[_c('i',{staticClass:"far fa-calendar-alt"})])]}},{key:"top-row",fn:function(data){return _vm._l((data.fields),function(field){return _c('td',{key:field.key,staticClass:"align-middle text-center"},[(field.key === 'actions')?_c('b-button',{attrs:{"variant":"primary","size":"sm","block":""},on:{"click":_vm.createResident}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v("\n          Ajouter\n        ")]):(field.key === 'room')?_c('b-form-select',{staticClass:"small-size rooms-list-select",attrs:{"size":"sm","state":_vm.$v.newResident[field.key] && !_vm.$v.newResident[field.key].$error},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(_vm._s(_vm.roomsSelectPrompt))])]},proxy:true}],null,true),model:{value:(_vm.newResident[field.key]),callback:function ($$v) {_vm.$set(_vm.newResident, field.key, $$v)},expression:"newResident[field.key]"}},[_vm._v(" "),_vm._l((_vm.rooms),function(room){return _c('b-form-select-option',{key:room.id,attrs:{"value":room.id,"disabled":!room.isFree}},[_vm._v(_vm._s(`N° ${room.number}`))])}),_vm._v(" "),_c('b-form-invalid-feedback',[_vm._v(_vm._s(`${field.label} requis`))])],2):(
            field.key !== 'lastCrp' &&
              field.key !== 'lastMna' &&
              field.key !== 'lastAlbumine' &&
              field.key !== 'lastDiagnostic' &&
              field.key !== 'sector' &&
              field.key !== 'lastTexture'
          )?_c('b-form-group',{staticClass:"m-0",class:_vm.classFor(field.key),attrs:{"invalid-feedback":`${field.label} requis`,"state":_vm.$v.newResident[field.key] && !_vm.$v.newResident[field.key].$error}},[_c('b-form-input',{class:{
              'is-invalid': _vm.$v.newResident[field.key] && _vm.$v.newResident[field.key].$error,
            },attrs:{"size":"sm","type":_vm.setInputType(field.key),"min":"0","placeholder":field.label},model:{value:(_vm.newResident[field.key]),callback:function ($$v) {_vm.$set(_vm.newResident, field.key, $$v)},expression:"newResident[field.key]"}})],1):_vm._e()],1)})}}]),model:{value:(_vm.residentsWithRoomSorted),callback:function ($$v) {_vm.residentsWithRoomSorted=$$v},expression:"residentsWithRoomSorted"}}),_vm._v(" "),_c('hr')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }