<template>
  <b-modal v-bind="$attrs" :title="modalTitle" class="col-12 text-center">
    <b-container fluid>
      <b-form>
        <div class="form-inline">
          <label for="inline-form-input-email" class="pr-4">Courriel</label>
          <b-input
            id="inline-form-input-email"
            size="sm"
            type="text"
            v-model="editableUser.email"
            :class="{ 'is-invalid': $v.editableUser.email.$error }"
          />
          <b-form-invalid-feedback>Entrez un email valide</b-form-invalid-feedback>
        </div>

        <div class="mt-2 form-inline">
          <label for="inline-form-input-password" class="pr-4">Mot de passe</label>
          <b-input
            id="inline-form-input-password"
            size="sm"
            type="password"
            v-model="editableUser.password"
            :class="{ 'is-invalid': $v.editableUser.password.$error }"
            autocomplete="new-password"
          />
          <b-form-invalid-feedback
            >Entrez un mot de passe valide (8 caractères minimum)</b-form-invalid-feedback
          >
        </div>

        <div class="pt-2 d-flex form-inline">
          <label for="inline-form-input-right" class="pr-4">Droits d'accès</label>
          <b-form-select
            size="sm"
            v-model="editableUser.role"
            :options="roleOptions"
            :class="{ 'is-invalid': $v.editableUser.role.$anyError }"
          />
          <b-form-invalid-feedback>Droits d'accès requis</b-form-invalid-feedback>
          <i
            class="fas fa-exclamation-triangle mx-auto mt-2"
            style="color: red;"
            v-b-tooltip.hover.v-danger.html
            title="<b>Administrateur</b> : Il gére les utilisateurs du logiciel, le paramétrage et accéde à toutes les fonctionnalités</br> <b>Médical</b> : Accéde à l'ensemble des fonctionnalités médicales, nutritionnelles et aux tableaux de bords</br> <b>Ingesta</b> : Accéde uniquement à la saisi des ingesta</br> <b>Intendance</b> : Accéde à l'ensemble des fonctionnalités médicales, nutritionnelles, aux tableaux de bords et à la gestion des prix des plats"
          />
        </div>

        <div class="pt-2 form-inline" v-if="currentUser.ehpad === null">
          <label for="inline-form-input-ehpad" class="pr-4">Ehpad</label>
          <b-form-select size="sm" v-model="editableUser.ehpadId">
            <template #first>
              <b-form-select-option :value="null">Accès à tous les EHPAD</b-form-select-option>
            </template>
            <b-form-select-option v-for="ehpad in ehpads" :value="ehpad.id" :key="ehpad.id">{{
              ehpad.name
            }}</b-form-select-option>
          </b-form-select>
        </div>

        <div v-if="error" style="color: red;" class="text-center mt-2">
          <p v-for="(error, i) of error.graphQLErrors" :key="i">
            {{ error.message }}
          </p>
        </div>

        <b-button class="mt-3 d-flex mx-auto" variant="primary" size="sm" @click.prevent="updateUser"
          >Valider</b-button
        >
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email, minLength } from 'vuelidate/lib/validators';

export default {
  props: ['user'],
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      editableUser: null,
    };
  },
  created() {
    this.duplicateUser(this.user);
  },
  validations: {
    editableUser: {
      email: {
        required,
        email,
      },
      password: {
        minLength: minLength(8),
      },
      role: {
        required,
      },
    },
  },
  computed: {
    modalTitle() {
      return 'Modifier un utilisateur';
    },
    roleOptions() {
      return [
        { value: null, text: 'Choisissez un role', disabled: true },
        'médical',
        'ingesta',
        'intendance',
        'administrateur',
      ];
    },
    ...mapGetters({
      ehpads: 'ehpads',
      currentUser: 'user',
    }),
  },
  methods: {
    duplicateUser(user) {
      const ehpadId = user && user.ehpad && user.ehpad.id;
      this.editableUser = { ...user, ehpadId };
    },
    async updateUser() {
      this.$v.editableUser.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('updateUser', {
        id: this.editableUser.id,
        email: this.editableUser.email,
        role: this.editableUser.role,
        password: this.editableUser.password,
        ehpadId: this.editableUser.ehpadId,
      }).then((result) => {
        this.error = null;
        this.$v.$reset();
        this.$bvModal.hide('info-user-modal');
      }).catch((error) => {
        this.error = error;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
