<template>
  <b-modal v-bind="$attrs" title="Ajouter un nouveau buffet" size="lg" class="col-12 text-center">
    <b-container fluid>
      <b-form>
          <b-row>
            <b-col cols="2">
              <label for="inline-form-input-name" class="pr-4 text-nowrap">Nom : </label>
            </b-col>
            <b-col cols="10">
                <b-form-input
                  id="inline-form-input-name"
                  size="sm"
                  type="text"
                  v-model="newBuffet.name"
                  :class="{ 'is-invalid': $v.newBuffet.name.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise (60 caractères max)</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-cal" class="pr-4 text-nowrap">Calories (kcal) minimum servies: </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  id="inline-form-input-cal"
                  size="sm"
                  type="number"
                  min="0"
                  v-model="newBuffet.defaultCalories"
                  :class="{ 'is-invalid': $v.newBuffet.defaultCalories.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-prot" class="pr-4 text-nowrap">Protéines (g) minimum servies: </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  id="inline-form-input-prot"
                  size="sm"
                  type="number"
                  min="0"
                  v-model="newBuffet.defaultProteins"
                  :class="{ 'is-invalid': $v.newBuffet.defaultProteins.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-hydr" class="pr-4 text-nowrap">Apports hydriques (ml) minimum servies: </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  id="inline-form-input-hydr"
                  size="sm"
                  type="number"
                  min="0"
                  v-model="newBuffet.defaultHydration"
                  :class="{ 'is-invalid': $v.newBuffet.defaultHydration.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-option" class="pr-4 text-nowrap">Catégorie : </label>
            </b-col>
            <b-col cols="6">
              <b-form-select
                id="inline-form-input-option"
                v-model="newBuffet.dType"
                :options="dTypeOptions"
                :class="{ 'is-invalid': $v.newBuffet.dType.$anyError }"
              />
              <b-form-invalid-feedback>Type de plat requis</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="4">
              <label for="inline-form-input-option" class="pr-4 text-nowrap">Plats : </label>
            </b-col>
            <b-col cols="8">
              <multiselect
                v-model="newBuffet.dishes"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :options="activeDishesByType"
                :custom-label="customLabel"
                placeholder="Rechercher/Sélectionner un plat"
                label="name"
                track-by="name"
                selectLabel=""
                deselectLabel=""
                selectedLabel="Sélectionné"
                :class="{ 'is-invalid': $v.newBuffet.dishes.$anyError }"
              >
                <template #noResult>
                  <span>Pas de résultat correspondant</span>
                </template>
              </multiselect>
              <b-form-invalid-feedback>Un buffet doit contenir au moins un plat</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <div>
            <b-form-group
              class="mt-2"
              label="Disponible dans les listes de plats :"
              :class="{ 'is-invalid': $v.newBuffet.active.$anyError }"
            >
              <b-form-radio v-model="newBuffet.active" name="Actif" value="true">Actif</b-form-radio>
              <b-form-radio v-model="newBuffet.active" name="Désactivé" value="false"
                >Désactivé</b-form-radio
              >
            </b-form-group>
          </div>

        <div v-if="error" style="color: red;" class="text-center mt-2">
          <p v-for="(error, i) of error.graphQLErrors" :key="i">
            {{ error.message }}
          </p>
        </div>

        <b-button class="d-flex mx-auto" variant="primary" size="sm" @click.prevent="createBuffet"
          >Valider</b-button
        >
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import Multiselect from 'vue-multiselect';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      newBuffet: {
        defaultProteins: null,
        defaultCalories: null,
        defaultHydration: null,
        active: 'true',
        dType: null,
        name: '',
        dishes: [],
      },
    };
  },
  components: {
    Multiselect,
  },
  validations: {
    newBuffet: {
      name: {
        required,
        maxLength: maxLength(60),
      },
      dType: {
        required,
      },
      active: {
        required,
      },
      defaultProteins: {
        required,
      },
      defaultCalories: {
        required,
      },
      defaultHydration: {
        required,
      },
      dishes: {
        required,
      },
    },
  },
  methods: {
    async createBuffet() {
      this.$v.newBuffet.$touch();
      if (this.$v.$anyError) return;
      const activeBoolean = this.newBuffet.active === 'true';
      await this.$store.dispatch('createBuffet', {
        defaultCalories: parseInt(this.newBuffet.defaultCalories, 10),
        defaultProteins: parseInt(this.newBuffet.defaultProteins, 10),
        defaultHydration: parseInt(this.newBuffet.defaultHydration, 10),
        active: activeBoolean,
        name: this.newBuffet.name,
        dType: this.newBuffet.dType,
        ehpadId: this.currentEhpad.id,
        dishes: this.newBuffet.dishes,
      }).then((result) => {
        this.error = null;
        this.$v.$reset();
        this.resetData();
        this.$bvModal.hide('new-buffet-modal');
      }).catch((error) => {
        this.error = error;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
    resetData() {
      // For resetting data object
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    customLabel({ name, humanType }) {
      return `${name} (${humanType})`;
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      activeDishesByType: 'activeDishesByType',
    }),
    dTypeOptions() {
      return [
        { value: null, text: 'Type de plat', disabled: true },
        'Autre',
        'Entrée',
        'Plat principal',
        'Fromage',
        'Dessert',
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
