<template>
  <b-container fluid>
    <div v-if="currentEhpadForDashboard">
      <div class="d-flex justify-content-center align-items-center mb-3">
        <span class="text-center">
          <h3 class="m-0" style="display: inline-block;margin: 0;">
            Tableau de bord
          </h3>
        </span>
      </div>
      <hr />

      <div class="d-print-none">
        <li class="text-center" style="list-style-type:none;"><a href="#stats">Tableau de bord des états nutritionnels</a></li>
        <li class="text-center" style="list-style-type:none;"><a href="#measure-control">Indicateurs de controle des mesures</a></li>
        <li class="text-center" style="list-style-type:none;"><a href="#nutrition-state">Suivi des états nutritionnels</a></li>
        <li class="text-center" style="list-style-type:none;"><a href="#average-ratio-nutrition">Moyennes des écarts nutritionnels</a></li>
        <li class="text-center" style="list-style-type:none;"><a href="#ingesta-control">Taux de remplissage des ingesta</a></li>
        <li class="text-center" style="list-style-type:none;"><a href="#average-contants">Moyennes des constantes</a></li>
        <li class="text-center" style="list-style-type:none;" v-if="currentUser.role == 'administrateur' || currentUser.role == 'intendance'"><a href="#average-prices">Moyennes des prix</a></li>
        <hr />
      </div>

      <a id="stats"></a>
      <h5 class="text-center font-weight-bold">Tableau de bord des états nutritionnels</h5>
      <app-stats></app-stats>
      <hr class="mt-5 mb-4"/>

      <a id="measure-control"></a>
      <h5 class="text-center font-weight-bold">Indicateurs de controle des mesures</h5>
      <h6 class="text-center">Les Constantes</h6>
      <b-container fluid v-if="currentEhpadForDashboard.constantsControl">
        <b-row class="justify-content-center">
          <b-col
            xs="8"
            md="6"
            lg="4"
            xl="3"
            v-for="(elements, key, index) in currentEhpadForDashboard.constantsControl"
            v-if="elements.number_of_residents !== 0"
            :key="index"
            class="p-3 m-2 measure-control-card"
          >
            <h6 class="text-center" v-html="coloredDiagHtml(name(key))"></h6>

            <span class="text-center d-block" style="color: green; font-size: small;">{{ elements.number_of_residents }} résidents</span>
            <hr />
            <b-row>
              <template v-for="(element, key) in elements">
                <div class="mx-auto text-center my-3" v-if="key != 'number_of_residents'">
                    <span> {{ name(key) }}</span>
                    <pie-chart
                      suffix="%"
                      height="150px"
                      width="200px"
                      :legend="false"
                      :colors="['#32B796', '#EE5F5B']"
                      :library="options"
                      :data="[['Dans les délais', element.in_delay], ['Hors délais', element.out_of_delay ]]"
                    ></pie-chart>
                </div>
              </template>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <hr class="mt-5 mb-4"/>
      <h6 class="text-center">Les Tests des résidents</h6>
      <b-container fluid v-if="currentEhpadForDashboard.testTypesControl">
        <b-row class="justify-content-center">
          <b-col
            xs="8"
            md="6"
            lg="4"
            xl="3"
            v-for="(elements, key, index) in currentEhpadForDashboard.testTypesControl"
            v-if="elements.number_of_residents !== 0"
            :key="index"
            class="p-3 m-2 measure-control-card"
          >
            <h6 class="text-center" v-html="coloredDiagHtml(name(key))"></h6>

            <span class="text-center d-block" style="color: green; font-size: small;">{{ elements.number_of_residents }} résidents</span>
            <hr />
            <b-row>
              <template v-for="(element, key) in elements">
                <div class="mx-auto text-center mb-3" v-if="key != 'number_of_residents'">
                    <span class="mx-1" style="font-size: small;">{{ key }}</span>
                    <pie-chart
                      class="mx-auto"
                      suffix="%"
                      height="100px"
                      width="130px"
                      :legend="false"
                      :colors="['#32B796', '#EE5F5B']"
                      :library="options"
                      :data="[['Dans les délais', element.in_delay], ['Hors délais', element.out_of_delay ]]"
                    ></pie-chart>
                </div>
              </template>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <hr class="mt-5 mb-4"/>

      <a id="nutrition-state"></a>
      <span class="d-flex justify-content-center">
        <h5 class="text-center font-weight-bold">Suivi des états nutritionnels</h5>
        <b-button class="mb-2 ml-4" size="sm" variant="primary" :to="{ path: diagsHistory() }" target="_blank"
          v-b-tooltip.hover.v-primary title="Exporter les données"
        >
          <i class="far fa-file-excel" />
        </b-button>
      </span>

      <template>
        <div v-for="stateType, index in stateTypes">
          <app-diagnostics-chart
            :stateType="stateType"
          />
          <!-- Last element ? -->
          <hr v-if="index != stateTypes.length - 1" />
          <hr v-if="index == stateTypes.length - 1" class="mt-5 mb-4"/>
        </div>
      </template>

      <a id="average-ratio-nutrition"></a>
      <span class="d-flex justify-content-center">
        <h5 class="font-weight-bold">Moyennes des écarts nutritionnels</h5>
        <i
          class="fas fa-exclamation-triangle mt-1 ml-2"
          style="color: #5bc0de;"
          v-b-tooltip.hover.v-primary
          title="Vous pouvez cliquer sur un point du graphique pour avoir le détail du jour"
        />
      </span>
      <app-average-ratio-nutrition-chart/>
      <hr class="mt-5 mb-4"/>

      <a id="ingesta-control"></a>
      <span class="d-flex justify-content-center">
        <h5 class="font-weight-bold">Taux de remplissage des ingesta</h5>
        <i
          class="fas fa-exclamation-triangle mt-1 ml-2"
          style="color: #5bc0de;"
          v-b-tooltip.hover.v-primary
          title="Vous pouvez cliquer sur un point du graphique pour avoir le détail du jour"
        />
      </span>
      <app-ehpad-ingesta-control-chart/>
      <hr class="mt-5 mb-4"/>

      <a id="average-contants"></a>
      <h5 class="text-center font-weight-bold">Moyennes des constantes</h5>
      <template>
        <div v-for="(constant, index) in constants">

            <app-constants-average-chart
              :constant="constant"
            />

<!-- Last element ? -->
            <hr v-if="index != constants.length - 1" />
            <hr v-if="index == constants.length - 1" class="mt-5 mb-4"/>

        </div>
      </template>

      <div v-if="currentUser.role == 'administrateur' || currentUser.role == 'intendance'">
        <!-- Same Component, Differents queries : -->
        <a id="average-prices"></a>
        <h5 class="text-center font-weight-bold">Moyenne des prix par jour</h5>
        <h5 class="text-center">Menu global :</h5>
        <app-prices-chart />
        <hr>
        <h5 class="text-center">Menus avec spécificités des résidents :</h5>
        <app-average-residents-prices-chart />
        <hr>

        <h5 class="text-center font-weight-bold">Moyenne des prix par jour et par repas</h5>
        <h5 class="text-center">Menu global :</h5>
        <app-prices-chart-by-meal-categories />
        <hr>
        <h5 class="text-center">Menus avec spécificités des résidents :</h5>
        <app-average-residents-prices-chart-by-meal-categories />
      </div>

    </div>

    <div class="text-center" style="color: #469AE0;" v-else-if="currentEhpadForDashboard === null">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement...
      </h2>
    </div>

  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { coloredDiagHtml } from '../../utilities/filters';
import DiagnosticsChart from '../../components/dashboard/DiagnosticsChart.vue';
import PricesChart from '../../components/dashboard/PricesChart.vue';
import PricesChartByMealCategories from '../../components/dashboard/PricesChartByMealCategories.vue';
import AverageResidentsPricesChart from '../../components/dashboard/AverageResidentsPricesChart.vue';
import AverageResidentsPricesChartByMealCategories from '../../components/dashboard/AverageResidentsPricesChartByMealCategories.vue';
import ConstantsAverageChart from '../../components/dashboard/ConstantsAverageChart.vue';
import AverageRatioNutritionChart from '../../components/dashboard/AverageRatioNutritionChart.vue';
import EhpadIngestaControlChart from '../../components/dashboard/EhpadIngestaControlChart.vue';
import Stats from '../../components/dashboard/Stats.vue';

export default {
  name: 'EhpadDashboard',
  components: {
    appDiagnosticsChart: DiagnosticsChart,
    appPricesChart: PricesChart,
    appPricesChartByMealCategories: PricesChartByMealCategories,
    appAverageResidentsPricesChart: AverageResidentsPricesChart,
    appAverageResidentsPricesChartByMealCategories: AverageResidentsPricesChartByMealCategories,
    appConstantsAverageChart: ConstantsAverageChart,
    appAverageRatioNutritionChart: AverageRatioNutritionChart,
    appStats: Stats,
    appEhpadIngestaControlChart: EhpadIngestaControlChart,
  },
  data() {
    return {
      options: {
        plugins: {
          datalabels: {
					  color: 'white',
          },
        },
      },
      constants: [
        { name: 'weight', title: 'Poids', color: '#ff6347' },
        { name: 'crp', title: 'Crp', color: '#A9A9A9' },
        { name: 'albumine', title: 'Albumine', color: '#f08080' },
        { name: 'mna', title: 'Mna', color: '#FDB631' },
      ],
      stateTypes: ['state', 'nri_state', 'has2007_state', 'has2021_state'],
      moderate_undernutrition: {
        name: 'Dénutrition modérée',
      },
      severe_undernutrition: {
        name: 'Dénutrition sévère',
      },
      high_risk: {
        name: 'A risque élevé',
      },
      default_undernutrition: {
        name: 'Autres états nutritionnels',
      },
      resident_total: {
        name: 'Total du suivi des mesures',
      },
      weight: {
        name: 'Poids',
      },
      mna: {
        name: 'MNA',
      },
      albumine: {
        name: 'Albumine',
      },
      crp: {
        name: 'CRP',
      },
      diagnostic: {
        name: 'Diagnostics',
      },
    };
  },
  methods: {
    ...mapActions(['findEhpad']),
    coloredDiagHtml,
    name(el) {
      return eval(`this.${el}.name`);
    },
    diagsHistory() {
      return `/ehpads/${this.currentEhpad.shortname}/diags_history/download.xlsx`;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'user',
      currentEhpad: 'ehpad',
      currentEhpadForDashboard: 'ehpadForDashboard',
    }),
  },
  created() {
    moment.locale('fr-FR');
  },
  watch: {
    currentEhpad: {
      handler() {
        if (this.currentEhpad !== null) {
          this.findEhpad(this.currentEhpad.id);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>

.measure-control-card {
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

:target {
  display: block;
  position: relative;
  top: -11.5rem;
  visibility: hidden;
}

</style>
