<template v-on:scroll.passive="this.handleScroll">
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <b-button variant="primary" :to="{ path: formatButtonLink('prev') }" @click="loading = true;menuEditState = false">
        Semaine précédente
      </b-button>
      <b-col>
        <b-row align-h="center">
            <span class="text-center">
              <h3 class="m-0" style="display: inline-block;margin: 0;">
                Semaine {{ addNumPadding(this.week) }} ({{ formattedDate }})
              </h3>
              <b-button class="mb-2 ml-4" size="sm" variant="primary" :to="{ path: pdf() }" target="_blank"
                v-b-tooltip.hover.v-primary title="Exporter en PDF"
              >
                <i class="fas fa-print" />
              </b-button>
            </span>

        </b-row>
        <b-row align-h="center">
            <span class="text-center">
              <b-button class="mb-2" variant="primary" size="sm" v-b-modal.new-dish-modal>
              <i class="fas fa-plus" />
                  Ajouter un nouveau plat
              </b-button>
              <b-button class="mb-2 ml-4" variant="primary" size="sm" v-if="canEditMenu()" @click="menuEditState = true">
              <i class="" />
                  Modifier le menu
              </b-button>
            </span>

        </b-row>
      </b-col>

      <b-button variant="primary" :to="{ path: formatButtonLink('next') }" @click="loading = true;menuEditState = false">
        Semaine suivante
      </b-button>

    </div>

    <div class="text-center" v-if="loading">
      <h4>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement...
      </h4>
    </div>

    <div class="text-center"  v-else-if="menus.length === 0">
      <h5>
        Aucun menu pour cette date
      </h5>
      <p> Les menus ne sont pas générés avant le {{ dateFormatterFull(currentEhpad.startingDate) }}</p>
    </div>

    <b-container id="container_menu-week" fluid v-else>
      <b-row>
        <app-menu-card
          v-for="menu in menus"
          :key="menu.id"
          :menu="menu"
          :menuEditState="menuEditState"
        />
      </b-row>
    </b-container>
    <app-new-dish-modal
      id="new-dish-modal"
      hide-footer
    />

        <div id="stickyAddDishBtn" class="d-none">
          <b-button class="mb-2 ml-4 fixedElementRight" variant="primary" size="sm" v-b-modal.new-dish-modal>
          <i class="fas fa-plus" />
              Ajouter un nouveau plat
          </b-button>
    </div>

  </div>
</template>

<script>
import moment from 'moment';

import { mapActions, mapGetters } from 'vuex';
import { FETCH_MENUS } from '../../graphql/menus/queries';

import MenuCard from '../../components/menus/Card.vue';
import EventBus from '../../utilities/event_bus';
import NewDishModal from '../../components/dishes/NewDishModal.vue';
import { addNumPadding, dateFormatterFull } from '../../utilities/filters';

export default {
  props: {
    year: {
      type: String,
      required: true,
    },
    week: {
      type: String,
      required: true,
    },
  },
  components: {
    appMenuCard: MenuCard,
    appNewDishModal: NewDishModal,
  },
  data() {
    return {
      menuEditState: false,
      loading: true,
    };
  },
  apollo: {
    menus: {
      query: FETCH_MENUS,
      variables() {
        return {
          ehpadId: this.currentEhpad.id,
          year: parseInt(this.year, 10),
          week: parseInt(this.week, 10),
        };
      },
      fetchPolicy: 'network-only',
      result() {
        this.loading = false;
      },
    },
  },
  created() {
    moment.locale('fr-FR');
    window.addEventListener('scroll', this.handleScroll);
    this.setDishes(this.currentEhpad.id);
    this.setBuffets(this.currentEhpad.id);
    EventBus.$on('refetch-menus', async () => {
      await this.$apollo.queries.menus.refetch();
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions(['setDishes', 'setBuffets']),
    addNumPadding,
    dateFormatterFull,
    pdf() {
      return `/ehpads/${this.currentEhpad.shortname}/menus/${this.year}/${this.week}/pdf-menu.pdf`;
    },
    pdftest() {
      return `/ehpads/${this.currentEhpad.shortname}/menus/${this.year}/${this.week}/pdf-menu.pdf?debug`;
    },
    canEditMenu() {
      if (this.currentEhpad.allSettings.configuration.menus_config.can_edit_in_past) return true;
      if (this.monday() > moment().subtract(3, 'week')) return true;
      return false;
    },
    monday() {
      return moment()
        .year(this.year)
        .isoWeek(this.week)
        .startOf('isoWeek')
        .day('Lundi');
    },
    initNextButton() {
      const monday = this.monday();
      const nextMonday = monday.add(1, 'isoWeek');
      const week = nextMonday.isoWeek();
      const year = nextMonday.year();
      return { week, year };
    },
    initPrevButton() {
      const monday = this.monday();
      const prevMonday = monday.subtract(1, 'isoWeek');
      const week = prevMonday.isoWeek();
      const year = prevMonday.year();
      return { week, year };
    },
    formatButtonLink(type) {
      let week;
      let year;
      let data;
      if (type === 'next') {
        data = this.initNextButton();
        week = data.week;
        year = data.year;
      } else {
        data = this.initPrevButton();
        week = data.week;
        year = data.year;
      }
      return `/ehpads/${this.currentEhpad.shortname}/menus/${year}/${week}`;
    },
    handleScroll() {
      const stickyBtn = document.getElementById('stickyAddDishBtn');
      const y = window.pageYOffset;
      if (y <= 250) {
        stickyBtn.className = 'd-none';
      } else {
        stickyBtn.className = '';
      }
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    formattedDate() {
      const sunday = this.monday().weekday(7);
      return `du ${this.monday().date()} ${this.monday().format('MMMM')} au ${sunday.date()} ${sunday.format('MMMM')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.fixedElementRight {
  position: fixed;
  top: 11.5rem;
  right: 2rem;
  z-index: 100;
}
</style>
