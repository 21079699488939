<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Nouvelle enquête
        </h3>
      </span>
    </div>

    <app-new-survey-form />

  </div>
</template>

<script>
import NewSurveyForm from '../../components/surveys/NewSurveyForm.vue';

export default {
  components: {
    appNewSurveyForm: NewSurveyForm,
  },
};

</script>

<style>

</style>
