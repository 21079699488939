import { render, staticRenderFns } from "./SidebarInfo.vue?vue&type=template&id=d3fbd576&scoped=true"
import script from "./SidebarInfo.vue?vue&type=script&lang=js"
export * from "./SidebarInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3fbd576",
  null
  
)

export default component.exports