<template>
  <b-container fluid>
    <div v-if="resident !== null">
      <app-resident-nav-bar
        :targetRoute="'diagnostics'"
      />
      <div>
        <hr />
        <div class="d-print-none">
          <li class="text-center" style="list-style-type:none;"><a href="#resident-infos">Informations sur le résident et saisie des constantes</a></li>
          <li class="text-center" style="list-style-type:none;"><a href="#auto-diag">États nutritionnels proposés par le logiciel</a></li>
          <li class="text-center" style="list-style-type:none;"><a href="#individual-program">Programme Individuel</a></li>
          <li class="text-center" style="list-style-type:none;"><a href="#constant-chart">Courbes des constantes</a></li>
          <li class="text-center" style="list-style-type:none;"><a href="#diag-history">Historique des états nutritionnels et commentaires</a></li>
          <li class="text-center" style="list-style-type:none;"><a href="#resident-tests">Les Tests</a></li>
          <li class="text-center" style="list-style-type:none;"><a href="#resident-tests-history">Historique des tests et courbes</a></li>
          <hr />
        </div>

        <a id="resident-infos"></a>
        <b-row align-h="center">
          <b-col cols="12" xl="4">
            <app-resident-infos-form
              :resident="resident"
              v-on:updated-resident="rangeForConstantsChart = { months: null, year: null };"
            />
          </b-col>

          <b-col class="mt-3 d-print-none" cols="12" xl="5">
            <app-resident-constants-form
              :resident="resident"
              v-on:updated-resident="rangeForConstantsChart = { months: null, year: null };"
            />

            <app-resident-general-comment-form
              :resident="resident"
            />
          </b-col>

        </b-row>
        <hr class="mb-4"/>

        <a id="auto-diag"></a>
        <app-resident-diags-form-alerts
            :resident="resident"
            v-on:updated-resident="rangeForConstantsChart = { months: null, year: null };"
          />
        <hr class="mt-5 mb-4"/>

        <a id="individual-program"></a>
        <app-individual-program-form
            :resident="resident"
          />
        <hr class="mt-5 mb-4"/>


        <a id="constant-chart"></a>
        <h5 class="text-center font-weight-bold">Courbes des constantes</h5>
          <b-row>
            <b-col class="col-2">Intervalle </b-col>
            <b-col class="col-2">
              <div>
                <b-form-select
                  v-model="rangeForConstantsChart"
                  :options="chartRangeOptions"
                  @change="updateResidentData"
                />
              </div>
            </b-col>
          </b-row>

          <div class="text-center" style="color: #469AE0;" v-if="chartLoading === true">
            <h2>
              <i class="fa fa-spin fa-circle-notch" />
              Chargement des graphiques...
            </h2>
          </div>
          <app-resident-chart v-else
            :residentHeight="resident.height"
            :residentWeights="resident.weights"
            :residentMnas="resident.mnas"
            :residentCrps="resident.crps"
            :residentAlbumines="resident.albumines"
          />
        <hr class="mt-5 mb-4"/>

        <a id="diag-history"></a>
        <h5 class="text-center font-weight-bold">États nutritionnels et commentaires</h5>
        <app-resident-diagnostics-chart
          :resident="resident"
        />
        <p class="text-center font-weight-bold mb-0">1 = Dénutrition Sévère, 2 = Dénutrition modérée, 3 = A risque élevé, 4 = Autres états nutritionnels</p>
        <p class="small text-center mb-0" style="color: #BFBFBF;">Une courbe qui monte est une amélioration de l'état nutritionnel du résident</p>
        <p class="small text-center mb-3" style="color: #BFBFBF;">Une courbe qui descend est une dégradation de l'état nutritionnel du résident</p>
        <app-resident-diag-log
          :residentDiagnostics="resident.diagnostics"
        />

        <hr class="mt-5 mb-4"/>

        <a id="resident-tests"></a>
        <h5 class="text-center font-weight-bold">Les Tests</h5>
        <b-row class="justify-content-around">

          <b-col cols="12" lg="8" class="mt-2">
            <app-resident-test-form
              :resident="resident"
            />
          </b-col>

          <b-col cols="12" lg="4" class="mt-2" v-if="this.resident.expiredTestTypes.length !== 0">
            <h6 class="text-center mb-2">Liste des Tests à passer ou ayant expiré</h6>
            <li
              v-for="(expiredTestType) in this.resident.expiredTestTypes"
              :key="expiredTestType.id"
              class="text-center"
            >
              <b style="color: red;">{{expiredTestType.name}}</b>
            </li>
          </b-col>

        </b-row>

        <app-expired-tests-alert-modal
          :expiredTests="resident.expiredTestTypes"
        />

        <hr class="mb-4"/>

        <a id="resident-tests-history"></a>
        <h6 class="text-center mb-3">
          Historique des tests et courbes
        </h6>
        <app-resident-tests-table
          :resident="resident"
        />
        <hr class="mb-4"/>
        <app-resident-tests-charts
          :resident="resident"
        />
        <hr class="mb-4"/>
      </div>
    </div>

    <div class="text-center" style="color: #469AE0;" v-else-if="resident === null">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement...
      </h2>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import ResidentChart from '../../components/residents/ResidentChart.vue';
import ResidentInfosForm from '../../components/residents/ResidentInfosForm.vue';
import ResidentConstantsForm from '../../components/residents/ResidentConstantsForm.vue';
import ResidentGeneralCommentForm from '../../components/residents/ResidentGeneralCommentForm.vue';
import IndividualProgramForm from '../../components/residents/forms/IndividualProgram.vue';
import ResidentDiagsFormAlerts from '../../components/residents/ResidentDiagsFormAlerts.vue';
import ResidentDiagLog from '../../components/residents/ResidentDiagLog.vue';
import ResidentDiagnosticsChart from '../../components/residents/ResidentDiagnosticsChart.vue';
import ResidentNavBar from '../../components/residents/ResidentNavBar.vue';
import ResidentTestsTable from '../../components/residents/ResidentTestsTable.vue';
import ResidentTestForm from '../../components/residents/ResidentTestForm.vue';
import ResidentTestsCharts from '../../components/residents/ResidentTestsCharts.vue';
import ExpiredTestsAlertModal from '../../components/residents/ExpiredTestsAlertModal.vue';

export default {
  props: ['residentId'],
  components: {
    appResidentChart: ResidentChart,
    appResidentDiagLog: ResidentDiagLog,
    appResidentInfosForm: ResidentInfosForm,
    appResidentConstantsForm: ResidentConstantsForm,
    appResidentGeneralCommentForm: ResidentGeneralCommentForm,
    appIndividualProgramForm: IndividualProgramForm,
    appResidentDiagsFormAlerts: ResidentDiagsFormAlerts,
    appResidentDiagnosticsChart: ResidentDiagnosticsChart,
    appResidentTestsTable: ResidentTestsTable,
    appResidentTestForm: ResidentTestForm,
    appResidentTestsCharts: ResidentTestsCharts,
    appExpiredTestsAlertModal: ExpiredTestsAlertModal,
    appResidentNavBar: ResidentNavBar,
  },
  data() {
    return {
      rangeForConstantsChart: { months: null, year: null },
      chartLoading: false,
    };
  },
  created() {
    // Pour ChartKick :
    moment.locale('fr-FR');
    this.findResident({
      id: this.residentId,
    });
  },
  watch: {
    residentId: {
      handler() {
        this.findResident({
          id: this.residentId,
        });
        this.updateResidentData();
      },
    },
    // resident: {
    //   handler() {
    //     if (this.resident && this.resident.expiredTestTypes.length !== 0) {
    //       setTimeout(() => { this.$bvModal.show('expired-tests-alert-modal'); }, 1000);
    //     }
    //   },
    // },
  },
  computed: {
    ...mapGetters({
      resident: 'resident',
      currentEhpad: 'ehpad',
    }),
    chartRangeOptions() {
      return [
        { value: { months: null, year: null }, text: '3 mois glissants' },
        { value: { months: 6, year: null }, text: '6 mois glissants' },
        { value: { months: 12, year: null }, text: '12 mois glissants' },
        { value: { months: null, year: moment().year() - 2 }, text: moment().year() - 2 },
        { value: { months: null, year: moment().year() - 1 }, text: moment().year() - 1 },
        { value: { months: null, year: moment().year() }, text: moment().year() },
      ];
    },
  },
  methods: {
    ...mapActions(['findResident']),
    async updateResidentData() {
      this.chartLoading = true;
      await this.$store.dispatch('findResident', {
        id: this.residentId,
        months: this.rangeForConstantsChart.months,
        year: this.rangeForConstantsChart.year,
      });
      this.chartLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>

:target {
  display: block;
  position: relative;
  top: -11.5rem;
  visibility: hidden;
}

</style>
