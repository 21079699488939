import { render, staticRenderFns } from "./ResidentGustatoryCategoryItem.vue?vue&type=template&id=7418aa0b&scoped=true"
import script from "./ResidentGustatoryCategoryItem.vue?vue&type=script&lang=js"
export * from "./ResidentGustatoryCategoryItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7418aa0b",
  null
  
)

export default component.exports