<template>
  <b-modal v-bind="$attrs" size="lg" class="col-12 text-center">
    <template #modal-title>
      Modifier un buffet
      <b-button
        variant="primary"
        size="sm"
        style="font-size: xx-small;"
        class="ml-2"
        @click="advancedOptions = !advancedOptions">
        Options avancées
      </b-button>
    </template>
    <b-container fluid>
      <b-form>
          <b-row>
            <b-col cols="2">
              <label for="inline-form-input-name" class="pr-1 text-nowrap">Nom :</label>
            </b-col>
            <b-col cols="10">
                <b-form-input
                  id="inline-form-input-name"
                  size="sm"
                  type="text"
                  v-model="editableBuffet.name"
                  :class="{ 'is-invalid': $v.editableBuffet.name.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise (60 caractères max)</b-form-invalid-feedback>
            </b-col>
            <span style="font-size: smaller;" class="text-center m-2"><i class="fas fa-exclamation-triangle" style="color: red;"/>
              <i>Attention, renommer un buffet le modifiera également dans les menus existants</i>
            <i class="fas fa-exclamation-triangle" style="color: red;"/></span>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-cal" class="pr-4 text-nowrap">Calories (kcal) minimum servies: </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  id="inline-form-input-cal"
                  size="sm"
                  type="number"
                  min="0"
                  v-model="editableBuffet.defaultCalories"
                  :class="{'is-invalid': $v.editableBuffet.defaultCalories.$error}"
                />
                <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-prot" class="pr-4 text-nowrap">Protéines (g) minimum servies: </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  id="inline-form-input-prot"
                  size="sm"
                  type="number"
                  min="0"
                  v-model="editableBuffet.defaultProteins"
                  :class="{'is-invalid': $v.editableBuffet.defaultProteins.$error}"
                />
                <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-hydr" class="pr-4 text-nowrap">Apports hydriques (ml) minimum servies: </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  id="inline-form-input-hydr"
                  size="sm"
                  type="number"
                  min="0"
                  v-model="editableBuffet.defaultHydration"
                  :class="{'is-invalid': $v.editableBuffet.defaultHydration.$error}"
                />
                <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-option" class="pr-4 text-nowrap">Catégorie : </label>
            </b-col>
            <b-col cols="6">
              <b-form-select
                id="inline-form-input-option"
                v-model="editableBuffet.dType"
                :options="dTypeOptions"
                :class="{ 'is-invalid': $v.editableBuffet.dType.$anyError }"
              />
              <b-form-invalid-feedback>Type de plat requis</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="4">
              <label for="inline-form-input-option" class="pr-4 text-nowrap">Plats : </label>
            </b-col>
            <b-col cols="8">
              <multiselect
                v-model="editableBuffet.dishes"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :options="activeDishesByType"
                :custom-label="customLabel"
                placeholder="Rechercher/Sélectionner un plat"
                label="name"
                track-by="name"
                selectLabel=""
                deselectLabel=""
                selectedLabel="Sélectionné"
                :class="{ 'is-invalid': $v.editableBuffet.dishes.$anyError }"
              >
                <template #noResult>
                  <span>Pas de résultat correspondant</span>
                </template>
              </multiselect>
              <b-form-invalid-feedback>Un buffet doit contenir au moins un plat</b-form-invalid-feedback>
            </b-col>
          </b-row>

        <b-form-group class="mt-2" label="Disponible dans les listes de plats :">
          <b-form-radio v-model="editableBuffet.active" name="Actif" value=true>Actif</b-form-radio>
          <b-form-radio v-model="editableBuffet.active" name="Désactivé" value=false>Désactivé</b-form-radio>
        </b-form-group>


        <div v-if="advancedOptions">
          <label for="changePastForm">Appliquer la modification aux menus passés ?
          <i class="fas fa-exclamation-triangle"
            style="color: red;"
            v-b-tooltip.hover.v-primary
            title="Si vous choisissez 'Oui' ce buffet sera modifié dans tout les menus existants (y compris dans les menus passés).
            Si vous choisissez 'Non' les modifications s'appliqueront uniquement à partir de ce jour dans les menus futurs."
          />
          </label>
          <b-form-group class="mt-2" id="changePastForm">
            <b-form-radio v-model="changePast" name="Oui" value=true>Oui</b-form-radio>
            <b-form-radio v-model="changePast" name="Non" value=false>Non</b-form-radio>
          </b-form-group>
        </div>

        <div v-if="error" style="color: red;" class="text-center mt-2">
          <p v-for="(error, i) of error.graphQLErrors" :key="i">
            {{ error.message }}
          </p>
        </div>

        <b-button class="d-flex mx-auto" variant="primary" size="sm" @click.prevent="updateBuffet">
          Valider
        </b-button>
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import Multiselect from 'vue-multiselect';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  props: ['buffet'],
  mixins: [validationMixin],
  data() {
    return {
      editableBuffet: null,
      error: null,
      advancedOptions: false,
    };
  },
  components: {
    Multiselect,
  },
  created() {
    this.duplicateBuffet(this.buffet);
  },
  validations: {
    editableBuffet: {
      defaultCalories: {
        required,
      },
      defaultProteins: {
        required,
      },
      defaultHydration: {
        required,
      },
      active: {
        required,
      },
      name: {
        required,
        maxLength: maxLength(60),
      },
      dType: {
        required,
      },
      dishes: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      activeDishesByType: 'activeDishesByType',
    }),
    dTypeOptions() {
      return [
        { value: null, text: 'Type de plat', disabled: true },
        { value: 'other', text: 'Autre' },
        { value: 'starter', text: 'Entrée' },
        { value: 'main_course', text: 'Plat principal' },
        { value: 'dairy', text: 'Fromage' },
        { value: 'desert', text: 'Dessert' },
      ];
    },
  },
  methods: {
    duplicateBuffet(buffet) {
      this.editableBuffet = { ...buffet };
    },
    customLabel({ name, humanType }) {
      return `${name} (${humanType})`;
    },
    async updateBuffet() {
      this.$v.editableBuffet.$touch();
      if (this.$v.$anyError) return;
      const activeBoolean = (this.editableBuffet.active === 'true' || this.editableBuffet.active === true);
      const changePastBoolean = (this.changePast === 'true' || this.changePast === true);
      await this.$store.dispatch('updateBuffet', {
      // const updatedBuffet = await this.$store.dispatch('updateBuffet', {
        id: this.editableBuffet.id,
        name: this.editableBuffet.name,
        dType: this.editableBuffet.dType,
        ehpadId: this.currentEhpad.id,
        dishes: this.editableBuffet.dishes,
        defaultCalories: parseInt(this.editableBuffet.defaultCalories, 10),
        defaultProteins: parseInt(this.editableBuffet.defaultProteins, 10),
        defaultHydration: parseInt(this.editableBuffet.defaultHydration, 10),
        active: activeBoolean,
        changePast: changePastBoolean,
      }).then((result) => {
        this.error = null;
        this.$v.$reset();
        this.$bvModal.hide('info-buffet-modal');
      }).catch((error) => {
        this.error = error;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
