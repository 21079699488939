<template>
  <b-container fluid>
    <div v-if="resident !== null">
      <app-resident-nav-bar :targetRoute="'nutrition'" />
      <div>
        <hr />
        <div class="d-print-none">
          <li class="text-center" style="list-style-type:none;">
            <a href="#resident-infos">Informations sur le résident</a>
          </li>
          <li class="text-center" style="list-style-type:none;">
            <a href="#nutrition-state">Rapport d'état nutritionnel</a>
          </li>
          <li class="text-center" style="list-style-type:none;">
            <a href="#ingesta-control">Taux de remplissage des ingesta</a>
          </li>
          <li class="text-center" style="list-style-type:none;">
            <a href="#menus-config">Configuration des menus du résident</a>
          </li>
          <hr />
        </div>

        <a id="resident-infos"></a>
        <b-row>
          <b-col class="col-xl-3 col-md-6 col-sm-12">
            <h5>Informations</h5>
            <dl class="row">
              <dd class="col-4 small">Taille :</dd>
              <dt class="col-8">
                <div>
                  <label>{{ resident.height }} cm</label>
                </div>
              </dt>

              <dd class="col-4 small">Poids actuel :</dd>
              <dt class="col-8">
                <div>
                  <label>{{ resident.lastWeight.value }} Kg</label>
                </div>
              </dt>

              <dd class="col-4 small">Poids de forme:</dd>
              <dt class="col-8">
                <div>
                  <label>{{ resident.idealWeight }} Kg</label>
                </div>
              </dt>

              <dd class="col-4 small">Lit :</dd>
              <dt class="col-8">
                <div>
                  <label>{{ selectedRoomLabel }}</label>
                </div>
              </dt>
            </dl>
          </b-col>

          <b-col class="col-xl-3 col-md-6 col-sm-12">
            <app-resident-nutrition-goal-form :resident="resident" :readonly="false" />
          </b-col>

          <b-col class="col-xl-3 col-md-6 col-sm-12">
            <app-resident-nutrition-details :resident="resident" :readonly="false" />
          </b-col>

          <app-resident-nutritional-infos-modal
            id="nutrional-infos-modal"
            :resident="resident"
            hide-footer
          />

          <b-col class="col-xl-3 col-md-6 col-sm-12">
            <app-resident-gustatory-form :resident="resident" />
          </b-col>
        </b-row>
        <hr class="mb-4" />

        <a id="nutrition-state"></a>
        <h5 class="text-center font-weight-bold">Rapport d'état nutritionnel</h5>

        <app-resident-nutrition-chart
          :residentId="residentId"
          :nutritionGoals="resident.nutritionGoals"
        />
        <hr class="mt-5 mb-4" />

        <a id="ingesta-control"></a>
        <span class="d-flex justify-content-center">
          <h5 class="font-weight-bold">Taux de remplissage des ingesta</h5>
          <i
            class="fas fa-exclamation-triangle mt-1 ml-2"
            style="color: #5bc0de;"
            v-b-tooltip.hover.v-primary
            title="Vous pouvez cliquer sur un point du graphique pour avoir le détail du jour"
          />
        </span>
        <app-resident-ingesta-control-chart :residentId="residentId" />
        <hr class="mt-5 mb-4" />

        <a id="menus-config"></a>
        <h5 class="text-center font-weight-bold d-print-none">Configuration des menus</h5>
        <b-row class="d-print-none">
          <b-col>
            <h5 class="text-center">Options des repas</h5>
            <p class="text-center">
              Les modifications sont appliquées à partir du jour de leur saisie
            </p>
            <p class="text-center">
              <i class="fas fa-exclamation-triangle" style="color: #5bc0de;" /><i>
                Vous pouvez utiliser le menu global ou uniquement le menu spécifique du résident.
                Vous pouvez également utiliser le menu global et ajouter en plus des plats permanents aux repas du résident.
              </i>
              <i class="fas fa-exclamation-triangle" style="color: #5bc0de;" />
            </p>

            <b-container fluid>
              <b-row class="text-center justify-content-center">
                <app-resident-replication-settings-form
                  v-for="(mealCategoryHuman, mealCategory) in mealCategories"
                  :key="mealCategory"
                  :mealCategoryHuman="mealCategoryHuman"
                  :mealCategory="mealCategory"
                  :editableResident="editableResident"
                  class="mb-2"
                />
              </b-row>
            </b-container>
          </b-col>

          <b-col>
            <div>
              <h5 class="text-center">Plats permanents du résident</h5>
              <p class="text-center">Les modifications sont appliquées à partir du jour de leur saisie</p>
              <b-container fluid>
                <b-row class="justify-content-center">
                  <app-resident-default-dishes
                    v-for="(mealCategoryHuman, mealCategory) in mealCategories"
                    :key="mealCategory"
                    :mealCategoryHuman="mealCategoryHuman"
                    :mealCategory="mealCategory"
                    class="mb-2"
                  />
                </b-row>
              </b-container>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="text-center" style="color: #469AE0;" v-else-if="resident === null">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement...
      </h2>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import ResidentNutritionChart from '../../components/residents/ResidentNutritionChart.vue';
import ResidentNutritionDetails from '../../components/residents/ResidentNutritionDetails.vue';
import ResidentIngestaControlChart from '../../components/residents/ResidentIngestaControlChart.vue';
import ResidentReplicationSettingsForm from '../../components/residents/ResidentReplicationSettingsForm.vue';
import ResidentDefaultDishes from '../../components/residents/ResidentDefaultDishes.vue';
import ResidentGustatoryForm from '../../components/residents/ResidentGustatoryForm.vue';
import ResidentNutritionGoalForm from '../../components/residents/ResidentNutritionGoalForm.vue';
import ResidentNutritionalInfosModal from '../../components/residents/ResidentNutritionalInfosModal.vue';
import ResidentNavBar from '../../components/residents/ResidentNavBar.vue';

export default {
  name: 'ShowResident',
  props: ['residentId'],
  components: {
    appResidentDefaultDishes: ResidentDefaultDishes,
    appResidentReplicationSettingsForm: ResidentReplicationSettingsForm,
    appResidentNutritionChart: ResidentNutritionChart,
    appResidentIngestaControlChart: ResidentIngestaControlChart,
    appResidentNutritionDetails: ResidentNutritionDetails,
    appResidentGustatoryForm: ResidentGustatoryForm,
    appResidentNutritionGoalForm: ResidentNutritionGoalForm,
    appResidentNutritionalInfosModal: ResidentNutritionalInfosModal,
    appResidentNavBar: ResidentNavBar,
  },
  methods: {
    ...mapActions(['findResident', 'setDishes', 'setBuffets']),
    duplicateResident(resident) {
      this.editableResident = resident;
    },
  },
  created() {
    moment.locale('fr-FR');
    this.findResident({
      id: this.residentId,
    });
    this.setDishes(this.currentEhpad.id);
    this.setBuffets(this.currentEhpad.id);
  },
  watch: {
    residentId: {
      handler() {
        this.findResident({
          id: this.residentId,
        });
      },
    },
    resident: {
      handler() {
        this.duplicateResident(this.resident);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      resident: 'resident',
    }),
    mealCategories() {
      return {
        breakfast: 'Petit déjeuner',
        morning_snack: 'Collation du matin',
        lunch: 'Déjeuner',
        afternoon_snack: 'Goûter',
        dinner: 'Dîner',
        evening_snack: 'Collation du soir',
      };
    },
    selectedRoomLabel() {
      const { room } = this.resident;
      return room ? `N° ${room.number}` : 'Aucun lit assigné';
    },
  },
};
</script>

<style lang="scss" scoped>

:target {
  display: block;
  position: relative;
  top: -11.5rem;
  visibility: hidden;
}

</style>
