import _ from 'lodash';

import apiClient from '../../utilities/api_client';
import { FIND_RESIDENT, FETCH_RESIDENTS } from '../../graphql/residents/queries';
import {
  CREATE_RESIDENT,
  UPDATE_RESIDENT,
  UPDATE_RESIDENT_MEAL_SETTINGS,
  CREATE_RESIDENT_DEFAULT_MEAL_DISH,
  CREATE_RESIDENT_DEFAULT_MEAL_BUFFET,
  DESTROY_RESIDENT_DEFAULT_MEAL_DISH,
  DESTROY_RESIDENT_DEFAULT_MEAL_BUFFET,
} from '../../graphql/residents/mutations';
import { CREATE_NUTRITION_GOAL } from '../../graphql/nutrition_goals/mutations';
import { CREATE_TEXTURE } from '../../graphql/textures/mutations';
import { CREATE_CRP } from '../../graphql/crps/mutations';
import { CREATE_MNA } from '../../graphql/mnas/mutations';
import { CREATE_WEIGHT } from '../../graphql/weights/mutations';
import { CREATE_ALBUMINE } from '../../graphql/albumines/mutations';
import { CREATE_DIAGNOSTIC } from '../../graphql/diagnostics/mutations';
import { CREATE_GUSTATORY_PERCEPTION } from '../../graphql/gustatory_perceptions/mutations';
import { CREATE_RESIDENT_STATUS } from '../../graphql/resident_statuses/mutations';
import { CREATE_RESIDENT_TEST } from '../../graphql/resident_tests/mutations';
import { CREATE_GENERAL_COMMENT } from '../../graphql/general_comment/mutations';
import { CREATE_INDIVIDUAL_PROGRAM } from '../../graphql/individual_programs/mutations';

const residentState = {
  resident: null,
  residents: [],
};

const mutations = {
  SET_RESIDENTS(state, residents) {
    state.residents = residents;
  },
  SELECT_RESIDENT(state, resident) {
    state.resident = resident;
  },
  ADD_RESIDENT(state, resident) {
    state.residents.push(resident);
  },
  UPDATE_RESIDENT(state, resident) {
    const index = _.findIndex(state.residents, { id: resident.id });
    state.residents.splice(index, 1, resident);
  },
  ADD_NUTRITION_GOAL(state, nutritionGoal) {
    state.resident.lastNutritionGoal = nutritionGoal;
    const nutritionGoalIndex = _.findIndex(state.resident.nutritionGoals, { id: nutritionGoal.id });
    if (nutritionGoalIndex !== null) {
      state.resident.nutritionGoals.splice(nutritionGoalIndex, 1, nutritionGoal);
    } else {
      state.resident.nutritionGoals.push(nutritionGoal);
    }
  },
  ADD_TEXTURE(state, texture) {
    state.resident.lastTexture = texture;
    const textureIndex = _.findIndex(state.resident.textures, { id: texture.id });
    if (textureIndex !== null) {
      state.resident.textures.splice(textureIndex, 1, texture);
    } else {
      state.resident.textures.push(texture);
    }
  },
};

const actions = {
  setResidents: async ({ commit, state }, ehpadId) => {
    state.residents = [];
    const { data } = await apiClient.query({
      query: FETCH_RESIDENTS,
      variables: { ehpadId },
      fetchPolicy: 'network-only',
    });
    commit('SET_RESIDENTS', data.residents);
  },
  findResident: async ({ commit, state }, formData) => {
    state.resident = null;
    const { data } = await apiClient.query({
      query: FIND_RESIDENT,
      variables: { ...formData },
      fetchPolicy: 'network-only',
    });
    commit('SELECT_RESIDENT', data.findResident);
  },
  createResident: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: CREATE_RESIDENT,
      variables: { ...formData },
    });
    commit('ADD_RESIDENT', data.createResident.resident);
  },
  updateResident: async ({ commit, dispatch }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_RESIDENT,
      variables: { ...formData },
    });
    const { resident } = data.updateResident;
    commit('UPDATE_RESIDENT', resident);
    dispatch('findResident', { id: formData.id });
    return resident;
  },
  createIndividualProgram: async ({ commit, dispatch }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: CREATE_INDIVIDUAL_PROGRAM,
      variables: { ...formData },
    });
    dispatch('findResident', { id: formData.residentId });
  },
  updateResidentMealSettings: async ({ commit, dispatch }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_RESIDENT_MEAL_SETTINGS,
      variables: { ...formData },
    });
    const { resident } = data.updateResidentMealSettings;
    commit('UPDATE_RESIDENT', resident);
    dispatch('findResident', { id: formData.id });
    return resident;
  },
  createWeight: async ({ commit, dispatch }, formData) => {
    await apiClient.mutate({
      mutation: CREATE_WEIGHT,
      variables: { ...formData },
    });
    dispatch('findResident', { id: formData.residentId });
    // Actuellement ces actions sont utilisées mais n'ont pas de MUTATION (il y a un eventbus et un refetch 'manuel', on utilise pas le store pour ca, pas encore) !! Il faut régler ca.
    // On envoi à la mutation le weight bien structurée (id, date, value) et le resident en parametre sous forme d'objet
    // Attention à récupérer les données au create et update (meme si vide) sinon il y aura une erreur au push
    // commit('ADD_WEIGHT', { weight: data.createWeight.weight, residentId: formData.residentId });
  },
  createAlbumine: async ({ commit, dispatch }, formData) => {
    await apiClient.mutate({
      mutation: CREATE_ALBUMINE,
      variables: { ...formData },
    });
    dispatch('findResident', { id: formData.residentId });
  },
  createMna: async ({ commit, dispatch }, formData) => {
    await apiClient.mutate({
      mutation: CREATE_MNA,
      variables: { ...formData },
    });
    dispatch('findResident', { id: formData.residentId });
  },
  createCrp: async ({ commit, dispatch }, formData) => {
    await apiClient.mutate({
      mutation: CREATE_CRP,
      variables: { ...formData },
    });
    dispatch('findResident', { id: formData.residentId });
  },
  createGeneralComment: async ({ commit, dispatch }, formData) => {
    await apiClient.mutate({
      mutation: CREATE_GENERAL_COMMENT,
      variables: { ...formData },
    });
    dispatch('findResident', { id: formData.residentId });
  },
  createDiagnostic: async ({ commit, dispatch }, formData) => {
    await apiClient.mutate({
      mutation: CREATE_DIAGNOSTIC,
      variables: { ...formData },
    });
    dispatch('findResident', { id: formData.residentId });
  },
  async createNutritionGoal({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: CREATE_NUTRITION_GOAL,
      variables: { ...formData },
    });
    const { nutritionGoal } = data.createNutritionGoal;
    commit('ADD_NUTRITION_GOAL', nutritionGoal);
  },
  async createTexture({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: CREATE_TEXTURE,
      variables: { ...formData },
    });
    const { texture } = data.createTexture;
    commit('ADD_TEXTURE', texture);
  },
  createResidentStatus: async ({ commit, dispatch }, formData) => {
    await apiClient.mutate({
      mutation: CREATE_RESIDENT_STATUS,
      variables: { ...formData },
    });
    dispatch('findResident', { id: formData.residentId });
  },
  createResidentTest: async ({ commit, dispatch }, formData) => {
    await apiClient.mutate({
      mutation: CREATE_RESIDENT_TEST,
      variables: { ...formData },
    });
    dispatch('findResident', { id: formData.residentId });
  },
  async createGustatoryPerception({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: CREATE_GUSTATORY_PERCEPTION,
      variables: { ...formData },
    });
  },
  async createResidentDefaultMealDish({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: CREATE_RESIDENT_DEFAULT_MEAL_DISH,
      variables: { ...formData },
    });
    // const { residentDefaultMealDish } = data.createResidentDefaultMealDish;
    // commit('ADD_RESIDENT_DEFAULT_MEAL_DISH', residentDefaultMealDish);
  },
  async destroyResidentDefaultMealDish({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: DESTROY_RESIDENT_DEFAULT_MEAL_DISH,
      variables: { ...formData },
    });
    // const { residentDefaultMealDish } = data.destroyResidentDefaultMealDish;
    // commit('REMOVE_RESIDENT_DEFAULT_MEAL_DISH', residentDefaultMealDish);
  },
  async createResidentDefaultMealBuffet({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: CREATE_RESIDENT_DEFAULT_MEAL_BUFFET,
      variables: { ...formData },
    });
  },
  async destroyResidentDefaultMealBuffet({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: DESTROY_RESIDENT_DEFAULT_MEAL_BUFFET,
      variables: { ...formData },
    });
  },
};

const getters = {
  residentsWithRoom(state) {
    return state.residents.filter((resident) => resident.room !== null);
  },
  residentsWithoutRoom(state) {
    return state.residents.filter((resident) => resident.room === null);
  },
  residents: (state) => state.residents,
  resident: (state) => state.resident,
};

export default {
  state: residentState,
  mutations,
  actions,
  getters,
};
