import gql from 'graphql-tag';

export const UPDATE_TEST_TYPE_SETTINGS = gql`
  mutation UPDATE_TEST_TYPE_SETTINGS($id: ID!, $alertTreshold: Int!, $diagnosticState: String!) {
    updateTestTypeSettings(
      input: {
        id: $id
        alertTreshold: $alertTreshold
        diagnosticState: $diagnosticState
      }
    ) {
      testType {
        id
        name
        description
        url
        isBoolean
        allSettings
      }
    }
  }
`;
