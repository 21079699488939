<template>
  <div class="d-flex justify-content-between align-items-center mb-3" v-if="resident !== null">
    <b-button
      v-if="checkFirstItem"
      variant="primary"
      :to="{ path: formatButtonLink('prev') }"
      size="sm"
    >
      Résident précédent
    </b-button>
    <b-col>
      <b-row align-h="center">
          <div class="d-flex justify-content-center align-items-center top-title">
            <h3 v-html="title"></h3>
            <b-button
              class="mb-2 ml-4 d-print-none"
              variant="primary"
              size="sm"
              :to="{ path: `/ehpads/${currentEhpad.shortname}/resident/${resident.id}/menus/${currentYear}/${currentWeek}` }"
            >
              <i class="far fa-calendar-alt" />
            </b-button>
            <b-button
              v-if="targetRoute != 'diagnostics'"
              class="mb-2 ml-4 d-print-none"
              variant="primary"
              size="sm"
              :to="{ path: `/ehpads/${currentEhpad.shortname}/resident/${resident.id}/diagnostics` }"
            >
              <i class="fas fa-chart-line" />
            </b-button>
            <b-button
              v-if="targetRoute != 'nutrition'"
              class="mb-2 ml-4 d-print-none"
              variant="primary"
              size="sm"
              :to="{ path: `/ehpads/${currentEhpad.shortname}/resident/${resident.id}/nutrition` }"
            >
              <i class="fas fa-utensils" />
            </b-button>
            <b-button
              v-if="targetRoute != 'bilan'"
              class="mb-2 ml-4 d-print-none"
              variant="primary"
              size="sm"
              :to="{ path: `/ehpads/${currentEhpad.shortname}/resident/${resident.id}/bilan` }"
            >
              <i class="fas fa-print" />
            </b-button>
          </div>
      </b-row>
    </b-col>

    <b-button
      v-if="checkLastItem"
      variant="primary"
      :to="{ path: formatButtonLink('next') }"
      size="sm"
    >
      Résident suivant
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import { coloredDiagHtml } from '../../utilities/filters';

export default {
  props: ['targetRoute'],
  created() {
    moment.locale('fr-FR');
  },
  computed: {
    ...mapGetters({
      resident: 'resident',
      currentEhpad: 'ehpad',
    }),
    title() {
      let { firstName } = this.resident;
      if (firstName === null) {
        firstName = '';
      }
      let icon = '<i class="fas fa-utensils"></i>';
      if (this.targetRoute === 'diagnostics') { icon = '<i class="fas fa-chart-line"></i>'; }
      return `${icon} <span>${this.resident.fullName} (${this.coloredDiagHtml(
        this.resident.lastDiagnostic.humanState,
      )}) </span>`;
    },
    checkFirstItem() {
      if ((this.prevResidentId() === null) || (this.resident.room === null)) { return false; }
      return true;
    },
    checkLastItem() {
      if ((this.nextResidentId() === null) || (this.resident.room === null)) { return false; }
      return true;
    },
    currentYear() {
      return moment().year();
    },
    currentWeek() {
      return moment().isoWeek();
    },
  },
  methods: {
    coloredDiagHtml,
    nextResidentId() {
      if (localStorage.nutriviewResidentsWithRoomSorted === undefined) { return null; }
      const residentsWithRoomSorted = JSON.parse(localStorage.nutriviewResidentsWithRoomSorted);
      const index = _.findIndex(residentsWithRoomSorted, { id: this.resident.id });
      if (residentsWithRoomSorted[index + 1] === undefined) { return null; }
      return residentsWithRoomSorted[index + 1].id;
    },
    prevResidentId() {
      if (localStorage.nutriviewResidentsWithRoomSorted === undefined) { return null; }
      const residentsWithRoomSorted = JSON.parse(localStorage.nutriviewResidentsWithRoomSorted);
      const index = _.findIndex(residentsWithRoomSorted, { id: this.resident.id });
      if (residentsWithRoomSorted[index - 1] === undefined) { return null; }
      return residentsWithRoomSorted[index - 1].id;
    },
    formatButtonLink(type) {
      let id;
      if (type === 'next') {
        id = this.nextResidentId();
      } else {
        id = this.prevResidentId();
      }
      return `/ehpads/${this.currentEhpad.shortname}/resident/${id}/${this.targetRoute}`;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
