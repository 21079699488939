<template>
  <b-container fluid>
    <div>
      <div class="d-flex justify-content-center align-items-center mb-3">
        <span class="text-center">
          <h3 class="m-0" style="display: inline-block;margin: 0;">
            Informations
          </h3>
        </span>
      </div>
      <hr />

      <li class="text-center" style="list-style-type:none;"><a href="#videos-tuto">Vidéos Tutoriels</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#contact">Support Technique</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#links">Liens Utiles</a></li>

      <hr />

      <a id="videos-tuto"></a>
      <h5 class="text-center font-weight-bold mb-3">Vidéos Tutoriels</h5>

      <hr />

      <li class="text-center" style="list-style-type:none;"><a href="#videos-tuto-residents">Gestion des Résidents</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#videos-tuto-menus">Les Menus</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#videos-tuto-ingesta">Les Ingesta</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#videos-tuto-tdb">Le Tableau de Bord</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#videos-tuto-users">Gestion des Utilisateurs</a></li>
      <!-- <li class="text-center" style="list-style-type:none;"><a href="#videos-tuto-survey">Les enquêtes</a></li> -->
      <li class="text-center" style="list-style-type:none;"><a href="#videos-tuto-params">Le paramétrage</a></li>

      <hr />

      <a id="videos-tuto-residents"></a>
      <li class="mb-3">Gestion des Résidents</li>
      <b-row>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- création d'un résident -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/oFawSdCqCgE">
            </iframe>
          </div>
        </b-col>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- Résidents Données personnelles -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/Rh2kEmZRiao">
            </iframe>
          </div>
        </b-col>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- Sortie d'un résident -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/5Epre88mUtA">
            </iframe>
          </div>
        </b-col>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- Résidents réintégration aprés sortie -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/Mca8iyE2s-A">
            </iframe>
          </div>
        </b-col>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- Résidents : Sortie en famille -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/mTzrh8d5f-s">
            </iframe>
          </div>
        </b-col>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- Espace Nutrition -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/l4qhN0Mau7s">
            </iframe>
          </div>
        </b-col>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- Etats nutritionnels -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/QhiPi3Cqnwg">
            </iframe>
          </div>
        </b-col>
      </b-row>

      <a id="videos-tuto-menus"></a>
      <li class="mb-3">Les Menus</li>
      <b-row>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- menus -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/az01oPrhKv8">
            </iframe>
          </div>
        </b-col>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- menus individuels -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/9rN7fMkrS5U">
            </iframe>
          </div>
        </b-col>
      </b-row>

      <a id="videos-tuto-ingesta"></a>
      <li class="mb-3">Les Ingesta</li>
      <b-row>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- Remplissage des ingesta -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/X2JaE1kdAL0">
            </iframe>
          </div>
        </b-col>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- Ingesta : Les buffets -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/W9szrB7mixw">
            </iframe>
          </div>
        </b-col>
      </b-row>

      <a id="videos-tuto-tdb"></a>
      <li class="mb-3">Le Tableau de Bord</li>
      <b-row>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- TDB -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/tmkwXYbpcVk">
            </iframe>
          </div>
        </b-col>
      </b-row>

      <a id="videos-tuto-users"></a>
      <li class="mb-3">Gestion des Utilisateurs</li>
      <b-row>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- Gestion des comptes -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/c_qV4cLWs-4">
            </iframe>
          </div>
        </b-col>
      </b-row>

      <a id="videos-tuto-params"></a>
      <li class="mb-3">Le paramétrage</li>
      <b-row>
        <b-col xs="12" md="6" xl="4">
          <div class="video-tuto mb-3">
            <!-- Gestion des comptes -->
            <iframe allow="fullscreen;"
              src="https://www.youtube.com/embed/fny7aOwxVjg">
            </iframe>
          </div>
        </b-col>
      </b-row>

      <!-- <li class="text-center" style="list-style-type:none;"><a href="#tech">Avant propos et prérequis techniques</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#params">Paramétrage de votre établissement et de vos protocoles</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#users">Gestion des utilisateurs du logiciel</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#dishes">Les plats, les supplémentations et les buffets</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#users">Le menu global</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#residents">Les résidents</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#datas">Données personnelles</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#nutrition">Nutrition du résident</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#nutrition">Nutrition du résident</a></li> -->



      <hr class="mt-5 mb-4"/>

      <a id="contact"></a>
      <h5 class="text-center font-weight-bold">Support Technique</h5>
      <b-row align-h="center">
        <b-col cols="8">
          <div class="link-card p-3 text-center">
            <h6><span class="font-weight-bold">Horaires d'ouverture du support technique : </span> Du lundi au vendredi de 8h à 12h et de 14h à 17h</h6>
            <p class="mb-0">Le support est joignable via le formulaire suivant</p>
            <p>Un technicien vous recontactera</p>

            <div class="video-tuto mb-3">
              <!-- Support technique -->
              <iframe allow="fullscreen;"
                src="https://www.youtube.com/embed/sY_7GGV8MKg">
              </iframe>
            </div>

            <b-form>
              <b-row v-if="globalSettings && globalSettings.multi_ehpad.data === true && currentUser.ehpad === null ">
                <b-col cols="6">
                  <label for="inline-form-input-ehpad" class="pr-4 text-nowrap">Etablissement : </label>
                </b-col>
                <b-col cols="6">
                  <b-form-select size="sm" v-model="newTicket.ehpadId">
                    <template #first>
                      <b-form-select-option :value="null">Aucun</b-form-select-option>
                    </template>
                    <b-form-select-option v-for="ehpad in ehpads" :value="ehpad.id" :key="ehpad.id">{{
                      ehpad.name
                    }}</b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-row>

              <b-row class="pt-2">
                <b-col cols="6">
                  <label for="inline-form-input-last-name" class="pr-4 text-nowrap">Votre nom : </label>
                </b-col>
                <b-col cols="6">
                    <b-form-input
                      id="inline-form-input-last-name"
                      size="sm"
                      type="text"
                      v-model="newTicket.lastName"
                      :class="{ 'is-invalid': $v.newTicket.lastName.$anyError }"
                    />
                    <b-form-invalid-feedback>Nom requis</b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row class="pt-2">
                <b-col cols="6">
                  <label for="inline-form-input-first-name" class="pr-4 text-nowrap">Votre prénom : </label>
                </b-col>
                <b-col cols="6">
                    <b-form-input
                      id="inline-form-input-first-name"
                      size="sm"
                      type="text"
                      v-model="newTicket.firstName"
                      :class="{ 'is-invalid': $v.newTicket.firstName.$anyError }"
                    />
                    <b-form-invalid-feedback>Prénom requis</b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row class="pt-2">
                <b-col cols="6">
                  <label for="inline-form-input-email" class="pr-4 text-nowrap">Adresse email : </label>
                </b-col>
                <b-col cols="6">
                    <b-form-input
                      id="inline-form-input-email"
                      size="sm"
                      type="email"
                      v-model="newTicket.email"
                      :class="{ 'is-invalid': $v.newTicket.email.$anyError }"
                    />
                    <b-form-invalid-feedback>Email requis</b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row class="pt-2">
                <b-col cols="6">
                  <label for="inline-form-input-phone" class="pr-4 text-nowrap">Numéro de téléphone : </label>
                </b-col>
                <b-col cols="6">
                    <b-form-input
                      id="inline-form-input-phone"
                      size="sm"
                      type="tel"
                      v-model="newTicket.phone"
                      :class="{ 'is-invalid': $v.newTicket.phone.$anyError }"
                    />
                    <b-form-invalid-feedback>Numéro de téléphone requis</b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row class="pt-2">
                <b-col cols="6">
                  <label for="inline-form-input-option" class="pr-4 text-nowrap">Objet de la demande : </label>
                </b-col>
                <b-col cols="6">
                  <b-form-select
                    id="inline-form-input-option"
                    v-model="newTicket.category"
                    :options="categoryFormOptions"
                    :class="{ 'is-invalid': $v.newTicket.category.$anyError }"
                  />
                  <b-form-invalid-feedback>Objet requis</b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="my-4">
                    <b-form-textarea
                      id="textarea-desc"
                      v-model="newTicket.description"
                      placeholder="Décrivez votre demande..."
                      rows="4"
                      max-rows="12"
                      :class="{ 'is-invalid': $v.newTicket.description.$anyError }"
                    />
                    <b-form-invalid-feedback>Description requise</b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-button
                class="d-flex mx-auto"
                variant="primary"
                size="sm"
                @click.prevent="createTicket"
                :disabled="updating"
              >
                Envoyer votre demande
              </b-button>
            </b-form>

            <h6 v-if="lastTicketId !== null" style="color: red;" class="mt-4">
              Votre demande a bien été enregistrée. Nous vous recontacterons sous peu. Le numéro de votre demande est le :
              <span class="font-weight-bold">{{ lastTicketId }}</span>
              . Notez le pour un meilleur suivi de votre demande.
            </h6>

          </div>
        </b-col>
      </b-row>
      <hr class="mt-5 mb-4"/>

      <a id="links"></a>
      <h5 class="text-center font-weight-bold">Liens utiles</h5>
      <b-row align-h="center">
        <b-col cols="8">
          <div class="link-card p-3 text-center">
            <h6><span class="font-weight-bold">Conditions Générales d'Utilisation de Nutriview : </span><a href="/assets/documentation/cgu.pdf" target="_blank">Cliquez ICI</a></h6>
            <h6><span class="font-weight-bold">Site internet Nutriview : </span><a href="http://www.nutriview.fr" target="_blank">http://www.nutriview.fr</a></h6>
            <h6><span class="font-weight-bold">Lutte contre la dénutrition : </span><a href="https://www.luttecontreladenutrition.fr/" target="_blank">https://www.luttecontreladenutrition.fr</a></h6>
            <!-- <h6><span class="font-weight-bold">Adresse email du support Nutriview : </span><a :href="`mailto:${globalSettings && globalSettings.support.email}`">{{globalSettings && globalSettings.support.email }}</a></h6> -->
          </div>
        </b-col>
      </b-row>

    </div>

  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'EhpadInfos',
  mixins: [validationMixin],
  components: {

  },
  created() {
    if (this.currentUser.lastTicket !== null) {
      this.newTicket.firstName = this.currentUser.lastTicket.firstName;
      this.newTicket.lastName = this.currentUser.lastTicket.lastName;
      this.newTicket.email = this.currentUser.lastTicket.email;
      this.newTicket.phone = this.currentUser.lastTicket.phone;
    }
    this.setEhpads();
  },
  data() {
    return {
      newTicket: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        category: null,
        description: '',
        ehpadId: null,
      },
      lastTicketId: null,
      updating: false,
    };
  },
  validations: {
    newTicket: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      category: {
        required,
      },
      description: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(['setEhpads']),
    async createTicket() {
      this.$v.newTicket.$touch();
      if (this.$v.$anyError) return;
      this.updating = true;
      await this.$store.dispatch('createTicket', {
        firstName: this.newTicket.firstName,
        lastName: this.newTicket.lastName,
        ehpadId: this.ehpadId,
        email: this.newTicket.email,
        phone: this.newTicket.phone,
        category: this.newTicket.category,
        description: this.newTicket.description,
        url: this.url,
      }).then((result) => {
        this.lastTicketId = result.id;
        this.$v.$reset();
        this.newTicket.description = '';
        this.newTicket.category = null;
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      this.updating = false;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'user',
      currentEhpad: 'ehpad',
      globalSettings: 'globalSettings',
      ehpads: 'ehpads',
    }),
    categoryFormOptions() {
      return [
        { value: null, text: 'Objet de la demande', disabled: true },
        'Problème technique',
        'Demande commerciale',
        'Question générale',
        "Proposition d'amélioration",
        'Autre',
      ];
    },
    url() {
      return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    },
    ehpadId() {
      if ((this.globalSettings && this.globalSettings.multi_ehpad.data === false) || this.currentUser.ehpad !== null) {
        return this.currentUser.ehpad.id;
      }
      return this.newTicket.ehpadId;
    },
  },
};
</script>

<style lang="scss" scoped>

:target {
  display: block;
  position: relative;
  top: -11.5rem;
  visibility: hidden;
}

.link-card {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.video-tuto {
  text-align:center;
  width:100%;
}

.video-tuto iframe {
  width: 400px;
  height: 250px;
}

</style>
