<template>
  <li>
    <p class="m-0 d-flex justify-content-between" style="font-size: medium;">
      <span v-if="editableMealDish.dish">
        {{ editableMealDish.dish.name }}
        <i
          v-if="editableMealDish.permanent"
          class="fas fas fa-anchor ml-2"
          style="color: #5bc0de;"
          v-b-tooltip.hover.v-primary title="Plat permanent du menu global"
        />
      </span>
      <span v-if="editableMealDish.buffet">
        {{ editableMealDish.buffet.name }}
        <i class="far fa-list-alt" style="color: #5bc0de;" v-b-tooltip.hover.v-primary title="Buffet"/>
        <i
          v-if="editableMealDish.permanent"
          class="fas fas fa-anchor ml-2"
          style="color: #5bc0de;"
          v-b-tooltip.hover.v-primary title="Buffet permanent du menu global"
        />
      </span>

      <b-button
        variant="link"
        size="sm"
        :class="destroyClasses"
        @click="onDestroy"
        v-if="menuEditState"
      />
    </p>

    <p class="mb-1 dishInfos" v-if="!editing">
      <span class="calories">{{ displayCalories() }} kcal</span>
      |
      <span class="proteins">{{ displayProteins()}} g</span>
      |
      <span class="hydration">{{ displayHydration() }} ml</span>
      <template v-if="currentUser.role == 'administrateur' || currentUser.role == 'intendance'">
        |
        <span class="price small" v-if="!editableMealDish.buffet">{{ displayDishPrice() }} €</span>
        <span class="price small" v-if="editableMealDish.buffet">{{ displayBuffetPrice() }} €</span>
      </template>
      <b-button
        variant="link"
        size="sm"
        class="fa fa-edit"
        @click="editing = true"
        v-if="menuEditState"
      ></b-button>
    </p>
    <b-form v-else>
      <b-input-group size="sm" class="pr-5">
        <b-form-input placeholder="Cal" type="number" min="0" v-model="editableMealDish.calories" />
        <b-form-input placeholder="Prot" type="number" min="0" v-model="editableMealDish.proteins" />
        <b-form-input placeholder="Eau" type="number" min="0" v-model="editableMealDish.hydration" />
        <b-input-group-append>
          <b-button variant="primary" @click.prevent="onUpdate" :disabled="updating">
            <i :class="updatingClasses" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import EventBus from '../../utilities/event_bus';
import { DESTROY_MEAL_DISH, UPDATE_MEAL_DISH } from '../../graphql/meal_dishes/mutations';

export default {
  name: 'Dish',
  props: ['mealDish', 'menuEditState'],
  data() {
    return {
      editableMealDish: null,
      removing: false,
      editing: false,
      updating: false,
    };
  },
  created() {
    this.duplicateMealDish();
  },
  computed: {
    ...mapGetters({
      currentUser: 'user',
    }),
    destroyClasses() {
      return this.removing ? 'fa fa-circle-notch fa-spin' : 'text-danger fa fa-trash-alt';
    },
    updatingClasses() {
      return this.updating ? 'fa fa-circle-notch fa-spin' : 'fa fa-check';
    },
  },
  methods: {
    duplicateMealDish() {
      this.editableMealDish = { ...this.mealDish };
    },
    displayCalories() {
      if (this.editableMealDish.calories || this.editableMealDish.calories === 0) {
        return this.editableMealDish.calories;
      }
      return '?';
    },
    displayProteins() {
      if (this.editableMealDish.proteins || this.editableMealDish.proteins === 0) {
        return this.editableMealDish.proteins;
      }
      return '?';
    },
    displayHydration() {
      if (this.editableMealDish.hydration || this.editableMealDish.hydration === 0) {
        return this.editableMealDish.hydration;
      }
      return '?';
    },
    displayDishPrice() {
      if (this.editableMealDish.price || this.editableMealDish.price === 0) {
        return this.editableMealDish.price;
      }
      return '?';
    },
    displayBuffetPrice() {
      if (this.editableMealDish.buffet.averagePrice || this.editableMealDish.buffet.averagePrice === 0) {
        return this.editableMealDish.buffet.averagePrice;
      }
      return '?';
    },
    onUpdate() {
      Swal.fire({
        title: 'Vous allez modifier les calories/protéines pour ce plat',
        text: "La modification ne s'appliquera que pour ce plat et ce jour précis. Si vous souhaitez modifier le plat dans tous les menus vous devez le faire dans la liste des plats.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.updating = true;
          await this.$apollo.mutate({
            mutation: UPDATE_MEAL_DISH,
            variables: {
              id: this.mealDish.id,
              proteins: parseInt(this.editableMealDish.proteins, 10),
              calories: parseInt(this.editableMealDish.calories, 10),
              hydration: parseInt(this.editableMealDish.hydration, 10),
            },
          }).then((result) => {
            EventBus.$emit('refetch-menus');
          }).catch((error) => {
            this.removingDish = false;
            // Revert change :
            this.duplicateMealDish();
            this.flashMessage.show({
              status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
        }
        this.editing = false;
        this.updating = false;
      });
    },
    onDestroy() {
      Swal.fire({
        title: 'Êtes vous sûr ?',
        text: 'Êtes vous sûr de vouloir supprimer ce plat ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.removing = true;
          await this.$apollo.mutate({
            mutation: DESTROY_MEAL_DISH,
            variables: {
              id: this.mealDish.id,
            },
          }).then((result) => {
            EventBus.$emit('refetch-menus');
          }).catch((error) => {
            this.removing = false;
            this.flashMessage.show({
              status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dishInfos {
  font-size: 0.95rem;
  .calories {
    color: green;
  }
  .proteins {
    color: red;
  }
  .hydration {
    color: blue;
  }
  .price {
    color: gray;
  }
}
</style>
