<template>
  <!-- https://bootstrap-vue.js.org/docs/components/table -->
  <div>
    <b-row align-h="center">
      <b-col cols="10">
        <b-table
          bordered
          striped
          small
          hover
          :items="resident.residentTests"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="m-0"
          ref="testTable"
          id="testTable"
        >
          <template #cell(value)="data">
            <span v-if="data.item.testType.isBoolean" style="font-size: medium;">{{ data.item.value === 0 ? "OUI" : "NON" }}</span>
            <span v-else style="font-size: medium;">{{ data.item.value }}</span>
          </template>

        </b-table>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['resident'],
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
  data() {
    return {
      sortBy: 'date',
      sortDesc: true,
      fields: [
        {
          key: 'testType.name',
          label: 'Nom du Test',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'date',
          label: 'Passé le',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'value',
          label: 'Résultat',
          sortable: true,
          sortByFormatted: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'comment',
          label: 'Commentaire',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
