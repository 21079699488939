<template>
  <div>
    <h5>Perceptions Gustatives</h5>
    <app-resident-gustatory-category-item
      v-for="gustatoryCategory in currentEhpad.gustatoryCategories"
      :key="gustatoryCategory.id"
      :resident="resident"
      :gustatoryCategory="gustatoryCategory"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ResidentGustatoryCategoryItem from './ResidentGustatoryCategoryItem.vue';

export default {
  name: 'Gustatory-Form',
  props: ['resident'],
  components: {
    appResidentGustatoryCategoryItem: ResidentGustatoryCategoryItem,
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped>

</style>
