<template>
  <b-modal
    v-bind="$attrs"
    title="Ajouter une supplémentation"
    class="col-12 text-center"
  >
    <b-container fluid>
      <div v-if="!$apollo.loading && residentMealDish !== null">
        Pour le plat : {{ residentMealDish.dish.name.toUpperCase() }}
      </div>
      <b-form>
        <div class="pt-2">

          <multiselect
            @keyup.enter="createResidentMealDishSupplementation"
            :class="{ 'is-invalid': $v.newSupplementation.$anyError }"
            placeholder="Ajouter une supplémentation"
            label="text"
            track-by="text"
            selectLabel=""
            deselectLabel=""
            selectedLabel="Sélectionné"
            :options="supplementationsOptions"
            v-model="newSupplementation"
          >
            <template #noResult>
              <span>Pas de résultat correspondant</span>
            </template>
            <template #option="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.text }}
                  <span class="calories">{{ props.option.calories }} kcal</span>
                  |
                  <span class="proteins">{{ props.option.proteins }} g</span>
                  |
                  <span class="hydration">{{ props.option.hydration }} ml</span>
                </span>
              </div>
            </template>
            <template #singleLabel="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.text }}
                  <span class="calories">{{ props.option.calories }} kcal</span>
                  |
                  <span class="proteins">{{ props.option.proteins }} g</span>
                  |
                  <span class="hydration">{{ props.option.hydration }} ml</span>
                </span>
              </span>
            </template>
          </multiselect>
          <b-form-invalid-feedback>Supplémentation requise</b-form-invalid-feedback>

        </div>

        <div v-if="error" style="color: red;" class="text-center mt-2">
          <p v-for="(error, i) of error.graphQLErrors" :key="i">
            {{ error.message }}
          </p>
        </div>

        <b-button
          class="mt-3 d-flex mx-auto"
          variant="primary"
          size="sm"
          @click.prevent="createResidentMealDishSupplementation"
          >Valider</b-button
        >
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import EventBus from '../../utilities/event_bus';
import { CREATE_RESIDENT_MEAL_DISH_SUPPLEMENTATION } from '../../graphql/resident_meal_dish_supplementations/mutations';

export default {
  mixins: [validationMixin],
  props: ['residentMealDish'],
  data() {
    return {
      error: null,
      newSupplementation: null,
    };
  },
  validations: {
    newSupplementation: {
      required,
    },
  },
  components: {
    Multiselect,
  },
  methods: {
    async createResidentMealDishSupplementation() {
      this.$v.newSupplementation.$touch();
      if (this.$v.$anyError) return;
      await this.$apollo.mutate({
        mutation: CREATE_RESIDENT_MEAL_DISH_SUPPLEMENTATION,
        variables: {
          residentMealDishId: this.residentMealDish.id,
          supplementationId: this.newSupplementation.id,
        },
      }).then((result) => {
        this.error = null;
        this.$v.$reset();
        EventBus.$emit('refetch-resident-menus');
        this.newSupplementation = null;
        this.$bvModal.hide(`create-rmds-modal${this.residentMealDish.id}`);
      }).catch((error) => {
        this.error = error;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      supplementations: 'sortedSupplementationsByName',
    }),
    supplementationsOptions() {
      return this.supplementations.map((el) => ({
        text: el.name,
        id: el.id,
        calories: el.calories,
        proteins: el.proteins,
        hydration: el.hydration,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>

  .calories {
    font-size: 0.8rem;
    color: green;
  }
  .proteins {
    font-size: 0.8rem;
    color: red;
  }
  .hydration {
    font-size: 0.8rem;
    color: blue;
  }

</style>
