<template>
  <b-modal
    v-bind="$attrs"
    :title="modalTitle"
    size="xl"
    id='ratio-nutritonal-table-modal'
    class="col-12 text-center"
  >
    <b-container fluid>
      <b-table
        sticky-header="500px"
        bordered
        striped
        small
        hover
        :items="data"
        :fields="fields"
      >

        <template #cell(proteinsGoal)="data">
          <span><b style="color: blue; font-size: medium;">{{ data.item.proteinsGoal }}</b></span>
        </template>
        <template #cell(proteinsServed)="data">
          <span v-html="colorNumberNutrition(data.item.proteinsServed, data.item.proteinsGoal)"></span>
        </template>
        <template #cell(ratioProteinsServed)="data">
          <span v-html="colorNumberPercent(data.item.ratioProteinsServed)"></span>
        </template>
        <template #cell(proteinsIngested)="data">
          <span v-html="colorNumberNutrition(data.item.proteinsIngested, data.item.proteinsGoal)"></span>
        </template>
        <template #cell(ratioProteinsIngested)="data">
          <span v-html="colorNumberPercent(data.item.ratioProteinsIngested)"></span>
        </template>

        <template #cell(caloriesGoal)="data">
          <span><b style="color: blue; font-size: medium;">{{ data.item.caloriesGoal }}</b></span>
        </template>
        <template #cell(caloriesServed)="data">
          <span v-html="colorNumberNutrition(data.item.caloriesServed, data.item.caloriesGoal)"></span>
        </template>
        <template #cell(ratioCaloriesServed)="data">
          <span v-html="colorNumberPercent(data.item.ratioCaloriesServed)"></span>
        </template>
        <template #cell(caloriesIngested)="data">
          <span v-html="colorNumberNutrition(data.item.caloriesIngested, data.item.caloriesGoal)"></span>
        </template>
        <template #cell(ratioCaloriesIngested)="data">
          <span v-html="colorNumberPercent(data.item.ratioCaloriesIngested)"></span>
        </template>

        <template #cell(hydrationGoal)="data">
          <span><b style="color: blue; font-size: medium;">{{ data.item.hydrationGoal }}</b></span>
        </template>
        <template #cell(hydrationServed)="data">
          <span v-html="colorNumberNutrition(data.item.hydrationServed, data.item.hydrationGoal)"></span>
        </template>
        <template #cell(ratioHydrationServed)="data">
          <span v-html="colorNumberPercent(data.item.ratioHydrationServed)"></span>
        </template>
        <template #cell(hydrationIngested)="data">
          <span v-html="colorNumberNutrition(data.item.hydrationIngested, data.item.hydrationGoal)"></span>
        </template>
        <template #cell(ratioHydrationIngested)="data">
          <span v-html="colorNumberPercent(data.item.ratioHydrationIngested)"></span>
        </template>

        <template #cell(lastDiagnostic)="data">
          <span v-html="coloredDiagHtml(data.item.lastDiagnostic)"></span>
        </template>

        <template #cell(room)="data">{{
          (data.item.room && `N° ${data.item.room.number}`) || 'Aucun lit assigné'
        }}</template>

      </b-table>
    </b-container>
  </b-modal>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { coloredDiagHtml, colorNumberNutrition, colorNumberPercent } from '../../utilities/filters';

export default {
  props: ['date', 'data'],
  data() {
    return {
      fields: [
        {
          key: 'firstName',
          label: 'Prénom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'lastDiagnostic',
          label: 'État nutritionnel',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElState',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'proteinsGoal',
          label: 'Cible Prot',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'proteinsServed',
          label: 'Prot Serv (g)',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'ratioProteinsServed',
          label: 'Ratio Prot Serv',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'proteinsIngested',
          label: 'Prot Ingest (g)',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'ratioProteinsIngested',
          label: 'Ratio Prot Ingest',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'caloriesGoal',
          label: 'Cible Cal',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'caloriesServed',
          label: 'Cal Serv (kcal)',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'ratioCaloriesServed',
          label: 'Ratio Cal Serv',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'caloriesIngested',
          label: 'Cal Ingest (g)',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'ratioCaloriesIngested',
          label: 'Ratio Cal Ingest',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'hydrationGoal',
          label: 'Cible Hydr',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'hydrationServed',
          label: 'Hydr Serv (ml)',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'ratioHydrationServed',
          label: 'Ratio Hydr Serv',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'hydrationIngested',
          label: 'Hydr Ingest (ml)',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'ratioHydrationIngested',
          label: 'Ratio Hydr Ingest',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'room',
          label: 'Lit',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElNumber',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
      ],
    };
  },
  computed: {
    modalTitle() {
      const formattedDate = moment(this.date, 'YYYY-MM-DD').format('DD-MM-YYYY');
      return `Détails des écarts nutritionnels pour le ${formattedDate}`;
    },
  },
  methods: {
    coloredDiagHtml,
    colorNumberNutrition,
    colorNumberPercent,
    residentElState(el) {
      return _.get(el, 'state', null);
    },
    residentElNumber(el) {
      return _.get(el, 'number', null);
    },
  },
};
</script>

<style lang="scss" scoped>
  :deep(.small-size) {
    width: 90px;
  }

  :deep(.modal-xl) {
    max-width: 90% !important;
  }
</style>
