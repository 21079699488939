<template>
  <b-container fluid>
    <div v-if="resident !== null && !chartLoading">
      <div class="d-print-none">
        <app-resident-nav-bar
          :targetRoute="'bilan'"
        />
        <hr />
      </div>

      <div class="header">
        <img src="/assets/images/nutriviewCOUL_Borderless.png" class="print-only nutriview-logo">
      </div>

      <table>
        <thead><tr><td>
          <div class="header-space print-only">&nbsp;</div>
        </td></tr></thead>
        <tbody><tr><td>
          <div class="content">

            <b-row class="d-print-none my-5 text-center d-flex justify-content-center">
              <b-col class="col-4"><b>Sélectionner l'intervalle souhaitée pour les graphiques à l'édition et cliquez sur Imprimer la page</b></b-col>
              <b-col class="col-2">
                <div>
                  <b-form-select
                    v-model="rangeForChart"
                    :options="chartRangeOptions"
                    @change="updateData"
                  />
                </div>
              </b-col>
              <b-col class="col-2">
                <b-button @click="printPage">Imprimer la page</b-button>
              </b-col>
            </b-row>
            <hr class="d-print-none" />


            <div class="page">
              <b-row align-h="center">
                <b-col cols="8">
                  <h1 class="text-center text-dark-gray mb-5"><b>Bilan nutritionnel individuel de {{ resident.fullName }}</b></h1>
                </b-col>
              </b-row>

              <div class="mb-5">
                <h2 class="text-purple mb-3"><b>Données générales</b></h2>
                <h5 class="mb-3"><b>Données au : {{ today }}</b></h5>
                <b-row align-h="center">
                  <b-col cols="5">
                    <app-resident-infos-form
                      :resident="resident"
                      :readonly="true"
                    />
                  </b-col>
                  <b-col cols="3">
                    <app-resident-nutrition-details
                      :resident="resident"
                      :readonly="true"
                    />
                  </b-col>
                  <b-col cols="4">
                    <app-resident-gustatory-form :resident="resident" />
                  </b-col>

                </b-row>

                <app-resident-general-comment-form
                  :resident="resident"
                  :readonly="true"
                />
              </div>

              <div>
                <h2 class="text-purple mb-3"><b>Diagnostic</b></h2>
                <h5 class="mb-3"><b>État nutritionnel actuel : <span v-html="coloredDiagHtml(resident.lastDiagnostic.humanState)"></span></b></h5>

                <b-row class="my-2">
                  <b-col cols="12">
                    <h6><b>Situation nutritionnelle actuelle :</b></h6>
                      <b-form-textarea
                        v-model="resident.lastDiagnostic.comment"
                        size="sm"
                        type="text"
                        rows="4"
                        disabled
                      />
                  </b-col>
                </b-row>
              </div>
            </div>

            <div class="page">
              <h5 class="mb-3"><b>Évolution de l’état nutritionnel :</b></h5>
              <app-resident-diagnostic-table
                :diagnostics="last8Diagnostics"
              />
            </div>

            <div class="page">
              <h5 class="mb-3"><b>Variation de l'albuminémie ({{ selectedRangeText }}) :</b></h5>
              <AlbumineChart :residentAlbumines="resident.albumines" :options="options" :currentEhpad="currentEhpad" :noTitle="true" height="220px" />
              <h5 class="mb-3"><b>Variation du poids ({{ selectedRangeText }}) :</b></h5>
              <WeightChart :residentWeights="resident.weights" :options="options" :noTitle="true" height="220px" />
              <h5 class="mb-3"><b>Suivi des ingesta ({{ selectedRangeText }}) :</b></h5>
              <div v-if="residentMenusForCharts !== undefined">
                <CaloriesChart :residentMenusForCharts="residentMenusForCharts" :nutritionGoals="resident.nutritionGoals" :options="options" :noTitle="true" height="220px" />
                <ProteinsChart :residentMenusForCharts="residentMenusForCharts" :nutritionGoals="resident.nutritionGoals" :options="options" :noTitle="true" height="220px" />
              </div>
            </div>

            <div class="page">
              <h2 class="text-purple mb-3"><b>Programme individuel</b></h2>
              <b-row class="mb-3">
                <b-col cols="4">
                  <app-resident-nutrition-goal-form
                    :resident="resident"
                    :readonly="true"
                  />
                </b-col>
                <b-col cols="8">
                  <GoalsComponent
                    v-if="resident.individualProgram && residentGoals.length > 0"
                    :goals="residentGoals"
                    :currentDate="today"
                    :readonly="true"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="12">
                  <NutritionalActionsComponent
                    v-if="resident.individualProgram && residentNutritionalActions.length > 0"
                    :actions="residentNutritionalActions"
                    :currentDate="today"
                    :readonly="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <TherapeuticActionsComponent
                    v-if="resident.individualProgram && residentTherapeuticActions.length > 0"
                    :actions="residentTherapeuticActions"
                    :currentDate="today"
                    :readonly="true"
                  />
                </b-col>
              </b-row>
            </div>

            <!-- TODO -->
             <!-- Menage dans les composants, trié les forms... virer Resident de tout les noms des composants -->
              <!-- Utiliser les noms des composants importés directement plutot que app-machin -->


          </div>
        </td></tr></tbody>
        <tfoot><tr><td>
          <div class="footer-space print-only">&nbsp;</div>
        </td></tr></tfoot>
      </table>
    </div>

    <div class="text-center" style="color: #469AE0;" v-else>
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement...
      </h2>
    </div>

    <div class="footer">
      <h6 class="text-purple print-only">{{ currentEhpad.name }} - Résultats nutritionnels au {{ today }} - NutriView</h6>
    </div>

  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { coloredDiagHtml } from '../../utilities/filters';

import ResidentInfosForm from '../../components/residents/ResidentInfosForm.vue';
import ResidentNutritionDetails from '../../components/residents/ResidentNutritionDetails.vue';
import ResidentGustatoryForm from '../../components/residents/ResidentGustatoryForm.vue';
import ResidentGeneralCommentForm from '../../components/residents/ResidentGeneralCommentForm.vue';
import ResidentNutritionGoalForm from '../../components/residents/ResidentNutritionGoalForm.vue';
import ResidentDiagnosticTable from '../../components/residents/ResidentDiagnosticTable.vue';
import GoalsComponent from '../../components/residents/forms/GoalsComponent.vue';
import NutritionalActionsComponent from '../../components/residents/forms/NutritionalActionsComponent.vue';
import TherapeuticActionsComponent from '../../components/residents/forms/TherapeuticActionsComponent.vue';
import EventBus from '../../utilities/event_bus';
import WeightChart from '../../components/residents/charts/Weight.vue';
import AlbumineChart from '../../components/residents/charts/Albumine.vue';
import CaloriesChart from '../../components/residents/charts/Calories.vue';
import ProteinsChart from '../../components/residents/charts/Proteins.vue';
import ResidentNavBar from '../../components/residents/ResidentNavBar.vue';
import { FETCH_RESIDENT_MENUS_FOR_CHARTS } from '../../graphql/resident_menus/queries';

export default {
  props: ['residentId'],
  components: {
    appResidentInfosForm: ResidentInfosForm,
    appResidentNutritionDetails: ResidentNutritionDetails,
    appResidentGustatoryForm: ResidentGustatoryForm,
    appResidentGeneralCommentForm: ResidentGeneralCommentForm,
    appResidentDiagnosticTable: ResidentDiagnosticTable,
    appResidentNavBar: ResidentNavBar,
    appResidentNutritionGoalForm: ResidentNutritionGoalForm,
    GoalsComponent,
    NutritionalActionsComponent,
    TherapeuticActionsComponent,
    WeightChart,
    AlbumineChart,
    CaloriesChart,
    ProteinsChart,
  },
  data() {
    return {
      rangeForChart: { months: null, year: null },
      chartLoading: true,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              displayFormats: {
                day: 'MMM YYYY',
              },
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  apollo: {
    residentMenusForCharts: {
      query: FETCH_RESIDENT_MENUS_FOR_CHARTS,
      variables() {
        return {
          residentId: this.residentId,
          year: parseInt(this.rangeForChart.year, 10),
          months: parseInt(this.rangeForChart.months, 10),
        };
      },
      fetchPolicy: 'network-only',
      result() {
        this.chartLoading = false;
      },
    },
  },
  created() {
    moment.locale('fr-FR');
    this.findResident({
      id: this.residentId,
    });
  },
  mounted() {
    EventBus.$emit('disable-col-content');
  },
  beforeDestroy() {
    EventBus.$emit('enable-col-content');
  },
  watch: {
    residentId: {
      handler() {
        this.findResident({
          id: this.residentId,
        });
        this.updateResidentData();
      },
    },
  },
  computed: {
    ...mapGetters({
      resident: 'resident',
      currentEhpad: 'ehpad',
    }),
    today() {
      const now = new Date();
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return moment(date).format("DD/MM/YYYY");
    },
    chartRangeOptions() {
      return [
        { value: { months: null, year: null }, text: '3 mois glissants' },
        { value: { months: 6, year: null }, text: '6 mois glissants' },
        { value: { months: 12, year: null }, text: '12 mois glissants' },
        { value: { months: null, year: moment().year() - 2 }, text: moment().year() - 2 },
        { value: { months: null, year: moment().year() - 1 }, text: moment().year() - 1 },
        { value: { months: null, year: moment().year() }, text: moment().year() },
      ];
    },
    selectedRangeText() {
      const selectedOption = this.chartRangeOptions.find(option =>
        option.value.months === this.rangeForChart.months &&
        option.value.year === this.rangeForChart.year
      );
      return selectedOption ? selectedOption.text : '';
    },
    last8Diagnostics() {
      return this.resident.diagnostics.slice(-8);
    },
    residentNutritionalActions() {
      return this.resident.individualProgram.actions.filter(action => action.actionType === 'nutritional')
    },
    residentTherapeuticActions() {
      return this.resident.individualProgram.actions.filter(action => action.actionType === 'therapeutic')
    },
    residentGoals() {
      return this.resident.individualProgram.goals
    },
  },
  methods: {
    ...mapActions(['findResident']),
    coloredDiagHtml,
    printPage() {
      var tempTitle = document.title;
      document.title = this.resident.fullName + "-" + this.currentEhpad.shortname + "-" + this.today
      window.print();
      document.title = tempTitle;
    },
    updateData() {
      this.chartLoading = true;
      this.updateResidentData();
      this.residentMenusForCharts = undefined;
    },
    async updateResidentData() {
      await this.$store.dispatch('findResident', {
        id: this.residentId,
        months: this.rangeForChart.months,
        year: this.rangeForChart.year,
      });
    },
  }
};
</script>

<style lang="scss" scoped>

.nutriview-logo {
  width: 500px;
  height: auto;
}

div.page {
  page-break-after: always;
  page-break-inside: avoid;
}

div.page:last-child {
  page-break-after: auto;
}

.header, .header-space {
  height: 220px;
  z-index: -1;
}

.footer, .footer-space {
  height: 25px;
}

.header {
  position: fixed;
  top: 0;
  width: 95%; /* Assurez-vous que la div prend toute la largeur de la page */
  display: flex;
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
}

.footer {
  position: fixed;
  bottom: 0;
  width: 95%; /* Assurez-vous que la div prend toute la largeur de la page */
  display: flex;
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
}

</style>
