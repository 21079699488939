import Home from "./pages/Home.vue";
import Signin from "./pages/Signin.vue";
import EhpadShow from "./pages/ehpads/Show.vue";
import EhpadMainMenu from "./pages/ehpads/MainMenu.vue";
import EhpadSetup from "./pages/ehpads/Setup.vue";
import EhpadInfos from "./pages/ehpads/Infos.vue";
import EhpadDashboard from "./pages/ehpads/Dashboard.vue";
import EhpadsIndex from "./pages/ehpads/Index.vue";
import UsersIndex from "./pages/users/Index.vue";
import UserShow from "./pages/users/Show.vue";
import DishesIndex from "./pages/dishes/Index.vue";
import SupplementationsIndex from "./pages/supplementations/Index.vue";
import EhpadMenus from "./pages/ehpads/Menus.vue";
import ResidentsIndex from "./pages/residents/Index.vue";
import ResidentsIngesta from "./pages/residents/IngestaIndex.vue";
import ResidentShow from "./pages/residents/Show.vue";
import ResidentDiagnostics from "./pages/residents/Diagnostics.vue";
import ResidentBilan from "./pages/residents/Bilan.vue";
import ResidentMenus from "./pages/residents/Menus.vue";
import ResidentIngesta from "./pages/residents/Ingesta.vue";
import SurveysIndex from "./pages/surveys/Index.vue";
import SurveyShow from "./pages/surveys/Show.vue";
import SurveyNew from "./pages/surveys/New.vue";

const routes = [
  {
    path: "/signin",
    component: Signin,
    name: "SignIn",
    exact: true,
  },
  {
    path: "/ehpads",
    component: EhpadsIndex,
    exact: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    component: UsersIndex,
    exact: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    component: UserShow,
    exact: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ehpads/:shortname",
    component: EhpadShow,
    exact: true,
    props: true,
    // Meta : logique dans application.js pour vérifier l"authentification avant le routage
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "menus/:year/:week",
        component: EhpadMenus,
        props: true,
        children: [
          {
            path: "pdf-menu",
          },
          {
            path: "pdf-menu.pdf",
          },
        ],
      },
      {
        path: "main",
        component: EhpadMainMenu,
      },
      {
        path: "infos",
        component: EhpadInfos,
      },
      {
        path: "setup",
        component: EhpadSetup,
      },
      {
        path: "dashboard",
        component: EhpadDashboard,
      },
      {
        path: "residents",
        component: ResidentsIndex,
      },
      {
        path: "surveys",
        component: SurveysIndex,
      },
      {
        path: "survey/new",
        component: SurveyNew,
        props: true,
      },
      {
        path: "survey/:id",
        component: SurveyShow,
        props: true,
      },
      {
        path: ":date/ingesta",
        component: ResidentsIngesta,
        props: true,
      },
      {
        path: "resident/:residentId/nutrition",
        component: ResidentShow,
        props: true,
      },
      {
        path: "resident/:residentId/diagnostics",
        component: ResidentDiagnostics,
        props: true,
      },
      {
        path: "resident/:residentId/bilan",
        component: ResidentBilan,
        props: true,
      },
      {
        path: "resident/:residentId/menus/:year/:week",
        component: ResidentMenus,
        name: "ResidentMenus",
        props: true,
      },
      {
        path: "resident/:residentId/ingesta/:date",
        component: ResidentIngesta,
        name: "ResidentIngesta",
        props: true,
      },
      {
        path: "dishes",
        component: DishesIndex,
      },
      {
        path: "supplementations",
        component: SupplementationsIndex,
      },
    ],
  },
  {
    path: "/",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  { path: "*", redirect: "/" },
];

export default routes;
