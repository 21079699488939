<template>
  <b-col cols="12" lg="5" class="mx-2">
    <span class="d-flex">
      <h5 v-html="coloredDiagHtml(name(rootState))"></h5>
      <i
        v-if="message(rootState) !== undefined"
        class="fas fa-exclamation-triangle mt-1 ml-2"
        style="color: #5bc0de;"
        v-b-tooltip.hover.v-primary
        :title="message(rootState)"
      />
    </span>

    <app-child-settings-form
      v-for="(values, state, index) in rootValues"
      :state="state"
      :values="values"
      :rootState="rootState"
      :key="index"
    />

  </b-col>
</template>

<script>

import { coloredDiagHtml } from '../../utilities/filters';
import ChildSettingsForm from './ChildSettingsForm.vue';

export default {
  name: 'RootSettings',
  props: ['rootValues', 'rootState'],
  components: {
    appChildSettingsForm: ChildSettingsForm,
  },
  data() {
    return {
      editableSetting: null,
      updating: false,
      moderate_undernutrition: {
        name: 'Dénutrition modérée',
        message: 'Les valeurs des seuils de dénutrition modérée sont utilisées pour les seuils bas des courbes',
      },
      severe_undernutrition: {
        name: 'Dénutrition sévère',
        message: 'Les valeurs des seuils de dénutrition modérée sont utilisées pour les seuils critiques des courbes',
      },
      high_risk: {
        name: 'A risque élevé',
      },
      default_undernutrition: {
        name: 'Autres états nutritionnels (risques faibles et fin de vie)',
      },
      protocols: {
        name: 'Protocoles de calcul',
      },
      configuration: {
        name: 'Autres paramétres',
      },
      meal_categories_options: {
        name: 'Options des repas',
      },
    };
  },
  methods: {
    coloredDiagHtml,
    name(setting) {
      return eval(`this.${setting}.name`);
    },
    message(setting) {
      return eval(`this.${setting}.message`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
