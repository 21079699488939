<template>
    <div>
      <b-row align-h="center">
        <b-col cols="12" xl="8">
          <app-dishes-table />
          <hr />
          <app-buffets-table />
          <hr />
        </b-col>
        <b-col cols="12" xl="4">
          <app-dishes-settings />
        </b-col>
      </b-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DishesTable from '../../components/dishes/Table.vue';
import BuffetsTable from '../../components/buffets/Table.vue';
import DishesSettings from '../../components/dishes/Settings.vue';

export default {
  components: {
    appDishesTable: DishesTable,
    appBuffetsTable: BuffetsTable,
    appDishesSettings: DishesSettings,
  },
  methods: {
    ...mapActions(['setDishes', 'setBuffets']),
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
  created() {
    this.setDishes(this.currentEhpad.id);
    this.setBuffets(this.currentEhpad.id);
  },
};
</script>

<style lang="scss" scoped>
</style>
