<template>
  <!-- https://bootstrap-vue.js.org/docs/components/table -->
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Supplémentations
        </h3>
        <b-button class="mb-2 ml-4" variant="primary" size="sm" v-b-modal.new-supplementation-modal>
          <i class="fas fa-plus" />
          Ajouter une nouvelle Supplémentation
        </b-button>
      </span>
    </div>

    <app-new-supplementation-modal id="new-supplementation-modal" hide-footer />

    <b-form-group
      label-size="sm"
      label-for="filterInput"
      class="mb-3"
      style="width: 40%;"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Filtre de recherche"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-table
      :filter="filter"
      bordered
      striped
      small
      hover
      :items="supplementations"
      :fields="computedFields"
      :sort-by.sync="sortBy"
      class="m-0"
      ref="supplementationTable"
      id="supplementationTable"
    >

      <template #head(calories)="data">
        <span>
          <img src="/assets/images/calories.png" class="cal-icon"
            v-b-tooltip.hover.v-primary
            title="Valeurs en kcal"
          >
        </span>
      </template>

      <template #head(proteins)="data">
        <span>
          <img src="/assets/images/proteins.png" class="prot-icon"
            v-b-tooltip.hover.v-primary
            title="Valeurs en g"
          >
        </span>
      </template>

      <template #head(hydration)="data">
        <span>
          <img src="/assets/images/hydration.png" class="hydration-icon"
            v-b-tooltip.hover.v-primary
            title="Valeurs en ml"
          >
        </span>
      </template>

      <template #cell(active)="data">{{ humanActive(data.item) }}</template>

      <template #cell(price)="data">
        <span style="font-size: medium;" v-if="data.item.price !== null">
          {{ data.item.price }}€
        </span>
      </template>

      <template #head(actions)>{{ '' }}</template>

      <template #cell(actions)="data">
        <b-button
          variant="link"
          size="sm"
          v-b-modal.info-supplementation-modal
          @click="selectSupplementation(data.item)"
        >
          <i class="fas fa-edit"
            v-b-tooltip.hover.v-primary
            title="Modifier"
          />
        </b-button>
      </template>
    </b-table>

    <app-info-supplementation-modal
      v-if="selectedSupplementation"
      id="info-supplementation-modal"
      hide-footer
      :supplementation="selectedSupplementation"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoSupplementationModal from './InfoSupplementationModal.vue';
import NewSupplementationModal from './NewSupplementationModal.vue';

export default {
  components: {
    appInfoSupplementationModal: InfoSupplementationModal,
    appNewSupplementationModal: NewSupplementationModal,
  },
  data() {
    return {
      selectedSupplementation: null,
      filter: null,
      sortBy: 'humanType',
      fields: [
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'calories',
          label: 'Calories',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'proteins',
          label: 'Protéines',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'hydration',
          label: 'Apports hydriques',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'price',
          label: 'Prix',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'actions',
          label: 'actions',
          class: 'text-center',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
      ],
    };
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.selectedSupplementation = null;
    });
  },
  methods: {
    selectSupplementation(supplementation) {
      this.selectedSupplementation = supplementation;
    },
  },
  computed: {
    ...mapGetters({
      supplementations: 'sortedSupplementationsByName',
      currentUser: 'user',
    }),
    computedFields() {
      if (this.currentUser.role !== 'administrateur' && this.currentUser.role !== 'intendance') {
        return this.fields.filter((field) => field.key !== 'price');
      }
      return this.fields;
    },
  },
};
</script>

<style lang="scss" scoped>
  :deep(.small-size) {
    max-width: 75px;
  }

  .cal-icon {
    filter: invert(21%) sepia(91%) saturate(5127%) hue-rotate(118deg) brightness(94%) contrast(105%);
    width: 80%;
    max-width: 40px;
  }

  .hydration-icon {
    filter: invert(58%) sepia(85%) saturate(7456%) hue-rotate(234deg) brightness(99%) contrast(103%);
    width: 80%;
    max-width: 40px;
  }

  .prot-icon {
    filter: invert(46%) sepia(86%) saturate(2816%) hue-rotate(330deg) brightness(113%) contrast(109%);
    width: 80%;
    max-width: 40px;
  }
</style>
