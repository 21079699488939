<template>
  <div>
    <p class="font-weight-bold text-center m-0" id="title">{{ residentMeal.mealCategory.humanType }}
      <span v-if="currentUser.role == 'administrateur' || currentUser.role == 'intendance'" class="font-weight-normal small">
        |
        <span class="price">{{ residentMeal.totalPrice }} €</span>
      </span>
      <b-button
        variant="link"
        size="sm"
        :class="emptyClassesResidentMeal"
        @click="emptyResidentMeal"
        v-if="menuEditState"
        v-b-tooltip.hover.v-danger
        title="Supprimer tous les plats de ce repas. Ex: Si le résident n'a pas pris ce repas dans l'établissement (hospitalisation, déplacement...)"
      />
    </p>

    <app-resident-dishes
      :residentMealDishes="residentMeal.filteredResidentMealDishes"
      v-if="residentMeal.filteredResidentMealDishes"
      :menuEditState="menuEditState"
    />
    <p class="text-center" v-else><i class="fa fa-circle-notch fa-spin" /> Chargement...</p>

    <b-form class="d-flex mt-2" v-if="menuEditState">
      <app-dishes-buffets-multiselect
        :options="activeDishesAndBuffetsByTypeOptions"
        :onChange="setDishOrBuffet"
        :dataToWatch="dishOrBuffet"
      />

      <b-button
        variant="primary"
        @click.prevent="onClick"
        :disabled="loading"
        v-b-tooltip.hover.v-primary
        title="Ajouter un plat"
      >
        <i :class="loading ? 'fa fa-spin fa-circle-notch' : 'fa fa-plus'" />
      </b-button>
    </b-form>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import DishesBuffetsMultiselect from '../shared/DishesBuffetsMultiselect.vue';
import ResidentDishes from './Dishes.vue';
import EventBus from '../../utilities/event_bus';
import { CREATE_RESIDENT_MEAL_DISH, EMPTY_RESIDENT_MEAL } from '../../graphql/resident_meal_dishes/mutations';

export default {
  name: 'ResidentMeal',
  props: ['type', 'residentMeal', 'menuEditState'],
  components: {
    appResidentDishes: ResidentDishes,
    appDishesBuffetsMultiselect: DishesBuffetsMultiselect,
  },
  data() {
    return {
      loading: false,
      dishOrBuffet: null,
      emptyingResidentMeal: false,
    };
  },
  computed: {
    ...mapGetters({
      activeDishesAndBuffetsByType: 'activeDishesAndBuffetsByType',
      currentUser: 'user',
    }),
    emptyClassesResidentMeal() {
      return this.emptyingResidentMeal ? 'text-decoration-none fa fa-circle-notch fa-spin' : ' text-decoration-none text-danger fa fa-ban';
    },
    activeDishesAndBuffetsByTypeOptions() {
      return this.activeDishesAndBuffetsByType.map((el) => ({
        text: `${el.name} (${el.humanType})`,
        id: el.id,
        proteins: el.defaultProteins,
        calories: el.defaultCalories,
        hydration: el.defaultHydration,
        type: el.__typename,
      }));
    },
  },
  methods: {
    setDishOrBuffet(value) {
      this.dishOrBuffet = value;
    },
    emptyResidentMeal() {
      Swal.fire({
        title: 'Êtes vous sûr ?',
        text: "Cette action va supprimer TOUS les plats de ce repas. Vous pouvez utiliser cette fonctionnalité pour supprimer les plats d'un repas que le résident n'a pas pris dans l'établissement (hospitalisation, déplacement...)",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.emptyingResidentMeal = true;
          await this.$apollo.mutate({
            mutation: EMPTY_RESIDENT_MEAL,
            variables: {
              id: this.residentMeal.id,
            },
          }).then((result) => {
            EventBus.$emit('refetch-resident-menus');
          }).catch((error) => {
            this.flashMessage.show({
              status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
          this.emptyingResidentMeal = false;
        }
      });
    },
    async onClick() {
      if (this.dishOrBuffet === null) return;
      this.loading = true;
      await this.$apollo.mutate({
        mutation: CREATE_RESIDENT_MEAL_DISH,
        variables: {
          residentMealId: this.residentMeal.id,
          dishOrBuffetId: this.dishOrBuffet.id,
          type: this.dishOrBuffet.type,
        },
      }).then((result) => {
        EventBus.$emit('refetch-resident-menus');
        this.dishOrBuffet = null;
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.is-valid {
  background-image: none !important;
}

.is-invalid {
  background-image: none !important;
}

.price {
  color: gray;
}
</style>
