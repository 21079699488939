<template>
  <div>
    <div class="d-flex align-items-center">
      <h6>Texture des plats</h6>
      <div class="pl-3 d-print-none" v-if="!editTexture && !readonly">
        <b-button variant="primary" size="sm" @click="editTexture = true">
          <i class="fas fa-edit" />
        </b-button>
      </div>
    </div>
    <b-form>
      <dl class="row">
        <dt class="col-12">
          <div v-if="editTexture">
            <b-form-select
              :options="textureTypesOptions"
              v-model="texture.humanType"
              :class="{ 'is-invalid': $v.texture.humanType.$error }"
            />
            <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
          </div>
          <div v-else>
            <label>{{ resident.lastTexture.humanType }}</label>
          </div>
        </dt>

        <div class="pl-3 pt-2" v-if="editTexture">
          <b-button variant="danger" size="sm" @click="cancelEdit" v-if="editTexture"
            >Annuler</b-button
          >
          <b-button variant="primary" size="sm" @click="createTexture" v-if="editTexture"
            >Valider</b-button
          >
        </div>
      </dl>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'Texture-Form',
  mixins: [validationMixin],
  props: ['resident', 'readonly'],
  data() {
    return {
      editTexture: false,
      texture: {
        humanType: null,
      },
    };
  },
  validations: {
    texture: {
      humanType: {
        required,
      },
    },
  },
  methods: {
    async createTexture() {
      this.$v.texture.$touch();
      if (this.$v.$anyError) return;
      this.editTexture = false;
      await this.$store.dispatch('createTexture', {
        tType: this.texture.humanType,
        residentId: this.resident.id,
      }).then((result) => {
        this.$v.$reset();
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
    cancelEdit() {
      this.editTexture = false;
    },
  },
  computed: {
    textureTypesOptions() {
      return [
        { value: null, text: 'Choisissez une texture', disabled: true },
        'Normal',
        'Mouliné / Mixé',
        'Haché',
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
