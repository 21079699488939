import gql from 'graphql-tag';

export const FETCH_MENUS = gql`
  query FETCH_MENUS($year: Int!, $ehpadId: ID!, $week: Int!) {
    menus(week: $week, ehpadId: $ehpadId, year: $year) {
      id
      date
      caloriesTotal
      proteinsTotal
      hydrationTotal
      totalPrice
      meals {
        id
        mealCategory {
          humanType
          name
        }
        totalPrice
        mealDishes {
          id
          calories
          proteins
          hydration
          price
          permanent
          dish {
            id
            name
            dType
            humanType
          }
          buffet {
            id
            name
            dType
            humanType
            averagePrice
          }
        }
      }
    }
  }
`;
