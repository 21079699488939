import gql from 'graphql-tag';

export const FETCH_RESIDENTS_FOR_SAMPLE = gql`
  query FETCH_RESIDENTS_FOR_SAMPLE($startDate: String!, $endDate: String!, $ehpadId: ID!) {
    residentsForSample(startDate: $startDate, endDate: $endDate, ehpadId: $ehpadId)
  }
`;

export const FETCH_NEW_RESIDENTS_FOR_SAMPLE = gql`
  query FETCH_NEW_RESIDENTS_FOR_SAMPLE($startDate: String!, $endDate: String!, $ehpadId: ID!) {
    newResidentsForSample(startDate: $startDate, endDate: $endDate, ehpadId: $ehpadId)
  }
`;

export const FETCH_AVERAGE_DIAGNOSTICS = gql`
  query FETCH_AVERAGE_DIAGNOSTICS($startDate: String!, $endDate: String!, $ehpadId: ID!) {
    averageDiagnostics(startDate: $startDate, endDate: $endDate, ehpadId: $ehpadId)
  }
`;

export const FETCH_AVERAGE_DIAGS_RESULTS_FOR_NEW_RESIDENTS = gql`
  query FETCH_AVERAGE_DIAGS_RESULTS_FOR_NEW_RESIDENTS($startDate: String!, $endDate: String!, $ehpadId: ID!) {
    averageDiagsResultsForNewResidents(startDate: $startDate, endDate: $endDate, ehpadId: $ehpadId)
  }
`;

export const FETCH_PERCENTAGE_BY_STATE = gql`
  query FETCH_PERCENTAGE_BY_STATE($date: String!, $ehpadId: ID!) {
    percentageByState(date: $date, ehpadId: $ehpadId)
  }
`;

export const FETCH_CALCULATE_RESIDENT_STATUS_CHANGE = gql`
  query FETCH_CALCULATE_RESIDENT_STATUS_CHANGE($startDate: String!, $endDate: String!, $ehpadId: ID!) {
    calculateResidentStatusChange(startDate: $startDate, endDate: $endDate, ehpadId: $ehpadId)
  }
`;

export const FETCH_CALCULATE_TOTAL_GAINS_AND_LOSSES_LEVELS = gql`
  query FETCH_CALCULATE_TOTAL_GAINS_AND_LOSSES_LEVELS($startDate: String!, $endDate: String!, $ehpadId: ID!) {
    calculateTotalGainsAndLossesLevels(startDate: $startDate, endDate: $endDate, ehpadId: $ehpadId)
  }
`;

export const FETCH_CALCULATE_AVERAGE_GAIN_AND_LOSS_DAYS = gql`
  query FETCH_CALCULATE_AVERAGE_GAIN_AND_LOSS_DAYS($startDate: String!, $endDate: String!, $ehpadId: ID!) {
    calculateAverageGainAndLossDays(startDate: $startDate, endDate: $endDate, ehpadId: $ehpadId)
  }
`;
