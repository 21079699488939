<template>
  <b-modal
    v-bind="$attrs"
    :title="modalTitle"
    size="lg"
    class="col-12 text-center"
  >
    <b-container fluid>
      <b-table
        bordered
        striped
        small
        hover
        :items="data"
        :fields="fields"
      >

      <template #cell(actions)="data">
        <b-button
          variant="link"
          size="md"
          :to="{ path: `/ehpads/CDC/resident/${data.item.id}/diagnostics` }"
          v-b-tooltip.hover.v-primary.noninteractive
          title="Données personnelles"
        >
          <i class="fas fa-chart-line" />
        </b-button>
      </template>

      </b-table>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      fields: [
        {
          key: "firstName",
          label: "Prénom",
          sortable: true,
          tdClass: "align-middle",
          thClass: "align-middle",
        },
        {
          key: "name",
          label: "Nom",
          sortable: true,
          tdClass: "align-middle",
          thClass: "align-middle",
        },
        {
          key: "diagnosticsCount",
          label: "Nombre de diagnostics",
          sortable: true,
          tdClass: "align-middle",
          thClass: "align-middle",
        },
        {
          key: "actions",
          label: "Actions",
          tdClass: "align-middle",
          thClass: "align-middle",
        }
      ],
    };
  },
  computed: {
    modalTitle() {
      return "Détails des résidents";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
