import gql from 'graphql-tag';

export const CREATE_DIAGNOSTIC = gql`
  mutation CREATE_DIAGNOSTIC(
      $date: String!,
      $state: String!,
      $comment: String!,
      $nriState: String,
      $has2007State: String,
      $has2021State: String,
      $residentId: ID!,
      $nri: Float
    ) {
    createDiagnostic(
      input: {
        date: $date,
        state: $state,
        nriState: $nriState,
        has2007State: $has2007State,
        has2021State: $has2021State,
        comment: $comment,
        residentId: $residentId,
        nri: $nri
      }
    ) {
      diagnostic {
        id
        date
        state
        nriState
        has2007State
        has2021State
        comment
        user {
          email
        }
        nri
      }
    }
  }
`;
