import gql from 'graphql-tag';

export const UPDATE_DISH = gql`
  mutation UPDATE_DISH($id: ID!, $name: String!, $defaultProteins: Int, $defaultCalories: Int, $defaultHydration: Int, $price: Float, $active: Boolean!, $changePast: Boolean!, $dType: String!) {
    updateDish(
      input: {
        id: $id
        name: $name
        dType: $dType
        defaultProteins: $defaultProteins
        defaultCalories: $defaultCalories
        defaultHydration: $defaultHydration
        price: $price
        active: $active
        changePast: $changePast
      }
    ) {
      dish {
        id
        name
        humanType
        dType
        active
        defaultCalories
        defaultProteins
        defaultHydration
        price
      }
    }
  }
`;

export const ARCHIVE_DISH = gql`
  mutation ARCHIVE_DISH($id: ID!) {
    archiveDish(
      input: {
        id: $id
      }
    ) {
      dish {
        id
      }
    }
  }
`;

export const CREATE_DISH = gql`
  mutation CREATE_DISH($name: String!, $defaultProteins: Int, $defaultCalories: Int, $defaultHydration: Int, $price: Float, $active: Boolean!, $ehpadId: ID!, $dType: String!) {
    createDish(
      input: {
        name: $name
        defaultProteins: $defaultProteins
        defaultCalories: $defaultCalories
        defaultHydration: $defaultHydration
        price: $price
        active: $active
        ehpadId: $ehpadId
        dType: $dType
      }
    ) {
      dish {
        id
        name
        humanType
        dType
        active
        defaultCalories
        defaultProteins
        defaultHydration
        price
      }
    }
  }
`;

export const DESTROY_DEFAULT_DISH = gql`
  mutation DESTROY_DEFAULT_DISH($id: ID!, $mealCategory: String!) {
    destroyDefaultDish(input: {id: $id, mealCategory: $mealCategory}) {
      dish {
        id
      }
    }
  }
`;

export const ADD_DEFAULT_DISH = gql`
  mutation ADD_DEFAULT_DISH($id: ID!, $mealCategory: String!) {
    addDefaultDish(input: {id: $id, mealCategory: $mealCategory}) {
      dish {
        id
      }
    }
  }
`;
