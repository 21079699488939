import Vue from 'vue';
import Vuex from 'vuex';

import users from './modules/users';
import ehpads from './modules/ehpads';
import residents from './modules/residents';
import dishes from './modules/dishes';
import buffets from './modules/buffets';
import supplementations from './modules/supplementations';
import rooms from './modules/rooms';
import sectors from './modules/sectors';
import surveys from './modules/surveys';
import globalSettings from './modules/globalSettings';
import testTypes from './modules/testTypes';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    users,
    ehpads,
    residents,
    dishes,
    buffets,
    supplementations,
    rooms,
    sectors,
    globalSettings,
    surveys,
    testTypes,
  },
});
