<template>
  <b-modal v-bind="$attrs" title="Sortie du résident" size="lg" class="col-12 text-center">
    <b-container fluid>

      <b-form>
          <b-row class="my-2">
            <b-col cols="4">
              <label for="status-category" class="text-nowrap">Status : </label>
            </b-col>
            <b-col cols="8">
              <b-form-select
                id="status-category"
                v-model="statusCategory"
                :options="statusCategoriesOptions"
                :class="{'is-invalid': $v.statusCategory.$error}"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>Selectionner un status</b-form-select-option>
                </template>
              </b-form-select>

              <b-form-invalid-feedback>Status Requis</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="my-2">
            <b-col cols="4">
              <label for="status-category" class="text-nowrap">Date : </label>
            </b-col>
            <b-col cols="8">
              <b-form-datepicker
                id="date-datepicker-status"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                label-help
                label-no-date-selected="Aucune date sélectionnée"
                size="sm"
                :max="today"
                :min="lastStatusDatePlusOneDay"
                v-model="residentStatus.date"
                locale="fr"
              />
            </b-col>
          </b-row>

          <b-row class="my-2">
            <b-col cols="4">
              <label for="resident-status-description" class="text-nowrap">Description (optionnel) </label>
            </b-col>
            <b-col cols="8">
                <b-form-textarea
                  id="resident-status-description"
                  size="sm"
                  type="text"
                  rows="4"
                  v-model="residentStatus.description"
                />
            </b-col>
          </b-row>

        <div v-if="error" style="color: red;" class="text-center mt-2">
          <p v-for="(error, i) of error.graphQLErrors" :key="i">
            {{ error.message }}
          </p>
        </div>

        <b-button class="d-flex mx-auto" variant="primary" size="sm" @click.prevent="createResidentStatus"
          >Valider</b-button
        >
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
  mixins: [validationMixin],
  props: ['editableResident'],
  data() {
    return {
      error: null,
      statusCategory: null,
      residentStatus: {
        description: null,
        date: null,
      },
    };
  },
  validations: {
    statusCategory: {
      id: {
        required,
      },
      name: {
        required,
      },
    },
  },
  created() {
    this.residentStatus.date = this.today;
  },
  methods: {
    ...mapActions(['setRooms']),
    async createResidentStatus() {
      this.$v.statusCategory.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('createResidentStatus', {
        residentId: this.editableResident.id,
        statusCategory: this.statusCategory,
        residentStatus: this.residentStatus,
      }).then((result) => {
        this.resetData();
        this.$v.$reset();
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      await this.setRooms(this.currentEhpad.id);
    },
    resetData() {
      // For resetting data object
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      currentUser: 'user',
    }),
    today() {
      const now = new Date();
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return moment(date).format('YYYY-MM-DD');
    },
    lastStatusDatePlusOneDay() {
      return moment(this.editableResident.lastStatus.date).add(1, 'd').format('YYYY-MM-DD');
    },
    statusCategoriesOptions() {
      return this.currentEhpad.statusCategories.map((el) => ({
        text: el.name,
        value: {
          id: el.id,
          name: el.name,
        },
      }));
    },
  },
};
</script>

<style lang="scss" scoped></style>
