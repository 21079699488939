import _ from 'lodash';

import apiClient from '../../utilities/api_client';
import { FETCH_SUPPLEMENTATIONS } from '../../graphql/supplementations/queries';
import { UPDATE_SUPPLEMENTATION, CREATE_SUPPLEMENTATION } from '../../graphql/supplementations/mutations';

const supplementationState = {
  supplementations: [],
};

const mutations = {
  SET_SUPPLEMENTATIONS(state, supplementations) {
    state.supplementations = supplementations;
  },
  UPDATE_SUPPLEMENTATION(state, supplementation) {
    const index = state.supplementations.findIndex((e) => e.id === supplementation.id);
    state.supplementations.splice(index, 1, supplementation);
  },
  ADD_SUPPLEMENTATION(state, supplementation) {
    state.supplementations.push(supplementation);
  },
};

const actions = {
  async setSupplementations({ commit }, ehpadId) {
    const { data } = await apiClient.query({
      query: FETCH_SUPPLEMENTATIONS,
      variables: { ehpadId },
      // network-only for fetching queries BECAUSE apollo use its own cache and we use vuex : conflict
      fetchPolicy: 'network-only',
    });
    commit('SET_SUPPLEMENTATIONS', data.supplementations);
  },
  async updateSupplementation({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_SUPPLEMENTATION,
      variables: { ...formData },
    });
    const { supplementation } = data.updateSupplementation;
    commit('UPDATE_SUPPLEMENTATION', supplementation);
  },
  async createSupplementation({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: CREATE_SUPPLEMENTATION,
      variables: { ...formData },
    });
    const { supplementation } = data.createSupplementation;
    commit('ADD_SUPPLEMENTATION', supplementation);
  },
};

const getters = {
  sortedSupplementationsByName(state) {
    return _.sortBy(state.supplementations, (supplementation) => supplementation.name.toLowerCase());
  },
  supplementations: (state) => state.supplementations,
};

export default {
  state: supplementationState,
  mutations,
  actions,
  getters,
};
