<template>
  <b-col cols="12" class="meal-settings-card p-3 m-1">
    <h6 class="text-center"><strong>{{ this.mealCategoryHuman.toUpperCase() }}</strong></h6>
    <div class="text-center" v-if="(defaultDishes && defaultDishes.length === 0) && (defaultBuffets && defaultBuffets.length === 0)">
      <i>Pas de plat ou de buffet par défaut pour ces repas</i>
    </div>
    <template v-if="defaultDishes && defaultDishes.length > 0">
      <p class="mb-1 text-center"><u>Plats</u></p>
      <div
        v-for="defaultDish in defaultDishes"
        class="m-0 d-flex justify-content-between"
        :key="defaultDish.id"
      >
        <li>
          <span style="font-size: smaller">{{`${defaultDish.name} (${defaultDish.humanType})`}}</span>
          <div class="text-nowrap" style="width: 350px;">
            <span class="calories"><img src="/assets/images/calories.png" class="cal-icon"> {{ defaultDish.defaultCalories }} kcal</span>
            |
            <span class="proteins"><img src="/assets/images/proteins.png" class="prot-icon"> {{ defaultDish.defaultProteins }} g</span>
            |
            <span class="hydration"><img src="/assets/images/hydration.png" class="hydration-icon"> {{ defaultDish.defaultHydration }} ml</span>
          </div>
        </li>
        <b-button
          variant="link"
          size="sm"
          class="text-danger fa fa-trash-alt"
          @click="onDestroyDefaultDish(defaultDish)"
        />
      </div>
    </template>

    <template v-if="defaultBuffets && defaultBuffets.length > 0">
      <p class="mb-1 text-center"><u>Buffets</u></p>
      <div
        v-for="defaultBuffet in defaultBuffets"
        class="m-0 d-flex justify-content-between"
        :key="defaultBuffet.id"
      >
        <li>
          <span style="font-size: smaller">{{`${defaultBuffet.name} (${defaultBuffet.humanType})`}}</span>
          <div class="text-nowrap" style="width: 350px;">
            <span class="calories"><img src="/assets/images/calories.png" class="cal-icon"> {{ defaultBuffet.defaultCalories }} kcal</span>
            |
            <span class="proteins"><img src="/assets/images/proteins.png" class="prot-icon"> {{ defaultBuffet.defaultProteins }} g</span>
            |
            <span class="hydration"><img src="/assets/images/hydration.png" class="hydration-icon"> {{ defaultBuffet.defaultHydration }} ml</span>
          </div>
        </li>
        <b-button
          variant="link"
          size="sm"
          class="text-danger fa fa-trash-alt"
          @click="onDestroyDefaultBuffet(defaultBuffet)"
        />
      </div>
    </template>

    <div>
      <b-form class="d-flex mt-2">
        <app-dishes-buffets-multiselect
          :options="activeDishesAndBuffetsByTypeOptions"
          :onChange="setDishOrBuffet"
          :dataToWatch="dishOrBuffet"
        />
        <b-button variant="primary" @click.prevent="onSubmit" :disabled="loading">
          <i :class="loading ? 'fa fa-spin fa-circle-notch' : 'fa fa-plus'" />
        </b-button>
      </b-form>
    </div>

  </b-col>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import EventBus from '../../utilities/event_bus';
import DishesBuffetsMultiselect from '../shared/DishesBuffetsMultiselect.vue';

import { DEFAULT_DISHES } from '../../graphql/dishes/queries';
import { DEFAULT_BUFFETS } from '../../graphql/buffets/queries';
import { DESTROY_DEFAULT_DISH, ADD_DEFAULT_DISH } from '../../graphql/dishes/mutations';
import { DESTROY_DEFAULT_BUFFET, ADD_DEFAULT_BUFFET } from '../../graphql/buffets/mutations';

export default {
  props: ['mealCategory', 'mealCategoryHuman'],
  data() {
    return {
      dishOrBuffet: null,
      loading: false,
    };
  },
  components: {
    appDishesBuffetsMultiselect: DishesBuffetsMultiselect,
  },
  apollo: {
    defaultDishes: {
      query: DEFAULT_DISHES,
      variables() {
        return {
          ehpadId: this.currentEhpad.id,
          mealCategory: this.mealCategory,
        };
      },
    },
    defaultBuffets: {
      query: DEFAULT_BUFFETS,
      variables() {
        return {
          ehpadId: this.currentEhpad.id,
          mealCategory: this.mealCategory,
        };
      },
    },
  },
  methods: {
    setDishOrBuffet(value) {
      this.dishOrBuffet = value;
    },
    onDestroyDefaultDish(defaultDish) {
      Swal.fire({
        title: 'Êtes vous sûr ?',
        text:
          'Êtes vous sûr de vouloir supprimer ce plat permanent ? Il ne sera plus ajouté automatiquement dans les futurs menus.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          await this.$apollo.mutate({
            mutation: DESTROY_DEFAULT_DISH,
            variables: {
              id: defaultDish.id,
              mealCategory: this.mealCategory,
            },
          }).catch((error) => {
              this.flashMessage.show({
                status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
          await this.$apollo.queries.defaultDishes.refetch();
          EventBus.$emit('refetch-menus');
        }
      });
    },
    onDestroyDefaultBuffet(defaultBuffet) {
      Swal.fire({
        title: 'Êtes vous sûr ?',
        text:
          'Êtes vous sûr de vouloir supprimer ce buffet permanent ? Il ne sera plus ajouté automatiquement dans les futurs menus.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          await this.$apollo.mutate({
            mutation: DESTROY_DEFAULT_BUFFET,
            variables: {
              id: defaultBuffet.id,
              mealCategory: this.mealCategory,
            },
          }).catch((error) => {
            this.flashMessage.show({
              status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
          await this.$apollo.queries.defaultBuffets.refetch();
          EventBus.$emit('refetch-menus');
        }
      });
    },
    async onSubmit() {
      if (this.dishOrBuffet === null) return;
      this.loading = true;
      if (this.dishOrBuffet.type === 'Dish') {
        await this.$apollo.mutate({
          mutation: ADD_DEFAULT_DISH,
          variables: {
            id: this.dishOrBuffet.id,
            mealCategory: this.mealCategory,
          },
        }).then((result) => {
          this.dishOrBuffet = null;
        }).catch((error) => {
          this.flashMessage.show({
            status: 'error',
            title: error.graphQLErrors[0].extensions.code,
            message: error.graphQLErrors[0].message,
          });
        });
      } else if (this.dishOrBuffet.type === 'Buffet') {
        await this.$apollo.mutate({
          mutation: ADD_DEFAULT_BUFFET,
          variables: {
            id: this.dishOrBuffet.id,
            mealCategory: this.mealCategory,
          },
        }).then((result) => {
          this.dishOrBuffet = null;
        }).catch((error) => {
          this.flashMessage.show({
            status: 'error',
            title: error.graphQLErrors[0].extensions.code,
            message: error.graphQLErrors[0].message,
          });
        });
      }
      await this.$apollo.queries.defaultDishes.refetch();
      await this.$apollo.queries.defaultBuffets.refetch();
      this.loading = false;
      EventBus.$emit('refetch-menus');
    },
  },
  computed: {
    ...mapGetters({
      activeDishesAndBuffetsByType: 'activeDishesAndBuffetsByType',
      currentEhpad: 'ehpad',
    }),
    activeDishesAndBuffetsByTypeOptions() {
      return this.activeDishesAndBuffetsByType.map((el) => ({
        text: `${el.name} (${el.humanType})`,
        id: el.id,
        proteins: el.defaultProteins,
        calories: el.defaultCalories,
        hydration: el.defaultHydration,
        type: el.__typename,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>

.meal-settings-card {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.calories {
  font-size: 0.8rem;
  color: green;
}
.proteins {
  font-size: 0.8rem;
  color: red;
}
.hydration {
  font-size: 0.8rem;
  color: blue;
}
.hydration-icon {
  filter: invert(58%) sepia(85%) saturate(7456%) hue-rotate(234deg) brightness(99%) contrast(103%);
  width: 8%;
  height: 46%;
  max-width: 35px;
}
.cal-icon {
  filter: invert(21%) sepia(91%) saturate(5127%) hue-rotate(118deg) brightness(94%) contrast(105%);
  width: 8%;
  height: 46%;
  max-width: 35px;
}
.prot-icon {
  filter: invert(46%) sepia(86%) saturate(2816%) hue-rotate(330deg) brightness(113%) contrast(109%);
  width: 8%;
  height: 46%;
  max-width: 35px;
}

</style>
