<template>
  <div>
    <h5 class="text-center" v-if="!noTitle">Calories</h5>
    <line-chart :data="chartData" class="mb-5" :points=false :library="options" :height="height"></line-chart>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    noTitle: {
      type: Boolean,
      default: false,
    },
    residentMenusForCharts: {
      type: Array,
      required: true,
    },
    nutritionGoals: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: '300px',
    },
  },
  computed: {
    chartData() {
      const caloriesIngested = _.map(this.residentMenusForCharts, (v) => ({
        date: v.date,
        value: v.caloriesIngested,
      }));
      const caloriesTotal = _.map(this.residentMenusForCharts, (v) => ({
        date: v.date,
        value: v.caloriesTotal,
      }));
      const caloriesGoal = _.map(caloriesIngested, (v) => ({
        date: v.date,
        value: this.buildCaloriesGoal(v.date),
      }));
      return [
        {
          name: 'Calories ingérées',
          data: caloriesIngested.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#469be0',
        },
        {
          name: 'Calories servies',
          data: caloriesTotal.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#FF9900',
        },
        {
          name: 'Cible nutritionnelle (calories)',
          data: caloriesGoal.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#DC3912',
        },
      ];
    },
  },
  methods: {
    buildCaloriesGoal(date) {
      let index = 0;
      if (this.nutritionGoals.length === 1) return this.nutritionGoals[0].calories;
      do {
        if (moment(date).isBefore(this.nutritionGoals[index + 1].date)) {
          return this.nutritionGoals[index].calories;
        }
        index++;
      } while (this.nutritionGoals[index + 1] !== undefined);
      return this.nutritionGoals.slice(-1)[0].calories;
    },
  },
};
</script>

<style scoped></style>
