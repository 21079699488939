var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"text-center mb-3"},[_vm._v("\n    Residents sans lit\n    "),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}}],staticClass:"fas fa-exclamation-triangle",staticStyle:{"color":"red","font-size":"smaller"},attrs:{"title":"Les menus ne se répliquent pas pour les résidents sans lit. Il est nécessaire d'assigner un lit à un résident pour pouvoir utiliser le logiciel"}})]),_vm._v(" "),_c('b-form-group',{staticClass:"mb-3",staticStyle:{"width":"40%"},attrs:{"label-size":"sm","label-for":"filterInput"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"type":"search","id":"filterInput","placeholder":"Filtre de recherche"},model:{value:(_vm.filterNoRoomTable),callback:function ($$v) {_vm.filterNoRoomTable=$$v},expression:"filterNoRoomTable"}}),_vm._v(" "),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filterNoRoomTable},on:{"click":function($event){_vm.filterNoRoomTable = ''}}},[_vm._v("Effacer")])],1)],1)],1),_vm._v(" "),_c('b-table',{ref:"residentTable",staticClass:"m-0",attrs:{"filter":_vm.filterNoRoomTable,"sticky-header":"800px","bordered":"","striped":"","small":"","hover":"","items":_vm.residentsWithoutRoom,"fields":_vm.fields,"id":"residentTable"},scopedSlots:_vm._u([{key:"cell(lastStatus)",fn:function(data){return [(data.item.lastStatus === null)?_c('span',[_vm._v("Pas de status")]):_vm._e(),_vm._v(" "),(data.item.lastStatus !== null)?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.dataWithDate(
            data.item.lastStatus.statusCategory.name,
            data.item.lastStatus.date,
          )
        )}}):_vm._e()]}},{key:"cell(lastWeight)",fn:function(data){return [(data.item.lastDiagnostic.state == 'severe_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastWeight,
            _vm.currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_weight_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'moderate_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastWeight,
            _vm.currentEhpad.allSettings.moderate_undernutrition.constants_config.minimum_weight_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'high_risk')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastWeight,
            _vm.currentEhpad.allSettings.high_risk.constants_config.minimum_weight_value_taking_in_days,
          )
        )}}):_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastWeight,
            _vm.currentEhpad.allSettings.default_undernutrition.constants_config.minimum_weight_value_taking_in_days,
          )
        )}})]}},{key:"cell(lastMna)",fn:function(data){return [(data.item.lastDiagnostic.state == 'severe_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastMna,
            _vm.currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_mna_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'moderate_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastMna,
            _vm.currentEhpad.allSettings.moderate_undernutrition.constants_config.minimum_mna_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'high_risk')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastMna,
            _vm.currentEhpad.allSettings.high_risk.constants_config.minimum_mna_value_taking_in_days,
          )
        )}}):_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastMna,
            _vm.currentEhpad.allSettings.default_undernutrition.constants_config.minimum_mna_value_taking_in_days,
          )
        )}})]}},{key:"cell(lastCrp)",fn:function(data){return [(data.item.lastDiagnostic.state == 'severe_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastCrp,
            _vm.currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_crp_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'moderate_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastCrp,
            _vm.currentEhpad.allSettings.moderate_undernutrition.constants_config.minimum_crp_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'high_risk')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastCrp,
            _vm.currentEhpad.allSettings.high_risk.constants_config.minimum_crp_value_taking_in_days,
          )
        )}}):_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastCrp,
            _vm.currentEhpad.allSettings.default_undernutrition.constants_config.minimum_crp_value_taking_in_days,
          )
        )}})]}},{key:"cell(lastAlbumine)",fn:function(data){return [(data.item.lastDiagnostic.state == 'severe_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastAlbumine,
            _vm.currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_albumine_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'moderate_undernutrition')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastAlbumine,
            _vm.currentEhpad.allSettings.moderate_undernutrition
              .constants_config.minimum_albumine_value_taking_in_days,
          )
        )}}):(data.item.lastDiagnostic.state == 'high_risk')?_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastAlbumine,
            _vm.currentEhpad.allSettings.high_risk.constants_config.minimum_albumine_value_taking_in_days,
          )
        )}}):_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.coloredDataHtml(
            data.item.lastAlbumine,
            _vm.currentEhpad.allSettings.default_undernutrition.constants_config.minimum_albumine_value_taking_in_days,
          )
        )}})]}},{key:"cell(lastDiagnostic)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.coloredDiagHtml(data.item.lastDiagnostic.humanState))}})]}},{key:"head(actions)",fn:function(){return [_vm._v(_vm._s(''))]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary.noninteractive",modifiers:{"hover":true,"v-primary":true,"noninteractive":true}}],attrs:{"variant":"link","size":"md","to":{ path: `/ehpads/${_vm.currentEhpad.shortname}/resident/${data.item.id}/diagnostics` },"title":"Données personnelles"}},[_c('i',{staticClass:"fas fa-chart-line"})]),_vm._v(" "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary.noninteractive",modifiers:{"hover":true,"v-primary":true,"noninteractive":true}}],attrs:{"variant":"link","size":"md","to":{ path: `/ehpads/${_vm.currentEhpad.shortname}/resident/${data.item.id}/nutrition` },"title":"Nutrition"}},[_c('i',{staticClass:"fas fa-utensils"})]),_vm._v(" "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary.noninteractive",modifiers:{"hover":true,"v-primary":true,"noninteractive":true}}],attrs:{"variant":"link","size":"md","to":{
          path: `/ehpads/${_vm.currentEhpad.shortname}/resident/${data.item.id}/menus/${_vm.currentYear}/${_vm.currentWeek}`,
        },"title":"Menus du résident"}},[_c('i',{staticClass:"far fa-calendar-alt"})])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }