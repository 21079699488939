<template>
  <div>
    <b-row v-if="residentMenusForCharts !== undefined">
      <b-col class="col-2">Intervalle</b-col>
      <b-col class="col-2">
        <div>
          <b-form-select
            v-model="rangeForNutritionChart"
            :options="chartRangeOptions"
            @change="resetChartData"
          />
        </div>
      </b-col>
    </b-row>
    <div v-if="residentMenusForCharts !== undefined">
      <CaloriesChart :residentMenusForCharts="residentMenusForCharts" :nutritionGoals="nutritionGoals" :options="options" />
      <ProteinsChart :residentMenusForCharts="residentMenusForCharts" :nutritionGoals="nutritionGoals" :options="options" />
      <HydrationChart :residentMenusForCharts="residentMenusForCharts" :nutritionGoals="nutritionGoals" :options="options" />
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="residentMenusForCharts === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import CaloriesChart from './charts/Calories.vue';
import ProteinsChart from './charts/Proteins.vue';
import HydrationChart from './charts/Hydration.vue';
import { FETCH_RESIDENT_MENUS_FOR_CHARTS } from '../../graphql/resident_menus/queries';

export default {
  components: {
    CaloriesChart,
    ProteinsChart,
    HydrationChart,
  },
  props: ['nutritionGoals', 'residentId'],
  data() {
    return {
      rangeForNutritionChart: { months: null, year: null },
      options: {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              displayFormats: {
                day: 'DD MMM',
              },
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  apollo: {
    residentMenusForCharts: {
      query: FETCH_RESIDENT_MENUS_FOR_CHARTS,
      variables() {
        return {
          residentId: this.residentId,
          year: parseInt(this.rangeForNutritionChart.year, 10),
          months: parseInt(this.rangeForNutritionChart.months, 10),
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    resetChartData() {
      this.residentMenusForCharts = undefined;
    },
  },
  computed: {
    chartRangeOptions() {
      return [
        { value: { months: null, year: null }, text: '3 mois glissants' },
        { value: { months: 6, year: null }, text: '6 mois glissants' },
        { value: { months: 12, year: null }, text: '12 mois glissants' },
        { value: { months: null, year: moment().year() - 2 }, text: moment().year() - 2 },
        { value: { months: null, year: moment().year() - 1 }, text: moment().year() - 1 },
        { value: { months: null, year: moment().year() }, text: moment().year() },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
