<template>
  <div>
    <b-row v-if="averageResidentsPricesForChartsByMealCategories !== undefined">
      <b-col class="col-2">Intervalle </b-col>
      <b-col class="col-2">
        <div>
          <b-form-select
            v-model="rangeForPriceChartByMealCategories"
            :options="chartRangeOptions"
            @change="averageResidentsPricesForChartsByMealCategories = undefined"
          />
        </div>
      </b-col>
    </b-row>
    <div v-if="averageResidentsPricesForChartsByMealCategories !== undefined" class="mt-3">
      <line-chart :data="dataPricesForChartsByMealCategories" suffix="€" :points=false :library="options"></line-chart>
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="averageResidentsPricesForChartsByMealCategories === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { FETCH_EHPAD_AVERAGE_RESIDENTS_PRICES_FOR_CHARTS_BY_MEAL_CATEGORIES } from '../../graphql/dashboard/queries';

export default {
  data() {
    return {
      rangeForPriceChartByMealCategories: { months: null, year: null },
      dataPricesForChartsByMealCategories: [],
      options: {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              displayFormats: {
                day: 'DD MMM YYYY',
              },
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  apollo: {
    averageResidentsPricesForChartsByMealCategories: {
      query: FETCH_EHPAD_AVERAGE_RESIDENTS_PRICES_FOR_CHARTS_BY_MEAL_CATEGORIES,
      variables() {
        return {
          ehpadId: this.currentEhpad.id,
          year: parseInt(this.rangeForPriceChartByMealCategories.year, 10),
          months: parseInt(this.rangeForPriceChartByMealCategories.months, 10),
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  watch: {
    averageResidentsPricesForChartsByMealCategories: {
      handler() {
        if (this.averageResidentsPricesForChartsByMealCategories !== undefined) {
          this.dataPricesForChartsByMealCategories = this.buildDataPricesForChartsByMealCategories;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    chartRangeOptions() {
      return [
        { value: { months: null, year: null }, text: '3 mois glissants' },
        { value: { months: 6, year: null }, text: '6 mois glissants' },
        { value: { months: 12, year: null }, text: '12 mois glissants' },
        { value: { months: null, year: moment().year() - 2 }, text: moment().year() - 2 },
        { value: { months: null, year: moment().year() - 1 }, text: moment().year() - 1 },
        { value: { months: null, year: moment().year() }, text: moment().year() },
      ];
    },
    buildDataPricesForChartsByMealCategories() {
      const datesArray = Object.keys(this.averageResidentsPricesForChartsByMealCategories);
      const fetchValuesForField = (field) => datesArray.map(date => [date, _.get(this.averageResidentsPricesForChartsByMealCategories[date], field, 0)]);

      return [
        {
          name: 'Petit déjeuner',
          data: fetchValuesForField('Petit déjeuner').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#ff6347',
        },
        {
          name: 'Collation du matin',
          data: fetchValuesForField('Collation du matin').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#f08080',
        },
        {
          name: 'Déjeuner',
          data: fetchValuesForField('Déjeuner').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#A9A9A9',
        },
        {
          name: 'Goûter',
          data: fetchValuesForField('Goûter').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#FDB631',
        },
        {
          name: 'Dîner',
          data: fetchValuesForField('Dîner').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#32B796',
        },
        {
          name: 'Collation du soir',
          data: fetchValuesForField('Collation du soir').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#6f42c1',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
