<template>
    <div>
        <app-supplementations-table/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SupplementationsTable from '../../components/supplementations/Table.vue';

export default {
  components: {
    appSupplementationsTable: SupplementationsTable,
  },
  methods: {
    ...mapActions(['setSupplementations']),
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
  created() {
    this.setSupplementations(this.currentEhpad.id);
  },
};
</script>

<style lang="scss" scoped>
</style>
