<template>
  <b-form>
    <b-row class="mb-1">
      <b-col cols="4" class="my-auto">
        <label for="rating-inline" class="small"> {{ gustatoryCategory.name }}</label>
      </b-col>
      <b-col cols="8">
        <b-form-rating
          :id="gustatoryCategory.name"
          inline
          v-model="value"
          @change="createGustatoryPerception()"
        >
        </b-form-rating>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'Gustatory-Item',
  props: ['resident', 'gustatoryCategory'],
  data() {
    return {
      value: 0,
      error: null,
    };
  },
  created() {
    this.value = 0;
    if (this.resident.gustatoryPerceptions !== null &&
        this.resident.gustatoryPerceptions.length > 0) {
      const obj = _.find(this.resident.gustatoryPerceptions, { gustatoryCategoryId: this.gustatoryCategory.id });
      this.value = _.get(obj, 'value', 0);
    }
  },
  watch: {
    resident: {
      handler() {
        this.value = 0;
        if (this.resident.gustatoryPerceptions !== null &&
            this.resident.gustatoryPerceptions.length > 0) {
          const obj = _.find(this.resident.gustatoryPerceptions, { gustatoryCategoryId: this.gustatoryCategory.id });
          this.value = _.get(obj, 'value', 0);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async createGustatoryPerception() {
      await this.$store.dispatch('createGustatoryPerception', {
        value: this.value,
        residentId: this.resident.id,
        gustatoryCategoryId: this.gustatoryCategory.id,
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
