<template>
  <li>
    <p class="m-0 d-flex justify-content-between">

      <span style="font-size: medium;">
        <span v-if="residentMealDish.dish">
          {{ residentMealDish.dish.name }}
        <i
          v-if="residentMealDish.globalPermanent"
          class="fas fas fa-anchor ml-2"
          style="color: #5bc0de;"
          v-b-tooltip.hover.v-primary title="Plat permanent du menu global"
        />
        <i
          v-if="residentMealDish.residentPermanent"
          class="fas fas fa-anchor ml-2"
          style="color: orange;"
          v-b-tooltip.hover.v-primary title="Plat permanent du résident"
        />
        </span>

        <span v-if="residentMealDish.buffet">
          {{ residentMealDish.buffet.name }}
          <i class="far fa-list-alt"
            style="color: #5bc0de;"
            v-b-tooltip.hover.v-primary
            title="Buffet"
          />
          <i
            v-if="residentMealDish.globalPermanent"
            class="fas fas fa-anchor ml-2"
            style="color: #5bc0de;"
            v-b-tooltip.hover.v-primary title="Buffet permanent du menu global"
          />
          <i
            v-if="residentMealDish.residentPermanent"
            class="fas fas fa-anchor ml-2"
            style="color: orange;"
            v-b-tooltip.hover.v-primary title="Buffet permanent du résident"
          />
        </span>

<!-- Supplementation uniquement sur les plats, pas sur les buffets -->
        <b-button
          v-if="residentMealDish.dish && menuEditState"
          variant="link"
          size="sm"
          v-b-modal="`create-rmds-modal${residentMealDish.id}`"
          class="fa fa-plus p-0 text-decoration-none"
          v-b-tooltip.hover.v-primary
          title="Ajouter une supplémention à ce plat"
        />

        <b-button
          variant="link"
          size="sm"
          class="far fa-comment-dots text-decoration-none"
          v-if="residentMealDish.comment !== null"
          v-b-tooltip.hover.v-primary
          :title="`${residentMealDish.comment} (de ${residentMealDish.user.email})`"
        />
      </span>

      <b-button
        variant="link"
        size="sm"
        :class="destroyClassesDish"
        @click="onDestroyDish"
        v-if="menuEditState"
      />
    </p>

    <template v-if="residentMealDish.residentMealDishSupplementations.length !== 0">
      <p
        v-for="residentMealDishSupplementation in residentMealDishSupplementations"
        class="m-0 d-flex justify-content-between"
        :key="residentMealDishSupplementation.id"
      >
        <span style="font-size: smaller; color: blue">
          <li class="ml-4">{{ residentMealDishSupplementation.supplementation.name }}</li>
        </span>

        <b-button
          variant="link"
          size="sm"
          :class="destroyClassesSupplementation"
          @click="onDestroySupplementation(residentMealDishSupplementation.id)"
          v-if="menuEditState"
          style="font-size: 0.7rem"
        />
      </p>
    </template>

    <p class="mb-1 dishInfos">
      <span class="calories">{{ displayCalories() }} kcal</span>
      |
      <span class="proteins">{{ displayProteins()}} g</span>
      |
      <span class="hydration">{{ displayHydration() }} ml</span>
      <template v-if="currentUser.role == 'administrateur' || currentUser.role == 'intendance'">
        |
        <span class="price small" v-if="!residentMealDish.buffet">{{ displayDishPrice() }} €</span>
        <span class="price small" v-if="residentMealDish.buffet">{{ displayBuffetPrice() }} €</span>
      </template>
    </p>

    <app-create-resident-meal-dish-supplementation-modal
      v-if="residentMealDish.dish && menuEditState"
      :id="`create-rmds-modal${residentMealDish.id}`"
      hide-footer
      :residentMealDish="residentMealDish"
      v-on:updated-supplementation="EventBus.$emit('refetch-resident-menus')"
    />
  </li>
</template>

<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import EventBus from '../../utilities/event_bus';
import CreateResidentMealDishSupplementationModal from './CreateResidentMealDishSupplementationModal.vue';
import { DESTROY_RESIDENT_MEAL_DISH } from '../../graphql/resident_meal_dishes/mutations';
import { DESTROY_RESIDENT_MEAL_DISH_SUPPLEMENTATION } from '../../graphql/resident_meal_dish_supplementations/mutations';

export default {
  name: 'Dish',
  props: ['residentMealDish', 'menuEditState'],
  components: {
    AppCreateResidentMealDishSupplementationModal: CreateResidentMealDishSupplementationModal,
  },
  data() {
    return {
      removingDish: false,
      removingSupplementation: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'user',
    }),
    destroyClassesDish() {
      return this.removingDish ? 'fa fa-circle-notch fa-spin' : 'text-danger fa fa-trash-alt';
    },
    destroyClassesSupplementation() {
      return this.removingSupplementation
        ? 'fa fa-circle-notch fa-spin'
        : 'text-danger fa fa-trash-alt';
    },
    residentMealDishSupplementations() {
      return this.residentMealDish.residentMealDishSupplementations;
    },
  },
  methods: {
    displayCalories() {
      return this.residentMealDish.caloriesWSuppl;
    },
    displayProteins() {
      return this.residentMealDish.proteinsWSuppl;
    },
    displayHydration() {
      return this.residentMealDish.hydrationWSuppl;
    },
    displayDishPrice() {
      if (this.residentMealDish.price || this.residentMealDish.price === 0) {
        return this.residentMealDish.price;
      }
      return '?';
    },
    displayBuffetPrice() {
      if (this.residentMealDish.buffet.averagePrice || this.residentMealDish.buffet.averagePrice === 0) {
        return this.residentMealDish.buffet.averagePrice;
      }
      return '?';
    },
    onDestroyDish() {
      Swal.fire({
        title: 'Êtes vous sûr ?',
        text: 'Êtes vous sûr de vouloir supprimer ce plat ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.removingDish = true;
          await this.$apollo.mutate({
            mutation: DESTROY_RESIDENT_MEAL_DISH,
            variables: {
              id: this.residentMealDish.id,
            },
          }).then((result) => {
            EventBus.$emit('refetch-resident-menus');
          }).catch((error) => {
            this.flashMessage.show({
              status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
          this.removingDish = false;
        }
      });
    },
    onDestroySupplementation(id) {
      Swal.fire({
        title: 'Êtes vous sûr ?',
        text: 'Êtes vous sûr de vouloir supprimer cette supplémentation ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.removingSupplementation = true;
          await this.$apollo.mutate({
            mutation: DESTROY_RESIDENT_MEAL_DISH_SUPPLEMENTATION,
            variables: {
              id,
            },
          }).then((result) => {
            EventBus.$emit('refetch-resident-menus');
          }).catch((error) => {
            this.flashMessage.show({
              status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
          this.removingSupplementation = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dishInfos {
  font-size: 0.95rem;
  .calories {
    color: green;
  }
  .proteins {
    color: red;
  }
  .hydration {
    color: blue;
  }
  .price {
    color: gray;
  }
}
</style>
