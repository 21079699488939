<template>
  <b-col xs="12" md="6" lg="3">
    <div class="menu-card d-flex flex-column">
      <div class="menu-card-date text-center">
        <h5 class="m-0 font-weight-bold">
          {{ capitalizeString(dateFormatter(residentMenu.date)) }}
        </h5>
        <p class="mb-1 dishInfos">
          <span class="calories"><img src="/assets/images/calories.png" class="cal-icon"> {{ residentMenu.caloriesTotal }} kcal</span>
          <i
            v-if="nutritionGoalAlertCal()"
            class="fas fa-exclamation-triangle"
            style="color: red;"
            v-b-tooltip.hover.v-danger
            title="Le total de calories pour ce menu est inférieur à l'objectif nutritionnel quotidien de ce résident"
          />
          |
          <span class="proteins"><img src="/assets/images/proteins.png" class="prot-icon"> {{ residentMenu.proteinsTotal }} g</span>
          <i
            v-if="nutritionGoalAlertProt()"
            class="fas fa-exclamation-triangle"
            style="color: red;"
            v-b-tooltip.hover.v-danger
            title="Le total de protéines pour ce menu est inférieur à l'objectif nutritionnel quotidien de ce résident"
          />
          |
          <span class="hydration"><img src="/assets/images/hydration.png" class="hydration-icon"> {{ residentMenu.hydrationTotal }} ml</span>
          <i
            v-if="nutritionGoalAlertHydration()"
            class="fas fa-exclamation-triangle"
            style="color: red;"
            v-b-tooltip.hover.v-danger
            title="Le total des apports hydriques pour ce menu est inférieur à l'objectif nutritionnel quotidien de ce résident"
          />
          <template v-if="currentUser.role == 'administrateur' || currentUser.role == 'intendance'">
            |
            <span class="price">{{ residentMenu.totalPrice }} €</span>
          </template>
        </p>
      </div>
      <app-resident-meal
        class="menu-card-meal"
        v-for="type in mealCategories"
        :key="type"
        :type="type"
        :residentMeal="findMeal(type)"
        :menuEditState="menuEditState"
      />
    </div>
  </b-col>
</template>

<script>
import { mapGetters } from 'vuex';
import { dateFormatter, capitalizeString } from '../../utilities/filters';
import ResidentMeal from './Meal.vue';

export default {
  name: 'ResidentMenuCard',
  props: ['residentMenu', 'menuEditState'],
  components: {
    appResidentMeal: ResidentMeal,
  },
  methods: {
    dateFormatter,
    capitalizeString,
    findMeal(type) {
      return this.residentMenu.residentMeals.find((el) => el.mealCategory.name === type);
    },
    nutritionGoalAlertCal() {
      if (this.residentMenu.caloriesTotal < this.resident.lastNutritionGoal.calories) {
        return true;
      }
      return false;
    },
    nutritionGoalAlertProt() {
      if (this.residentMenu.proteinsTotal < this.resident.lastNutritionGoal.proteins) {
        return true;
      }
      return false;
    },
    nutritionGoalAlertHydration() {
      if (this.residentMenu.hydrationTotal < this.resident.lastNutritionGoal.hydration) {
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapGetters({
      resident: 'resident',
      currentUser: 'user',
    }),
    mealCategories() {
      return ['breakfast', 'morning_snack', 'lunch', 'afternoon_snack', 'dinner', 'evening_snack'];
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-card {
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  &:last-child {
    margin-top: 10px;
  }
  &-date {
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  &-meal {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 5px;
  }
  &-meal:last-child {
    border-bottom: none;
  }
}
.dishInfos {
  font-size: 0.95rem;
  .calories {
    color: green;
  }
  .proteins {
    color: red;
  }
  .hydration {
    color: blue;
  }
  .price {
    color: gray;
  }
}
.hydration-icon {
  filter: invert(58%) sepia(85%) saturate(7456%) hue-rotate(234deg) brightness(99%) contrast(103%);
  width: 7%;
}
.cal-icon {
  filter: invert(21%) sepia(91%) saturate(5127%) hue-rotate(118deg) brightness(94%) contrast(105%);
  width: 7%;
}
.prot-icon {
  filter: invert(46%) sepia(86%) saturate(2816%) hue-rotate(330deg) brightness(113%) contrast(109%);
  width: 7%;
}
</style>
