<template v-on:scroll.passive="this.handleScroll">
  <div v-if="resident !== null">
    <div class="d-flex justify-content-between align-items-center mb-3 top-title">
      <b-button variant="primary" :to="{ path: formatButtonLink('prev') }" @click="loading = true;menuEditState = false">
        Semaine précédente
      </b-button>
      <b-col>
        <b-row align-h="center">
          <div class="text-center">
            <h5 class="m-0" style="display: inline-block;margin: 0;">
              Semaine {{ addNumPadding(this.week)  }} ({{ formattedDate }})
            </h5>
            <span>
              <h5 v-html="title" class="m-0"></h5>
            </span>
          </div>
        </b-row>
        <b-row align-h="center">
            <span class="text-center">
              <b-button class="mt-2" variant="primary" size="sm" v-if="canEditMenu()" @click="menuEditState = true">
              <i class="" />
                  Modifier le menu
              </b-button>
            </span>

        </b-row>
      </b-col>

      <b-button variant="primary" :to="{ path: formatButtonLink('next') }" @click="loading = true;menuEditState = false">
        Semaine suivante
      </b-button>


    </div>

    <div class="text-center" v-if="loading">
      <h4>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement...
      </h4>
    </div>

    <div class="text-center"  v-else-if="residentMenus.length === 0">
      <h5>
        Aucun menu pour ce résident à cette date
      </h5>
      <p> Si il s'agit d'un menu à venir, assurez vous que le menu global soit rempli</p>
    </div>

    <b-container id="container_menu-week" fluid v-else>
      <b-row>
        <app-resident-menu-card
          v-for="residentMenu in residentMenus"
          :key="residentMenu.id"
          :residentMenu="residentMenu"
          :menuEditState="menuEditState"
        />
      </b-row>
    </b-container>

    <div id="stickyName" class="hide">
      <span v-if="resident !== null" class="fixedElementRight d-print-none">
        <b-row align-h="end" style="background-color: white;">
          <div>
            <h5 v-html="title" class="m-0"></h5>
            <span style="font-size: medium;">Semaine {{ addNumPadding(this.week) }} ({{ formattedDate }})</span>
            <app-sidebar-info
              :resident="resident"
            />
          </div>
        </b-row>
      </span>
    </div>

  </div>
</template>

<script>
import moment from 'moment';

import { mapActions, mapGetters } from 'vuex';
import { addNumPadding, coloredDiagHtml } from '../../utilities/filters';
import { FETCH_RESIDENT_MENUS } from '../../graphql/resident_menus/queries';
import SidebarInfo from '../../components/residents/SidebarInfo.vue';
import ResidentMenuCard from '../../components/resident_menus/Card.vue';
import EventBus from '../../utilities/event_bus';

export default {
  name: 'ResidentMenu',
  props: {
    year: {
      type: String,
      required: true,
    },
    week: {
      type: String,
      required: true,
    },
    residentId: {
      type: String,
      required: true,
    },
  },
  components: {
    appResidentMenuCard: ResidentMenuCard,
    appSidebarInfo: SidebarInfo,
  },
  data() {
    return {
      menuEditState: false,
      loading: true,
    };
  },
  apollo: {
    residentMenus: {
      query: FETCH_RESIDENT_MENUS,
      variables() {
        return {
          residentId: this.residentId,
          year: parseInt(this.year, 10),
          week: parseInt(this.week, 10),
        };
      },
      fetchPolicy: 'network-only',
      result() {
        this.loading = false;
      },
    },
  },
  created() {
    moment.locale('fr-FR');
    window.addEventListener('scroll', this.handleScroll);
    this.setSupplementations(this.currentEhpad.id);
    this.findResident({
      id: this.residentId,
    });
    this.setDishes(this.currentEhpad.id);
    this.setBuffets(this.currentEhpad.id);
    EventBus.$on('refetch-resident-menus', async () => {
      await this.$apollo.queries.residentMenus.refetch();
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  // Useless : Scroll behavior is defined on the Vue Router config
  // mounted() {
  //   this.scrollToTop();
  // },
  methods: {
    addNumPadding,
    coloredDiagHtml,
    ...mapActions(['setDishes', 'setBuffets', 'findResident', 'setSupplementations']),
    // scrollToTop() {
    //   let el = null;
    //   [el] = this.$el.getElementsByClassName('top-title');
    //   if (el) {
    //     el.scrollIntoView();
    //     window.scrollBy(0, -220);
    //   }
    // },
    canEditMenu() {
      if (this.currentEhpad.allSettings.configuration.menus_config.can_edit_in_past) return true;
      if (this.monday() > moment().subtract(3, 'week')) return true;
      return false;
    },
    monday() {
      return moment()
        .year(this.year)
        .isoWeek(this.week)
        .startOf('isoWeek')
        .day('Lundi');
    },
    initNextButton() {
      const monday = this.monday();
      const nextMonday = monday.add(1, 'isoWeek');
      const week = nextMonday.isoWeek();
      const year = nextMonday.year();
      return { week, year };
    },
    initPrevButton() {
      const monday = this.monday();
      const prevMonday = monday.subtract(1, 'isoWeek');
      const week = prevMonday.isoWeek();
      const year = prevMonday.year();
      return { week, year };
    },
    formatButtonLink(type) {
      let week;
      let year;
      let data;
      if (type === 'next') {
        data = this.initNextButton();
        week = data.week;
        year = data.year;
      } else if (type === 'prev') {
        data = this.initPrevButton();
        week = data.week;
        year = data.year;
      }
      return `/ehpads/${this.currentEhpad.shortname}/resident/${this.residentId}/menus/${year}/${week}`;
    },
    handleScroll() {
      const stickyName = document.getElementById('stickyName');
      const y = window.pageYOffset;
      if (y <= 100) {
        stickyName.className = 'hide';
      } else {
        stickyName.className = '';
      }
    },
  },
  computed: {
    ...mapGetters({
      resident: 'resident',
      currentEhpad: 'ehpad',
    }),
    title() {
      return `${this.resident.fullName} (${this.coloredDiagHtml(
        this.resident.lastDiagnostic.humanState,
      )})`;
    },
    formattedDate() {
      const sunday = this.monday().weekday(7);
      return `du ${this.monday().date()} ${this.monday().format('MMMM')} au ${sunday.date()} ${sunday.format('MMMM')}`;
    },
  },
};
</script>

<style lang="scss" scoped>

.fixedElementRight {
  position: fixed;
  top: 1rem;
  right: 2rem;
  z-index: 100;
  background-color: white;
}
.hide {
    display: none;
}

</style>
