import gql from 'graphql-tag';

export const CREATE_NUTRITION_GOAL = gql`
  mutation CREATE_NUTRITION_GOAL(
    $proteins: Int!
    $calories: Int!
    $hydration: Int!
    $residentId: ID!
    $manual: Boolean!
  ) {
    createNutritionGoal(
      input: { proteins: $proteins, calories: $calories, hydration: $hydration, residentId: $residentId, manual: $manual }
    ) {
      nutritionGoal {
        id
        proteins
        calories
        hydration
        manual
      }
    }
  }
`;
