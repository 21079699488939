<template>
  <div>
    <b-form class="mt-4">
      <b-row class="my-2">
        <b-col cols="12">
          <h6><b>Commentaire général sur l'état du résident :</b></h6>
            <b-form-textarea
              v-model="generalComment.commentText"
              id="resident-general-comment"
              size="sm"
              type="text"
              rows="4"
              placeholder="Saisissez un commentaire général sur l'état du résident"
              :disabled="readonly"
            />
        </b-col>
      </b-row>


      <b-button v-if="!readonly" class="d-flex mx-auto d-print-none" variant="primary" size="sm" @click="createGeneralComment">
        Valider
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
  props: ['resident', 'readonly'],
  mixins: [validationMixin],
  data() {
    return {
      generalComment: {
        commentText: null,
      }
    };
  },
  validations: {
    generalComment: {
      commentText: {
        required,
      },
    },
  },
  created() {
    this.generalComment.commentText = this.resident.lastGeneralComment?.commentText;
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
  methods: {
    async createGeneralComment() {
      this.$v.generalComment.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('createGeneralComment', {
        commentText: this.generalComment.commentText,
        residentId: this.resident.id,
      }).then((result) => {
        this.$v.$reset();
        this.$emit('updated-resident');
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error,
          message: error,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
