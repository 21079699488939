<template>
  <div>
    <h3 class="text-center">Plats permanents des repas</h3>
    <p class="text-center">Note : Les plats permanents sont automatiquement ajoutés aux repas dans les futurs menus à partir du jour de leur saisie</p>
    <b-container fluid>
      <b-row class="justify-content-center">
        <app-meal-settings
          v-for="(mealCategoryHuman, mealCategory) in mealCategories"
          :key="mealCategory"
          :mealCategoryHuman="mealCategoryHuman"
          :mealCategory="mealCategory"
          class="mb-2"
        />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MealSettings from './MealSettings.vue';

export default {
  components: {
    appMealSettings: MealSettings,
  },
  computed: {
    mealCategories() {
      return {
        breakfast: 'Petit déjeuner',
        morning_snack: 'Collation du matin',
        lunch: 'Déjeuner',
        afternoon_snack: 'Goûter',
        dinner: 'Dîner',
        evening_snack: 'Collation du soir',
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
