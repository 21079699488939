import gql from 'graphql-tag';

export const FIND_EHPAD_WITH_SHORTNAME = gql`
  query FIND_EHPAD_WITH_SHORTNAME($shortname: String!) {
    findEhpadWithShortname(shortname: $shortname) {
      id
      shortname
      color
      name
      email
      allSettings
      startingDate
      statusCategories {
        id
        name
      }
      gustatoryCategories {
        id
        name
      }
    }
  }
`;

export const FIND_EHPAD = gql`
  query FIND_EHPAD($id: ID!) {
    findEhpad(id: $id) {
      id
      shortname
      color
      name
      email
      allSettings
      constantsControl
      testTypesControl
      startingDate
      statusCategories {
        id
        name
      }
      gustatoryCategories {
        id
        name
      }
    }
  }
`;
