<template>
  <div>
    <h5 class="text-center">MNA</h5>
    <line-chart :data="chartData" :colors="['#469be0']" class="mb-5" :library="options"></line-chart>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    residentMnas: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    currentEhpad: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chartData() {
      const sortedMnas = _.orderBy(this.residentMnas, (mna) => moment(mna.date), ['asc']);
      const criticalTreshold = _.map(sortedMnas, (v) => {
        return {
          date: v.date,
          value: this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_has_2007.mna_treshold,
        };
      });
      return [
        {
          name: 'Courbe MNA',
          data: sortedMnas.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
        },
        {
          name: 'Seuil Critique',
          data: criticalTreshold.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#DC3912',
        },
      ];
    },
  },
};
</script>

<style scoped>
</style>
