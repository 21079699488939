<template>
  <div>
    <app-resident-texture-form :resident="resident" :readonly="readonly" />
    <app-resident-diet-form :resident="resident" :readonly="readonly" />
    <div>
      <div class="d-flex align-items-center">
        <h6 class="text-nowrap">Infos Nutritionelles </h6>
        <div class="pl-3" v-if="!readonly">
          <b-button variant="primary" size="sm" v-b-modal.nutrional-infos-modal>
            <i class="fas fa-edit" />
          </b-button>
        </div>
      </div>
      <p style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
        v-b-tooltip.hover.v-primary
        :title="resident.nutritionalInfo"
      >
      <b>
        {{ resident.nutritionalInfo || "NC" }}
      </b>
      </p>
    </div>
    <app-resident-help-form :resident="resident" :readonly="readonly" />
  </div>
</template>

<script>
import ResidentTextureForm from '../../components/residents/ResidentTextureForm.vue';
import ResidentDietForm from '../../components/residents/ResidentDietForm.vue';
import ResidentHelpForm from '../../components/residents/ResidentHelpForm.vue';

export default {
  name: 'ResidentNutritionDetails',
  props: {
    resident: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    appResidentTextureForm: ResidentTextureForm,
    appResidentDietForm: ResidentDietForm,
    appResidentHelpForm: ResidentHelpForm,
  },
};
</script>
