import gql from 'graphql-tag';

export const CREATE_GENERAL_COMMENT = gql`
  mutation CREATE_GENERAL_COMMENT($commentText: String!, $residentId: ID!) {
    createGeneralComment(input: { commentText: $commentText, residentId: $residentId }) {
      generalComment {
        id
        commentText
      }
    }
  }
`;
