<template>
  <b-container fluid>
    <div class="nutriview-title">
      <img src="/assets/images/nutriviewNB_Borderless.png" class="nutriview-logo mx-auto d-flex">
      <p class="text-center mt-3">Veuillez saisir vos informations de connexion</p>
    </div>

    <div class="d-flex align-items-center">
    <b-row class="flex-fill">
      <b-col cols="4" offset-sm="4">
        <b-form @submit="onSubmit" novalidate>
          <b-form-group id="input-group-email" label-for="email" label="Courriel">
            <b-form-input
              autofocus
              v-model="email"
              id="email"
              style="background-color: cornsilk;"
              :class="{'is-invalid': $v.email.$error}"
            />
            <!-- class invalid feedback pour afficher les erreurs puis utilisation de vuelidate -->
            <div
              class="invalid-feedback"
            >Veuillez saisir un email {{ !$v.email.required ? '' : 'valide'}}</div>
          </b-form-group>
          <b-form-group id="input-group-password" label-for="password" label="Mot de passe">
            <b-form-input
              type="password"
              class="form-control"
              v-model="password"
              id="password"
              style="background-color: cornsilk;"
              :class="{'is-invalid': $v.password.$error}"
            />
            <div class="invalid-feedback">Veuillez saisir un mot de passe</div>
          </b-form-group>
          <p v-if="badCreds" class="text-danger text-center">{{ errorMessage }}</p>
          <b-button type="submit" block variant="primary">Connexion</b-button>
        </b-form>
      </b-col>
    </b-row>
    </div>
  </b-container>
</template>

<script>
import { validationMixin } from 'vuelidate';
import moment from 'moment';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'SignIn',
  mixins: [validationMixin],
  data() {
    return {
      email: '',
      password: '',
      badCreds: false,
      errorMessage: '',
    };
  },
  // Validations via vuelidate
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    currentDate() {
      return moment().format('DD-MM-YYYY');
    },
  },
  methods: {
    // async, await methodes ES8, equivalent de .then.then...
    async onSubmit(event) {
      // On previent l'envoi du form
      event.preventDefault();
      // this : composant, $v : objet d'etat de vulidate, $touch : lit les valdiations
      // debugger ici pour point d'arret
      this.$v.$touch();
      if (this.$v.$anyError) return;
      await this.$store
        .dispatch('signinUser', {
          // On envoi à l'action les informations du formulaire
          email: this.email,
          password: this.password,
        })
        // On récupére les erreurs
        .catch((error) => {
          this.badCreds = true;
          if (error.message === 'bad credentials') {
            this.errorMessage = 'Email ou Mot de passe incorrect.';
            return;
          }
          this.errorMessage = 'Une erreur est survenue.';
        });
      if (!this.badCreds && this.$route.fullPath !== '/signin') {
        this.$router.replace(this.$route.query.redirect);
      } else if (this.$store.getters.user.ehpad === null) {
        this.$router.push({ path: '/ehpads' });
      } else if (this.$store.getters.user.ehpad && (this.$store.getters.user.role !== 'ingesta')) {
        // Interpolation ES6
        this.$router.push({
          path: `/ehpads/${this.$store.getters.user.ehpad.shortname}/main`,
        });
      } else {
        this.$router.push({ path: `/ehpads/${this.$store.getters.user.ehpad.shortname}/${this.currentDate}/ingesta` });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

body {
  background-color: white !important;
}

.nutriview-logo {
  width: 625px;
}

.nutriview-title {
  padding-top: 130px;
  margin-bottom: 125px;
}

</style>
