import gql from 'graphql-tag';

export const FETCH_BUFFETS = gql`
  query FETCH_BUFFETS($ehpadId: ID!) {
    buffets(ehpadId: $ehpadId) {
      id
      name
      dType
      humanType
      active
      defaultProteins
      defaultCalories
      defaultHydration
      averagePrice
      totalPrice
      dishes {
        id
        name
        dType
        humanType
        active
        defaultProteins
        defaultCalories
        defaultHydration
      }
    }
  }
`;

export const DEFAULT_BUFFETS = gql`
  query DEFAULT_BUFFETS($ehpadId: ID!, $mealCategory: String!) {
    defaultBuffets(ehpadId: $ehpadId, mealCategory: $mealCategory) {
      id
      name
      dType
      humanType
      defaultProteins
      defaultCalories
      defaultHydration
    }
  }
`;
