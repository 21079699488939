import gql from 'graphql-tag';

export const FETCH_RESIDENT_MENUS = gql`
  query FETCH_RESIDENT_MENUS($year: Int!, $week: Int!, $residentId: ID!) {
    residentMenus(year: $year, week: $week, residentId: $residentId) {
      id
      date
      caloriesTotal
      proteinsTotal
      hydrationTotal
      totalPrice
      residentMeals {
        id
        mealCategory {
          humanType
          name
        }
        totalPrice
        filteredResidentMealDishes {
          id
          caloriesWSuppl
          proteinsWSuppl
          hydrationWSuppl
          calories
          proteins
          hydration
          price
          comment
          globalPermanent
          residentPermanent
          user {
            email
          }
          residentMealDishSupplementations {
            id
            supplementation {
              id
              name
              calories
              proteins
              hydration
              price
            }
          }
          dish {
            id
            name
            dType
            humanType
          }
          buffet {
            id
            name
            dType
            humanType
            averagePrice
          }
        }
      }
    }
  }
`;

export const FETCH_RESIDENT_MENU = gql`
  query FETCH_RESIDENT_MENU($date: String!, $residentId: ID!) {
    findResidentMenu(date: $date, residentId: $residentId) {
      id
      date
      residentMeals {
        id
        mealCategory {
          humanType
          name
        }
        residentMealDishes {
          id
          ingesta
          prefilled
          comment
          residentMealDishSupplementations {
            id
            supplementation {
              id
              name
            }
          }
          dish {
            id
            name
            dType
            humanType
          }
          buffet {
            id
            name
            dType
            humanType
            dishes {
              id
              name
            }
          }
          buffetResidentMealDishesIngested {
            id
            ingesta
            prefilled
            comment
            dish {
              id
              name
              dType
              humanType
            }
          }
          buffetDishesNotIngested {
            id
            name
            dType
            humanType
          }
        }
      }
    }
  }
`;

export const FETCH_RESIDENT_MENUS_FOR_CHARTS = gql`
  query FETCH_RESIDENT_MENUS_FOR_CHARTS($year: Int, $months: Int, $residentId: ID!) {
    residentMenusForCharts(year: $year, months: $months, residentId: $residentId) {
      id
      date
      caloriesTotal
      proteinsTotal
      hydrationTotal
      caloriesIngested
      proteinsIngested
      hydrationIngested
    }
  }
`;
