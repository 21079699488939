import gql from 'graphql-tag';

export const CREATE_RESIDENT = gql`
  mutation CREATE_RESIDENT(
    $name: String!
    $firstName: String!
    $height: Int!
    $ehpadId: ID!
    $weight: Float!
    $roomId: Int
  ) {
    createResident(
      input: {
        name: $name
        firstName: $firstName
        height: $height
        ehpadId: $ehpadId
        weight: $weight
        roomId: $roomId
      }
    ) {
      resident {
        id
        name
        firstName
        gender
        height
        idealWeight
        equipmentWeight
        nutritionalInfo
        diet
        requireHelp
        lastNri
        lastWeight {
          id
          date
          value
        }
        lastMna {
          id
          date
          value
        }
        lastCrp {
          id
          date
          value
        }
        lastAlbumine {
          id
          date
          value
          correctedAlbumine
        }
        lastDiagnostic {
          id
          date
          state
          comment
          humanState
        }
        lastNutritionGoal {
          id
          proteins
          calories
          hydration
        }
        lastTexture {
          id
          date
          tType
          humanType
        }
        lastGeneralComment {
          id
          date
          commentText
        }
        lastStatus {
          id
          description
          date
          statusCategory {
            name
          }
        }
        room {
          id
          number
        }
        sector {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_RESIDENT = gql`
  mutation UPDATE_RESIDENT(
    $id: ID!
    $name: String!
    $firstName: String!
    $gender: String
    $nutritionalInfo: String
    $diet: String
    $requireHelp: Boolean
    $height: Int!
    $idealWeight: Float
    $equipmentWeight: Float
    $roomId: ID
    $sectorId: ID
    $entryDate: String
  ) {
    updateResident(
      input: {
        id: $id,
        name: $name,
        firstName: $firstName,
        gender: $gender,
        nutritionalInfo: $nutritionalInfo,
        diet: $diet,
        requireHelp: $requireHelp,
        height: $height,
        idealWeight: $idealWeight,
        equipmentWeight: $equipmentWeight,
        roomId: $roomId,
        sectorId: $sectorId,
        entryDate: $entryDate,
      }
    ) {
      resident {
        id
        name
        firstName
        height
        gender
        idealWeight
        equipmentWeight
        nutritionalInfo
        diet
        requireHelp
        lastNri
        lastStatus {
          id
          description
          date
          statusCategory {
            name
          }
        }
        lastWeight {
          id
          date
          value
        }
        lastMna {
          id
          date
          value
        }
        lastCrp {
          id
          date
          value
        }
        lastAlbumine {
          id
          date
          value
          correctedAlbumine
        }
        lastDiagnostic {
          id
          date
          state
          comment
          humanState
        }
        lastNutritionGoal {
          id
          proteins
          calories
          hydration
        }
        lastTexture {
          id
          date
          tType
          humanType
        }
        lastGeneralComment {
          id
          date
          commentText
        }
        room {
          id
          number
        }
        sector {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_RESIDENT_MEAL_SETTINGS = gql`
  mutation UPDATE_RESIDENT_MEAL_SETTINGS(
    $id: ID!
    $meal: String!
    $value: Boolean!
    $useDefaultScheduleTime: Boolean!
    $scheduleTime: String!
  ) {
    updateResidentMealSettings(
      input: { id: $id, meal: $meal, value: $value, useDefaultScheduleTime: $useDefaultScheduleTime, scheduleTime: $scheduleTime }
    ) {
      resident {
        id
        name
        firstName
        height
        gender
        idealWeight
        equipmentWeight
        nutritionalInfo
        diet
        requireHelp
        allSettings
        lastNri
        room {
          number
          id
        }
        sector {
          id
          name
        }
        weights {
          id
          date
          value
        }
      }
    }
  }
`;

export const CREATE_RESIDENT_DEFAULT_MEAL_DISH = gql`
  mutation CREATE_RESIDENT_DEFAULT_MEAL_DISH(
    $mealCategory: String!
    $dishId: ID!
    $residentId: ID!
  ) {
    createResidentDefaultMealDish(
      input: { mealCategory: $mealCategory, dishId: $dishId, residentId: $residentId }
    ) {
      residentDefaultMealDish {
        id
        mealCategory
        dish {
          id
          name
        }
        resident {
          id
        }
      }
    }
  }
`;

export const DESTROY_RESIDENT_DEFAULT_MEAL_DISH = gql`
  mutation DESTROY_RESIDENT_DEFAULT_MEAL_DISH(
    $mealCategory: String!
    $dishId: ID!
    $residentId: ID!
  ) {
    destroyResidentDefaultMealDish(
      input: { mealCategory: $mealCategory, dishId: $dishId, residentId: $residentId }
    ) {
      residentDefaultMealDish {
        mealCategory
        dish {
          id
          name
        }
        resident {
          id
        }
      }
    }
  }
`;


export const CREATE_RESIDENT_DEFAULT_MEAL_BUFFET = gql`
  mutation CREATE_RESIDENT_DEFAULT_MEAL_BUFFET(
    $mealCategory: String!
    $buffetId: ID!
    $residentId: ID!
  ) {
    createResidentDefaultMealBuffet(
      input: { mealCategory: $mealCategory, buffetId: $buffetId, residentId: $residentId }
    ) {
      residentDefaultMealBuffet {
        id
        mealCategory
        buffet {
          id
          name
        }
        resident {
          id
        }
      }
    }
  }
`;

export const DESTROY_RESIDENT_DEFAULT_MEAL_BUFFET = gql`
  mutation DESTROY_RESIDENT_DEFAULT_MEAL_BUFFET(
    $mealCategory: String!
    $buffetId: ID!
    $residentId: ID!
  ) {
    destroyResidentDefaultMealBuffet(
      input: { mealCategory: $mealCategory, buffetId: $buffetId, residentId: $residentId }
    ) {
      residentDefaultMealBuffet {
        mealCategory
        buffet {
          id
          name
        }
        resident {
          id
        }
      }
    }
  }
`;
