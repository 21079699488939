<template>
  <div>
        <div class="card bg-light mb-3" style="max-width: 18rem;">
          <div class="card-header">
            <span
              v-html="`${coloredDiagHtml(residentDiagnostic.humanState)}`"
            >
            </span>
          </div>
          <div class="card-body">
            <div class="card-text">
            <div style="font-style: italic;font-size: smaller;">
              Le {{`${dateFormatterShort(residentDiagnostic.date)}`}}
            </div>
            <div style="font-style: italic;font-size: smaller;">Par : <b>{{ capitalizeString(residentDiagnostic.user.email) }}</b></div>
            <p v-if="residentDiagnostic.nri !== null" class="mb-0" style="font-size: smaller; color: red;">NRI : {{residentDiagnostic.nri }}</p>
            <p
              v-if="residentDiagnostic.comment !== '' && residentDiagnostic.comment !== null"
              style="word-wrap: break-word;font-size: small;margin-bottom: 0;"
            >
              <span v-if="!readMoreActivated">
                {{residentDiagnostic.comment.slice(0, 75)}}
                <span class="fake-link" v-if="!readMoreActivated && residentDiagnostic.comment.length > 75" @click="activateReadMore">
                  ...Lire plus...
                </span>
              </span>
              <span v-if="readMoreActivated" v-html="residentDiagnostic.comment"></span>
            </p>
            <p v-else style="word-wrap: break-word;white-space: pre-line;font-size: smaller;">Pas de commentaire</p>
            </div>
          </div>
        </div>

  </div>
</template>

<script>

import { capitalizeString, dateFormatterShort, coloredDiagHtml } from '../../utilities/filters';

export default {
  props: ['residentDiagnostic'],
  data() {
    return {
      readMoreActivated: false,
    };
  },
  methods: {
    capitalizeString,
    dateFormatterShort,
    coloredDiagHtml,
    activateReadMore() {
      this.readMoreActivated = true;
    },
  },
};

</script>

<style lang="scss" scoped>
  .fake-link {
      color: blue;
      cursor: pointer;
  }
</style>
