<template>
  <!-- https://bootstrap-vue.js.org/docs/components/table -->
  <div>
    <h3 class="text-center mb-3">
      Residents sans lit
      <i
        class="fas fa-exclamation-triangle"
        style="color: red;font-size: smaller;"
        v-b-tooltip.hover.v-danger
        title="Les menus ne se répliquent pas pour les résidents sans lit. Il est nécessaire d'assigner un lit à un résident pour pouvoir utiliser le logiciel"
      />
    </h3>

    <b-form-group
      label-size="sm"
      label-for="filterInput"
      class="mb-3"
      style="width: 40%;"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="filterNoRoomTable"
          type="search"
          id="filterInput"
          placeholder="Filtre de recherche"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filterNoRoomTable" @click="filterNoRoomTable = ''">Effacer</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-table
      :filter="filterNoRoomTable"
      sticky-header="800px"
      bordered
      striped
      small
      hover
      :items="residentsWithoutRoom"
      :fields="fields"
      class="m-0"
      ref="residentTable"
      id="residentTable"
    >
      <!-- Implicite avec les tables Vue : possibilité de surcharger -->
      <!-- <template #cell(name)="data">{{ data.item.name }}</template> -->
      <!-- On surcharge l'element avec "weight" et pas weights car on récupére data.fields plus loin pour créer un poids à la volée à l'ajout d'un nouveau résident -->
      <template #cell(lastStatus)="data">
        <span v-if="data.item.lastStatus === null">Pas de status</span>
        <span
          v-if="data.item.lastStatus !== null"
          v-html="
            dataWithDate(
              data.item.lastStatus.statusCategory.name,
              data.item.lastStatus.date,
            )
          "
        >
        </span>
      </template>

      <template #cell(lastWeight)="data">
        <span
          v-if="data.item.lastDiagnostic.state == 'severe_undernutrition'"
          v-html="
            coloredDataHtml(
              data.item.lastWeight,
              currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_weight_value_taking_in_days,
            )
          "
        >
        </span>
        <span
          v-else-if="data.item.lastDiagnostic.state == 'moderate_undernutrition'"
          v-html="
            coloredDataHtml(
              data.item.lastWeight,
              currentEhpad.allSettings.moderate_undernutrition.constants_config.minimum_weight_value_taking_in_days,
            )
          "
        >
        </span>
        <span
          v-else-if="data.item.lastDiagnostic.state == 'high_risk'"
          v-html="
            coloredDataHtml(
              data.item.lastWeight,
              currentEhpad.allSettings.high_risk.constants_config.minimum_weight_value_taking_in_days,
            )
          "
        >
        </span>
        <span
          v-else
          v-html="
            coloredDataHtml(
              data.item.lastWeight,
              currentEhpad.allSettings.default_undernutrition.constants_config.minimum_weight_value_taking_in_days,
            )
          "
        >
        </span>
      </template>
      <template #cell(lastMna)="data">
        <span
          v-if="data.item.lastDiagnostic.state == 'severe_undernutrition'"
          v-html="
            coloredDataHtml(
              data.item.lastMna,
              currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_mna_value_taking_in_days,
            )
          "
        >
        </span>
        <span
          v-else-if="data.item.lastDiagnostic.state == 'moderate_undernutrition'"
          v-html="
            coloredDataHtml(
              data.item.lastMna,
              currentEhpad.allSettings.moderate_undernutrition.constants_config.minimum_mna_value_taking_in_days,
            )
          "
        >
        </span>
        <span
          v-else-if="data.item.lastDiagnostic.state == 'high_risk'"
          v-html="
            coloredDataHtml(
              data.item.lastMna,
              currentEhpad.allSettings.high_risk.constants_config.minimum_mna_value_taking_in_days,
            )
          "
        >
        </span>
        <span
          v-else
          v-html="
            coloredDataHtml(
              data.item.lastMna,
              currentEhpad.allSettings.default_undernutrition.constants_config.minimum_mna_value_taking_in_days,
            )
          "
        >
        </span>
      </template>
      <template #cell(lastCrp)="data">
        <span
          v-if="data.item.lastDiagnostic.state == 'severe_undernutrition'"
          v-html="
            coloredDataHtml(
              data.item.lastCrp,
              currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_crp_value_taking_in_days,
            )
          "
        >
        </span>
        <span
          v-else-if="data.item.lastDiagnostic.state == 'moderate_undernutrition'"
          v-html="
            coloredDataHtml(
              data.item.lastCrp,
              currentEhpad.allSettings.moderate_undernutrition.constants_config.minimum_crp_value_taking_in_days,
            )
          "
        >
        </span>
        <span
          v-else-if="data.item.lastDiagnostic.state == 'high_risk'"
          v-html="
            coloredDataHtml(
              data.item.lastCrp,
              currentEhpad.allSettings.high_risk.constants_config.minimum_crp_value_taking_in_days,
            )
          "
        >
        </span>
        <span
          v-else
          v-html="
            coloredDataHtml(
              data.item.lastCrp,
              currentEhpad.allSettings.default_undernutrition.constants_config.minimum_crp_value_taking_in_days,
            )
          "
        >
        </span>
      </template>
      <template #cell(lastAlbumine)="data">
        <span
          v-if="data.item.lastDiagnostic.state == 'severe_undernutrition'"
          v-html="
            coloredDataHtml(
              data.item.lastAlbumine,
              currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_albumine_value_taking_in_days,
            )
          "
        >
        </span>
        <span
          v-else-if="data.item.lastDiagnostic.state == 'moderate_undernutrition'"
          v-html="
            coloredDataHtml(
              data.item.lastAlbumine,
              currentEhpad.allSettings.moderate_undernutrition
                .constants_config.minimum_albumine_value_taking_in_days,
            )
          "
        >
        </span>
        <span
          v-else-if="data.item.lastDiagnostic.state == 'high_risk'"
          v-html="
            coloredDataHtml(
              data.item.lastAlbumine,
              currentEhpad.allSettings.high_risk.constants_config.minimum_albumine_value_taking_in_days,
            )
          "
        >
        </span>
        <span
          v-else
          v-html="
            coloredDataHtml(
              data.item.lastAlbumine,
              currentEhpad.allSettings.default_undernutrition.constants_config.minimum_albumine_value_taking_in_days,
            )
          "
        >
        </span>
      </template>
      <template #cell(lastDiagnostic)="data">
        <span v-html="coloredDiagHtml(data.item.lastDiagnostic.humanState)"></span>
      </template>

      <template #head(actions)>{{ '' }}</template>

      <template #cell(actions)="data">
        <b-button
          variant="link"
          size="md"
          :to="{ path: `/ehpads/${currentEhpad.shortname}/resident/${data.item.id}/diagnostics` }"
          v-b-tooltip.hover.v-primary.noninteractive
          title="Données personnelles"
        >
          <i class="fas fa-chart-line" />
        </b-button>

        <b-button
          variant="link"
          size="md"
          :to="{ path: `/ehpads/${currentEhpad.shortname}/resident/${data.item.id}/nutrition` }"
          v-b-tooltip.hover.v-primary.noninteractive
          title="Nutrition"
        >
          <i class="fas fa-utensils" />
        </b-button>

        <b-button
          variant="link"
          size="md"
          :to="{
            path: `/ehpads/${currentEhpad.shortname}/resident/${data.item.id}/menus/${currentYear}/${currentWeek}`,
          }"
          v-b-tooltip.hover.v-primary.noninteractive
          title="Menus du résident"
        >
          <i class="far fa-calendar-alt" />
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
// Webpack :
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import { dataWithDate, coloredDataHtml, coloredDiagHtml } from '../../utilities/filters';
// version Node : const moment = require('moment');

export default {
  methods: {
    dataWithDate,
    coloredDataHtml,
    coloredDiagHtml,
    residentElValue(el) {
      return _.get(el, 'value', null);
    },
    residentElState(el) {
      return _.get(el, 'state', null);
    },
    residentElNumber(el) {
      return _.get(el, 'number', null);
    },
    residentEltType(el) {
      return _.get(el, 'tType', null);
    },
    residentElStatus(el) {
      return _.get(el, 'statusCategory.name', null);
    },
    setInputType(key) {
      return ['lastWeight', 'height'].includes(key) ? 'number' : 'text';
    },
    selectResident(resident) {
      this.selectedResident = resident;
    },
    classFor(key) {
      if (key === 'height') { return 'small-size'; }
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      residentsWithoutRoom: 'residentsWithoutRoom',
    }),
    currentYear() {
      return moment().year();
    },
    currentWeek() {
      return moment().isoWeek();
    },
  },
  data() {
    return {
      selectedResident: null,
      filterNoRoomTable: null,
      fields: [
        {
          key: 'firstName',
          label: 'Prénom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'height',
          label: 'Taille',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'lastStatus',
          label: 'Status',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElStatus',
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'lastWeight',
          label: 'Poids (en Kg)',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElValue',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'lastMna',
          label: 'MNA',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElValue',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'lastCrp',
          label: 'CRP',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElValue',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'lastAlbumine',
          label: 'Albumine',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElValue',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'lastDiagnostic',
          label: 'État nutritionnel',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElState',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'actions',
          label: 'actions',
          class: 'text-center',
          sortable: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
  .small-size {
    width: 75px;
  }
</style>
