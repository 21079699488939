import gql from 'graphql-tag';

export const FETCH_SUPPLEMENTATIONS = gql`
  query FETCH_SUPPLEMENTATIONS($ehpadId: ID!) {
    supplementations(ehpadId: $ehpadId) {
      id
      name
      proteins
      calories
      hydration
      price
    }
  }
`;