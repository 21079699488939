import apiClient from '../../utilities/api_client';
import { FETCH_TEST_TYPES } from '../../graphql/test_types/queries';
import { UPDATE_TEST_TYPE_SETTINGS } from '../../graphql/test_types/mutations';

const testTypeState = {
  testTypes: [],
};

const mutations = {
  SET_TEST_TYPES(state, testTypes) {
    state.testTypes = testTypes;
  },
  UPDATE_TEST_TYPE_SETTINGS(state, testType) {
    const index = state.testTypes.findIndex((e) => e.id === testType.id);
    state.testTypes.splice(index, 1, testType);
  },
};

const actions = {
  setTestTypes: async ({ commit }, ehpadId) => {
    const { data } = await apiClient.query({
      query: FETCH_TEST_TYPES,
      variables: { ehpadId },
      fetchPolicy: 'network-only',
    });
    commit('SET_TEST_TYPES', data.testTypes);
  },
  updateTestTypeSettings: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_TEST_TYPE_SETTINGS,
      variables: { ...formData },
    });
    const { testType } = data.updateTestTypeSettings;
    commit('UPDATE_TEST_TYPE_SETTINGS', testType);
  },
};

const getters = {
  testTypes: (state) => state.testTypes,
};

export default {
  state: testTypeState,
  mutations,
  actions,
  getters,
};
