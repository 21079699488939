<template>
  <b-col cols=10>
    <div class="menu-card d-flex flex-column">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <b-button variant="primary" :to="{ path: formatButtonLink('prev') }" class="d-print-none mt-1 ml-2">
          Jour précédent
        </b-button>
        <b-row align-h="center">
          <div class="menu-card-date text-center">
            <h2 class="m-0 font-weight-bold">
              {{ capitalizeString(dateFormatterFull(residentMenu.date)) }}
            </h2>
          </div>
        </b-row>
        <b-button variant="primary" :to="{ path: formatButtonLink('next') }" class="d-print-none mt-1 mr-2">
          Jour suivant
        </b-button>
      </div>

      <app-ingesta-meal
        class="menu-card-meal"
        v-for="type in mealCategories"
        :key="type"
        :type="type"
        :residentMeal="findMeal(type)"
        :menuEditState="menuEditState"
        :ingestaPrefilled="ingestaPrefilled"
      />
    </div>
  </b-col>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import IngestaMeal from './Meal.vue';
import { dateFormatterFull, capitalizeString } from '../../utilities/filters';

export default {
  name: 'ResidentMenuCard',
  props: ['residentMenu', 'menuEditState', 'ingestaPrefilled', 'date', 'residentId'],
  components: {
    appIngestaMeal: IngestaMeal,
  },
  methods: {
    dateFormatterFull,
    capitalizeString,
    findMeal(type) {
      return this.residentMenu.residentMeals.find((el) => el.mealCategory.name === type);
    },
    momentDate() {
      return moment(this.date, 'DD-MM-YYYY');
    },
    initNextButton() {
      const date = this.momentDate();
      const nextDay = date.add(1, 'd');
      return { nextDay };
    },
    initPrevButton() {
      const date = this.momentDate();
      const prevDay = date.subtract(1, 'd');
      return { prevDay };
    },
    formatButtonLink(type) {
      let day;
      let data;
      if (type === 'next') {
        data = this.initNextButton();
        day = data.nextDay.format('DD-MM-YYYY');
      } else {
        data = this.initPrevButton();
        day = data.prevDay.format('DD-MM-YYYY');
      }
      return `/ehpads/${this.currentEhpad.shortname}/resident/${this.residentId}/ingesta/${day}`;
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    mealCategories() {
      return ['breakfast', 'morning_snack', 'lunch', 'afternoon_snack', 'dinner', 'evening_snack'];
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-card {
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  &:last-child {
    margin-top: 10px;
  }
  &-date {
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  &-meal {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 5px;
  }
  &-meal:last-child {
    border-bottom: none;
  }
}
</style>
