<template>
  <b-row class="justify-content-center m-4">
    <b-col cols="3">
      <b-row>
        <b-col cols="4">
          <label for="date-datepicker-startDate" class="pr-4 text-nowrap">Date de début : </label>
        </b-col>
        <b-col cols="8">
          <b-form-datepicker
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            label-help
            label-no-date-selected="Aucune date sélectionnée"
            :min="minimum"
            :max="endDate"
            size="sm"
            v-model="startDate"
            locale="fr"
            :class="{'is-invalid': $v.startDate.$anyError}"
            @input="emitDates"
          />
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="3">
      <b-row>
        <b-col cols="4">
          <label for="date-datepicker-endDate" class="pr-4 text-nowrap">Date de fin : </label>
        </b-col>
        <b-col cols="8">
          <b-form-datepicker
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            label-help
            label-no-date-selected="Aucune date sélectionnée"
            :min="supStartDate"
            size="sm"
            v-model="endDate"
            locale="fr"
            :class="{'is-invalid': $v.endDate.$anyError}"
            @input="emitDates"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "MyCustomDatePicker",
  mixins: [validationMixin],
  data() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  created() {
    this.startDate = this.sixMonthsAgo;
    this.endDate = this.today;
  },
  validations: {
    startDate: {
      required,
    },
    endDate: {
      required,
    },
  },
  computed: {
    today() {
      const now = new Date();
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return moment(date).format("YYYY-MM-DD");
    },
    sixMonthsAgo() {
      const now = new Date();
      const date = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      return moment(date).format("YYYY-MM-DD");
    },
    minimum() {
      const now = new Date("2020-01-01");
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return moment(date).format("YYYY-MM-DD");
    },
    supStartDate() {
      if (this.startDate === null) { return this.today; }
      return moment(this.startDate).add(1, "d").format("YYYY-MM-DD");
    },
  },
  methods: {
    emitDates() {
      if (this.startDate && this.endDate) {
        this.$emit("dates-selected", { start: this.startDate, end: this.endDate });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
