import _ from 'lodash';

import apiClient from '../../utilities/api_client';
import { FETCH_BUFFETS } from '../../graphql/buffets/queries';
import { UPDATE_BUFFET, ARCHIVE_BUFFET, CREATE_BUFFET } from '../../graphql/buffets/mutations';

const buffetState = {
  buffets: [],
};

const mutations = {
  SET_BUFFETS(state, buffets) {
    state.buffets = buffets;
  },
  UPDATE_BUFFET(state, buffet) {
    const index = state.buffets.findIndex((e) => e.id === buffet.id);
    state.buffets.splice(index, 1, buffet);
  },
  ARCHIVE_BUFFET(state, buffet) {
    const index = state.buffets.findIndex((e) => e.id === buffet.id);
    state.buffets.splice(index, 1);
  },
  ADD_BUFFET(state, buffet) {
    state.buffets.push(buffet);
  },
};

const actions = {
  async setBuffets({ commit }, ehpadId) {
    const { data } = await apiClient.query({
      query: FETCH_BUFFETS,
      variables: { ehpadId },
      fetchPolicy: 'network-only',
    });
    commit('SET_BUFFETS', data.buffets);
  },
  async updateBuffet({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_BUFFET,
      variables: { ...formData },
    });
    const { buffet } = data.updateBuffet;
    commit('UPDATE_BUFFET', buffet);
  },
  async archiveBuffet({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: ARCHIVE_BUFFET,
      variables: { ...formData },
    });
    const { buffet } = data.archiveBuffet;
    commit('ARCHIVE_BUFFET', buffet);
  },
  async createBuffet({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: CREATE_BUFFET,
      variables: { ...formData },
    });
    const { buffet } = data.createBuffet;
    commit('ADD_BUFFET', buffet);
  },
};

const getters = {
  sortedBuffetsByName(state) {
    return _.sortBy(state.buffets, (buffet) => buffet.name.toLowerCase());
  },
  activeBuffets(state) {
    return state.buffets.filter((buffet) => buffet.active);
  },
  activeBuffetsByName(state) {
    return _.sortBy(getters.activeBuffets(state), (el) => el.name.toLowerCase());
  },
  buffets: (state) => state.buffets,
};

export default {
  state: buffetState,
  mutations,
  actions,
  getters,
};
