import gql from 'graphql-tag';

export const FETCH_SURVEYS = gql`
    query FETCH_SURVEYS($ehpadId: ID!) {
        surveys(ehpadId: $ehpadId) {
            id
            mealCategories {
                humanType
                name
            }
            questions {
                id
                description
            }
            answers {
                id
                description
            }
            name
            startDate
            endDate
            ingestaMax
            ingestaMin
        }
    }
`;

export const FIND_SURVEYS_ON_DATE = gql`
    query FIND_SURVEYS_ON_DATE($ehpadId: ID!, $date: String!) {
        findSurveysOnDate(ehpadId: $ehpadId, date: $date) {
            id
            mealCategories {
                name
                humanType
            }
            questions {
                id
                description
            }
            answers {
                id
                description
            }
            name
            startDate
            endDate
            ingestaMax
            ingestaMin
        }
    }
`;

export const FIND_SURVEY = gql`
  query FIND_SURVEY($id: ID!) {
    findSurvey(id: $id) {
        id
        mealCategories {
            name
            humanType
        }
        questions {
            id
            description
        }
        answers {
            id
            description
        }
        name
        startDate
        endDate
        ingestaMax
        ingestaMin
        statsForCharts
    }
  }
`;

export const FETCH_RESIDENT_MEAL_DISH_ANSWER = gql`
  query FETCH_RESIDENT_MEAL_DISH_ANSWER($rmdId: ID!) {
    findResidentMealDishAnswer(rmdId: $rmdId) {
        id
        answer {
            id
            description
        }
    }
  }
`;
