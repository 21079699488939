<template>
  <div>
    <b-row v-if="residentTestsForCharts !== undefined">
      <b-col class="col-2">Intervalle </b-col>
      <b-col class="col-2">
        <div>
          <b-form-select
            v-model="rangeForResidentTestsCharts"
            :options="chartRangeOptions"
            @change="residentTestsForCharts = undefined"
          />
        </div>
      </b-col>
    </b-row>
    <div v-if="residentTestsForCharts !== undefined" class="mt-3">
        <template>
          <div v-for="(dataResidentTest, testName, index) in residentTestsForCharts" class="mb-3" :key="index">
            <h5 class="text-center">{{ capitalizeString(testName) }}</h5>
            <line-chart :data="dataResidentTest" :library="options"></line-chart>
          </div>
        </template>
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="residentTestsForCharts === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { capitalizeString } from '../../utilities/filters';
import { FETCH_RESIDENT_TESTS_FOR_CHARTS } from '../../graphql/resident_charts/queries';

export default {
  props: ['resident'],
  data() {
    return {
      dataResidentTestsForCharts: [],
      rangeForResidentTestsCharts: { months: null, year: null },
      options: {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              displayFormats: {
                day: 'DD MMM YYYY',
              },
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  apollo: {
    residentTestsForCharts: {
      query: FETCH_RESIDENT_TESTS_FOR_CHARTS,
      variables() {
        return {
          residentId: this.resident.id,
          year: parseInt(this.rangeForResidentTestsCharts.year, 10),
          months: parseInt(this.rangeForResidentTestsCharts.months, 10),
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    capitalizeString,
  },
  computed: {
    chartRangeOptions() {
      return [
        { value: { months: null, year: null }, text: '12 mois glissants' },
        { value: { months: 6, year: null }, text: '6 mois glissants' },
        { value: { months: 3, year: null }, text: '3 mois glissants' },
        { value: { months: null, year: moment().year() - 2 }, text: moment().year() - 2 },
        { value: { months: null, year: moment().year() - 1 }, text: moment().year() - 1 },
        { value: { months: null, year: moment().year() }, text: moment().year() },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
