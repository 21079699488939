<template>
  <b-container fluid>
    <b-form>
      <b-row>
        <b-col cols="2">
          <label for="inline-form-input-name" class="pr-4 text-nowrap">Titre de l'enquête: </label>
        </b-col>
        <b-col cols="10">
            <b-form-input
              id="inline-form-input-name"
              size="sm"
              type="text"
              v-model="editableSurvey.name"
              :class="{ 'is-invalid': $v.editableSurvey.name.$anyError }"
            />
            <b-form-invalid-feedback>Valeur requise (100 caractères max)</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="6">
          <b-form-select
            id="inline-form-input-ingestaMin"
            v-model="editableSurvey.ingestaMin"
            :options="ingestaOptions"
            :class="{ 'is-invalid': $v.editableSurvey.ingestaMin.$anyError }"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>Selectionner un ingesta Minimum (inclus)</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
        </b-col>
        <b-col cols="6">
          <b-form-select
            id="inline-form-input-ingestaMax"
            v-model="editableSurvey.ingestaMax"
            :options="ingestaOptions"
            :class="{ 'is-invalid': $v.editableSurvey.ingestaMax.$anyError }"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>Selectionner un ingesta Maximum (inclus)</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="6">
          <b-row>
            <b-col cols="6">
              <label for="date-datepicker-startDate" class="pr-4 text-nowrap">Date de début : </label>
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                id="date-datepicker-startDate"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                label-help
                label-no-date-selected="Aucune date sélectionnée"
                :min="today"
                size="sm"
                v-model="editableSurvey.startDate"
                locale="fr"
                :class="{'is-invalid': $v.editableSurvey.startDate.$anyError}"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row>
            <b-col cols="6">
              <label for="date-datepicker-endDate" class="pr-4 text-nowrap">Date de fin : </label>
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                id="date-datepicker-endDate"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                label-help
                label-no-date-selected="Aucune date sélectionnée"
                :min="today"
                size="sm"
                v-model="editableSurvey.endDate"
                locale="fr"
                :class="{'is-invalid': $v.editableSurvey.endDate.$anyError}"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="4">
          <label for="inline-form-input-mealCategories" class="pr-4 text-nowrap">Repas concernés : </label>
        </b-col>
        <b-col cols="8">
          <multiselect
            v-model="editableSurvey.mealCategories"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :options="mealCategoriesOptions"
            placeholder="Selectionner un/plusieurs repas"
            label="humanType"
            track-by="name"
            selectLabel=""
            deselectLabel=""
            selectedLabel="Sélectionné"
            :class="{ 'is-invalid': $v.editableSurvey.mealCategories.$anyError }"
          >
            <template #noResult>
              <span>Pas de résultat correspondant</span>
            </template>
          </multiselect>
          <b-form-invalid-feedback>Une enquête doit porter au moins sur un type de repas</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="3">
          <label for="inline-form-input-question" class="pr-4 text-nowrap">Question de l'enquête : </label>
        </b-col>
        <b-col cols="9"
          v-for="(question, index) in editableSurvey.questions"
          v-bind:key="index"
        >
            <b-form-textarea
              id="inline-form-input-question"
              size="sm"
              type="text"
              v-model="question.description"
              :class="{ 'is-invalid': $v.editableSurvey.questions.$each[index].$anyError }"
            />
            <b-form-invalid-feedback>Valeur requis</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="12">
          <label for="inline-form-input-answer" class="pr-4 text-nowrap">Choix de réponses : </label>
          <b-button class="mb-2 ml-4" variant="primary" size="sm" @click="addAnswer">
          <i class="fas fa-plus" />
              Ajouter une réponse
          </b-button>
        </b-col>
      </b-row>

      <b-row class="pt-4"
        v-for="(answer, index) in editableSurvey.answers"
        v-bind:key="index"
      >
        <b-col cols="1">
          <label>{{index+1}}. </label>
        </b-col>
        <b-col cols="10">
          <b-form-input
            :id="`${index}`"
            size="sm"
            type="text"
            v-model="answer.description"
            :class="{ 'is-invalid': $v.editableSurvey.answers.$each[index].$anyError }"
          />
        </b-col>
        <b-col cols="1">
          <b-button variant="link" size="sm" @click="deleteAnswer(index)" v-if="answer.id === undefined">
            <i class="text-danger fa fa-trash-alt"
              v-b-tooltip.hover.v-danger
              title="Supprimer"
            />
          </b-button>
        </b-col>
      </b-row>

      <div v-if="error" style="color: red;" class="text-center mt-2">
        <p v-for="(error, i) of error.graphQLErrors" :key="i">
          {{ error.message }}
        </p>
      </div>

      <b-button class="d-flex mx-auto mt-4" variant="primary" size="sm" @click.prevent="updateSurvey">
        Valider
      </b-button>
    </b-form>

  </b-container>
</template>

<script>

import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import Multiselect from "vue-multiselect";
import { required, maxLength } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  props: ["survey"],
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      editableSurvey: null,
    };
  },
  created() {
    this.duplicateSurvey(this.survey);
  },
  watch: {
    survey: {
      handler() {
        this.duplicateSurvey(this.survey);
      },
      immediate: true,
    },
  },
  components: {
    Multiselect,
  },
  validations: {
    editableSurvey: {
      name: {
        required,
        maxLength: maxLength(100),
      },
      startDate: {
        required,
      },
      endDate: {
        required,
      },
      ingestaMin: {
        required,
      },
      ingestaMax: {
        required,
      },
      mealCategories: {
        required,
      },
      questions: {
        $each: {
          description: {
            required,
          },
        },
      },
      answers: {
        $each: {
          description: {
            required,
          },
        },
      },
    },
  },
  methods: {
    duplicateSurvey(survey) {
      this.editableSurvey = { ...survey };
    },
    addAnswer() {
      this.editableSurvey.answers.push({
        description: "",
      });
    },
    deleteAnswer(counter) {
      this.editableSurvey.answers.splice(counter, 1);
    },
    async updateSurvey() {
      // Typename Fix :
      this.editableSurvey.answers.forEach((answer) => { delete answer.__typename; });
      this.editableSurvey.questions.forEach((question) => { delete question.__typename; });
      this.editableSurvey.mealCategories.forEach((mealCategory) => { delete mealCategory.__typename; });
      this.$v.editableSurvey.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch("updateSurvey", {
        id: this.editableSurvey.id,
        name: this.editableSurvey.name,
        startDate: this.editableSurvey.startDate,
        endDate: this.editableSurvey.endDate,
        ingestaMin: this.editableSurvey.ingestaMin,
        ingestaMax: this.editableSurvey.ingestaMax,
        mealCategories: this.editableSurvey.mealCategories,
        questions: this.editableSurvey.questions,
        answers: this.editableSurvey.answers,
      }).then((result) => {
        this.$router.push({
          path: `/ehpads/${this.currentEhpad.shortname}/surveys`,
        });
      }).catch((error) => {
        this.error = error;
        this.flashMessage.show({
          status: "error",
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: "ehpad",
    }),
    today() {
      const now = new Date();
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return moment(date).format("YYYY-MM-DD");
    },
    mealCategoriesOptions() {
      return [
        {
          humanType: "Petit déjeuner",
          name: "breakfast",
        },
        {
          humanType: "Collation du matin",
          name: "morning_snack",
        },
        {
          humanType: "Déjeuner",
          name: "lunch",
        },
        {
          humanType: "Goûter",
          name: "afternoon_snack",
        },
        {
          humanType: "Dîner",
          name: "dinner",
        },
        {
          humanType: "Collation du soir",
          name: "evening_snack",
        },
      ];
    },
    ingestaOptions() {
      return [
        { value: 0, text: "0 %" },
        { value: 0.25, text: "25 %" },
        { value: 0.5, text: "50 %" },
        { value: 0.75, text: "75 %" },
        { value: 1.0, text: "100 %" },
        { value: 1.25, text: "125 %" },
        { value: 1.5, text: "150 %" },
        { value: 1.75, text: "175 %" },
        { value: 2, text: "200 %" },
      ];
    },
  },
};
</script>

<style lang="scss">

.multiselect__tags {
  border: 1px solid #ced4da !important;
}

</style>
