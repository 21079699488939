<template>
  <div>
    <h5 class="text-center">CRP</h5>
    <line-chart :data="chartData" :colors="['#469be0']" class="mb-5" :library="options"></line-chart>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    residentCrps: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chartData() {
      const sortedCrps = _.orderBy(this.residentCrps, (crp) => moment(crp.date), ['asc']);
      return [
        {
          name: 'Courbe CRP',
          data: sortedCrps.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
        },
      ];
    },
  },
};
</script>

<style scoped>
</style>
