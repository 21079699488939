<template>
  <div>
    <b-row v-if="pricesForCharts !== undefined">
      <b-col class="col-2">Intervalle </b-col>
      <b-col class="col-2">
        <div>
          <b-form-select
            v-model="rangeForPriceChart"
            :options="chartRangeOptions"
            @change="pricesForCharts = undefined"
          />
        </div>
      </b-col>
    </b-row>
    <div v-if="pricesForCharts !== undefined" class="mt-3">
      <line-chart :data="dataPricesForCharts" suffix="€" :points=false :library="options"></line-chart>
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="pricesForCharts === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { FETCH_EHPAD_PRICES_FOR_CHARTS } from '../../graphql/dashboard/queries';

export default {
  data() {
    return {
      rangeForPriceChart: { months: null, year: null },
      dataPricesForCharts: [],
      options: {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              displayFormats: {
                day: 'DD MMM YYYY',
              },
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  apollo: {
    pricesForCharts: {
      query: FETCH_EHPAD_PRICES_FOR_CHARTS,
      variables() {
        return {
          ehpadId: this.currentEhpad.id,
          year: parseInt(this.rangeForPriceChart.year, 10),
          months: parseInt(this.rangeForPriceChart.months, 10),
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  watch: {
    pricesForCharts: {
      handler() {
        if (this.pricesForCharts !== undefined) {
          this.dataPricesForCharts = this.buildDataPricesForCharts;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    chartRangeOptions() {
      return [
        { value: { months: null, year: null }, text: '3 mois glissants' },
        { value: { months: 6, year: null }, text: '6 mois glissants' },
        { value: { months: 12, year: null }, text: '12 mois glissants' },
        { value: { months: null, year: moment().year() - 2 }, text: moment().year() - 2 },
        { value: { months: null, year: moment().year() - 1 }, text: moment().year() - 1 },
        { value: { months: null, year: moment().year() }, text: moment().year() },
      ];
    },
    buildDataPricesForCharts() {
      const datesArray = Object.keys(this.pricesForCharts);
      const fetchValuesForField = (field) => datesArray.map(date => [date, _.get(this.pricesForCharts[date], field, 0)]);

      return [
        {
          name: 'Prix à la journée du menu global',
          data: fetchValuesForField('total').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#ff6347',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
