<template>
  <div>
    <h5 class="text-center">Apports hydriques</h5>
    <line-chart :data="chartData" class="mb-5" :points=false :library="options"></line-chart>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: ['residentMenusForCharts', 'nutritionGoals', 'options'],
  computed: {
    chartData() {
      const hydrationIngested = _.map(this.residentMenusForCharts, (v) => ({
        date: v.date,
        value: v.hydrationIngested,
      }));
      const hydrationTotal = _.map(this.residentMenusForCharts, (v) => ({
        date: v.date,
        value: v.hydrationTotal,
      }));
      const hydrationGoal = _.map(hydrationIngested, (v) => ({
        date: v.date,
        value: this.buildHydrationGoal(v.date),
      }));
      return [
        {
          name: 'Apports hydriques ingérés',
          data: hydrationIngested.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#469be0',
        },
        {
          name: 'Apports hydriques servis',
          data: hydrationTotal.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#FF9900',
        },
        {
          name: 'Cible nutritionnelle (apports hydriques)',
          data: hydrationGoal.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#DC3912',
        },
      ];
    },
  },
  methods: {
    buildHydrationGoal(date) {
      let index = 0;
      if (this.nutritionGoals.length === 1) return this.nutritionGoals[0].hydration;
      do {
        if (moment(date).isBefore(this.nutritionGoals[index + 1].date)) {
          return this.nutritionGoals[index].hydration;
        }
        index++;
      } while (this.nutritionGoals[index + 1] !== undefined);
      return this.nutritionGoals.slice(-1)[0].hydration;
    },
  },
};
</script>

<style scoped></style>
