<template>
  <b-col xs="4" md="4" lg="4" class="meal-replication-settings-card p-3 m-1">

    <h6>{{ this.mealCategoryHuman.toUpperCase() }}</h6>

    <b-form
      class="align-items-center"
    >
        <b-form-group>
          <b-form-radio-group
            v-model="replicationState"
            :options="options"
            name="radio-inline"
            :class="{ 'is-invalid': $v.replicationState.$error }"
          ></b-form-radio-group>
        </b-form-group>

        <b-input-group class="mb-2">
          <b-form-input
            id="scheduleTime"
            v-model="scheduleTime"
            type="text"
            placeholder="Heure de passage"
            size="sm"
            @input="useDefaultScheduleTime = false"
            :class="{ 'is-invalid': $v.scheduleTime.$anyError }"
          ></b-form-input>
          <b-input-group-append>
            <b-form-timepicker
              v-model="scheduleTime"
              locale="fr"
              button-only
              right
              size="sm"
              minutes-step="15"
              @input="useDefaultScheduleTime = false"
              :class="{ 'is-invalid': $v.scheduleTime.$anyError }"
            />
          </b-input-group-append>
        </b-input-group>

        <b-button
          variant="primary"
          size="sm"
          @click="updateResidentMealSettings()"
          :disabled="updating"
          >Valider</b-button
        >
    </b-form>

  </b-col>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, helpers } from 'vuelidate/lib/validators';
import { hourCheck } from '../../regex';

const hourRegex = helpers.regex('hourRegex', hourCheck);

export default {
  mixins: [validationMixin],
  props: ['mealCategory', 'mealCategoryHuman', 'editableResident'],
  data() {
    return {
      updating: false,
      replicationState: null,
      scheduleTime: null,
      useDefaultScheduleTime: null,
      options: [
        { text: 'Menu global', value: true },
        { text: 'Menu individuel', value: false },
      ],
    };
  },
  created() {
    this.useDefaultScheduleTime = this.editableResident.allSettings[this.mealCategory].use_default_schedule_time;
    this.replicationState = this.editableResident.allSettings[this.mealCategory].replication;
    // Get resident settings or ehpad if null
    this.scheduleTime = this.editableResident.allSettings[this.mealCategory].schedule_time;
    if (this.scheduleTime === null) {
      this.scheduleTime = this.currentEhpad.allSettings.meal_categories_options.default_schedule_time[this.mealCategory];
    }
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
  watch: {
    scheduleTime() {
      this.scheduleTime = this.scheduleTime.split(':').slice(0,2).join(':');
    },
  },
  validations: {
    replicationState: {
      required,
    },
    scheduleTime: {
      required,
      hourRegex,
    },
  },
  methods: {
    async updateResidentMealSettings() {
      this.$v.replicationState.$touch();
      this.$v.scheduleTime.$touch();
      if (this.$v.$anyError) return;
      this.updating = true;
      await this.$store.dispatch('updateResidentMealSettings', {
        id: this.editableResident.id,
        meal: this.mealCategory,
        value: this.replicationState,
        scheduleTime: this.scheduleTime,
        useDefaultScheduleTime: this.useDefaultScheduleTime,
      }).then((result) => {
        this.$v.$reset();
      }).catch((error) => {
        this.replicationState = this.editableResident.allSettings[this.mealCategory].replication;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      this.updating = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.meal-replication-settings-card {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
</style>
