<template>
  <!-- https://bootstrap-vue.js.org/docs/components/table -->
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Buffets
        </h3>
        <b-button class="mb-2 ml-4" variant="primary" size="sm" v-b-modal.new-buffet-modal>
        <i class="fas fa-plus" />
            Ajouter un nouveau buffet
        </b-button>
      </span>
    </div>

    <app-new-buffet-modal
      id="new-buffet-modal"
      hide-footer
    />

    <b-form-group
      label-size="sm"
      label-for="filterBuffetInput"
      class="mb-3"
      style="width: 40%;"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="filterBuffet"
          type="search"
          id="filterBuffetInput"
          placeholder="Filtre de recherche"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filterBuffet" @click="filterBuffet = ''">Effacer</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-table
      sticky-header="500px"
      :filter="filterBuffet"
      bordered
      striped
      small
      hover
      :items="buffets"
      :fields="computedFields"
      :sort-by.sync="sortBy"
      class="m-0"
      ref="buffetTable"
      id="buffetTable"
    >

      <template #head(defaultCalories)="data">
        <span>
          <img src="/assets/images/calories.png" class="cal-icon"
            v-b-tooltip.hover.v-primary
            title="Valeurs en kcal"
          >
        </span>
      </template>

      <template #head(defaultProteins)="data">
        <span>
          <img src="/assets/images/proteins.png" class="prot-icon"
            v-b-tooltip.hover.v-primary
            title="Valeurs en g"
          >
        </span>
      </template>

      <template #head(defaultHydration)="data">
        <span>
          <img src="/assets/images/hydration.png" class="hydration-icon"
            v-b-tooltip.hover.v-primary
            title="Valeurs en ml"
          >
        </span>
      </template>

      <template #cell(active)="data">{{ humanActive(data.item) }}</template>

      <template #cell(averagePrice)="data">
        <span style="font-size: medium;" v-if="data.item.averagePrice !== null">
          {{ data.item.averagePrice }}€
        </span>
      </template>

      <template #cell(totalPrice)="data">
        <span style="font-size: medium;" v-if="data.item.totalPrice !== null">
          {{ data.item.totalPrice }}€
        </span>
      </template>

      <template #head(actions)>{{ '' }}</template>

      <template #cell(actions)="data">
        <b-button variant="link" size="sm" v-b-modal.info-buffet-modal @click="selectBuffet(data.item)">
          <i class="fas fa-edit"
            v-b-tooltip.hover.v-primary
            title="Modifier"
          />
        </b-button>
        <b-button variant="link" size="sm" @click="archiveBuffet(data.item)">
          <i class="text-danger fa fa-trash-alt"
            v-b-tooltip.hover.v-danger
            title="Supprimer"
          />
        </b-button>
      </template>
    </b-table>

    <app-info-buffet-modal
      v-if="selectedBuffet"
      id="info-buffet-modal"
      hide-footer
      :buffet="selectedBuffet"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import InfoBuffetModal from './InfoBuffetModal.vue';
import NewBuffetModal from './NewBuffetModal.vue';

export default {
  components: {
    appInfoBuffetModal: InfoBuffetModal,
    appNewBuffetModal: NewBuffetModal,
  },
  data() {
    return {
      filterBuffet: null,
      selectedBuffet: null,
      sortBy: 'humanType',
      fields: [
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'humanType',
          label: 'Catégorie',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'defaultCalories',
          label: 'Calories',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'defaultProteins',
          label: 'Protéines',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'defaultHydration',
          label: 'Apports hydriques',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'totalPrice',
          label: 'Prix (Tot)',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'averagePrice',
          label: 'Prix (Moy)',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'active',
          label: 'Disponible',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'actions',
          label: 'actions',
          class: 'text-center',
        },
      ],
    };
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.selectedBuffet = null;
    });
  },
  methods: {
    archiveBuffet(buffet) {
      Swal.fire({
        title: 'Êtes vous sûr ?',
        text:
          'Êtes vous sûr de vouloir supprimer ce buffet de facon définitive ? Il restera affiché dans les anciens menus mais ne sera plus utilisable.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          await this.$store.dispatch('archiveBuffet', {
            id: buffet.id,
          }).catch((error) => {
            this.flashMessage.show({
              status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
        }
      });
    },
    humanActive(buffet) {
      if (buffet.active === true) return 'Actif';
      return 'Désactivé';
    },
    selectBuffet(buffet) {
      this.selectedBuffet = buffet;
    },
  },
  computed: {
    ...mapGetters({
      buffets: 'sortedBuffetsByName',
      currentUser: 'user',
    }),
    computedFields() {
      if (this.currentUser.role !== 'administrateur' && this.currentUser.role !== 'intendance') {
        return this.fields.filter((field) => field.key !== 'totalPrice' && field.key !== 'averagePrice');
      }
      return this.fields;
    },
  },
};
</script>

<style lang="scss" scoped>
  :deep(.small-size) {
    max-width: 75px;
  }

  .cal-icon {
    filter: invert(21%) sepia(91%) saturate(5127%) hue-rotate(118deg) brightness(94%) contrast(105%);
    width: 80%;
    max-width: 40px;
  }

  .hydration-icon {
    filter: invert(58%) sepia(85%) saturate(7456%) hue-rotate(234deg) brightness(99%) contrast(103%);
    width: 80%;
    max-width: 40px;
  }

  .prot-icon {
    filter: invert(46%) sepia(86%) saturate(2816%) hue-rotate(330deg) brightness(113%) contrast(109%);
    width: 80%;
    max-width: 40px;
  }
</style>
