<template>
  <b-modal v-bind="$attrs" id='expired-tests-alert-modal' class="col-12 text-center" hide-header hide-footer>
    <b-container fluid>
      <h3 class="text-center" style="color: red;">Tests expirés :</h3>
      <hr>

      <div v-for="expiredTest in expiredTests" :key="expiredTest.id">
        <div class="my-3">
          <h6>- {{ expiredTest.name }}</h6>
        </div>
      </div>

    </b-container>
      <template #modal-footer="{ cancel }">
      <b-button class="d-flex mx-auto" size="sm" variant="primary" @click="cancel()">
        Fermer
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  props: ['expiredTests'],
  data() {
    return {
      error: null,
    };
  },
};
</script>

<style lang="scss" scoped>


</style>
