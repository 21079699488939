<template>
  <div>
    <div v-if="residentMealDish.dish !== null && residentMealDish.buffet === null">
      <li>
        <p class="m-0">
          <span style="font-size: x-large;">
            {{ capitalizeString(residentMealDish.dish.name)  }}
          </span>
          <template v-if="residentMealDish.residentMealDishSupplementations.length !== 0">
            (<span
              v-for="residentMealDishSupplementation in residentMealDishSupplementations"
              :key="residentMealDishSupplementation.id"
            >
              <span style="font-size: smaller; color: blue">
                + {{ residentMealDishSupplementation.supplementation.name }}
              </span> </span
            >)
          </template>

          <b-button
            variant="link"
            v-b-modal="`add-comment-modal${residentMealDish.id}`"
            class="far fa-comment-dots"
          />

          <app-add-comment-modal
            :id="`add-comment-modal${residentMealDish.id}`"
            hide-footer
            :residentMealDish="residentMealDish"
          />

          <span class="d-print-only">
            INGESTA :
            <span v-for="(ingestaObject, index) in ingestaObjects" class="mx-2" :key="index">
              <span :class="ingestaClassForPrint(ingestaObject)">{{ ingestaObject.value * 100 }}%</span>
            </span>
          </span>
        </p>
      </li>

      <b-row class="mt-2 d-print-none d-flex justify-content-around mr-4">
        <b-col cols="1" v-for="ingestaObject in ingestaObjects" :key="ingestaObject.value">
          <div :class="ingestaClass(ingestaObject)" @click="updateIngesta(ingestaObject)">
            <span style="color: white;">{{ ingestaObject.value * 100 }}%</span>
          </div>
        </b-col>
      </b-row>

      <hr class="w-50 d-print-none" />
    </div>

    <!-- Buffets -->

    <div v-if="residentMealDish.buffet !== null && residentMealDish.dish === null">
      <li>
        <p class="m-0 mb-3">
          <span style="font-size: x-large;">
            {{ capitalizeString(residentMealDish.buffet.name) }}
            <i class="far fa-list-alt"
              style="color: #5bc0de;"
              v-b-tooltip.hover.v-primary
              title="Buffet"
            />
          </span>

          <b-button
            variant="link"
            v-b-modal="`add-comment-modal${residentMealDish.id}`"
            class="far fa-comment-dots"
          />

          <app-add-comment-modal
            :id="`add-comment-modal${residentMealDish.id}`"
            hide-footer
            :residentMealDish="residentMealDish"
          />

        </p>
      </li>

        <app-dish-chosen-for-buffet
          v-for="buffetResidentMealDishIngested in residentMealDish.buffetResidentMealDishesIngested"
          :key="buffetResidentMealDishIngested.id"
          :buffetResidentMealDishIngested="buffetResidentMealDishIngested"
          :mealCategory="mealCategory"
          :ingestaPrefilled="ingestaPrefilled"
        />

        <app-dish-for-buffet
          v-for="dish in residentMealDish.buffetDishesNotIngested"
          :key="dish.id"
          :residentMealDishBuffet="residentMealDish"
          :dish="dish"
          v-on:dish-is-chosen="dishIsChosen = true"
          :dishIsChosen="dishIsChosen"
          :mealCategory="mealCategory"
          :ingestaPrefilled="ingestaPrefilled"
        />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { capitalizeString } from '../../utilities/filters';
import _ from 'lodash';
import EventBus from '../../utilities/event_bus';
import { UPDATE_RESIDENT_MEAL_DISH } from '../../graphql/resident_meal_dishes/mutations';
import AddCommentModal from './AddCommentModal.vue';
import DishChosenForBuffet from './DishChosenForBuffet.vue';
import DishForBuffet from './DishForBuffet.vue';

export default {
  name: 'Dish',
  props: ['residentMealDish', 'menuEditState', 'mealCategory', 'ingestaPrefilled'],
  components: {
    AppAddCommentModal: AddCommentModal,
    AppDishChosenForBuffet: DishChosenForBuffet,
    AppDishForBuffet: DishForBuffet,
  },
  data() {
    return {
      ingestaObjects: [
        { value: 0, class: 'redCircle', isSelected: false, isPrefilled: false },
        { value: 0.25, class: 'redCircle', isSelected: false, isPrefilled: false },
        { value: 0.5, class: 'orangeCircle', isSelected: false, isPrefilled: false },
        { value: 0.75, class: 'orangeCircle', isSelected: false, isPrefilled: false },
        { value: 1.0, class: 'greenCircle', isSelected: false, isPrefilled: false },
        { value: 1.25, class: 'greenCircle', isSelected: false, isPrefilled: false },
        { value: 1.5, class: 'greenCircle', isSelected: false, isPrefilled: false },
        { value: 1.75, class: 'greenCircle', isSelected: false, isPrefilled: false },
        { value: 2, class: 'greenCircle', isSelected: false, isPrefilled: false },
      ],
      dishIsChosen: false,
      currentSurvey: null,
    };
  },
  created() {
    this.setIngestaValue();
    if (this.residentMealDish.buffetResidentMealDishesIngested !== null &&
        this.residentMealDish.buffetResidentMealDishesIngested.length > 0) {
      this.dishIsChosen = true;
    }
    if (this.currentSurveys.length > 0) {
      const filtered_survey = this.currentSurveys.filter(survey => survey.mealCategories.find(mc => mc.name === this.mealCategory.name) );
      this.currentSurvey = filtered_survey[0] || null;
    }
  },
  computed: {
    residentMealDishSupplementations() {
      return this.residentMealDish.residentMealDishSupplementations;
    },
    ...mapGetters({
      currentSurveys: 'currentSurveys',
    }),
  },
  methods: {
    capitalizeString,
    setIngestaValue() {
      if (this.residentMealDish.ingesta !== null) {
        const obj = _.find(this.ingestaObjects, { value: this.residentMealDish.ingesta });
        obj.isSelected = true;
      }
      if (this.residentMealDish.prefilled === true) {
        const obj = _.find(this.ingestaObjects, { value: this.residentMealDish.ingesta });
        obj.isPrefilled = true;
      }
    },
    updateIngesta(ingestaObject) {
      this.ingestaObjects.forEach((el) => {
        el.isSelected = false;
        el.isPrefilled = false;
      });
      ingestaObject.isSelected = true;
      if (this.ingestaPrefilled === true) {
        ingestaObject.isPrefilled = true;
      }
      this.updating(ingestaObject);
    },
    ingestaClass(item) {
      if (item.isSelected && item.isPrefilled === true) {
        return `selected-prefilled ${item.class}`;
      }
      if (item.isSelected && item.isPrefilled === false) {
        return `selected ${item.class}`;
      }
      return item.class;
    },
    ingestaClassForPrint(item) {
      if (item.isSelected) {
        return `selected-print ${item.class}`;
      }
      return item.class;
    },
    async updating(ingestaObject) {
      await this.$apollo.mutate({
        mutation: UPDATE_RESIDENT_MEAL_DISH,
        variables: {
          id: this.residentMealDish.id,
          ingesta: ingestaObject.value,
          comment: this.residentMealDish.comment,
          prefilled: ingestaObject.isPrefilled,
        },
      }).then((result) => {
        // For Survey Modal with RMD Id:
        if (this.currentSurvey !== null) {
          if (ingestaObject.value >= this.currentSurvey.ingestaMin
            && ingestaObject.value <= this.currentSurvey.ingestaMax
          ) {
            EventBus.$emit('trigger-survey', result.data.updateResidentMealDish.residentMealDish.id, this.currentSurvey);
          }
        }
      }).catch((error) => {
        ingestaObject.isSelected = false;
        ingestaObject.isPrefilled = false;
        this.setIngestaValue();
        if (error.message === 'Network error: Failed to fetch') {
          alert('Erreur réseau, veuillez rééssayer');
        } else {
          this.flashMessage.show({
            status: 'error',
            title: error.graphQLErrors[0].extensions.code,
            message: error.graphQLErrors[0].message,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.selected {
  box-shadow: 0px 0px 5px 5px #469ae0;
}

.selected-prefilled {
  box-shadow: 0px 0px 5px 5px red;
}

.selected-print {
  color: red;
  text-decoration: underline;
}

.redCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background: rgb(184, 31, 31);
  cursor: pointer;
}
.orangeCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background: orange;
  cursor: pointer;
}
.greenCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background: rgb(138, 210, 149);
  cursor: pointer;
}
</style>
