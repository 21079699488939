<template>
  <div>
    <div class="d-flex align-items-center">
      <h6>Aide au repas</h6>
      <div class="pl-3 d-print-none" v-if="!editRequireHelp && !readonly">
        <b-button variant="primary" size="sm" @click="editRequireHelp = true">
          <i class="fas fa-edit" />
        </b-button>
      </div>
    </div>
    <b-form>
      <dl class="row">
        <dt class="col-12">
          <div v-if="editRequireHelp">
            <b-form-group
              size="sm"
              style="margin-right: 125px;"
            >
              <b-form-radio v-model="editableResident.requireHelp" name="true" value=true
                >Oui</b-form-radio
              >
              <b-form-radio
                v-model="editableResident.requireHelp"
                name="false"
                value=false
                >Non</b-form-radio
              >
            </b-form-group>
          </div>
          <div v-else>
            <label v-if="resident.requireHelp === true"><b>Oui</b></label>
            <label v-else><b>Non</b></label>
          </div>
        </dt>

        <div class="pl-3 pt-2" v-if="editRequireHelp">
          <b-button variant="danger" size="sm" @click="cancelEdit" v-if="editRequireHelp"
            >Annuler</b-button
          >
          <b-button variant="primary" size="sm" @click="updateResident" v-if="editRequireHelp"
            >Valider</b-button
          >
        </div>
      </dl>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'requireHelp-Form',
  props: ['resident', 'readonly'],
  data() {
    return {
      error: null,
      editableResident: null,
      editRequireHelp: false,
    };
  },
  watch: {
    resident: {
      handler() {
        this.duplicateResident(this.resident);
      },
      immediate: true,
    },
  },
  created() {
    this.duplicateResident(this.resident);
  },
  methods: {
    cancelEdit() {
      this.editRequireHelp = false;
    },
    duplicateResident(resident) {
      const roomId = resident && resident.room && resident.room.id;
      const sectorId = resident && resident.sector && resident.sector.id;
      this.editableResident = { ...resident, roomId, sectorId };
    },
    async updateResident() {
      const requireHelpBoolean = (this.editableResident.requireHelp === 'true' || this.editableResident.requireHelp === true);
      await this.$store.dispatch('updateResident', {
        id: this.editableResident.id,
        name: this.editableResident.name,
        firstName: this.editableResident.firstName,
        gender: this.editableResident.gender,
        height: parseInt(this.editableResident.height, 10),
        idealWeight: parseFloat(this.editableResident.idealWeight),
        equipmentWeight: parseFloat(this.editableResident.equipmentWeight),
        nutritionalInfo: this.editableResident.nutritionalInfo,
        diet: this.editableResident.diet,
        requireHelp: requireHelpBoolean,
        roomId: this.editableResident.roomId,
        sectorId: this.editableResident.sectorId,
      }).then((result) => {
        this.duplicateResident(result);
      }).catch((error) => {
        // For resetting form with current values :
        this.duplicateResident(this.resident);
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
};

</script>

<style lang="scss" scoped>

</style>
