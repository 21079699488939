<template>
  <ul>
    <app-dish
      v-for="mealDish in sortedDishesAndBuffets"
      :key="mealDish.id"
      :mealDish="mealDish"
      :menuEditState="menuEditState"
    />
    <li v-if="mealDishes.length === 0">Aucun plat</li>
  </ul>
</template>

<script>
import _ from 'lodash';

import Dish from './Dish.vue';

export default {
  name: 'Dishes',
  props: ['mealDishes', 'menuEditState'],
  components: {
    appDish: Dish,
  },
  methods: {
    sortDishesAndBuffets(type) {
      const sortedDishesAndBuffets = _.sortBy(this.mealDishes, (el) => (el.buffet && el.buffet.name) || (el.dish && el.dish.name));
      return sortedDishesAndBuffets.filter((el) => (el.dish && el.dish.dType === type) || (el.buffet && el.buffet.dType === type));
    },
  },
  computed: {
    sortedDishesAndBuffets() {
      return [
        ...this.sortDishesAndBuffets('other'),
        ...this.sortDishesAndBuffets('starter'),
        ...this.sortDishesAndBuffets('main_course'),
        ...this.sortDishesAndBuffets('dairy'),
        ...this.sortDishesAndBuffets('desert'),
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
