import gql from 'graphql-tag';

export const UPDATE_SUPPLEMENTATION = gql`
  mutation UPDATE_SUPPLEMENTATION($id: ID!, $name: String!, $proteins: Int!, $calories: Int!, $hydration: Int!, $price: Float) {
    updateSupplementation(
      input: {
        id: $id
        name: $name
        proteins: $proteins
        calories: $calories
        hydration: $hydration
        price: $price
      }
    ) {
      supplementation {
        id
        name
        calories
        proteins
        hydration
        price
      }
    }
  }
`;

export const CREATE_SUPPLEMENTATION = gql`
  mutation CREATE_SUPPLEMENTATION($name: String!, $proteins: Int!, $calories: Int!, $hydration: Int!, $ehpadId: ID!, $price: Float) {
    createSupplementation(
      input: {
        name: $name
        proteins: $proteins
        calories: $calories
        hydration: $hydration
        ehpadId: $ehpadId
        price: $price
      }
    ) {
      supplementation {
        id
        name
        calories
        proteins
        hydration
        price
      }
    }
  }
`;