<template>
  <div>
    <div
      style="transform: scale(0.8);margin-top: -30px;"
    >
      <p class="m-0">
        <span style="font-size: x-large;">
          {{ capitalizeString(dish.name) }}
        </span>

        <span class="d-print-only">
          INGESTA :
          <span v-for="(ingestaObject, index) in ingestaObjects" class="mx-2" :key="index">
            <span :class="ingestaClassForPrint(ingestaObject)">{{ ingestaObject.value * 100 }}%</span>
          </span>
        </span>
      </p>

      <b-row class="mt-2 d-print-none d-flex justify-content-around mr-4">
        <b-col cols="1" v-for="ingestaObject in ingestaObjects" :key="ingestaObject.value">
          <div :class="ingestaClass(ingestaObject)" @click="createIngestaForBuffet(ingestaObject, dish.id)">
            <span style="color: white;">{{ ingestaObject.value * 100 }}%</span>
          </div>
        </b-col>
      </b-row>

      <hr class="w-50 d-print-none" />
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { capitalizeString } from '../../utilities/filters';
import _ from 'lodash';
import EventBus from '../../utilities/event_bus';
import { CREATE_RESIDENT_MEAL_DISH_FOR_BUFFET_INGESTA } from '../../graphql/resident_meal_dishes/mutations';

export default {
  name: 'DishForBuffet',
  props: ['residentMealDishBuffet', 'dish', 'dishIsChosen', 'mealCategory', 'ingestaPrefilled'],
  data() {
    return {
      ingestaObjects: [
        { value: 0, class: 'redCircle', isSelected: false, isPrefilled: false },
        { value: 0.25, class: 'redCircle', isSelected: false, isPrefilled: false },
        { value: 0.5, class: 'orangeCircle', isSelected: false, isPrefilled: false },
        { value: 0.75, class: 'orangeCircle', isSelected: false, isPrefilled: false },
        { value: 1.0, class: 'greenCircle', isSelected: false, isPrefilled: false },
        { value: 1.25, class: 'greenCircle', isSelected: false, isPrefilled: false },
        { value: 1.5, class: 'greenCircle', isSelected: false, isPrefilled: false },
        { value: 1.75, class: 'greenCircle', isSelected: false, isPrefilled: false },
        { value: 2, class: 'greenCircle', isSelected: false, isPrefilled: false },
      ],
      selectedIngestaObject: null,
      currentSurvey: null,
    };
  },
  created() {
    if (this.dishIsChosen) {
      this.setIngestaTo0();
    }
    if (this.currentSurveys.length > 0) {
      // Get matching survey if exist for meal_category
      const filtered_survey = this.currentSurveys.filter(survey => survey.mealCategories.find(mc => mc.name === this.mealCategory.name) );
      this.currentSurvey = filtered_survey[0] || null;
    }
  },
  watch: {
    dishIsChosen: {
      handler() {
        if (this.dishIsChosen) {
          this.setIngestaTo0();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      currentSurveys: 'currentSurveys',
    }),
  },
  methods: {
    capitalizeString,
    setIngestaTo0() {
      // A dish has been chosen or is choose we set other dishes to 0 for UX
      const obj = _.find(this.ingestaObjects, { value: 0 });
      obj.isSelected = true;
      if (this.selectedIngestaObject !== null && this.selectedIngestaObject.value !== 0) {
        const selectedIngesta = _.find(this.ingestaObjects, { value: 0 });
        selectedIngesta.isSelected = false;
      }
    },
    createIngestaForBuffet(ingestaObject, dishId) {
      this.ingestaObjects.forEach((el) => {
        el.isSelected = false;
        el.isPrefilled = false;
      });
      ingestaObject.isSelected = true;
      if (this.ingestaPrefilled === true) {
        ingestaObject.isPrefilled = true;
      }
      this.creatingIngestaForBuffet(ingestaObject, dishId);
    },
    ingestaClass(item) {
      if (item.isSelected && item.isPrefilled === true) {
        return `selected-prefilled ${item.class}`;
      }
      if (item.isSelected && item.isPrefilled === false) {
        return `selected ${item.class}`;
      }
      return item.class;
    },
    ingestaClassForPrint(item) {
      if (item.isSelected) {
        return `selected-print ${item.class}`;
      }
      return item.class;
    },
    async creatingIngestaForBuffet(ingestaObject, dishId) {
      // Buffet ResidentMeal Dish are not created by default.
      // When Ingesta is set for a dish in a buffet we create the Meal Dish for this dish and ingesta
      // We also set Ingesta for the Meal Dish Buffet to 0 in resolver (because it has to not count as not set ingesta)
      await this.$apollo.mutate({
        mutation: CREATE_RESIDENT_MEAL_DISH_FOR_BUFFET_INGESTA,
        variables: {
          id: this.residentMealDishBuffet.id,
          ingesta: ingestaObject.value,
          prefilled: ingestaObject.isPrefilled,
          dishId,
        },
      }).then((result) => {
        this.selectedIngestaObject = ingestaObject;
        this.$emit('dish-is-chosen');
        // For Survey Modal with RMD Id:
        if (this.currentSurvey !== null) {
          if (ingestaObject.value >= this.currentSurvey.ingestaMin
            && ingestaObject.value <= this.currentSurvey.ingestaMax
          ) {
            EventBus.$emit('trigger-survey', result.data.createResidentMealDishForBuffetIngesta.residentMealDish.id, this.currentSurvey);
          }
        }
      }).catch((error) => {
        ingestaObject.isSelected = false;
        ingestaObject.isPrefilled = false;
        if (error.message === 'Network error: Failed to fetch') {
          alert('Erreur réseau, veuillez rééssayer');
        } else {
          this.flashMessage.show({
            status: 'error',
            title: error.graphQLErrors[0].extensions.code,
            message: error.graphQLErrors[0].message,
          });
        }
      });
      // .catch((error) => {
      //   ingestaObject.isSelected = false;
      // });
      // We emit and event when a dish is choose
      // This is a parent component only event
      // EventBus target the all app, this target only the parent of this specific child
    },
  },
};
</script>

<style lang="scss" scoped>
.selected {
  box-shadow: 0px 0px 5px 5px #469ae0;
}

.selected-prefilled {
  box-shadow: 0px 0px 5px 5px red;
}

.selected-print {
  color: red;
  text-decoration: underline;
}

.redCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background: rgb(184, 31, 31);
  cursor: pointer;
}
.orangeCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background: orange;
  cursor: pointer;
}
.greenCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background: rgb(138, 210, 149);
  cursor: pointer;
}
</style>
