<template>
  <div v-if="survey !== null">
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Enquête : {{ survey.name }}
        </h3>
      </span>
      <b-button class="mb-2 ml-4" size="sm" variant="primary" :to="{ path: xlsx() }" target="_blank"
        v-b-tooltip.hover.v-primary title="Exporter les données"
      >
        <i class="far fa-file-excel" />
      </b-button>
    </div>
    <hr>

    <div class="d-flex justify-content-center align-items-center mb-3">
      <h5 class="m-0" style="display: inline-block;margin: 0;">
        Résultats de l'enquête
      </h5>
    </div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <h6 class="m-0" style="display: inline-block;margin: 0;">
        <i>Question de l'enquête : {{ survey.questions[0].description }}</i>
      </h6>
    </div>
    <b-row align-h="center" v-if="checkExistingResults()">
      <pie-chart
        suffix="%"
        height="300px"
        width="400px"
        :legend="true"
        color="white"
        :data="survey.statsForCharts"
        :library="options"
      ></pie-chart>
    </b-row>
    <b-row align-h="center" v-else>
      <p><i>Il n'existe pas encore de résultats pour cette enquête</i></p>
    </b-row>
    <hr>

    <div v-if="currentUser.role == 'administrateur' || currentUser.role == 'intendance'">
      <div class="d-flex justify-content-center align-items-center mb-3">
        <h5 class="m-0" style="display: inline-block;margin: 0;">
          Modifier l'enquête
        </h5>
      </div>

      <a id="edit-survey"></a>
      <app-edit-survey-form
        :survey="survey"
      />
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditSurveyForm from '../../components/surveys/EditSurveyForm.vue';

export default {
  props: ['id'],
  components: {
    appEditSurveyForm: EditSurveyForm,
  },
  data() {
    return {
      options: {
        plugins: {
          datalabels: {
						color: 'white',
          },
        },
      },
    };
  },
  methods: {
    ...mapActions(['findSurvey']),
    checkExistingResults() {
      const resultCheck = Object.values(this.survey.statsForCharts).some(x => (x !== null));
      return resultCheck;
    },
    xlsx() {
      return `/ehpads/${this.currentEhpad.shortname}/survey/${this.id}/download.xlsx`;
    },
  },
  computed: {
    ...mapGetters({
      survey: 'survey',
      currentEhpad: 'ehpad',
      currentUser: 'user',
    }),
  },
  created() {
    this.findSurvey(this.id);
  },
};

</script>

<style>

</style>
