<template>
  <b-modal v-bind="$attrs" title="Passer un Test" size="lg" class="col-12 text-center">
    <b-container fluid>
      <app-resident-test-form :resident=resident />
      <hr />
    </b-container>
  </b-modal>
</template>

<script>
import ResidentTestForm from './ResidentTestForm.vue';

export default {
  props: ['resident'],
  components: {
    appResidentTestForm: ResidentTestForm,
  },
};
</script>

<style lang="scss" scoped></style>
