import gql from 'graphql-tag';
import jwtDecode from 'jwt-decode';

import apiClient from '../../utilities/api_client';
import { FETCH_USERS } from '../../graphql/users/queries';
import {
  UPDATE_USER,
  CREATE_USER,
  CREATE_TICKET,
  UPDATE_CURRENT_USER_TERMS_OF_SERVICE,
  UPDATE_CURRENT_USER_PASSWORD,
} from '../../graphql/users/mutations';

// par convention on utilise pas state
// tout sera merge de facon et cela correspondra à state.user
const userState = {
  user: null,
  users: [],
};

// Mutations en UPPER_SNAKE_CASE
// On change l'etat de la data user en fonction des commits :
const mutations = {
  SIGNIN(state, user) {
    state.user = user;
  },
  SIGNOUT(state) {
    state.user = null;
  },
  SET_USERS(state, users) {
    state.users = users;
  },
  UPDATE_USER(state, user) {
    const index = state.users.findIndex((e) => e.id === user.id);
    state.users.splice(index, 1, user);
  },
  UPDATE_CURRENT_USER(state, user) {
    state.user = user;
  },
  ADD_USER(state, user) {
    state.users.push(user);
  },
  CREATE_TICKET(state, ticket) {
    state.user.lastTicket = ticket;
  },
};

// ACTION en camelCase
const actions = {
  // methode asynchrone on fera le commit aprés la logique
  signinUser: async ({ commit, dispatch }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: gql`
        mutation SIGNIN($email: String!, $password: String!) {
          signin(input: {email: $email, password: $password}) {
            user {
              id
              email
              token
              role
              termsOfService
              lastTicket {
                id
                email
                phone
                lastName
                firstName
              }
              ehpad {
                id
                name
                email
                shortname
                color
                allSettings
              }
            }
          }
        }`,
      // ...quelquechose pour applatir l'objet du formulaire
      variables: { ...formData },
    });
    // Si la requete gql fonctionne (data.signin)
    if (data.signin) {
      // const user = data.signin.user; IDEM :
      const { user } = data.signin;
      // Si user n'est pas null, on commit la mutation vuex et on
      // enregistre dans le local storage le token renvoyé par gql
      if (user) {
        commit('SIGNIN', user);
        const decodedToken = jwtDecode(user.token);
        // Recup la conf devise d'expiration du token
        const expirationDate = new Date(decodedToken.exp * 1000);
        // Va enregistrer le token et la date d'expiration dans les cookies :
        localStorage.setItem('auth_token', user.token);
        document.cookie = `uname=${user.email}`;
        localStorage.setItem('expirationDate', expirationDate);
        // Si le user n'as pas d'ehpad on return, il selectionnera son ehpad dans le menu suivant
        if (!user.ehpad) return;

        // Si le user à un ehpad on le select
        dispatch('selectEhpad', user.ehpad);
      }
    } else {
      throw Error('bad credentials');
    }
  },
  signoutUser({ commit }) {
    // Le signout supprime le token du local storage, tout simplement
    commit('SIGNOUT');
    localStorage.removeItem('auth_token');
    localStorage.removeItem('expirationDate');
  },
  // A chaque utilisation de l'app on "autolog" le user si le token est toujours valide :
  // on met à jour le state du currentUser
  // (car le state du user sera reset si on quitte/reviens sur l'app ou si elle est reload)
  autoLogin: async ({ commit, dispatch }) => {
    const token = localStorage.getItem('auth_token');
    if (!token) return;
    const expirationDate = new Date(localStorage.getItem('expirationDate'));
    const now = new Date();
    if (now >= expirationDate) {
      // On signout si le token n'est plus valide (suppr du localstorage)
      dispatch('signoutUser');
      return;
    }
    // On query gql le current user
    // d'ou vienne les infos ? de la const token (token suffit ?) ? TODO
    const { data } = await apiClient.query({
      query: gql`
        query CURRENT_USER {
          currentUser {
            id
            email
            token
            role
            termsOfService
            lastTicket {
              id
              email
              phone
              lastName
              firstName
            }
            ehpad {
              id
              name
              email
              shortname
              color
              allSettings
            }
          }
        }
      `,
    });
    // Déconstruction de la variable data pour récupérer le currentUser
    const { currentUser } = data;
    if (!currentUser) {
      // On signout si le token renvoi pas d'utilisateur via la query gql
      dispatch('signoutUser');
      this.$router.push('/signin');
      return;
    }
    // sinon on re-signin
    commit('SIGNIN', currentUser);

    // Si le user n'as pas d'ehpad on return, il selectionnera son ehpad dans le menu suivant
    if (!currentUser.ehpad) return;

    // Si le user à un ehpad on le select
    dispatch('selectEhpad', currentUser.ehpad);
  },
  setUsers: async ({ commit }) => {
    const { data } = await apiClient.query({
      query: FETCH_USERS,
      fetchPolicy: 'network-only',
    });
    commit('SET_USERS', data.users);
  },
  createUser: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: CREATE_USER,
      variables: { ...formData },
    });
    commit('ADD_USER', data.createUser.user);
  },
  updateUser: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_USER,
      variables: { ...formData },
    });
    const { user } = data.updateUser;
    commit('UPDATE_USER', user);
    return user;
  },
  updateCurrentUserTermsOfService: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_CURRENT_USER_TERMS_OF_SERVICE,
      variables: { ...formData },
    });
    const { user } = data.updateCurrentUserTermsOfService;
    commit('UPDATE_CURRENT_USER', user);
    return user;
  },
  updateCurrentUserPassword: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_CURRENT_USER_PASSWORD,
      variables: { ...formData },
    });
    const { user } = data.updateCurrentUserPassword;
    commit('UPDATE_CURRENT_USER', user);
    return user;
  },
  createTicket: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: CREATE_TICKET,
      variables: { ...formData },
    });
    const { ticket } = data.createTicket;
    commit('CREATE_TICKET', ticket);
    return ticket;
  },
};

// Pour recuperer le state du user (donc currentUser si il est loggué, sinon null)
const getters = {
  user: (state) => state.user,
  users: (state) => state.users,
};

export default {
  state: userState,
  mutations,
  actions,
  getters,
};
