import { render, staticRenderFns } from "./ResidentInfosForm.vue?vue&type=template&id=18a0793c&scoped=true"
import script from "./ResidentInfosForm.vue?vue&type=script&lang=js"
export * from "./ResidentInfosForm.vue?vue&type=script&lang=js"
import style0 from "./ResidentInfosForm.vue?vue&type=style&index=0&id=18a0793c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18a0793c",
  null
  
)

export default component.exports