import apiClient from '../../utilities/api_client';
import { FETCH_SURVEYS, FIND_SURVEY, FIND_SURVEYS_ON_DATE } from '../../graphql/surveys/queries';
import { CREATE_SURVEY, UPDATE_SURVEY } from '../../graphql/surveys/mutations';

const surveyState = {
  survey: null,
  surveys: [],
  currentSurveys: [],
};

const mutations = {
  SELECT_SURVEY(state, survey) {
    state.survey = survey;
  },
  SET_SURVEYS(state, surveys) {
    state.surveys = surveys;
  },
  SET_CURRENT_SURVEYS(state, currentSurveys) {
    state.currentSurveys = currentSurveys;
  },
  UPDATE_SURVEY(state, survey) {
    const index = state.surveys.findIndex((e) => e.id === survey.id);
    state.surveys.splice(index, 1, survey);
  },
  ADD_SURVEY(state, survey) {
    state.surveys.push(survey);
  },
};

const actions = {
  findSurveysOnDate: async ({ commit }, formData) => {
    const { data } = await apiClient.query({
      query: FIND_SURVEYS_ON_DATE,
      variables: { ...formData },
      fetchPolicy: 'network-only',
    });
    commit('SET_CURRENT_SURVEYS', data.findSurveysOnDate);
  },
  findSurvey: async ({ commit }, id) => {
    const { data } = await apiClient.query({
      query: FIND_SURVEY,
      variables: { id },
      fetchPolicy: 'network-only',
    });
    commit('SELECT_SURVEY', data.findSurvey);
  },
  setSurveys: async ({ commit }, ehpadId) => {
    const { data } = await apiClient.query({
      query: FETCH_SURVEYS,
      variables: { ehpadId },
      fetchPolicy: 'network-only',
    });
    commit('SET_SURVEYS', data.surveys);
  },
  createSurvey: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: CREATE_SURVEY,
      variables: { ...formData },
    });
    const { survey } = data.createSurvey;
    commit('ADD_SURVEY', survey);
  },
  updateSurvey: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_SURVEY,
      variables: { ...formData },
    });
    const { survey } = data.updateSurvey;
    commit('UPDATE_SURVEY', survey);
  },
};

const getters = {
  survey: (state) => state.survey,
  surveys: (state) => state.surveys,
  currentSurveys: (state) => state.currentSurveys,
};

export default {
  state: surveyState,
  mutations,
  actions,
  getters,
};
