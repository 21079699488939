import gql from 'graphql-tag';

export const UPDATE_BUFFET = gql`
  mutation UPDATE_BUFFET($id: ID!, $name: String!, $defaultProteins: Int!, $defaultCalories: Int!, $defaultHydration: Int!, $active: Boolean!, $changePast: Boolean!, $ehpadId: ID!, $dType: String!, $dishes: [DishAttributes!]!) {
    updateBuffet(
      input: {
        id: $id
        name: $name
        defaultProteins: $defaultProteins
        defaultCalories: $defaultCalories
        defaultHydration: $defaultHydration
        active: $active
        changePast: $changePast
        ehpadId: $ehpadId
        dType: $dType
        dishes: $dishes
      }
    ) {
      buffet {
        id
        name
        dType
        humanType
        active
        defaultProteins
        defaultCalories
        defaultHydration
        totalPrice
        averagePrice
        dishes {
          id
          name
          dType
          humanType
          active
          defaultProteins
          defaultCalories
          defaultHydration
        }
      }
    }
  }
`;

export const ARCHIVE_BUFFET = gql`
  mutation ARCHIVE_BUFFET($id: ID!) {
    archiveBuffet(
      input: {
        id: $id
      }
    ) {
      buffet {
        id
      }
    }
  }
`;

export const CREATE_BUFFET = gql`
  mutation CREATE_BUFFET($name: String!, $defaultProteins: Int!, $defaultCalories: Int!, $defaultHydration: Int!, $active: Boolean!, $ehpadId: ID!, $dType: String!, $dishes: [DishAttributes!]!) {
    createBuffet(
      input: {
        name: $name
        defaultProteins: $defaultProteins
        defaultCalories: $defaultCalories
        defaultHydration: $defaultHydration
        active: $active
        ehpadId: $ehpadId
        dType: $dType
        dishes: $dishes
      }
    ) {
      buffet {
        id
        name
        dType
        humanType
        active
        defaultProteins
        defaultCalories
        defaultHydration
        totalPrice
        averagePrice
        dishes {
          id
          name
          dType
          humanType
          active
          defaultProteins
          defaultCalories
          defaultHydration
        }
      }
    }
  }
`;

export const DESTROY_DEFAULT_BUFFET = gql`
  mutation DESTROY_DEFAULT_BUFFET($id: ID!, $mealCategory: String!) {
    destroyDefaultBuffet(input: {id: $id, mealCategory: $mealCategory}) {
      buffet {
        id
      }
    }
  }
`;

export const ADD_DEFAULT_BUFFET = gql`
  mutation ADD_DEFAULT_BUFFET($id: ID!, $mealCategory: String!) {
    addDefaultBuffet(input: {id: $id, mealCategory: $mealCategory}) {
      buffet {
        id
      }
    }
  }
`;

