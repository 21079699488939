<template>
    <div>
        <app-surveys-table />
    </div>
</template>

<script>
import SurveysTable from '../../components/surveys/Table.vue';

export default {
  components: {
    appSurveysTable: SurveysTable,
  },
};
</script>

<style lang="scss" scoped>
</style>
