<template>
  <div>
    <b-row v-if="residentDiagnosticsForCharts !== undefined">
      <b-col class="col-2">Intervalle </b-col>
      <b-col class="col-2">
        <div>
          <b-form-select
            v-model="rangeForDiagnosticsCharts"
            :options="chartRangeOptions"
            @change="residentDiagnosticsForCharts = undefined"
          />
        </div>
      </b-col>
    </b-row>
    <div v-if="residentDiagnosticsForCharts !== undefined" class="mt-3">
      <line-chart :data="dataResidentDiagnosticsForCharts" :library="options"></line-chart>
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="residentDiagnosticsForCharts === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { FETCH_RESIDENT_DIAGNOSTICS_FOR_CHARTS } from '../../graphql/resident_charts/queries';

export default {
  props: ['resident'],
  data() {
    return {
      dataResidentDiagnosticsForCharts: [],
      rangeForDiagnosticsCharts: { months: null, year: null },
      options: {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              displayFormats: {
                day: 'DD MMM YYYY',
              },
            },
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'États nutritionnels',
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  apollo: {
    residentDiagnosticsForCharts: {
      query: FETCH_RESIDENT_DIAGNOSTICS_FOR_CHARTS,
      variables() {
        return {
          residentId: this.resident.id,
          year: parseInt(this.rangeForDiagnosticsCharts.year, 10),
          months: parseInt(this.rangeForDiagnosticsCharts.months, 10),
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  watch: {
    residentDiagnosticsForCharts: {
      handler() {
        if (this.residentDiagnosticsForCharts !== undefined) {
          this.dataResidentDiagnosticsForCharts = this.buildDataResidentDiagnosticsForCharts;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    chartRangeOptions() {
      return [
        { value: { months: null, year: null }, text: '12 mois glissants' },
        { value: { months: 6, year: null }, text: '6 mois glissants' },
        { value: { months: 3, year: null }, text: '3 mois glissants' },
        { value: { months: null, year: moment().year() - 2 }, text: moment().year() - 2 },
        { value: { months: null, year: moment().year() - 1 }, text: moment().year() - 1 },
        { value: { months: null, year: moment().year() }, text: moment().year() },
      ];
    },
    buildDataResidentDiagnosticsForCharts() {
      return [
        {
          name: 'États Nutritionnels (Utilisateur)',
          data: this.residentDiagnosticsForCharts.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.state]),
          color: '#469be0',
        },
        {
          name: 'EN (NRI)',
          data: this.residentDiagnosticsForCharts.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.nri_state]),
          color: '#DC3911',
        },
        {
          name: 'EN (HAS2007)',
          data: this.residentDiagnosticsForCharts.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.has2007_state]),
          color: '#FF9900',
        },
        {
          name: 'EN (HAS2021)',
          data: this.residentDiagnosticsForCharts.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.has2021_state]),
          color: '#0F9618',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
