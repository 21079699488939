import gql from 'graphql-tag';

export const FETCH_RESIDENTS_FOR_INGESTA = gql`
  query FETCH_RESIDENTS_FOR_INGESTA($ehpadId: ID!, $date: String!) {
    residentsWithRoomOnDate(ehpadId: $ehpadId, date: $date) {
      id
      name
      firstName
      fullName
      gender
      height
      diet
      lastWeight {
        id
        date
        value
      }
      lastDiagnostic {
        id
        date
        state
        comment
        humanState
      }
      lastTexture {
        id
        date
        tType
        humanType
      }
      room {
        id
        number
      }
      sector {
        id
        name
      }
      individualProgram {
        id
        actions {
          id
          description
          date
          actionType
        }
        goals {
          id
          description
          date
        }
      }
      ingestaControl(date: $date)
    }
  }
`;

export const FETCH_RESIDENTS = gql`
  query FETCH_RESIDENTS($ehpadId: ID!) {
    residents(ehpadId: $ehpadId) {
      id
      name
      firstName
      fullName
      gender
      height
      idealWeight
      equipmentWeight
      nutritionalInfo
      diet
      requireHelp
      lastNri
      lastStatus {
        id
        description
        date
        statusCategory {
          name
        }
      }
      gustatoryPerceptions {
        id
        gustatoryCategoryId
        value
        gustatoryCategory {
          name
        }
      }
      lastWeight {
        id
        date
        value
      }
      lastMna {
        id
        date
        value
      }
      lastCrp {
        id
        date
        value
      }
      lastAlbumine {
        id
        date
        value
        correctedAlbumine
      }
      lastDiagnostic {
        id
        date
        state
        comment
        humanState
      }
      lastNutritionGoal {
        id
        proteins
        calories
        hydration
      }
      lastTexture {
        id
        date
        tType
        humanType
      }
      lastGeneralComment {
        id
        date
        commentText
      }
      room {
        id
        number
      }
      sector {
        id
        name
      }
    }
  }
`;

export const FIND_RESIDENT = gql`
  query FIND_RESIDENT(
      $id: ID!
      $year: Int
      $months: Int
    ) {
    findResident(
      id: $id
    ) {
      id
      name
      firstName
      fullName
      gender
      height
      lastNri
      idealWeight
      equipmentWeight
      nutritionalInfo
      diet
      requireHelp
      allSettings
      recentNutritionReduction
      residentTests {
        date
        value
        comment
        testType {
          name
          isBoolean
          allSettings
        }
      }
      expiredTestTypes {
        id
        name
      }
      sarcopeniaStatus
      absorptionStatus
      aggressionStatus
      lastStatus {
        id
        description
        date
        statusCategory {
          name
        }
      }
      gustatoryPerceptions {
        id
        gustatoryCategoryId
        value
        gustatoryCategory {
          name
        }
      }
      weights(year: $year, months: $months) {
        id
        date
        value
      }
      mnas(year: $year, months: $months) {
        id
        date
        value
      }
      crps(year: $year, months: $months) {
        id
        date
        value
      }
      albumines(year: $year, months: $months) {
        id
        date
        value
        correctedAlbumine
        nri
      }
      diagnostics {
        id
        date
        state
        comment
        humanState
        humanNriState
        humanHas2007State
        humanHas2021State
        nri
        user {
          email
        }
      }
      lastWeight {
        id
        date
        value
      }
      lastMna {
        id
        date
        value
      }
      lastCrp {
        id
        date
        value
      }
      lastAlbumine {
        id
        date
        value
        correctedAlbumine
        nri
      }
      lastNutritionGoal {
        id
        date
        proteins
        calories
        hydration
        manual
      }
      nutritionGoals {
        id
        proteins
        calories
        hydration
        date
      }
      lastTexture {
        id
        date
        tType
        humanType
      }
      lastGeneralComment {
        id
        date
        commentText
      }
      textures {
        id
        date
        tType
        humanType
      }
      lastDiagnostic {
        id
        date
        state
        comment
        humanState
        nri
        user {
          email
        }
      }
      individualProgram {
        id
        actions {
          id
          description
          date
          actionType
        }
        goals {
          id
          description
          date
        }
      }
      room {
        id
        number
      }
      sector {
        id
        name
      }
    }
  }
`;

export const RESIDENT_DEFAULT_MEAL_DISHES = gql`
  query RESIDENT_DEFAULT_MEAL_DISHES($residentId: ID!, $mealCategory: String!) {
    residentDefaultMealDishes(residentId: $residentId, mealCategory: $mealCategory) {
      id
      dish {
        id
        name
        dType
        humanType
        defaultProteins
        defaultCalories
        defaultHydration
      }
    }
  }
`;

export const RESIDENT_DEFAULT_MEAL_BUFFETS = gql`
  query RESIDENT_DEFAULT_MEAL_BUFFETS($residentId: ID!, $mealCategory: String!) {
    residentDefaultMealBuffets(residentId: $residentId, mealCategory: $mealCategory) {
      id
      buffet {
        id
        name
        dType
        humanType
        defaultProteins
        defaultCalories
        defaultHydration
      }
    }
  }
`;
