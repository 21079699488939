import gql from 'graphql-tag';

export const CREATE_ALBUMINE = gql`
  mutation CREATE_ALBUMINE($date: String!, $value: Float!, $residentId: ID!) {
    createAlbumine(input: { date: $date, value: $value, residentId: $residentId }) {
      albumine {
        id
        date
        value
      }
    }
  }
`;
