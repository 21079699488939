import gql from 'graphql-tag';

export const CREATE_MEAL_DISH = gql`
  mutation CREATE_MEAL_DISH($mealId: ID!, $dishOrBuffetId: ID!, $type: String!) {
    createMealDish(
      input: { mealId: $mealId, dishOrBuffetId: $dishOrBuffetId, type: $type }
    ) {
      mealDish {
        id
      }
    }
  }
`;

export const UPDATE_MEAL_DISH = gql`
  mutation UPDATE_MEAL_DISH($id: ID!, $proteins: Int, $calories: Int, $hydration: Int) {
    updateMealDish(input: {id: $id, proteins: $proteins, calories: $calories, hydration: $hydration}) {
      mealDish {
        id
      }
    }
  }
`;

export const DESTROY_MEAL_DISH = gql`
  mutation DESTROY_MEAL_DISH($id: ID!) {
    destroyMealDish(input: {id: $id}) {
      mealDish {
        id
      }
    }
  }
`;
