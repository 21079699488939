<template>
  <div>
    <b-row align-h="center">
        <b-col sm="12" md="6" lg="4" xl="2" class="text-center"
            v-for="residentDiagnostic in residentDiagnostics"
            :key="residentDiagnostic.id"
        >
          <app-resident-diag-log-card
            :residentDiagnostic="residentDiagnostic"
          />

        </b-col>
    </b-row>
  </div>
</template>

<script>
import ResidentDiagLogCard from './ResidentDiagLogCard.vue';

export default {
  props: ['residentDiagnostics'],
  components: {
    appResidentDiagLogCard: ResidentDiagLogCard,
  },
};

</script>

<style lang="scss" scoped>
.diag-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
</style>
