<template>
  <b-col v-bind="$attrs" :class="{'col-wrapper': !disableColWrapper}">
    <slot></slot>
  </b-col>
</template>

<script>
import EventBus from '../../utilities/event_bus';

export default {
  name: 'ColContent',
  data() {
    return {
      disableColWrapper: false
    };
  },
  created() {
    EventBus.$on('disable-col-content', this.disableCss);
    EventBus.$on('enable-col-content', this.enableCss);
  },
  beforeDestroy() {
    EventBus.$off('disable-col-content', this.disableCss);
    EventBus.$off('enable-col-content', this.enableCss);
  },
  methods: {
    disableCss() {
      this.disableColWrapper = true;
    },
    enableCss() {
      this.disableColWrapper = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.col-wrapper {
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  box-shadow: 1px 1px 2px #F4F4F4;
  padding: 20px;
}
</style>
