import gql from 'graphql-tag';

export const CREATE_TEXTURE = gql`
  mutation CREATE_TEXTURE(
    $tType: String!
    $residentId: ID!
  ) {
    createTexture(
      input: { tType: $tType, residentId: $residentId }
    ) {
      texture {
        id
        tType
        date
        humanType
      }
    }
  }
`;
