import gql from 'graphql-tag';

export const CREATE_INDIVIDUAL_PROGRAM = gql`
  mutation CREATE_INDIVIDUAL_PROGRAM($id: ID, $residentId: ID!, $actions: [ActionAttributes!]!, $goals: [GoalAttributes!]!,) {
    createIndividualProgram(input: { id: $id, residentId: $residentId, actions: $actions, goals: $goals }) {
      individualProgram {
        id
        actions {
          id
          description
          date
          actionType
        }
        goals {
          id
          description
          date
        }
      }
    }
  }
`;
