<template>
  <!-- https://bootstrap-vue.js.org/docs/components/table -->
  <div>

    <div class="d-flex justify-content-between align-items-center mb-3">
      <b-button variant="primary" :to="{ path: formatButtonLink('prev') }" class="d-print-none" @click="loading = true">
        Jour précédent
      </b-button>
      <span class="text-center">
        <h3>Residents - Ingesta du {{ capitalizeString(dateFormatterFull(momentDate())) }}</h3>
      </span>

      <b-button variant="primary" :to="{ path: formatButtonLink('next') }" class="d-print-none" @click="loading = true">
        Jour suivant
      </b-button>
    </div>

    <div class="text-center" v-if="loading">
      <h4>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement...
      </h4>
    </div>

    <div v-else>
      <b-form-group
        label-size="sm"
        label-for="filterInput"
        class="mb-3"
        style="width: 40%;"
      >
        <b-input-group size="sm">
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Filtre de recherche"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-table
        v-model="residentsIngestaSorted"
        :filter="filter"
        sticky-header="800px"
        bordered
        striped
        small
        hover
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="residentsWithRoomOnDate"
        :fields="computedFields"
        class="m-0"
        ref="residentTable"
        id="residentTable"
      >
        <template #cell(name)="data">
          <router-link
            :to="`/ehpads/${currentEhpad.shortname}/resident/${data.item.id}/ingesta/${date}`"
            >{{ data.value }}</router-link
          >
        </template>
        <template #cell(lastWeight)="data">
          <span
            v-if="data.item.lastDiagnostic.state == 'severe_undernutrition'"
            v-html="
              coloredDataHtml(
                data.item.lastWeight,
                currentEhpad.allSettings.severe_undernutrition.constants_config.minimum_weight_value_taking_in_days,
              )
            "
          >
          </span>
          <span
            v-else-if="data.item.lastDiagnostic.state == 'moderate_undernutrition'"
            v-html="
              coloredDataHtml(
                data.item.lastWeight,
                currentEhpad.allSettings.moderate_undernutrition.constants_config.minimum_weight_value_taking_in_days,
              )
            "
          >
          </span>
          <span
            v-else-if="data.item.lastDiagnostic.state == 'high_risk'"
            v-html="
              coloredDataHtml(
                data.item.lastWeight,
                currentEhpad.allSettings.high_risk.constants_config.minimum_weight_value_taking_in_days,
              )
            "
          >
          </span>
          <span
            v-else
            v-html="
              coloredDataHtml(
                data.item.lastWeight,
                currentEhpad.allSettings.default_undernutrition.constants_config.minimum_weight_value_taking_in_days,
              )
            "
          >
          </span>
        </template>
        <template #cell(lastDiagnostic)="data">
          <span v-html="coloredDiagHtml(data.item.lastDiagnostic.humanState)"></span>
        </template>

        <template #cell(ingestaControl)="data"
        >
          <div class="d-flex justify-content-between">
            <template>
              <span v-for="value, meal in data.item.ingestaControl" :key="`${data.item.id}_${meal}`">
                <b-badge
                  class="mr-1"
                  style="font-size: small;"
                  :variant="ingestaColor(value)"
                  v-b-tooltip.hover.v-primary
                  :title="ingestaTitle(value, meal)"
                >
                <span>
                  {{ ingestaShortName(meal) }}
                </span>
                </b-badge>
              </span>
            </template>
          </div>
        </template>

        <template #cell(lastTexture)="data">
          <span style="font-size: medium;">{{ data.item.lastTexture.humanType }}</span>
        </template>
        <template #cell(room)="data">{{
          (data.item.room && `N° ${data.item.room.number}`) || 'Aucun lit assigné'
        }}</template>
        <template #cell(sector)="data">
          <span style="font-size: medium;">{{ (data.item.sector && `${data.item.sector.name}`) || 'Pas de secteur' }}</span>
        </template>
      </b-table>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import {
  coloredDataHtml, coloredDiagHtml, dateFormatterFull, capitalizeString
} from '../../utilities/filters';

import { FETCH_RESIDENTS_FOR_INGESTA } from '../../graphql/residents/queries';

export default {
  props: ['date'],
  apollo: {
    residentsWithRoomOnDate: {
      query: FETCH_RESIDENTS_FOR_INGESTA,
      variables() {
        return {
          ehpadId: this.currentEhpad.id,
          date: this.date,
        };
      },
      fetchPolicy: 'network-only',
      result() {
        this.loading = false;
      },
    },
  },
  watch: {
    sortBy(newVal) {
      this.$cookies.set('nutriviewSortingMethodIngestaTable', newVal);
    },
    sortDesc(newVal) {
      this.$cookies.set('nutriviewSortingWayIngestaTable', newVal);
    },
    residentsIngestaSorted(newVal) {
      localStorage.nutriviewResidentsIngestaSorted = JSON.stringify(newVal);
    },
  },
  created() {
    this.sortBy = this.$cookies.get('nutriviewSortingMethodIngestaTable');
    const boolean = (this.$cookies.get('nutriviewSortingWayIngestaTable') === 'true' || this.$cookies.get('nutriviewSortingWayIngestaTable') === true);
    this.sortDesc = boolean;
  },
  methods: {
    dateFormatterFull,
    capitalizeString,
    coloredDataHtml,
    coloredDiagHtml,
    momentDate() {
      return moment(this.date, 'DD-MM-YYYY');
    },
    initNextButton() {
      const date = this.momentDate();
      const nextDay = date.add(1, 'd');
      return { nextDay };
    },
    initPrevButton() {
      const date = this.momentDate();
      const prevDay = date.subtract(1, 'd');
      return { prevDay };
    },
    formatButtonLink(type) {
      let day;
      let data;
      if (type === 'next') {
        data = this.initNextButton();
        day = data.nextDay.format('DD-MM-YYYY');
      } else {
        data = this.initPrevButton();
        day = data.prevDay.format('DD-MM-YYYY');
      }
      return `/ehpads/${this.currentEhpad.shortname}/${day}/ingesta/`;
    },
    residentElValue(el) {
      return _.get(el, 'value', null);
    },
    residentElState(el) {
      return _.get(el, 'state', null);
    },
    residentElNumber(el) {
      return _.get(el, 'number', null);
    },
    residentEltType(el) {
      return _.get(el, 'tType', null);
    },
    setInputType(key) {
      return ['lastWeight', 'height'].includes(key) ? 'number' : 'text';
    },
    ingestaColor(value) {
      if (value === 100) { return 'success'; }
      if (value >= 80) { return 'primary'; }
      if (value >= 50) { return 'warning'; }
      if (value === null) { return 'dark'; }
      return 'danger';
    },
    ingestaShortName(meal) {
      if (meal === 'Collation du matin') { return 'CM'; }
      if (meal === 'Petit déjeuner') { return 'PD'; }
      if (meal === 'Déjeuner') { return 'DJ'; }
      if (meal === 'Goûter') { return 'GO'; }
      if (meal === 'Dîner') { return 'DI'; }
      if (meal === 'Collation du soir') { return 'CS'; }
    },
    ingestaTitle(value, meal) {
      if (value === null) { return `${meal} : Pas de plat`; }
      return `${meal} : ${value}%`;
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    computedFields() {
      if (!this.currentEhpad.allSettings.configuration.sectors.activate_sectors) {
        return this.fields.filter((field) => field.key !== 'sector');
      }
      return this.fields;
    },
  },
  data() {
    return {
      residentsIngestaSorted: [],
      filter: null,
      loading: true,
      sortBy: 'name',
      sortDesc: false,
      fields: [
        {
          key: 'firstName',
          label: 'Prénom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'room',
          label: 'Lit',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElNumber',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'sector',
          label: 'Secteur',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        // {
        //   key: 'height',
        //   label: 'Taille',
        //   sortable: true,
        //   tdClass: 'align-middle',
        //   thClass: 'align-middle',
        // },
        // {
        //   key: 'lastWeight',
        //   label: 'Poids (en Kg)',
        //   sortable: true,
        //   sortByFormatted: true,
        //   formatter: 'residentElValue',
        //   tdClass: 'align-middle',
        //   thClass: 'align-middle',
        // },
        {
          key: 'lastDiagnostic',
          label: 'État nutritionnel',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElState',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'lastTexture',
          label: 'Texture',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentEltType',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'diet',
          label: 'Régime',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'ingestaControl',
          label: 'Remplissage des ingesta',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
