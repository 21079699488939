<template>
  <b-col cols="12" class="meal-settings-card p-3 m-1">
    <h6 class="text-center"><strong>{{ this.mealCategoryHuman.toUpperCase() }}</strong></h6>
    <div class="text-center" v-if="(residentDefaultMealDishes && residentDefaultMealDishes.length === 0) && (residentDefaultMealBuffets && residentDefaultMealBuffets.length === 0)">
      <i>Pas de plat ou de buffet par défaut pour ces repas</i>
    </div>
    <template v-if="residentDefaultMealDishes && residentDefaultMealDishes.length > 0">
      <p class="mb-1 text-center"><u>Plats</u></p>
      <div
        v-for="residentDefaultMealDish in residentDefaultMealDishes"
        class="m-0 d-flex justify-content-between"
        :key="residentDefaultMealDish.id"
      >
        <li>
          <span style="font-size: smaller">{{`${residentDefaultMealDish.dish.name} (${residentDefaultMealDish.dish.humanType})`}}</span>
          <div class="text-nowrap" style="width: 350px;">
            <span class="calories"><img src="/assets/images/calories.png" class="cal-icon"> {{ residentDefaultMealDish.dish.defaultCalories }} kcal</span>
            |
            <span class="proteins"><img src="/assets/images/proteins.png" class="prot-icon"> {{ residentDefaultMealDish.dish.defaultProteins }} g</span>
            |
            <span class="hydration"><img src="/assets/images/hydration.png" class="hydration-icon"> {{ residentDefaultMealDish.dish.defaultHydration }} ml</span>
        </div>
        </li>
        <b-button
          variant="link"
          size="sm"
          class="text-danger fa fa-trash-alt"
          @click="onDestroyDefaultDish(residentDefaultMealDish.dish)"
        />
      </div>
    </template>

    <template v-if="residentDefaultMealBuffets && residentDefaultMealBuffets.length > 0">
      <p class="mb-1 text-center"><u>Buffets</u></p>
      <div
        v-for="residentDefaultMealBuffet in residentDefaultMealBuffets"
        class="m-0 d-flex justify-content-between"
        :key="residentDefaultMealBuffet.id"
      >
        <li>
          <span style="font-size: smaller">{{`${residentDefaultMealBuffet.buffet.name} (${residentDefaultMealBuffet.buffet.humanType})`}}</span>
          <div class="text-nowrap" style="width: 350px;">
            <span class="calories"><img src="/assets/images/calories.png" class="cal-icon"> {{ residentDefaultMealBuffet.buffet.defaultCalories }} kcal</span>
            |
            <span class="proteins"><img src="/assets/images/proteins.png" class="prot-icon"> {{ residentDefaultMealBuffet.buffet.defaultProteins }} g</span>
            |
            <span class="hydration"><img src="/assets/images/hydration.png" class="hydration-icon"> {{ residentDefaultMealBuffet.buffet.defaultHydration }} ml</span>
          </div>
        </li>
        <b-button
          variant="link"
          size="sm"
          class="text-danger fa fa-trash-alt"
          @click="onDestroyDefaultBuffet(residentDefaultMealBuffet.buffet)"
        />
      </div>
    </template>

    <div>
      <b-form class="d-flex mt-2">
        <app-dishes-buffets-multiselect
          :options="activeDishesAndBuffetsByTypeOptions"
          :onChange="setDishOrBuffet"
          :dataToWatch="dishOrBuffet"
        />
        <b-button variant="primary" @click.prevent="onSubmit" :disabled="loading">
          <i :class="loading ? 'fa fa-spin fa-circle-notch' : 'fa fa-plus'" />
        </b-button>
      </b-form>
    </div>
  </b-col>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import DishesBuffetsMultiselect from '../shared/DishesBuffetsMultiselect.vue';

import { RESIDENT_DEFAULT_MEAL_DISHES, RESIDENT_DEFAULT_MEAL_BUFFETS } from '../../graphql/residents/queries';

export default {
  props: ['mealCategory', 'mealCategoryHuman'],
  data() {
    return {
      dishOrBuffet: null,
      loading: false,
    };
  },
  components: {
    appDishesBuffetsMultiselect: DishesBuffetsMultiselect,
  },
  apollo: {
    residentDefaultMealDishes: {
      query: RESIDENT_DEFAULT_MEAL_DISHES,
      variables() {
        return {
          residentId: this.resident.id,
          mealCategory: this.mealCategory,
        };
      },
    },
    residentDefaultMealBuffets: {
      query: RESIDENT_DEFAULT_MEAL_BUFFETS,
      variables() {
        return {
          residentId: this.resident.id,
          mealCategory: this.mealCategory,
        };
      },
    },
  },
  methods: {
    setDishOrBuffet(value) {
      this.dishOrBuffet = value;
    },
    onDestroyDefaultDish(defaultMealDish) {
      Swal.fire({
        title: 'Êtes vous sûr ?',
        text:
          'Êtes vous sûr de vouloir supprimer ce plat permanent des repas du résident ? Il ne sera plus ajouté automatiquement dans les futurs menus.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          await this.$store.dispatch('destroyResidentDefaultMealDish', {
            dishId: defaultMealDish.id,
            residentId: this.resident.id,
            mealCategory: this.mealCategory,
          }).catch((error) => {
            this.flashMessage.show({
              status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
          await this.$apollo.queries.residentDefaultMealDishes.refetch();
        }
      });
    },
    onDestroyDefaultBuffet(defaultMealBuffet) {
      Swal.fire({
        title: 'Êtes vous sûr ?',
        text:
          'Êtes vous sûr de vouloir supprimer ce buffet permanent des repas du résident ? Il ne sera plus ajouté automatiquement dans les futurs menus.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          await this.$store.dispatch('destroyResidentDefaultMealBuffet', {
            buffetId: defaultMealBuffet.id,
            residentId: this.resident.id,
            mealCategory: this.mealCategory,
          }).catch((error) => {
            this.flashMessage.show({
              status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
          await this.$apollo.queries.residentDefaultMealBuffets.refetch();
        }
      });
    },
    async onSubmit() {
      if (this.dishOrBuffet === null) return;
      this.loading = true;
      if (this.dishOrBuffet.type === 'Dish') {
        await this.$store.dispatch('createResidentDefaultMealDish', {
          dishId: this.dishOrBuffet.id,
          residentId: this.resident.id,
          mealCategory: this.mealCategory,
        }).catch((error) => {
          this.flashMessage.show({
            status: 'error',
            title: error.graphQLErrors[0].extensions.code,
            message: error.graphQLErrors[0].message,
          });
        });
      } else if (this.dishOrBuffet.type === 'Buffet') {
        await this.$store.dispatch('createResidentDefaultMealBuffet', {
          buffetId: this.dishOrBuffet.id,
          residentId: this.resident.id,
          mealCategory: this.mealCategory,
        }).catch((error) => {
          this.flashMessage.show({
            status: 'error',
            title: error.graphQLErrors[0].extensions.code,
            message: error.graphQLErrors[0].message,
          });
        });
      }
      this.loading = false;
      this.dishOrBuffet = null;
      await this.$apollo.queries.residentDefaultMealDishes.refetch();
      await this.$apollo.queries.residentDefaultMealBuffets.refetch();
    },
  },
  computed: {
    ...mapGetters({
      activeDishesAndBuffetsByType: 'activeDishesAndBuffetsByType',
      resident: 'resident',
    }),
    activeDishesAndBuffetsByTypeOptions() {
      return this.activeDishesAndBuffetsByType.map((el) => ({
        text: `${el.name} (${el.humanType})`,
        id: el.id,
        proteins: el.defaultProteins,
        calories: el.defaultCalories,
        hydration: el.defaultHydration,
        type: el.__typename,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.meal-settings-card {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.calories {
  font-size: 0.8rem;
  color: green;
}
.proteins {
  font-size: 0.8rem;
  color: red;
}
.hydration {
  font-size: 0.8rem;
  color: blue;
}
.hydration-icon {
  filter: invert(58%) sepia(85%) saturate(7456%) hue-rotate(234deg) brightness(99%) contrast(103%);
  width: 8%;
  height: 46%;
  max-width: 35px;
}
.cal-icon {
  filter: invert(21%) sepia(91%) saturate(5127%) hue-rotate(118deg) brightness(94%) contrast(105%);
  width: 8%;
  height: 46%;
  max-width: 35px;
}
.prot-icon {
  filter: invert(46%) sepia(86%) saturate(2816%) hue-rotate(330deg) brightness(113%) contrast(109%);
  width: 8%;
  height: 46%;
  max-width: 35px;
}

</style>
