<template>
  <div>
    <h5>Cibles Nutritionelles (Jour)</h5>
    <b-form>
      <dl class="row">
        <dd class="col-4 text-nowrap small">Calories :</dd>
        <dt class="col-8">
          <div v-if="editNutritionGoal">
            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="nutritionGoal.calories"
              :class="{ 'is-invalid': $v.nutritionGoal.calories.$error }"
            />
            <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
          </div>
          <div v-else>
            <label>{{ resident.lastNutritionGoal.calories }} kcal</label>
          </div>
        </dt>

        <dd class="col-4 text-nowrap small">Protéines :</dd>
        <dt class="col-8">
          <div v-if="editNutritionGoal">
            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="nutritionGoal.proteins"
              :class="{ 'is-invalid': $v.nutritionGoal.proteins.$error }"
            />
            <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
          </div>
          <div v-else>
            <label>{{ resident.lastNutritionGoal.proteins }} g</label>
          </div>
        </dt>

        <dd class="col-4 text-nowrap small">Hydriques :
          <i
            class="fas fa-exclamation-triangle d-print-none"
            style="color: #5bc0de;"
            v-b-tooltip.hover.v-primary
            title="Les valeurs sont exprimées en mililitres, 1000 ml = 1 litre"
          />
        </dd>
        <dt class="col-8">
          <div v-if="editNutritionGoal">
            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="nutritionGoal.hydration"
              :class="{ 'is-invalid': $v.nutritionGoal.hydration.$error }"
            />
            <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
          </div>
          <div v-else>
            <label>{{ resident.lastNutritionGoal.hydration }} ml</label>
          </div>
        </dt>

        <template v-if="!readonly">
          <dd class="col-4 text-nowrap small">Calcul auto :
            <i
              v-if="nutritionGoal.manual === true || nutritionGoal.manual === 'true'"
              class="fas fa-exclamation-triangle"
              style="color: red;"
              v-b-tooltip.hover.v-danger
              title="La cible nutritionnelle de ce résident a été modifiée à la main et n'est plus ajustée automatiquement"
            />
          </dd>
          <dt class="col-8">
            <div v-if="editNutritionGoal">

              <b-form-radio-group
                size="sm"
                v-model="nutritionGoal.manual"
              >
                <b-form-radio
                  name="Activé"
                  value=false
                  >Activé</b-form-radio
                >
                <b-form-radio
                  name="Désactivé"
                  value=true
                  >Désactivé</b-form-radio
                >
              </b-form-radio-group>

            </div>

            <div v-else>
              <label>{{ humanNutritionGoal(nutritionGoal.manual) }}</label>
            </div>

          </dt>
        </template>

        <div class="pl-3 pt-2" v-if="editNutritionGoal">
          <b-button variant="danger" size="sm" @click="cancelEdit" v-if="editNutritionGoal"
            >Annuler</b-button
          >
          <b-button
            variant="primary"
            size="sm"
            @click="createNutritionGoal"
            v-if="editNutritionGoal"
            >Valider</b-button
          >
        </div>
        <div class="pl-3 pt-2 d-print-none" v-else>
          <div v-if="!readonly">
            <b-button variant="primary" size="sm" @click="onEditNutritionGoal">
              <i class="fas fa-edit" />
              Editer
            </b-button>
          </div>
        </div>
      </dl>
    </b-form>
    <div class="mt-3" style="color: lightcoral;" v-if="!readonly">
      <p
        class="text-center"
        v-if="
          parseInt(computedCalories, 10) !==
            parseInt(resident.lastNutritionGoal.calories, 10)
        "
      >
        Attention : Une cible de {{ computedCalories }} kcal par jour minimum est
        préconisée.
      </p>
      <p
        class="text-center"
        v-if="
          parseInt(computedProteins, 10) !==
            parseInt(resident.lastNutritionGoal.proteins, 10)
        "
      >
        Attention : Une cible de {{ computedProteins }} g de protéines par jour minimum est
        préconisée.
      </p>
      <p
        class="text-center"
        v-if="
          parseInt(computedHydration, 10) !==
            parseInt(resident.lastNutritionGoal.hydration, 10)
        "
      >
        Attention : Une cible de {{ computedHydration }} ml d'apports hydriques par jour minimum est
        préconisée.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'ShowResident',
  mixins: [validationMixin],
  props: ['resident', 'readonly'],
  data() {
    return {
      editNutritionGoal: false,
      nutritionGoal: {
        proteins: null,
        calories: null,
        hydration: null,
        manual: false,
      },
    };
  },
  validations: {
    nutritionGoal: {
      proteins: {
        required,
      },
      calories: {
        required,
      },
      hydration: {
        required,
      },
    },
  },
  created() {
    this.nutritionGoal.proteins = this.resident.lastNutritionGoal.proteins;
    this.nutritionGoal.calories = this.resident.lastNutritionGoal.calories;
    this.nutritionGoal.hydration = this.resident.lastNutritionGoal.hydration;
    this.nutritionGoal.manual = this.resident.lastNutritionGoal.manual;
  },
  methods: {
    humanNutritionGoal(manual) {
      if (manual === true || manual === 'true') return 'Désactivé';
      return 'Activé';
    },
    onEditNutritionGoal() {
      if (this.nutritionGoal.manual === false || this.nutritionGoal.manual === 'false') {
        Swal.fire({
          title: 'Attention',
          text: 'Si vous modifiez la cible à la main, elle ne sera plus ajustée automatiquement pour ce résident',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          allowOutsideClick: true,
        }).then((result) => {
          if (result.value) {
            this.editNutritionGoal = true;
            this.nutritionGoal.manual = true;
            return;
          }
          this.editNutritionGoal = false;
        });
      }
      this.editNutritionGoal = true;
    },
    async createNutritionGoal() {
      this.$v.nutritionGoal.$touch();
      if (this.$v.$anyError) return;
      this.editNutritionGoal = false;
      if (this.nutritionGoal.manual === 'false' || this.nutritionGoal.manual === false) {
        this.nutritionGoal.proteins = this.computedProteins;
        this.nutritionGoal.calories = this.computedCalories;
        this.nutritionGoal.hydration = this.computedHydration;
      }
      const activeBoolean = (this.nutritionGoal.manual === 'true' || this.nutritionGoal.manual === true);
      await this.$store.dispatch('createNutritionGoal', {
        calories: parseInt(this.nutritionGoal.calories, 10),
        proteins: parseInt(this.nutritionGoal.proteins, 10),
        hydration: parseInt(this.nutritionGoal.hydration, 10),
        residentId: this.resident.id,
        manual: activeBoolean,
      }).then((result) => {
        this.$v.$reset();
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
        this.cancelEdit();
      });
    },
    cancelEdit() {
      this.editNutritionGoal = false;
      this.nutritionGoal.proteins = this.resident.lastNutritionGoal.proteins;
      this.nutritionGoal.calories = this.resident.lastNutritionGoal.calories;
      this.nutritionGoal.hydration = this.resident.lastNutritionGoal.hydration;
      this.nutritionGoal.manual = this.resident.lastNutritionGoal.manual;
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    computedCalories() {
      if (this.resident.lastDiagnostic.state === 'severe_undernutrition') {
        return Math.round(
          this.resident.lastWeight.value
          * this.currentEhpad.allSettings.severe_undernutrition.nutrition_goals.goals_calories,
        );
      }
      if (this.resident.lastDiagnostic.state === 'moderate_undernutrition') {
        return Math.round(
          this.resident.lastWeight.value
          * this.currentEhpad.allSettings.moderate_undernutrition.nutrition_goals.goals_calories,
        );
      }
      if (this.resident.lastDiagnostic.state === 'high_risk') {
        return Math.round(
          this.resident.lastWeight.value
          * this.currentEhpad.allSettings.high_risk.nutrition_goals.goals_calories,
        );
      }
      return Math.round(
        this.resident.lastWeight.value
        * this.currentEhpad.allSettings.default_undernutrition.nutrition_goals.goals_calories,
      );
    },
    computedProteins() {
      if (this.resident.lastDiagnostic.state === 'severe_undernutrition') {
        return Math.round(
          this.resident.lastWeight.value
          * this.currentEhpad.allSettings.severe_undernutrition.nutrition_goals.goals_proteins,
        );
      }
      if (this.resident.lastDiagnostic.state === 'moderate_undernutrition') {
        return Math.round(
          this.resident.lastWeight.value
          * this.currentEhpad.allSettings.moderate_undernutrition.nutrition_goals.goals_proteins,
        );
      }
      if (this.resident.lastDiagnostic.state === 'high_risk') {
        return Math.round(
          this.resident.lastWeight.value
          * this.currentEhpad.allSettings.high_risk.nutrition_goals.goals_proteins,
        );
      }
      return Math.round(
        this.resident.lastWeight.value
        * this.currentEhpad.allSettings.default_undernutrition.nutrition_goals.goals_proteins,
      );
    },
    computedHydration() {
      if (this.resident.lastDiagnostic.state === 'severe_undernutrition') {
        return Math.round(
          this.resident.lastWeight.value
          * this.currentEhpad.allSettings.severe_undernutrition.nutrition_goals.goals_hydration,
        );
      }
      if (this.resident.lastDiagnostic.state === 'moderate_undernutrition') {
        return Math.round(
          this.resident.lastWeight.value
          * this.currentEhpad.allSettings.moderate_undernutrition.nutrition_goals.goals_hydration,
        );
      }
      if (this.resident.lastDiagnostic.state === 'high_risk') {
        return Math.round(
          this.resident.lastWeight.value
          * this.currentEhpad.allSettings.high_risk.nutrition_goals.goals_hydration,
        );
      }
      return Math.round(
        this.resident.lastWeight.value
        * this.currentEhpad.allSettings.default_undernutrition.nutrition_goals.goals_hydration,
      );
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
