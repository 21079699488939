<template>
  <div>
    <h5 class="text-center" v-if="!noTitle">Poids</h5>
    <line-chart :data="chartData" :colors="['#469be0']" class="mb-5" :library="options" :height="height"></line-chart>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    noTitle: {
      type: Boolean,
      default: false,
    },
    residentWeights: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: '300px',
    },
  },
  computed: {
    chartData() {
      const sortedWeights = _.orderBy(this.residentWeights, (weight) => moment(weight.date), ['asc']);
      return [
        {
          name: 'Courbe de poids',
          data: sortedWeights.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
        },
      ];
    },
  },
};
</script>

<style scoped>
</style>
