<template>
    <b-container fluid>
      <b-table
        bordered
        striped
        small
        hover
        :items="diagnostics"
        :fields="fields"
      >
        <template #cell(humanState)="data">
          <span v-html="coloredDiagHtml(data.item.humanState)"></span>
        </template>
        <template #cell(date)="data">
          <span>{{ dateFormatterShort(data.item.date) }}</span>
        </template>

      </b-table>
    </b-container>
</template>

<script>
import { coloredDiagHtml, dateFormatterShort } from '../../utilities/filters';

export default {
  props: ["diagnostics"],
  data() {
    return {
      fields: [
        {
          key: "date",
          label: "Date",
          sortable: true,
          tdClass: "align-middle",
          thClass: "align-middle",
          class: 'date-size',
        },
        {
          key: "humanState",
          label: "Diagnostic",
          sortable: true,
          tdClass: "align-middle",
          thClass: "align-middle",
        },
        {
          key: "comment",
          label: "Commentaire",
          sortable: true,
          tdClass: "align-middle",
          thClass: "align-middle",
        },
      ],
    };
  },
  methods: {
    coloredDiagHtml,
    dateFormatterShort,
  },
};
</script>

<style lang="scss" scoped>
  :deep(.date-size) {
    width: 120px;
  }

</style>
