<template>
  <div>
    <b-row v-if="diagnosticsForCharts !== undefined">
      <b-col class="col-2">Intervalle </b-col>
      <b-col class="col-2">
        <div>
          <b-form-select
            v-model="rangeForDiagnosticChart"
            :options="chartRangeOptions"
            @change="diagnosticsForCharts = undefined"
          />
        </div>
      </b-col>
    </b-row>
    <h5 class="text-center">{{ this.title }}</h5>

    <div v-if="diagnosticsForCharts !== undefined" class="mt-3">
      <line-chart :data="dataDiagnosticsForCharts" :curve="false" :points=false :library="options"></line-chart>
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="diagnosticsForCharts === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { FETCH_EHPAD_DIAGNOSTICS_FOR_CHARTS } from '../../graphql/dashboard/queries';

export default {
  props: ['stateType'],
  data() {
    return {
      rangeForDiagnosticChart: { months: null, year: null },
      dataDiagnosticsForCharts: [],
      options: {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              displayFormats: {
                day: 'DD MMM YYYY',
              },
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  apollo: {
    diagnosticsForCharts: {
      query: FETCH_EHPAD_DIAGNOSTICS_FOR_CHARTS,
      variables() {
        return {
          ehpadId: this.currentEhpad.id,
          stateType: this.stateType,
          year: parseInt(this.rangeForDiagnosticChart.year, 10),
          months: parseInt(this.rangeForDiagnosticChart.months, 10),
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  watch: {
    diagnosticsForCharts: {
      handler() {
        if (this.diagnosticsForCharts !== undefined) {
          this.dataDiagnosticsForCharts = this.buildDataDiagnosticsForCharts;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    title() {
      if (this.stateType === 'state') { return 'Etats nutritionnels (Utilisateur)';}
      if (this.stateType === 'nri_state') { return 'EN (NRI)';}
      if (this.stateType === 'has2007_state') { return 'EN (HAS 2007)';}
      if (this.stateType === 'has2021_state') { return 'EN (HAS 2021)';}
    },
    chartRangeOptions() {
      return [
        { value: { months: null, year: null }, text: '3 mois glissants' },
        { value: { months: 6, year: null }, text: '6 mois glissants' },
        { value: { months: 12, year: null }, text: '12 mois glissants' },
        { value: { months: null, year: moment().year() - 2 }, text: moment().year() - 2 },
        { value: { months: null, year: moment().year() - 1 }, text: moment().year() - 1 },
        { value: { months: null, year: moment().year() }, text: moment().year() },
      ];
    },
    buildDataDiagnosticsForCharts() {
      const datesArray = Object.keys(this.diagnosticsForCharts);
      const fetchValuesForField = (field) => datesArray.map(date => [date, _.get(this.diagnosticsForCharts[date], field, 0)]);

      return [
        {
          name: 'Dénutrition sévère',
          data: fetchValuesForField('Dénutrition sévère').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#ff6347',
        },
        {
          name: 'Dénutrition modérée',
          data: fetchValuesForField('Dénutrition modérée').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#f08080',
        },
        {
          name: 'Non classé',
          data: fetchValuesForField('Non classé').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#A9A9A9',
        },
        {
          name: 'A risque élevé',
          data: fetchValuesForField('A risque élevé').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#FDB631',
        },
        {
          name: 'A risque faible',
          data: fetchValuesForField('A risque faible').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#32B796',
        },
        {
          name: 'Fin de vie',
          data: fetchValuesForField('Fin de vie').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#6f42c1',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
