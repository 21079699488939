<template>
  <div>
    <!-- Si le currentUser n'existe pas on affiche le sign in sur la home -->
    <app-sign-in v-if="!currentUser"></app-sign-in>

  </div>
</template>

<script>
import moment from 'moment';
import SignIn from './Signin.vue';

export default {
  components: {
    AppSignIn: SignIn,
  },
  computed: {
    currentUser() {
      return this.$store.getters.user;
    },
    currentDate() {
      return moment().format('DD-MM-YYYY');
    },
  },
  methods: {
    async redirect() {
      await this.$store.dispatch('autoLogin');
      // Si le user a un ehpad et accés complet on redirige vers les résidents de son ehpad
      if (this.$store.getters.user.ehpad && (this.$store.getters.user.role !== 'ingesta')) {
        this.$router.push({
          path: `/ehpads/${this.$store.getters.user.ehpad.shortname}/main`,
        });
      // Si le user a un ehpad et accés ingesta on redirige vers la table des rasidents Light (ingesta)
      } else if (this.$store.getters.user.ehpad && (this.$store.getters.user.role === 'ingesta')) {
        this.$router.push({ path: `/ehpads/${this.$store.getters.user.ehpad.shortname}/${this.currentDate}/ingesta` });
      } else {
        // Sinon vers l'index des ehpad
        this.$router.push({ path: '/ehpads' });
      }
    },
  },
  created() {
    this.redirect();
  },
};
</script>

<style lang="scss" scoped></style>
