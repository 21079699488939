<template>
    <b-modal v-bind="$attrs" title="Informations nutritionelles (dégouts...)" size="lg" class="col-12 text-center">
    <b-container fluid>

      <b-form>
          <b-row class="my-2">
            <b-col cols="12">
                <b-form-textarea
                  id="resident-status-description"
                  size="sm"
                  type="text"
                  rows="4"
                  v-model="editableResident.nutritionalInfo"
                />
            </b-col>
          </b-row>

        <div v-if="error" style="color: red;" class="text-center mt-2">
          <p v-for="(error, i) of error.graphQLErrors" :key="i">
            {{ error.message }}
          </p>
        </div>

        <b-button class="d-flex mx-auto" variant="primary" size="sm" @click.prevent="updateResident">
          Valider
        </b-button>
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>

export default {
  props: ['resident'],
  data() {
    return {
      error: null,
      editableResident: null,
    };
  },
  watch: {
    resident: {
      handler() {
        this.duplicateResident(this.resident);
      },
      immediate: true,
    },
  },
  created() {
    this.duplicateResident(this.resident);
  },
  methods: {
    duplicateResident(resident) {
      const roomId = resident && resident.room && resident.room.id;
      const sectorId = resident && resident.sector && resident.sector.id;
      this.editableResident = { ...resident, roomId, sectorId };
    },
    async updateResident() {
      await this.$store.dispatch('updateResident', {
        id: this.editableResident.id,
        name: this.editableResident.name,
        firstName: this.editableResident.firstName,
        gender: this.editableResident.gender,
        height: parseInt(this.editableResident.height, 10),
        idealWeight: parseFloat(this.editableResident.idealWeight),
        equipmentWeight: parseFloat(this.editableResident.equipmentWeight),
        nutritionalInfo: this.editableResident.nutritionalInfo,
        diet: this.editableResident.diet,
        requireHelp: this.editableResident.requireHelp,
        roomId: this.editableResident.roomId,
        sectorId: this.editableResident.sectorId,
      }).then((result) => {
        this.duplicateResident(result);
      }).catch((error) => {
        // For resetting form with current values :
        this.duplicateResident(this.resident);
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
