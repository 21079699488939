<template>
  <div class="card p-4">
    <div class="d-flex align-items-center mb-4">
      <h6 class="font-weight-bold">Actions Nutritionnelles</h6>
      <b-button class="mb-2 ml-4" variant="primary" size="sm" @click="addAction" v-if="!readonly">
        <i class="fas fa-plus"></i>
      </b-button>
    </div>
    <b-row v-for="(action, index) in actions" :key="`nutritional-${index}`" class="mb-2 align-items-center">
      <b-col cols="1">
        <label>{{ index + 1 }}.</label>
      </b-col>
      <b-col cols="8">
        <b-form-textarea
          :id="`nutritional-${index}`"
          size="sm"
          type="text"
          rows="2"
          v-model="action.description"
          :disabled="readonly"
          :class="{ 'is-invalid': $v.actions.$each[index].description.$error }"
          @blur="$v.actions.$each[index].description.$touch()"
        />
      </b-col>
      <b-col cols="2" class="d-flex align-items-center">
        <label class="text-muted small mb-0">
          Ajouté le : {{ dateFormatterShort(action.date || currentDate) }}
        </label>
      </b-col>
      <b-col cols="1" class="d-flex justify-content-start">
        <b-button variant="link" size="sm" @click="deleteAction(index)" v-if="!readonly">
          <i class="text-danger fa fa-trash-alt" v-b-tooltip.hover.v-danger title="Supprimer"></i>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { dateFormatterShort } from '../../../utilities/filters';

export default {
  mixins: [validationMixin],
  props: {
    actions: Array,
    currentDate: String,
    readonly: Boolean
  },
  validations: {
    actions: {
      $each: {
        description: { required }
      }
    }
  },
  methods: {
    dateFormatterShort,
    addAction() {
      this.$emit('add-action', 'nutritional');
    },
    deleteAction(index) {
      this.$emit('delete-action', index, 'nutritional');
    }
  }
};
</script>
