<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Paramétrage
        </h3>
      </span>
    </div>
    <hr />
    <div class="d-print-none">
      <li class="text-center" style="list-style-type:none;"><a href="#rooms-sectors">Chambres et Secteurs</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#ehpad-setup">Paramétrage de l'Ehpad</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#ehpad-variables-setup">Paramétrage et critéres nutritionnels</a></li>
      <li class="text-center" style="list-style-type:none;"><a href="#tests-setup">Paramétrage de l'expiration des tests</a></li>
      <hr />
    </div>

    <a id="rooms-sectors"></a>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h5 class="m-0" style="display: inline-block;margin: 0;">
          Chambres et Secteurs
        </h5>
      </span>
    </div>
    <b-row align-h="center">
      <b-col cols="12" md="5">
        <app-room-table />
      </b-col>
      <b-col cols="12" md="5" v-if="currentEhpad.allSettings && currentEhpad.allSettings.configuration.sectors.activate_sectors">
        <app-sector-table />
      </b-col>
    </b-row>
    <hr />

    <a id="ehpad-setup"></a>
    <b-row align-h="center">
      <b-col cols="12" md="5">
        <div class="d-flex justify-content-center align-items-center mb-3">
          <span class="text-center">
            <h5 class="m-0" style="display: inline-block;margin: 0;">
              Paramétrage de l'Ehpad
            </h5>
            <i
              class="fas fa-exclamation-triangle"
              style="color: red;"
              v-b-tooltip.hover.v-danger
              title="Modifier ces paramétres renverra à l'accueil du logiciel"
            />
          </span>
        </div>
        <b-form>
          <div>
            <label for="inline-form-input-name" class="pr-4">Nom </label>
            <b-input
              id="inline-form-input-name"
              size="sm"
              type="text"
              v-model="editableEhpad.name"
              :class="{ 'is-invalid': $v.editableEhpad.name.$error }"
            />
            <b-form-invalid-feedback>Valeur requise (50 caractères max)</b-form-invalid-feedback>
          </div>

          <div class="pt-2">
            <label for="inline-form-input-shortname" class="pr-4">Nom court </label>
            <b-input
              id="inline-form-input-shortname"
              size="sm"
              type="text"
              v-model="editableEhpad.shortname"
              :class="{ 'is-invalid': $v.editableEhpad.shortname.$error }"
            />
            <b-form-invalid-feedback>Valeur requise (3 caractères)</b-form-invalid-feedback>
          </div>

          <div class="pt-2">
            <label for="inline-form-input-email" class="pr-4">Email</label>
            <b-input
              id="inline-form-input-email"
              size="sm"
              type="text"
              v-model="editableEhpad.email"
              :class="{ 'is-invalid': $v.editableEhpad.email.$error }"
            />
            <b-form-invalid-feedback>Entrez un email valide</b-form-invalid-feedback>
          </div>

          <div class="pt-2">
            <label class="pr-4">Couleur</label>
            <app-verte
              v-model="editableEhpad.color"
              :showHistory="null"
              model="hex"
              display="widget"
            ></app-verte>
          </div>
          <b-button
            class="mt-3 d-flex mx-auto"
            variant="primary"
            size="sm"
            @click.prevent="updateEhpad"
            >Valider</b-button
          >
        </b-form>
      </b-col>
    </b-row>
    <hr />

    <a id="ehpad-variables-setup"></a>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h5 class="m-0" style="display: inline-block;margin: 0;">
          Paramétrage et critéres nutritionnels
        </h5>
      </span>
    </div>
    <b-row align-h="center">
      <app-ehpad-root-settings
        v-for="(rootValues, rootState) in currentEhpad.allSettings"
        :rootValues="rootValues"
        :rootState="rootState"
        :key="rootState"
      />
    </b-row>
    <hr />

    <a id="tests-setup"></a>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h5 class="m-0" style="display: inline-block;margin: 0;">
          Paramétrage de l'expiration des tests
        </h5>
        <i
          class="fas fa-exclamation-triangle mt-1 ml-2"
          style="color: #5bc0de;"
          v-b-tooltip.hover.v-primary
          title="Expiration (en jours)
          Si la date d'enregistrement de la derniére valeur dépasse le nombre de jours,
          un message d'alerte s'affiche."
        />
      </span>
    </div>
    <b-row align-h="center">
    <div v-for="diagnosticState in diagnosticStates"
        :key="diagnosticState.value">
      <app-test-types-root-settings
        :diagnosticState="diagnosticState.value"
      />
    </div>
    </b-row>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import verte from "verte";
import "verte/dist/verte.css";
import {
  required, maxLength, alphaNum, email, minLength,
} from "vuelidate/lib/validators";
import RoomTable from "../../components/rooms/Table.vue";
import SectorTable from "../../components/sectors/Table.vue";
import EhpadRootSettings from "../../components/ehpad/RootSettings.vue";
import TestTypesRootSettings from "../../components/test_types/RootSettings.vue";

export default {
  name: "EhpadSetup",
  components: {
    appVerte: verte,
    appEhpadRootSettings: EhpadRootSettings,
    appTestTypesRootSettings: TestTypesRootSettings,
    appRoomTable: RoomTable,
    appSectorTable: SectorTable,
  },
  mixins: [validationMixin],
  watch: {
    currentEhpad: {
      handler() {
        if (this.currentEhpad !== null) {
          this.duplicateEhpad(this.currentEhpad);
        }
      },
      immediate: true,
    },
  },
  validations: {
    editableEhpad: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      shortname: {
        required,
        maxLength: maxLength(3),
        minLength: minLength(3),
        alphaNum,
      },
      color: {
        required,
      },
      email: {
        email,
      },
    },
  },
  data() {
    return {
      updating: false,
      editableEhpad: null,
    };
  },
  methods: {
    duplicateEhpad(ehpad) {
      this.editableEhpad = { ...ehpad };
    },
    async updateEhpad() {
      this.$v.editableEhpad.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch("updateEhpad", {
        id: this.editableEhpad.id,
        name: this.editableEhpad.name,
        shortname: this.editableEhpad.shortname,
        color: this.editableEhpad.color,
        email: this.editableEhpad.email,
      }).then((result) => {
        this.$v.$reset();
        this.$router.push({ path: "/" });
      }).catch((error) => {
        this.flashMessage.show({
          status: "error",
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: "ehpad",
    }),
    diagnosticStates() {
      return [
        { value: "severe_undernutrition", text: "Dénutrition sévère" },
        { value: "moderate_undernutrition", text: "Dénutrition modéré" },
        { value: "high_risk", text: "A risque élevé" },
        { value: "default_undernutrition", text: "Autres états" },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

:target {
  display: block;
  position: relative;
  top: -11.5rem;
  visibility: hidden;
}

</style>
