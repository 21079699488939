<template v-on:scroll.passive="this.handleScroll">
  <b-container fluid>
    <div v-if="resident !== null">
        <app-resident-ingesta-nav-bar
          :date="date"
        />

      <div class="text-center" v-if="loading">
        <h4>
          <i class="fa fa-spin fa-circle-notch" />
          Chargement...
        </h4>
      </div>


      <div class="text-center" v-else-if="findResidentMenu === null">
        <h5>
          Aucun menu pour ce résident à cette date
        </h5>
        <p> Si il s'agit d'un menu à venir, assurez vous que le menu global soit rempli</p>
      </div>

      <b-container id="container_ingesta-day" fluid v-else>
        <b-row class="justify-content-center">
          <app-ingesta-card
            :residentMenu="findResidentMenu"
            :menuEditState="menuEditState()"
            :ingestaPrefilled="ingestaPrefilled"
            :date="date"
            :residentId="residentId"
          />
        </b-row>
      </b-container>

      <div id="stickyBtn">
          <b-button
          variant="primary"
          :to="{ path: `/ehpads/${currentEhpad.shortname}/${currentDate}/ingesta` }"
          class="fixedElementLeft d-print-none"
          >
          Résidents
          </b-button>
          <div
            class="mt-5 fixedElementLeft text-center"
          >
            <b-button
              v-if="checkFirstItem"
              variant="primary"
              :to="{ path: formatButtonLink('prev') }"
              size="sm"
              class="d-print-none w-25"
            >
              <i class="fas fa-arrow-left"></i>
            </b-button>

            <b-button
              v-if="checkLastItem"
              variant="primary"
              :to="{ path: formatButtonLink('next') }"
              size="sm"
              class="d-print-none w-25"
            >
              <i class="fas fa-arrow-right"></i>
            </b-button>
          </div>
      </div>

      <div id="stickyName" class="hide">
        <span v-if="resident !== null" class="fixedElementRight d-print-none">
          <h5 v-html="title" class="m-0"></h5>
          <span style="font-size: medium;">Menu du {{ capitalizeString(dateFormatterFull(findResidentMenu && findResidentMenu.date)) }}</span>
          <app-sidebar-info
            :resident="resident"
          />
        </span>
      </div>

      <div id="stickyBtn">
        <div class="fixedElementBotLeft d-print-none">
          <span style="font-size: small;"><b>Pré-remplissage Ingesta</b>
            <i
              class="fas fa-exclamation-triangle ml-1"
              style="color: #5bc0de;"
              v-b-tooltip.hover.v-primary
              title="Activer le pré-remplissage des ingesta pour le service des résidents"
            />
          </span>
          <div class="mt-1" >
            <toggle-button
              v-model="ingestaPrefilled"
              :labels="{checked: 'Activé', unchecked: 'Désactivé'}"
              :width=100
              :height=30
              color="#B81F1F"
            />
          </div>
        </div>
      </div>

      <app-survey-modal
        v-if="currentSurveys.length > 0"
        id="survey-modal"
        :survey="surveyForModal"
        :rmdId="rmdIdForSurvey"
        hide-header
        centered
      />
    </div>
    <div class="text-center" style="color: #469AE0;" v-else-if="resident === null">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement...
      </h2>
    </div>
  </b-container>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { capitalizeString, dateFormatterFull, coloredDiagHtml } from '../../utilities/filters';
import EventBus from '../../utilities/event_bus';
import { FETCH_RESIDENT_MENU } from '../../graphql/resident_menus/queries';
import IngestaCard from '../../components/ingesta/Card.vue';
import SurveyModal from '../../components/surveys/SurveyModal.vue';
import SidebarInfo from '../../components/residents/SidebarInfo.vue';
import ResidentIngestaNavBar from '../../components/residents/ResidentIngestaNavBar.vue';

export default {
  props: {
    date: {
      type: String,
      required: true,
    },
    residentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rmdIdForSurvey: null,
      surveyForModal: null,
      loading: true,
      ingestaPrefilled: false,
    };
  },
  components: {
    appIngestaCard: IngestaCard,
    appSurveyModal: SurveyModal,
    appSidebarInfo: SidebarInfo,
    appResidentIngestaNavBar: ResidentIngestaNavBar,
  },
  apollo: {
    findResidentMenu: {
      query: FETCH_RESIDENT_MENU,
      variables() {
        return {
          residentId: this.residentId,
          date: this.date,
        };
      },
      fetchPolicy: 'network-only',
      result() {
        this.loading = false;
      },
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    EventBus.$on('trigger-survey', (rmdId, currentSurvey) => {
      this.rmdIdForSurvey = rmdId;
      this.surveyForModal = currentSurvey;
      this.$bvModal.show('survey-modal');
    });
  },
  watch: {
    residentId: {
      handler() {
        this.findResident({
          id: this.residentId,
        });
      },
    },
    date: {
      handler() {
        this.findSurveysOnDate({
          ehpadId: this.currentEhpad.id,
          date: this.date,
        });
      },
      immediate: true,
    },
  },
  mounted() {
    this.findResident({
      id: this.residentId,
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions(['findResident', 'findSurveysOnDate']),
    capitalizeString,
    dateFormatterFull,
    coloredDiagHtml,
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    menuEditState() {
      if (this.currentEhpad.allSettings.configuration.menus_config.can_edit_in_past) return true;
      if (this.momentDate() > this.momentDate().subtract(2, 'week')) return true;
      return false;
    },
    momentDate() {
      return moment(this.date, 'DD-MM-YYYY');
    },
    handleScroll() {
      const stickyBtn = document.getElementById('stickyBtn');
      const stickyName = document.getElementById('stickyName');
      const y = window.pageYOffset;
      if (y <= 100) {
        stickyBtn.className = 'hide';
        stickyName.className = 'hide';
      } else {
        stickyBtn.className = '';
        stickyName.className = '';
      }
    },
    nextResidentId() {
      if (localStorage.nutriviewResidentsIngestaSorted === undefined) { return null; }
      const residentsIngestaSorted = JSON.parse(localStorage.nutriviewResidentsIngestaSorted);
      const index = _.findIndex(residentsIngestaSorted, { id: this.resident.id });
      if (residentsIngestaSorted[index + 1] === undefined) { return null; }
      return residentsIngestaSorted[index + 1].id;
    },
    prevResidentId() {
      if (localStorage.nutriviewResidentsIngestaSorted === undefined) { return null; }
      const residentsIngestaSorted = JSON.parse(localStorage.nutriviewResidentsIngestaSorted);
      const index = _.findIndex(residentsIngestaSorted, { id: this.resident.id });
      if (residentsIngestaSorted[index - 1] === undefined) { return null; }
      return residentsIngestaSorted[index - 1].id;
    },
    formatButtonLink(type) {
      let id;
      if (type === 'next') {
        id = this.nextResidentId();
      } else {
        id = this.prevResidentId();
      }
      return `/ehpads/${this.currentEhpad.shortname}/resident/${id}/ingesta/${this.date}`;
    },
  },
  computed: {
    ...mapGetters({
      resident: 'resident',
      currentEhpad: 'ehpad',
      currentUser: 'user',
      currentSurveys: 'currentSurveys',
    }),
    title() {
      return `${this.resident.fullName} (${this.coloredDiagHtml(
        this.resident.lastDiagnostic.humanState,
      )})`;
    },
    checkFirstItem() {
      if ((this.prevResidentId() === null) || (this.resident.room === null)) { return false; }
      return true;
    },
    checkLastItem() {
      if ((this.nextResidentId() === null) || (this.resident.room === null)) { return false; }
      return true;
    },
    formattedDay() {
      return `${this.momentDate()
        .locale('fr')
        .format('dddd DD MMMM YYYY')}`;
    },
    currentDate() {
      return moment().format('DD-MM-YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.fixedElementLeft {
  position: fixed;
  top: 1rem;
  width: 15%;
  z-index: 100;
}
.fixedElementRight {
  position: fixed;
  top: 1rem;
  right: 2rem;
  z-index: 100;
  background-color: white;
}
.fixedElementBotLeft {
  position: fixed;
  bottom: 1rem;
  left: 2rem;
  background-color: white;
}
.hide {
    display: none;
}
</style>
