<template>
  <div>
    <a :href="`#${type}`" :class="`${type}`"></a>
    <p class="font-weight-bold text-center m-0" style="font-size: xx-large;" id="title">
      {{ residentMeal.mealCategory.humanType }}
    </p>

    <app-ingesta-dishes
      :residentMealDishes="residentMeal.residentMealDishes"
      v-if="residentMeal.residentMealDishes"
      :menuEditState="menuEditState"
      :mealCategory="residentMeal.mealCategory"
      :ingestaPrefilled="ingestaPrefilled"
    />
    <p class="text-center" v-else><i class="fa fa-circle-notch fa-spin" /> Chargement...</p>
  </div>
</template>

<script>
import moment from 'moment';
import IngestaDishes from './Dishes.vue';

export default {
  name: 'IngestaMeal',
  props: ['type', 'residentMeal', 'menuEditState', 'ingestaPrefilled'],
  components: {
    appIngestaDishes: IngestaDishes,
  },
  methods: {
    scrollToElement() {
      let el = null;
      if (moment().hour() >= 11 && moment().hour() < 18) {
        [el] = this.$el.getElementsByClassName('lunch');
      }

      if (moment().hour() >= 18) {
        [el] = this.$el.getElementsByClassName('dinner');
      }

      if (el) {
        el.scrollIntoView();
      }
    },
  },
  mounted() {
    this.scrollToElement();
  },
};
</script>

<style lang="scss" scoped></style>
