import gql from 'graphql-tag';

export const CREATE_SURVEY = gql`
  mutation CREATE_SURVEY(
              $name: String!, 
              $ehpadId: ID!,
              $startDate: String!,
              $endDate: String!,
              $ingestaMin: Float!,
              $ingestaMax: Float!,
              $mealCategories: [MealCategoryAttributes!]!,
              $questions: [QuestionAttributes!]!,
              $answers: [AnswerAttributes!]!
            ) {
    createSurvey(
      input: {
        name: $name,
        ehpadId: $ehpadId,
        startDate: $startDate,
        endDate: $endDate,
        ingestaMin: $ingestaMin,
        ingestaMax: $ingestaMax,
        mealCategories: $mealCategories,
        questions: $questions,
        answers: $answers
      }
    ) {
      survey {
        id
        mealCategories {
          name
          humanType
        }
        questions {
          id
          description
        }
        answers {
          id
          description
        }
        name
        startDate
        endDate
        ingestaMax
        ingestaMin
      }
    }
  }
`;

export const UPDATE_SURVEY = gql`
  mutation UPDATE_SURVEY(
              $id: ID!,
              $name: String!, 
              $startDate: String!,
              $endDate: String!,
              $ingestaMin: Float!,
              $ingestaMax: Float!,
              $mealCategories: [MealCategoryAttributes!]!,
              $questions: [QuestionAttributes!]!,
              $answers: [AnswerAttributes!]!
            ) {
    updateSurvey(
      input: {
        id: $id,
        name: $name,
        startDate: $startDate,
        endDate: $endDate,
        ingestaMin: $ingestaMin,
        ingestaMax: $ingestaMax,
        mealCategories: $mealCategories,
        questions: $questions,
        answers: $answers
      }
    ) {
      survey {
        id
        mealCategories {
          name
          humanType
        }
        questions {
          id
          description
        }
        answers {
          id
          description
        }
        name
        startDate
        endDate
        ingestaMax
        ingestaMin
      }
    }
  }
`;

export const CREATE_RESIDENT_MEAL_DISH_ANSWER = gql`
  mutation CREATE_RESIDENT_MEAL_DISH_ANSWER(
              $rmdId: ID!,
              $answerId: ID!
            ) {
    createResidentMealDishAnswer(
      input: {
        rmdId: $rmdId,
        answerId: $answerId
      }
    ) {
      residentMealDishAnswer {
        id
        answer {
            id
            description
        }
      }
    }
  }
`;
