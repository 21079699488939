<template>
  <b-modal v-bind="$attrs" :title="modalTitle" class="col-12 text-center">
    <b-container fluid>
      <b-form>
        <b-row>
            <b-col cols="2">
              <label for="inline-form-input-name" class="pr-4 text-nowrap">Nom : </label>
            </b-col>
            <b-col cols="10">
                <b-form-input
                  id="inline-form-input-name"
                  size="sm"
                  type="text"
                  v-model="editableSupplementation.name"
                  :class="{ 'is-invalid': $v.editableSupplementation.name.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise (60 caractères max)</b-form-invalid-feedback>
            </b-col>
            <span style="font-size: smaller;" class="text-center m-2"><i class="fas fa-exclamation-triangle" style="color: red;"/>
              <i>Attention, renommer une supplémentation la modifiera également dans les menus existants</i>
            <i class="fas fa-exclamation-triangle" style="color: red;"/></span>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-cal" class="pr-4 text-nowrap">Calories (kcal) : </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  id="inline-form-input-cal"
                  size="sm"
                  type="number"
                  min="0"
                  v-model="editableSupplementation.calories"
                  :class="{ 'is-invalid': $v.editableSupplementation.calories.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-prot" class="pr-4 text-nowrap">Protéines (g) : </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  size="sm"
                  type="number"
                  min="0"
                  v-model="editableSupplementation.proteins"
                  :class="{ 'is-invalid': $v.editableSupplementation.proteins.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-prot" class="pr-4 text-nowrap">Apports hydriques (ml) : </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  size="sm"
                  type="number"
                  min="0"
                  v-model="editableSupplementation.hydration"
                  :class="{ 'is-invalid': $v.editableSupplementation.hydration.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2" v-if="currentUser.role == 'administrateur' || currentUser.role == 'intendance'">
            <b-col cols="6">
              <label for="inline-form-input-price" class="pr-4 text-nowrap">Prix par portion : </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  id="inline-form-input-price"
                  size="sm"
                  type="number"
                  min="0"
                  v-model="editableSupplementation.price"
                />
            </b-col>
          </b-row>

        <div v-if="error" style="color: red;" class="text-center mt-2">
          <p v-for="(error, i) of error.graphQLErrors" :key="i">
            {{ error.message }}
          </p>
        </div>

        <b-button
          class="mt-3 d-flex mx-auto"
          variant="primary"
          size="sm"
          @click.prevent="updateSupplementation"
          >Valider</b-button
        >
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  props: ['supplementation'],
  mixins: [validationMixin],
  data() {
    return {
      editableSupplementation: null,
      error: null,
    };
  },
  created() {
    this.duplicateSupplementation(this.supplementation);
  },
  validations: {
    editableSupplementation: {
      calories: {
        required,
      },
      proteins: {
        required,
      },
      hydration: {
        required,
      },
      name: {
        required,
        maxLength: maxLength(60),
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'user',
    }),
    modalTitle() {
      return 'Modifier une supplémentation';
    },
  },
  methods: {
    duplicateSupplementation(supplementation) {
      this.editableSupplementation = { ...supplementation };
    },
    async updateSupplementation() {
      this.$v.editableSupplementation.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('updateSupplementation', {
        id: this.editableSupplementation.id,
        name: this.editableSupplementation.name,
        calories: parseInt(this.editableSupplementation.calories, 10),
        proteins: parseInt(this.editableSupplementation.proteins, 10),
        hydration: parseInt(this.editableSupplementation.hydration, 10),
        price: parseFloat(this.editableSupplementation.price),
      }).then((result) => {
        this.error = null;
        this.$v.$reset();
        this.$bvModal.hide('info-supplementation-modal');
      }).catch((error) => {
        // Erreur dans la modale :
        this.error = error;
        // Erreur en Flash
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
