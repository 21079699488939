<template>
  <b-modal v-bind="$attrs" class="col-12 text-center">
    <template #modal-title>
      Modifier un plat
      <b-button
        variant="primary"
        size="sm"
        style="font-size: xx-small;"
        class="ml-2"
        @click="advancedOptions = !advancedOptions">
        Options avancées
      </b-button>
    </template>
    <b-container fluid>
      <b-form>
          <b-row>
            <b-col cols="2">
              <label for="inline-form-input-name" class="pr-1 text-nowrap">Nom :</label>
            </b-col>
            <b-col cols="10">
                <b-form-input
                  id="inline-form-input-name"
                  size="sm"
                  type="text"
                  v-model="editableDish.name"
                  :class="{ 'is-invalid': $v.editableDish.name.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise (60 caractères max)</b-form-invalid-feedback>
            </b-col>
            <span style="font-size: smaller;" class="text-center m-2"><i class="fas fa-exclamation-triangle" style="color: red;"/>
              <i>Attention, renommer un plat le modifiera dans tout les menus existants</i>
            <i class="fas fa-exclamation-triangle" style="color: red;"/></span>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-cal" class="pr-4 text-nowrap">Calories (kcal) : </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  id="inline-form-input-cal"
                  size="sm"
                  type="number"
                  min="0"
                  v-model="editableDish.defaultCalories"
                />
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-prot" class="pr-4 text-nowrap">Protéines (g) : </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  id="inline-form-input-prot"
                  size="sm"
                  type="number"
                  min="0"
                  v-model="editableDish.defaultProteins"
                />
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-hydr" class="pr-4 text-nowrap">Apports hydriques (ml) : </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  id="inline-form-input-hydr"
                  size="sm"
                  type="number"
                  min="0"
                  v-model="editableDish.defaultHydration"
                />
            </b-col>
          </b-row>

          <b-row class="pt-2" v-if="currentUser.role == 'administrateur' || currentUser.role == 'intendance'">
            <b-col cols="6">
              <label for="inline-form-input-price" class="pr-4 text-nowrap">Prix par portion : </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  id="inline-form-input-price"
                  size="sm"
                  type="number"
                  min="0"
                  v-model="editableDish.price"
                />
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-option" class="pr-4 text-nowrap">Catégorie : </label>
            </b-col>
            <b-col cols="6">
              <b-form-select
                id="inline-form-input-option"
                v-model="editableDish.dType"
                :options="dTypeOptions"
                :class="{ 'is-invalid': $v.editableDish.dType.$anyError }"
              />
              <b-form-invalid-feedback>Type de plat requis</b-form-invalid-feedback>
            </b-col>
          </b-row>

        <b-form-group class="mt-2" label="Disponible dans les listes de plats :">
          <b-form-radio v-model="editableDish.active" name="Actif" value=true>Actif</b-form-radio>
          <b-form-radio v-model="editableDish.active" name="Désactivé" value=false>Désactivé</b-form-radio>
        </b-form-group>

        <div v-if="advancedOptions">
          <label for="changePastForm">Appliquer la modification aux menus passés ?
          <i class="fas fa-exclamation-triangle"
            style="color: red;"
            v-b-tooltip.hover.v-primary
            title="Si vous choisissez 'Oui' ce plat sera modifié dans tout les menus existants (y compris dans les menus passés).
            Si vous choisissez 'Non' les modifications s'appliqueront uniquement à partir de ce jour dans les menus futurs."
          />
          </label>
          <b-form-group class="mt-2" id="changePastForm">
            <b-form-radio v-model="changePast" name="Oui" value=true>Oui</b-form-radio>
            <b-form-radio v-model="changePast" name="Non" value=false>Non</b-form-radio>
          </b-form-group>
        </div>

        <div v-if="error" style="color: red;" class="text-center mt-2">
          <p v-for="(error, i) of error.graphQLErrors" :key="i">
            {{ error.message }}
          </p>
        </div>

        <b-button class="d-flex mx-auto" variant="primary" size="sm" @click.prevent="updateDish">
          Valider
        </b-button>
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  props: ['dish'],
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      changePast: false,
      editableDish: null,
      advancedOptions: false,
    };
  },
  created() {
    this.duplicateDish(this.dish);
  },
  validations: {
    editableDish: {
      active: {
        required,
      },
      name: {
        required,
        maxLength: maxLength(60),
      },
      dType: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'user',
    }),
    dTypeOptions() {
      return [
        { value: null, text: 'Type de plat', disabled: true },
        { value: 'other', text: 'Autre' },
        { value: 'starter', text: 'Entrée' },
        { value: 'main_course', text: 'Plat principal' },
        { value: 'dairy', text: 'Fromage' },
        { value: 'desert', text: 'Dessert' },
      ];
    },
  },
  methods: {
    duplicateDish(dish) {
      this.editableDish = { ...dish };
    },
    async updateDish() {
      this.$v.editableDish.$touch();
      if (this.$v.$anyError) return;
      const activeBoolean = (this.editableDish.active === 'true' || this.editableDish.active === true);
      const changePastBoolean = (this.changePast === 'true' || this.changePast === true);
      await this.$store.dispatch('updateDish', {
        id: this.editableDish.id,
        name: this.editableDish.name,
        dType: this.editableDish.dType,
        defaultCalories: parseInt(this.editableDish.defaultCalories, 10),
        defaultProteins: parseInt(this.editableDish.defaultProteins, 10),
        defaultHydration: parseInt(this.editableDish.defaultHydration, 10),
        price: parseFloat(this.editableDish.price),
        active: activeBoolean,
        changePast: changePastBoolean,
      }).then((result) => {
        this.error = null;
        this.$v.$reset();
        this.$bvModal.hide('info-dish-modal');
      }).catch((error) => {
        this.error = error;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
