<template>
  <b-modal v-bind="$attrs" class="col-12 text-center">
    <b-container fluid v-if="rmdId !== null">
      <h3 class="text-center">{{ survey.questions[0].description }}</h3>
      <hr>

      <div v-for="answer in survey.answers" :key="answer.id">
        <div class="answer my-3" :class="{ selected: answer.id === activeQuestion }" :id="answer.id" @click="createRmdAnswer(answer.id)">
          <h5 class="my-3">{{ answer.description }}</h5>
        </div>
      </div>

    </b-container>
      <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="primary" @click="cancel()">
        Fermer
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { FETCH_RESIDENT_MEAL_DISH_ANSWER } from '../../graphql/surveys/queries';
import { CREATE_RESIDENT_MEAL_DISH_ANSWER } from '../../graphql/surveys/mutations';

export default {
  props: ['survey', 'rmdId'],
  data() {
    return {
      error: null,
      existingRmdA: null,
      activeQuestion: null,
    };
  },
  watch: {
    rmdId: {
      handler() {
        if (this.rmdId !== null) {
          this.findResidentMealDishAnswer(this.rmdId);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (this.existingRmdA !== null) {
        this.activeQuestion = this.existingRmdA.answer.id;
      }
    });
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.activeQuestion = null;
    });
  },
  methods: {
    async findResidentMealDishAnswer(rmdId) {
      await this.$apollo.query({
        query: FETCH_RESIDENT_MEAL_DISH_ANSWER,
        variables: { rmdId },
        fetchPolicy: 'network-only',
      }).then((result) => {
        this.existingRmdA = result.data.findResidentMealDishAnswer;
      });
    },
    async createRmdAnswer(answerId) {
      this.activeQuestion = answerId;
      await this.$apollo.mutate({
        mutation: CREATE_RESIDENT_MEAL_DISH_ANSWER,
        variables: {
          rmdId: this.rmdId,
          answerId,
        },
      }).then((result) => {
        this.existingRmdA = result.data.createResidentMealDishAnswer.residentMealDishAnswer;
        setTimeout(() => { this.$bvModal.hide('survey-modal'); }, 500);
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.answer {
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc!important;
  border-radius: 16px;
}

.answer:hover {
  background: rgb(138, 210, 149);
}

.selected {
  background: rgb(138, 210, 149);
}

</style>
