<template>
  <b-form>
      <b-row class="my-2">
        <b-col cols="3">
          <label for="test-type" class="text-nowrap">Test : </label>
        </b-col>
        <b-col cols="9">
          <b-form-select
            id="test-type"
            v-model="testType"
            :options="testTypesOptions"
            :class="{'is-invalid': $v.testType.$error}"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>Selectionner un test</b-form-select-option>
            </template>
          </b-form-select>

          <b-form-invalid-feedback>Test Requis</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="my-2" v-if="testType !== null">
        <b-col cols="12">
          <p class="m-3 text-center"><b>{{ testType.description }}</b></p>
        </b-col>
      </b-row>

      <b-row class="my-2">
        <b-col cols="3">
          <label for="test-date" class="text-nowrap">Date : </label>
        </b-col>
        <b-col cols="9">
          <b-form-datepicker
            id="test-date"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            label-help
            label-no-date-selected="Aucune date sélectionnée"
            size="sm"
            :max="today"
            v-model="residentTest.date"
            locale="fr"
            :class="{'is-invalid': $v.residentTest.date.$error}"
          />
          <b-form-invalid-feedback>Date Requise</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="my-2">
        <b-col cols="3">
          <label for="test-value" class="text-nowrap">Résultat du test : </label>
        </b-col>
        <b-col cols="9" v-if="testType && testType.isBoolean">
          <b-form-radio-group
            size="sm"
            v-model="residentTest.value"
          >
            <b-form-radio
              name="Activé"
              value=0
              >OUI</b-form-radio
            >
            <b-form-radio
              name="Désactivé"
              value=1
              >NON</b-form-radio
            >
          </b-form-radio-group>
          <b-form-invalid-feedback>Valeur Requise</b-form-invalid-feedback>
        </b-col>
        <b-col cols="9" v-else>
          <b-form-input
            id="test-value"
            size="sm"
            type="number"
            v-model="residentTest.value"
            :class="{'is-invalid': $v.residentTest.value.$error}"
          />
          <b-form-invalid-feedback>Valeur Requise</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="my-2">
        <b-col cols="3">
          <label for="resident-test-comment" class="text-nowrap">Commentaire : </label>
        </b-col>
        <b-col cols="9">
            <b-form-textarea
              id="resident-test-comment"
              size="sm"
              type="text"
              rows="4"
              v-model="residentTest.comment"
            />
        </b-col>
      </b-row>

    <div v-if="error" style="color: red;" class="text-center mt-2">
      <p v-for="(error, i) of error.graphQLErrors" :key="i">
        {{ error.message }}
      </p>
    </div>

    <b-button class="d-flex mx-auto" variant="primary" size="sm" @click.prevent="createResidentTest"
      >Valider</b-button
    >
  </b-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, decimal } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
  mixins: [validationMixin],
  props: ['resident'],
  data() {
    return {
      error: null,
      testType: null,
      residentTest: {
        value: null,
        date: null,
        comment: null,
      },
    };
  },
  validations: {
    testType: {
      id: {
        required,
      },
      name: {
        required,
      },
      description: {
        required,
      },
    },
    residentTest: {
      date: {
        required,
      },
      value: {
        required,
        decimal,
      },
    },
  },
  created() {
    this.residentTest.date = this.today;
  },
  methods: {
    async createResidentTest() {
      this.$v.testType.$touch();
      this.$v.residentTest.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('createResidentTest', {
        residentId: this.resident.id,
        testTypeId: this.testType.id,
        date: this.residentTest.date,
        value: parseFloat(this.residentTest.value),
        comment: this.residentTest.comment,
      }).then((result) => {
        this.resetData();
        this.$v.$reset();
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
    resetData() {
      // For resetting data object
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      testTypes: 'testTypes',
    }),
    today() {
      const now = new Date();
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return moment(date).format('YYYY-MM-DD');
    },
    testTypesOptions() {
      // const filteredTypes = this.testTypes.filter((ttype) => ttype.isBoolean !== true);
      return this.testTypes.map((el) => ({
        text: el.name,
        value: {
          id: el.id,
          name: el.name,
          description: el.description,
          url: el.url,
          isBoolean: el.isBoolean,
        },
      }));
    },
  },
};
</script>

<style lang="scss" scoped></style>
