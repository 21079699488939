<template>
  <b-modal v-bind="$attrs" :title="modalTitle" class="col-12 text-center">
    <b-container fluid>
      <b-form>
        <div class="form-inline">
          <label for="inline-form-input-name" class="pr-4">Nom du secteur</label>
          <b-input
            id="inline-form-input-name"
            size="sm"
            type="text"
            v-model="editableSector.name"
            :class="{ 'is-invalid': $v.editableSector.name.$error }"
          />
          <b-form-invalid-feedback>Nom requis (30 caractères max)</b-form-invalid-feedback>
        </div>

        <div v-if="error" style="color: red;" class="text-center mt-2">
          <p v-for="(error, i) of error.graphQLErrors" :key="i">
            {{ error.message }}
          </p>
        </div>

        <b-button
          class="mt-3 d-flex mx-auto"
          variant="primary"
          size="sm"
          @click.prevent="updateSector"
          >Valider</b-button
        >
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  props: ['sector'],
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      editableSector: null,
    };
  },
  created() {
    this.duplicateSector(this.sector);
  },
  validations: {
    editableSector: {
      name: {
        required,
        maxLength: maxLength(30),
      },
    },
  },
  computed: {
    modalTitle() {
      return 'Modifier un secteur';
    },
  },
  methods: {
    duplicateSector(sector) {
      this.editableSector = { ...sector };
    },
    async updateSector() {
      this.$v.editableSector.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('updateSector', {
        id: this.editableSector.id,
        name: this.editableSector.name,
      }).then((result) => {
        this.error = null;
        this.$v.$reset();
        this.$bvModal.hide('info-sector-modal');
      }).catch((error) => {
        // Erreur dans la modale :
        this.error = error;
        // Erreur en Flash
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
