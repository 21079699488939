import _ from 'lodash';

import apiClient from '../../utilities/api_client';
import { FETCH_DISHES } from '../../graphql/dishes/queries';
import { UPDATE_DISH, ARCHIVE_DISH, CREATE_DISH } from '../../graphql/dishes/mutations';

const dishState = {
  dishes: [],
};

const mutations = {
  SET_DISHES(state, dishes) {
    // SORTED in Mutation BREAKS reactivity, DONT :
    // const orderedDishes = _.sortBy(dishes, (dish) => dish.name);
    state.dishes = dishes;
  },
  UPDATE_DISH(state, dish) {
    const index = state.dishes.findIndex((e) => e.id === dish.id);
    state.dishes.splice(index, 1, dish);
  },
  ARCHIVE_DISH(state, dish) {
    const index = state.dishes.findIndex((e) => e.id === dish.id);
    state.dishes.splice(index, 1);
  },
  ADD_DISH(state, dish) {
    state.dishes.push(dish);
  },
};

const actions = {
  async setDishes({ commit }, ehpadId) {
    const { data } = await apiClient.query({
      query: FETCH_DISHES,
      variables: { ehpadId },
      // network-only for fetching queries BECAUSE apollo use its own cache and we use vuex : conflict
      fetchPolicy: 'network-only',
    });
    commit('SET_DISHES', data.dishes);
  },
  async updateDish({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_DISH,
      variables: { ...formData },
    });
    const { dish } = data.updateDish;
    commit('UPDATE_DISH', dish);
  },
  async archiveDish({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: ARCHIVE_DISH,
      variables: { ...formData },
    });
    const { dish } = data.archiveDish;
    commit('ARCHIVE_DISH', dish);
  },
  async createDish({ commit }, formData) {
    const { data } = await apiClient.mutate({
      mutation: CREATE_DISH,
      variables: { ...formData },
    });
    const { dish } = data.createDish;
    commit('ADD_DISH', dish);
  },
};

const getters = {
  // dishesByType(state) {
  //   const sortedDishes = [];
  //   ['Autre', 'Entrée', 'Plat principal', 'Fromage', 'Dessert'].flatMap((type) => {
  //     const sortedByName = _.sortBy(getters.dishes(state), (el) => el.name.toLowerCase());
  //     const filtered = sortedByName.filter(
  //       (el) => el.humanType === type,
  //     );
  //     sortedDishes.push(filtered);
  //   });
  //   return _.flatten(sortedDishes);
  // },
  activeDishesByType(state) {
    const sortedDishes = [];
    ['Autre', 'Entrée', 'Plat principal', 'Fromage', 'Dessert'].flatMap((type) => {
      const sortedByName = _.sortBy(getters.activeDishes(state), (el) => el.name.toLowerCase());
      const filtered = sortedByName.filter(
        (el) => el.humanType === type,
      );
      sortedDishes.push(filtered);
    });
    return _.flatten(sortedDishes);
  },
  activeDishesByName(state) {
    return _.sortBy(getters.activeDishes(state), (el) => el.name.toLowerCase());
  },
  inactiveDishesByName(state) {
    return _.sortBy(getters.inactiveDishes(state), (el) => el.name.toLowerCase());
  },
  activeDishesAndBuffetsByType(state, getters, rootState, rootGetters) {
    const sortedBuffetsAndDishes = [];
    const dishesAndBuffets = [...getters.activeDishesByName, ...rootGetters.activeBuffetsByName];
    ['Autre', 'Entrée', 'Plat principal', 'Fromage', 'Dessert'].flatMap((type) => {
      const filtered = dishesAndBuffets.filter(
        (el) => el.humanType === type,
      );
      sortedBuffetsAndDishes.push(filtered);
    });
    return _.flatten(sortedBuffetsAndDishes);
  },
  sortedDishesByName(state, getters) {
    return [...getters.activeDishesByName, ...getters.inactiveDishesByName];
  },
  activeDishes(state) {
    return state.dishes.filter((dish) => dish.active);
  },
  inactiveDishes(state) {
    return state.dishes.filter((dish) => (dish.active === false));
  },
  dishes: (state) => state.dishes,
};

export default {
  state: dishState,
  mutations,
  actions,
  getters,
};


// export default new Vuex.Store({
//   state: {
//     todos: [],
//     newTodo: ''
//   },
//   mutations: {
//     GET_TODO(state, todo){
//       state.newTodo = todo
//     },
//     ADD_TODO(state){
//       state.todos.push({
//         body: state.newTodo,
//         completed: false
//       })
//     },
//     EDIT_TODO(state, todo){
//        var todos = state.todos
//        todos.splice(todos.indexOf(todo), 1)
//        state.todos = todos
//        state.newTodo = todo.body
//     },
//     REMOVE_TODO(state, todo){
//        var todos = state.todos
//        todos.splice(todos.indexOf(todo), 1)
//     },
//     COMPLETE_TODO(state, todo){
//       todo.completed = !todo.completed
//     },
//     CLEAR_TODO(state){
//       state.newTodo = ''
//     }
//    },
//   actions: {
//     getTodo({commit}, todo){
//       commit('GET_TODO', todo)
//     },
//     addTodo({commit}){
//       commit('ADD_TODO')
//     },
//     editTodo({commit}, todo){
//       commit('EDIT_TODO', todo)
//     },
//     removeTodo({commit}, todo){
//       commit('REMOVE_TODO', todo)
//     },
//     completeTodo({commit}, todo){
//      commit('COMPLETE_TODO', todo)
//     },
//     clearTodo({commit}){
//       commit('CLEAR_TODO')
//     }
//  })