<template>
  <div>
    <span class="d-flex justify-content-center" v-if="!noTitle">
      <h5>Albumine (corrigée)</h5>
      <i
        class="fas fa-exclamation-triangle mt-1 ml-2"
        style="color: #5bc0de;"
        v-b-tooltip.hover.v-primary
        title="Attention : Pour chaque date, l'Albumine est corrigée avec la CRP antérieure la plus récente"
      />
    </span>
    <line-chart :data="chartData" :colors="['#469be0']" class="mb-5" :library="options" :height="height"></line-chart>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    noTitle: {
      type: Boolean,
      default: false,
    },
    residentAlbumines: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    currentEhpad: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: '300px',
    },
  },
  computed: {
    chartData() {
      const computedAlbumines = _.map(this.residentAlbumines, (v) => {
        return {
          date: v.date,
          value: v.correctedAlbumine,
        };
      });
      const sortedComputedAlbumines = _.orderBy(computedAlbumines, (albumine) => moment(albumine.date), ['asc']);
      const sortedAlbumines = _.orderBy(this.residentAlbumines, (albumine) => moment(albumine.date), ['asc']);
      const lowTreshold = _.map(sortedAlbumines, (v) => {
        return {
          date: v.date,
          value: this.currentEhpad.allSettings.moderate_undernutrition.diagnostic_condition_has_2007.albumine_treshold,
        };
      });
      const criticalTreshold = _.map(sortedAlbumines, (v) => {
        return {
          date: v.date,
          value: this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_has_2007.albumine_treshold,
        };
      });
      return [
        {
          name: "Courbe d'Albumine (corrigée)",
          data: sortedComputedAlbumines.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
        },
        {
          name: "Courbe d'Albumine",
          data: sortedAlbumines.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
        },
        {
          name: 'Seuil bas',
          data: lowTreshold.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#FF9900',
        },
        {
          name: 'Seuil Critique',
          data: criticalTreshold.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#DC3912',
        },
      ];
    },
  },
};
</script>

<style scoped>
</style>
