<template>
  <div>
    <h5 class="text-center">IMC</h5>
    <line-chart :data="chartData" :colors="['#469be0']" class="mb-5" :library="options"></line-chart>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    residentHeight: {
      type: Number,
      required: true,
    },
    residentWeights: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    currentEhpad: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chartData() {
      const imcs = _.map(this.residentWeights, (v) => {
        return {
          date: v.date,
          value: (v.value / ((this.residentHeight / 100) * (this.residentHeight / 100))).toFixed(2),
        };
      });
      const sortedImcs = _.orderBy(imcs, (imc) => moment(imc.date), ['asc']);
      const lowTreshold = _.map(sortedImcs, (v) => {
        return {
          date: v.date,
          value: this.currentEhpad.allSettings.moderate_undernutrition.diagnostic_condition_has_2007.imc_treshold,
        };
      });
      const criticalTreshold = _.map(sortedImcs, (v) => {
        return {
          date: v.date,
          value: this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_has_2007.imc_treshold,
        };
      });
      return [
        {
          name: "Courbe d'IMC",
          data: sortedImcs.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
        },
        {
          name: 'Seuil bas',
          data: lowTreshold.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#FF9900',
        },
        {
          name: 'Seuil Critique',
          data: criticalTreshold.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#DC3912',
        },
      ];
    },
  },
};
</script>

<style scoped>
</style>
