<template>
  <div>
    <div class="text-center" style="color: #469AE0;" v-if="percentageByStateLoading">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement du graphique...
      </h2>
    </div>
    <div v-else class="mt-3">
      <column-chart :data="dataPercentageByState" :library="options"></column-chart>
      <p class="small text-center mt-1 mb-1" style="color: #BFBFBF;">Ces grahiques se basent sur l'état des résidents avec une chambre aux dates de l'étude. Il peut s'agir de résidents différents.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FETCH_PERCENTAGE_BY_STATE } from "../../graphql/stats/queries";

export default {
  name: "PercentageStateBarChart",
  props: {
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      percentageByStateLoading: true,
      dataPercentageByState: [],
      options: {},
    };
  },
  apollo: {
    percentageByStateStart: {
      query: FETCH_PERCENTAGE_BY_STATE,
      variables() {
        this.percentageByStateLoading = true;
        return {
          ehpadId: this.currentEhpad.id,
          date: this.startDate,
        };
      },
      fetchPolicy: "network-only",
      result() {
        this.percentageByStateLoading = false;
        this.dataPercentageByState = this.buildPercentageByState;
      },
      update(data) {
        // Renommez la propriété pour correspondre à ce que vous attendez
        return data.percentageByState;
      },
    },
    percentageByStateEnd: {
      query: FETCH_PERCENTAGE_BY_STATE,
      variables() {
        this.percentageByStateLoading = true;
        return {
          ehpadId: this.currentEhpad.id,
          date: this.endDate,
        };
      },
      fetchPolicy: "network-only",
      result() {
        this.percentageByStateLoading = false;
        this.dataPercentageByState = this.buildPercentageByState;
      },
      update(data) {
        // Renommez la propriété pour correspondre à ce que vous attendez
        return data.percentageByState;
      },
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: "ehpad",
    }),
    buildPercentageByState() {
      let dataStart = [];
      let dataEnd = [];

      if (!this.percentageByStateLoading) {
        if (Array.isArray(this.percentageByStateStart)) {
          dataStart = this.percentageByStateStart.map((item) => [item.state, item.percentage]);
        }
        if (Array.isArray(this.percentageByStateEnd)) {
          dataEnd = this.percentageByStateEnd.map((item) => [item.state, item.percentage]);
        }
      }
      return [
        {
          name: "Date de début",
          data: dataStart,
          color: "#ff6347",
          stack: "Stack 1",
        },
        {
          name: "Date de fin",
          data: dataEnd,
          color: "#add8e6",
          stack: "Stack 2",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
