<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Liste des enquêtes
        </h3>
        <i class="fas fa-exclamation-triangle"
          style="color: #5bc0de;"
          v-b-tooltip.hover.v-primary
          title="Une enquête se déclenche à partir d'un certain seuil d'ingesta pour un ou plusieurs type de repas."
        />
        <b-button class="mb-2 ml-4" variant="primary" size="sm"
          :to="{path: `/ehpads/${currentEhpad.shortname}/survey/new`}"
        >
          <i class="fas fa-plus" />
          Créer une enquête
        </b-button>
      </span>
    </div>

    <b-form-group
      label-size="sm"
      label-for="filterInput"
      class="mb-3"
      style="width: 40%;"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Filtre de recherche"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-table
      :filter="filter"
      sticky-header="600px"
      bordered
      striped
      small
      hover
      :items="surveys"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="m-0"
      ref="surveyTable"
      id="surveyTable"
    >

      <template #cell(ingestaMin)="data">{{ data.item.ingestaMin * 100 }}%</template>
      <template #cell(ingestaMax)="data">{{ data.item.ingestaMax * 100 }}%</template>

      <template #head(actions)>{{ '' }}</template>

      <template #cell(actions)="data">
        <b-button
          variant="link"
          size="sm"
          :to="{path: `/ehpads/${currentEhpad.shortname}/survey/${data.item.id}`}"
        >
          <i class="fas fa-edit" />
          Modifier/Voir
        </b-button>
      </template>
    </b-table>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SurveyTable',
  data() {
    return {
      filter: null,
      selectedSurvey: null,
      sortBy: 'startDate',
      sortDesc: true,
      fields: [
        {
          key: 'name',
          label: "Nom de l'enquête",
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'startDate',
          label: 'Date de début',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'endDate',
          label: 'Date de fin',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'ingestaMin',
          label: 'Ingesta min',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'ingestaMax',
          label: 'Ingesta max',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'actions',
          label: 'actions',
          class: 'text-center',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
      ],
    };
  },
  created() {
    this.setSurveys(this.currentEhpad.id);
  },
  methods: {
    ...mapActions(['setSurveys']),
    selectSurvey(survey) {
      this.selectedSurvey = survey;
    },
  },
  computed: {
    ...mapGetters({
      surveys: 'surveys',
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
