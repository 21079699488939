<template>
  <!-- https://bootstrap-vue.js.org/docs/components/table -->
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Plats
        </h3>
        <i class="fas fa-exclamation-triangle"
          style="color: #5bc0de;"
          v-b-tooltip.hover.v-primary
          title="Les plats dont le nom est en gris sont les plats intégrés au logiciel.
          Le grammage est basé sur le GEMRCN. Vous pouvez les désactiver dans la configuration du logiciel."
        />
        <b-button class="mb-2 ml-4" variant="primary" size="sm" v-b-modal.new-dish-modal>
        <i class="fas fa-plus" />
            Ajouter un nouveau plat
        </b-button>
      </span>
    </div>

    <app-new-dish-modal
      id="new-dish-modal"
      hide-footer
    />

    <b-form-group
      label-size="sm"
      label-for="filterInput"
      class="mb-3"
      style="width: 40%;"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Filtre de recherche"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-table
      sticky-header="500px"
      :filter="filter"
      bordered
      striped
      small
      hover
      :items="dishes"
      :fields="computedFields"
      class="m-0"
      ref="dishTable"
      id="dishTable"
    >

      <template #cell(name)="data">
        <span
          v-if="data.item.provided"
          style="color: darkgrey;"
        >
          {{ data.item.name }}
        </span>
        <span v-else>
          {{ data.item.name }}
        </span>
      </template>

      <template #head(defaultCalories)="data">
        <span>
          <img src="/assets/images/calories.png" class="cal-icon"
            v-b-tooltip.hover.v-primary
            title="Valeurs en kcal"
          >
        </span>
      </template>

      <template #head(defaultProteins)="data">
        <span>
          <img src="/assets/images/proteins.png" class="prot-icon"
            v-b-tooltip.hover.v-primary
            title="Valeurs en g"
          >
        </span>
      </template>

      <template #head(defaultHydration)="data">
        <span>
          <img src="/assets/images/hydration.png" class="hydration-icon"
            v-b-tooltip.hover.v-primary
            title="Valeurs en ml"
          >
        </span>
      </template>

      <template #cell(active)="data">{{ humanActive(data.item) }}</template>

      <template #cell(price)="data">
        <span style="font-size: medium;" v-if="data.item.price !== null">
          {{ data.item.price }}€
        </span>
      </template>

      <template #head(actions)>{{ '' }}</template>

      <template #cell(actions)="data">
        <b-button variant="link" size="sm" v-b-modal.info-dish-modal @click="selectDish(data.item)">
          <i class="fas fa-edit"
            v-b-tooltip.hover.v-primary
            title="Modifier"
          />
        </b-button>
        <b-button variant="link" size="sm" @click="archiveDish(data.item)">
          <i class="text-danger fa fa-trash-alt"
            v-b-tooltip.hover.v-danger
            title="Supprimer"
          />
        </b-button>
      </template>
    </b-table>

    <app-info-dish-modal
      v-if="selectedDish"
      id="info-dish-modal"
      hide-footer
      :dish="selectedDish"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import InfoDishModal from './InfoDishModal.vue';
import NewDishModal from './NewDishModal.vue';

export default {
  components: {
    appInfoDishModal: InfoDishModal,
    appNewDishModal: NewDishModal,
  },
  data() {
    return {
      filter: null,
      selectedDish: null,
      fields: [
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'humanType',
          label: 'Catégorie',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'defaultCalories',
          label: 'Calories',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'defaultProteins',
          label: 'Protéines',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'defaultHydration',
          label: 'Apports hydriques',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'price',
          label: 'Prix',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'active',
          label: 'Disponible',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'actions',
          label: 'actions',
          class: 'text-center',
        },
      ],
    };
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.selectedDish = null;
    });
  },
  methods: {
    archiveDish(dish) {
      Swal.fire({
        title: 'Êtes vous sûr ?',
        text:
          'Êtes vous sûr de vouloir supprimer ce plat de facon définitive ? Il restera affiché dans les anciens menus mais ne sera plus utilisable.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          await this.$store.dispatch('archiveDish', {
            id: dish.id,
          }).catch((error) => {
            this.flashMessage.show({
              status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
        }
      });
    },
    humanActive(dish) {
      if (dish.active === true) return 'Actif';
      return 'Désactivé';
    },
    selectDish(dish) {
      this.selectedDish = dish;
    },
  },
  computed: {
    ...mapGetters({
      dishes: 'sortedDishesByName',
      currentUser: 'user',
    }),
    computedFields() {
      if (this.currentUser.role !== 'administrateur' && this.currentUser.role !== 'intendance') {
        return this.fields.filter((field) => field.key !== 'price');
      }
      return this.fields;
    },
  },
};
</script>

<style lang="scss" scoped>
  :deep(.small-size) {
    max-width: 75px;
  }

  .cal-icon {
    filter: invert(21%) sepia(91%) saturate(5127%) hue-rotate(118deg) brightness(94%) contrast(105%);
    width: 80%;
    max-width: 40px;
  }

  .hydration-icon {
    filter: invert(58%) sepia(85%) saturate(7456%) hue-rotate(234deg) brightness(99%) contrast(103%);
    width: 80%;
    max-width: 40px;
  }

  .prot-icon {
    filter: invert(46%) sepia(86%) saturate(2816%) hue-rotate(330deg) brightness(113%) contrast(109%);
    width: 80%;
    max-width: 40px;
  }
</style>
