import gql from "graphql-tag";

import apiClient from "../../utilities/api_client";
import { FIND_EHPAD_WITH_SHORTNAME, FIND_EHPAD } from "../../graphql/ehpad/queries";
import { UPDATE_EHPAD, UPDATE_EHPAD_SETTING } from "../../graphql/ehpad/mutations";

const ehpadState = {
  ehpad: null,
  ehpadForDashboard: null,
  ehpads: [],
};

const mutations = {
  SELECT_EHPAD(state, ehpad) {
    state.ehpad = ehpad;
  },
  SELECT_EHPAD_FOR_DASHBOARD(state, ehpad) {
    state.ehpadForDashboard = ehpad;
  },
  SET_EHPADS(state, ehpads) {
    state.ehpads = ehpads;
  },
  UPDATE_EHPAD(state, ehpad) {
    const index = state.ehpads.findIndex((e) => e.id === ehpad.id);
    state.ehpads.splice(index, 1, ehpad);
    state.ehpad = ehpad;
  },
};

const actions = {
  selectEhpad: ({ commit }, ehpad) => {
    commit("SELECT_EHPAD", ehpad);
  },
  findEhpadWithShortname: async ({ commit }, shortname) => {
    const { data } = await apiClient.query({
      query: FIND_EHPAD_WITH_SHORTNAME,
      // On déclare les variables (equivalents $) aprés la query
      variables: { shortname },
      fetchPolicy: "network-only",
    });
    // On commit le resultat : data.nomdelaquery
    commit("SELECT_EHPAD", data.findEhpadWithShortname);
  },
  findEhpad: async ({ commit }, id) => {
    const { data } = await apiClient.query({
      query: FIND_EHPAD,
      variables: { id },
      fetchPolicy: "network-only",
    });
    commit("SELECT_EHPAD_FOR_DASHBOARD", data.findEhpad);
  },
  setEhpads: async ({ commit }) => {
    const { data } = await apiClient.query({
      query: gql`
        query EHPADS {
          ehpads {
            id
            name
            shortname
            email
            color
            allSettings
          }
        }
      `,
      fetchPolicy: "network-only",
    });
    commit("SET_EHPADS", data.ehpads);
  },
  updateEhpad: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_EHPAD,
      variables: { ...formData },
    });
    const { ehpad } = data.updateEhpad;
    commit("UPDATE_EHPAD", ehpad);
    return ehpad;
  },
  updateEhpadSetting: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_EHPAD_SETTING,
      variables: { ...formData },
    });
    const { ehpad } = data.updateEhpadSetting;
    commit("UPDATE_EHPAD", ehpad);
    return ehpad;
  },
};

const getters = {
  ehpad: (state) => state.ehpad,
  ehpadForDashboard: (state) => state.ehpadForDashboard,
  ehpads: (state) => state.ehpads,
};

export default {
  state: ehpadState,
  mutations,
  actions,
  getters,
};
