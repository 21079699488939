import gql from 'graphql-tag';

export const FETCH_TEST_TYPES = gql`
  query FETCH_TEST_TYPES($ehpadId: ID!) {
    testTypes(ehpadId: $ehpadId) {
      id
      name
      description
      url
      isBoolean
      allSettings
    }
  }
`;