import gql from 'graphql-tag';

export const FETCH_DISHES = gql`
  query FETCH_DISHES($ehpadId: ID!) {
    dishes(ehpadId: $ehpadId) {
      id
      name
      dType
      humanType
      active
      defaultProteins
      defaultCalories
      defaultHydration
      provided
      price
    }
  }
`;

export const DEFAULT_DISHES = gql`
  query DEFAULT_DISHES($ehpadId: ID!, $mealCategory: String!) {
    defaultDishes(ehpadId: $ehpadId, mealCategory: $mealCategory) {
      id
      name
      dType
      humanType
      defaultProteins
      defaultCalories
      defaultHydration
    }
  }
`;
