<template>
  <div>
    <ImcChart :residentHeight="residentHeight" :residentWeights="residentWeights" :options="options" :currentEhpad="currentEhpad" />
    <WeightChart :residentWeights="residentWeights" :options="options" />
    <AlbumineChart :residentAlbumines="residentAlbumines" :options="options" :currentEhpad="currentEhpad" />
    <NriChart :residentAlbumines="residentAlbumines" :options="options" :currentEhpad="currentEhpad" />
    <CrpChart :residentCrps="residentCrps" :options="options" />
    <MnaChart :residentMnas="residentMnas" :options="options" :currentEhpad="currentEhpad" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ImcChart from './charts/Imc.vue';
import WeightChart from './charts/Weight.vue';
import AlbumineChart from './charts/Albumine.vue';
import NriChart from './charts/Nri.vue';
import CrpChart from './charts/Crp.vue';
import MnaChart from './charts/Mna.vue';

export default {
  components: {
    ImcChart,
    WeightChart,
    AlbumineChart,
    NriChart,
    CrpChart,
    MnaChart,
  },
  props: ['residentHeight', 'residentWeights', 'residentMnas', 'residentCrps', 'residentAlbumines'],
  data() {
    return {
      options: {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              displayFormats: {
                day: 'DD MMM YYYY',
              },
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style scoped>
</style>
