import gql from 'graphql-tag';

export const FETCH_EHPAD_DIAGNOSTICS_FOR_CHARTS = gql`
  query FETCH_EHPAD_DIAGNOSTICS_FOR_CHARTS($year: Int, $months: Int, $ehpadId: ID!, $stateType: String!) {
    diagnosticsForCharts(year: $year, months: $months, ehpadId: $ehpadId, stateType: $stateType)
  }
`;

export const FETCH_EHPAD_PRICES_FOR_CHARTS = gql`
  query FETCH_EHPAD_PRICES_FOR_CHARTS($year: Int, $months: Int, $ehpadId: ID!) {
    pricesForCharts(year: $year, months: $months, ehpadId: $ehpadId)
  }
`;

export const FETCH_EHPAD_PRICES_FOR_CHARTS_BY_MEAL_CATEGORIES = gql`
  query FETCH_EHPAD_PRICES_FOR_CHARTS_BY_MEAL_CATEGORIES($year: Int, $months: Int, $ehpadId: ID!) {
    pricesForChartsByMealCategories(year: $year, months: $months, ehpadId: $ehpadId)
  }
`;

export const FETCH_EHPAD_AVERAGE_RESIDENTS_PRICES_FOR_CHARTS = gql`
  query FETCH_EHPAD_AVERAGE_RESIDENTS_PRICES_FOR_CHARTS($year: Int, $months: Int, $ehpadId: ID!) {
    averageResidentsPricesForCharts(year: $year, months: $months, ehpadId: $ehpadId)
  }
`;

export const FETCH_EHPAD_AVERAGE_RESIDENTS_PRICES_FOR_CHARTS_BY_MEAL_CATEGORIES = gql`
  query FETCH_EHPAD_AVERAGE_RESIDENTS_PRICES_FOR_CHARTS_BY_MEAL_CATEGORIES($year: Int, $months: Int, $ehpadId: ID!) {
    averageResidentsPricesForChartsByMealCategories(year: $year, months: $months, ehpadId: $ehpadId)
  }
`;

export const FETCH_EHPAD_CONSTANTS_AVERAGE_FOR_CHARTS = gql`
  query FETCH_EHPAD_CONSTANTS_AVERAGE_FOR_CHARTS($year: Int, $months: Int, $ehpadId: ID!, $constant: String!) {
    constantsAverageForCharts(year: $year, months: $months, ehpadId: $ehpadId, constant: $constant)
  }
`;

export const FETCH_EHPAD_AVERAGE_RATIO_NUTRITION_FOR_CHARTS = gql`
  query FETCH_EHPAD_AVERAGE_RATIO_NUTRITION_FOR_CHARTS($year: Int, $months: Int, $ehpadId: ID!) {
    averageRatioNutritionForCharts(year: $year, months: $months, ehpadId: $ehpadId)
  }
`;

export const FETCH_EHPAD_AVERAGE_RATIO_NUTRITION_BY_RESIDENTS = gql`
  query FETCH_EHPAD_AVERAGE_RATIO_NUTRITION_BY_RESIDENTS($date: String!, $ehpadId: ID!) {
    averageRatioNutritionByResidents(date: $date, ehpadId: $ehpadId)
  }
`;

export const FETCH_EHPAD_INGESTA_CONTROL_FOR_CHARTS = gql`
  query FETCH_EHPAD_INGESTA_CONTROL_FOR_CHARTS($year: Int, $months: Int, $ehpadId: ID!) {
    ehpadIngestaControlForCharts(year: $year, months: $months, ehpadId: $ehpadId)
  }
`;
