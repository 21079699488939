<template>
  <div>
    <b-button variant="primary" size="sm" class="m-1" v-b-toggle.sidebar-right>
    <i class="fas fa-info" />
        Plus d'informations
    </b-button>
      <b-sidebar
        id="sidebar-right"
        right
        backdrop-variant="transparent"
        shadow
        backdrop
        width="30%"
      >
        <template #default="{ hide }">
          <div class="px-3 py-2">
            <h5 v-html="title" class="m-0"></h5>
            <hr>

            <h5 class="mb-4">Informations</h5>
            <div class="mx-3">
              <dl class="row">
                <dd class="col-6 small">Taille :</dd>
                <dt class="col-6">
                  <div>
                    <label>{{ resident.height }} cm</label>
                  </div>
                </dt>

                <dd class="col-6 small">Poids actuel :</dd>
                <dt class="col-6">
                  <div>
                    <label>{{ resident.lastWeight.value }} Kg</label>
                  </div>
                </dt>

                <dd class="col-6 small">Poids de forme:</dd>
                <dt class="col-6">
                  <div>
                    <label>{{ resident.idealWeight }} Kg</label>
                  </div>
                </dt>

                <dd class="col-6 small">Lit :</dd>
                <dt class="col-6">
                  <div>
                    <label>{{ selectedRoomLabel }}</label>
                  </div>
                </dt>
              </dl>
            </div>
            <hr>

            <h5 class="mb-3">Texture des plats</h5>
            <div class="mx-3">
              <p>
                Repas : {{ resident.lastTexture.humanType }}
              </p>
            </div>
            <hr>

            <div v-if="resident.nutritionalInfo !== null">
              <h5 class="mb-4">Informations Nutritionelles (Dégouts...)</h5>
              <div class="mx-3">
                <p class="m-0" style="font-size: medium;">
                  {{ resident.nutritionalInfo }}
                </p>
              </div>
            </div>
            <hr v-if="resident.nutritionalInfo !== null">

            <div v-if="resident.diet !== null">
              <h5 class="mb-4">Régime</h5>
              <div class="mx-3">
                <p class="m-0" style="font-size: medium;">
                  {{ resident.diet }}
                </p>
              </div>
            </div>
            <hr v-if="resident.diet !== null">

            <div v-if="resident.requireHelp === true">
              <h5 class="mb-4"><b>!!! Aide aux repas requise !!!</b></h5>
            </div>
            <hr v-if="resident.requireHelp === true">

            <h5 class="mb-4">Perceptions Gustatives</h5>
            <div class="mx-3 mb-3" style="zoom: 0.8;">
              <app-resident-gustatory-category-items-disabled
                v-for="gustatoryCategory in currentEhpad.gustatoryCategories"
                :key="gustatoryCategory.id"
                :resident="resident"
                :gustatoryCategory="gustatoryCategory"
              />
            </div>
            <hr>

            <div v-if="resident.individualProgram">
              <h5 class="mb-4">Programme individuel</h5>
              <b-row class="mb-3" v-if="resident.individualProgram">
                <b-col cols="12">
                  <GoalsComponent
                    v-if="residentGoals.length > 0"
                    :goals="residentGoals"
                    :currentDate="today"
                    :readonly="true"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="12">
                  <NutritionalActionsComponent
                    v-if="residentNutritionalActions.length > 0"
                    :actions="residentNutritionalActions"
                    :currentDate="today"
                    :readonly="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <TherapeuticActionsComponent
                    v-if="residentTherapeuticActions.length > 0"
                    :actions="residentTherapeuticActions"
                    :currentDate="today"
                    :readonly="true"
                  />
                </b-col>
              </b-row>
              <hr>
            </div>
          </div>
          <b-row align-h="center">
              <b-button variant="primary" class="mx-3 mt-3" @click="hide">Fermer</b-button>
          </b-row>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ResidentGustatoryCategoryItemsDisabled from './ResidentGustatoryCategoryItemsDisabled.vue';
import { coloredDiagHtml } from '../../utilities/filters';
import moment from 'moment';
import GoalsComponent from './forms/GoalsComponent.vue';
import NutritionalActionsComponent from './forms/NutritionalActionsComponent.vue';
import TherapeuticActionsComponent from './forms/TherapeuticActionsComponent.vue';

export default {
  name: 'Sidebar-info',
  props: ['resident'],
  components: {
    appResidentGustatoryCategoryItemsDisabled: ResidentGustatoryCategoryItemsDisabled,
    GoalsComponent,
    NutritionalActionsComponent,
    TherapeuticActionsComponent
  },
  data() {
    return {
    };
  },
  created() {
    moment.locale('fr-FR');
  },
  methods: {
    coloredDiagHtml
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    today() {
      const now = new Date();
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return moment(date).format("DD/MM/YYYY");
    },
    residentNutritionalActions() {
      return this.resident.individualProgram.actions.filter(action => action.actionType === 'nutritional')
    },
    residentTherapeuticActions() {
      return this.resident.individualProgram.actions.filter(action => action.actionType === 'therapeutic')
    },
    residentGoals() {
      return this.resident.individualProgram.goals
    },
    selectedRoomLabel() {
      const { room } = this.resident;
      return room ? `N° ${room.number}` : 'Aucun lit assigné';
    },
    title() {
      return `${this.resident.fullName} (${this.coloredDiagHtml(
        this.resident.lastDiagnostic.humanState,
      )})`;
    },

  },
};
</script>

<style lang="scss" scoped>

</style>
