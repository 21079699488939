import gql from 'graphql-tag';

export const CREATE_RESIDENT_TEST = gql`
  mutation CREATE_RESIDENT_TEST(
    $residentId: ID!
    $testTypeId: ID!
    $date: String!
    $value: Float!
    $comment: String
  ) {
    createResidentTest(
      input: { 
        residentId: $residentId, 
        testTypeId: $testTypeId,
        date: $date,
        value: $value,
        comment: $comment,
      }
    ) {
      residentTest {
        id
      }
    }
  }
`;