<template>
  <multiselect
    placeholder="Ajouter un plat/buffet"
    label="text"
    track-by="text"
    selectLabel=""
    deselectLabel=""
    selectedLabel="Sélectionné"
    :options="options"
    @input="onChange"
    v-model="dishOrBuffet"
    :options-limit="100"
  >
    <template #noResult>
      <span>Pas de résultat correspondant</span>
    </template>
    <template #option="props">
      <div class="option__desc">
        <i
          class="far fa-list-alt"
          style="color: #5bc0de;"
          v-b-tooltip.hover.v-primary
          title="Buffet"
          v-if="props.option.type === 'Buffet'"
        />
        <span class="option__title">{{ props.option.text }}
          <span class="calories"> {{ props.option.calories }} kcal</span>
          |
          <span class="proteins"> {{ props.option.proteins }} g</span>
          |
          <span class="hydration"> {{ props.option.hydration }} ml</span>
        </span>
      </div>
    </template>
    <template #singleLabel="props">
      <span class="option__desc">
        <i
          class="far fa-list-alt"
          style="color: #5bc0de;"
          v-b-tooltip.hover.v-primary
          title="Buffet"
          v-if="props.option.type === 'Buffet'"
        />
        <span class="option__title">{{ props.option.text }}
          <span class="calories"> {{ props.option.calories }} kcal</span>
          |
          <span class="proteins"> {{ props.option.proteins }} g</span>
          |
          <span class="hydration"> {{ props.option.hydration }} ml</span>
        </span>
      </span>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  props: ['options', 'onChange', 'dataToWatch'],
  data() {
    return {
      dishOrBuffet: null,
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    // premier argument : nouvelle valeur
    // second argument : ancienne valeur
    dataToWatch(newVal, oldVal) {
      if (newVal === null && oldVal !== null) {
        this.dishOrBuffet = null;
      }
    },
  },
};
</script>

<style lang="sass" scoped>

  .calories {
  font-size: 0.8rem;
  color: green;
  }
  .proteins {
    font-size: 0.8rem;
    color: red;
  }
  .hydration {
    font-size: 0.8rem;
    color: blue;
  }

</style>
