<template>
  <div>
    <b-row v-if="averageRatioNutritionForCharts !== undefined">
      <b-col class="col-2">Intervalle </b-col>
      <b-col class="col-2">
        <div>
          <b-form-select
            v-model="rangeForAverageRatioNutritionChart"
            :options="chartRangeOptions"
            @change="averageRatioNutritionForCharts = undefined"
          />
        </div>
      </b-col>
    </b-row>

    <h5 class="text-center">Protéines (Ecarts en %)</h5>
    <div v-if="averageRatioNutritionForCharts !== undefined" class="mt-3">
      <line-chart :data="dataAverageRatioProtNutritionForCharts" suffix="%" :points=false :library="options"></line-chart>
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="averageRatioNutritionForCharts === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>

    <hr/>

    <h5 class="text-center">Protéines (Moyennes en g)</h5>
    <div v-if="averageRatioNutritionForCharts !== undefined" class="mt-3">
      <line-chart :data="dataAverageProtNutritionForCharts" suffix=" g" :points=false :library="options"></line-chart>
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="averageRatioNutritionForCharts === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>

    <hr/>


    <h5 class="text-center">Calories (Ecarts en %)</h5>
    <div v-if="averageRatioNutritionForCharts !== undefined" class="mt-3">
      <line-chart :data="dataAverageRatioCalNutritionForCharts" suffix="%" :points=false :library="options"></line-chart>
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="averageRatioNutritionForCharts === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>

    <hr/>

    <h5 class="text-center">Calories (Moyennes en kcal)</h5>
    <div v-if="averageRatioNutritionForCharts !== undefined" class="mt-3">
      <line-chart :data="dataAverageCalNutritionForCharts" suffix=" kcal" :points=false :library="options"></line-chart>
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="averageRatioNutritionForCharts === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>

    <hr/>

    <h5 class="text-center">Apports hydriques (Ecarts en %)</h5>
    <div v-if="averageRatioNutritionForCharts !== undefined" class="mt-3">
      <line-chart :data="dataAverageRatioHydrationNutritionForCharts" suffix="%" :points=false :library="options"></line-chart>
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="averageRatioNutritionForCharts === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>

    <hr/>

    <h5 class="text-center">Apports hydriques (Moyennes en ml)</h5>
    <div v-if="averageRatioNutritionForCharts !== undefined" class="mt-3">
      <line-chart :data="dataAverageHydrationNutritionForCharts" suffix=" ml" :points=false :library="options"></line-chart>
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="averageRatioNutritionForCharts === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>

    <app-average-ration-nutrition-by-residents-table-modal
      :date="this.dateForAverageRatioNutritionByResidents"
      :data="this.averageRatioNutritionByResidents"
      id="ratio-nutritonal-table-modal"
      hide-footer
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import AverageRationNutritionByResidentsTableModal from './AverageRationNutritionByResidentsTableModal.vue';
import { FETCH_EHPAD_AVERAGE_RATIO_NUTRITION_FOR_CHARTS, FETCH_EHPAD_AVERAGE_RATIO_NUTRITION_BY_RESIDENTS } from '../../graphql/dashboard/queries';

export default {
  data() {
    return {
      dateForAverageRatioNutritionByResidents: null,
      dataAverageRatioNutritionForCharts: [],
      rangeForAverageRatioNutritionChart: { months: null, year: null },
      options: {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              displayFormats: {
                day: 'DD MMM',
              },
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        onClick: this.handleClick,
      },
    };
  },
  components: {
    appAverageRationNutritionByResidentsTableModal: AverageRationNutritionByResidentsTableModal,
  },
  apollo: {
    averageRatioNutritionForCharts: {
      query: FETCH_EHPAD_AVERAGE_RATIO_NUTRITION_FOR_CHARTS,
      variables() {
        return {
          ehpadId: this.currentEhpad.id,
          year: parseInt(this.rangeForAverageRatioNutritionChart.year, 10),
          months: parseInt(this.rangeForAverageRatioNutritionChart.months, 10),
        };
      },
      fetchPolicy: 'network-only',
    },
    averageRatioNutritionByResidents: {
      query: FETCH_EHPAD_AVERAGE_RATIO_NUTRITION_BY_RESIDENTS,
      variables() {
        return {
          ehpadId: this.currentEhpad.id,
          date: this.dateForAverageRatioNutritionByResidents,
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  watch: {
    averageRatioNutritionForCharts: {
      handler() {
        if (this.averageRatioNutritionForCharts !== undefined) {
          this.dataAverageRatioProtNutritionForCharts = this.buildDataAverageRatioProtNutritionForCharts;
          this.dataAverageProtNutritionForCharts = this.buildDataAverageProtNutritionForCharts;
          this.dataAverageRatioCalNutritionForCharts = this.buildDataAverageRatioCalNutritionForCharts;
          this.dataAverageCalNutritionForCharts = this.buildDataAverageCalNutritionForCharts;
          this.dataAverageRatioHydrationNutritionForCharts = this.buildDataAverageRatioHydrationNutritionForCharts;
          this.dataAverageHydrationNutritionForCharts = this.buildDataAverageHydrationNutritionForCharts;
        }
      },
      immediate: true,
    },
  },
  created() {
    moment.locale('fr-FR');
    this.dateForAverageRatioNutritionByResidents = this.today;
  },
  methods: {
    handleClick(point, event) {
      const item = event[0];
      if (item === undefined) { return; }
      // Use Prot but all datas have same date structure
      const date = this.dataAverageRatioProtNutritionForCharts[0].data[item._index][0];
      this.dateForAverageRatioNutritionByResidents = moment(date, 'YYYY/MM/DD').format('YYYY-MM-DD');
      this.$bvModal.show('ratio-nutritonal-table-modal');
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    today() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    },
    chartRangeOptions() {
      return [
        { value: { months: null, year: null }, text: '3 mois glissants' },
        { value: { months: 6, year: null }, text: '6 mois glissants' },
        { value: { months: 12, year: null }, text: '12 mois glissants' },
        { value: { months: null, year: moment().year() - 2 }, text: moment().year() - 2 },
        { value: { months: null, year: moment().year() - 1 }, text: moment().year() - 1 },
        { value: { months: null, year: moment().year() }, text: moment().year() },
      ];
    },
    buildDataAverageRatioProtNutritionForCharts() {
      const datesArray = Object.keys(this.averageRatioNutritionForCharts);
      const fetchValuesForField = (field) => datesArray.map(date => [date, _.get(this.averageRatioNutritionForCharts[date], field, 0)]);
      // const firstDate = moment(datesArray[0]).format('YYYY/MM/DD');
      // const lastDate = moment(datesArray.pop()).format('YYYY/MM/DD');

      return [
        {
          name: 'Base 0 (cibles nutritionnelles moyenne)',
          data: fetchValuesForField('ratio_proteins_served').map((el) => [moment(el[0]).format('YYYY/MM/DD'), 0]),
          color: '#DC3912',
        },
        {
          name: 'Ratio protéines servies',
          data: fetchValuesForField('ratio_proteins_served').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#FF9900',
        },
        {
          name: 'Ratio protéines ingérées',
          data: fetchValuesForField('ratio_proteins_ingested').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#469be0',
        },
      ];
    },
    buildDataAverageProtNutritionForCharts() {
      const datesArray = Object.keys(this.averageRatioNutritionForCharts);
      const fetchValuesForField = (field) => datesArray.map(date => [date, _.get(this.averageRatioNutritionForCharts[date], field, 0)]);

      return [
        {
          name: 'Cibles nutritionnelles (moyenne)',
          data: fetchValuesForField('avg_proteins_goal').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#DC3912',
        },
        {
          name: 'Protéines servies (moyenne)',
          data: fetchValuesForField('avg_proteins_served').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#FF9900',
        },
        {
          name: 'Protéines ingérées (moyenne)',
          data: fetchValuesForField('avg_proteins_ingested').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#469be0',
        },
      ];
    },
    buildDataAverageRatioCalNutritionForCharts() {
      const datesArray = Object.keys(this.averageRatioNutritionForCharts);
      const fetchValuesForField = (field) => datesArray.map(date => [date, _.get(this.averageRatioNutritionForCharts[date], field, 0)]);

      return [
        {
          name: 'Base 0 (cibles nutritionnelles moyenne)',
          data: fetchValuesForField('ratio_calories_served').map((el) => [moment(el[0]).format('YYYY/MM/DD'), 0]),
          color: '#DC3912',
        },
        {
          name: 'Ratio calories servies',
          data: fetchValuesForField('ratio_calories_served').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#FF9900',
        },
        {
          name: 'Ratio calories ingérées',
          data: fetchValuesForField('ratio_calories_ingested').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#469be0',
        },
      ];
    },
    buildDataAverageCalNutritionForCharts() {
      const datesArray = Object.keys(this.averageRatioNutritionForCharts);
      const fetchValuesForField = (field) => datesArray.map(date => [date, _.get(this.averageRatioNutritionForCharts[date], field, 0)]);

      return [
        {
          name: 'Cibles nutritionnelles (moyenne)',
          data: fetchValuesForField('avg_calories_goal').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#DC3912',
        },
        {
          name: 'Calories servies (moyenne)',
          data: fetchValuesForField('avg_calories_served').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#FF9900',
        },
        {
          name: 'Calories ingérées (moyenne)',
          data: fetchValuesForField('avg_calories_ingested').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#469be0',
        },
      ];
    },
    buildDataAverageRatioHydrationNutritionForCharts() {
      const datesArray = Object.keys(this.averageRatioNutritionForCharts);
      const fetchValuesForField = (field) => datesArray.map(date => [date, _.get(this.averageRatioNutritionForCharts[date], field, 0)]);

      return [
        {
          name: 'Base 0 (cibles nutritionnelles moyenne)',
          data: fetchValuesForField('ratio_hydration_served').map((el) => [moment(el[0]).format('YYYY/MM/DD'), 0]),
          color: '#DC3912',
        },
        {
          name: 'Ratio apports hydriques servies',
          data: fetchValuesForField('ratio_hydration_served').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#FF9900',
        },
        {
          name: 'Ratio apports hydriques ingérées',
          data: fetchValuesForField('ratio_hydration_ingested').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#469be0',
        },
      ];
    },
    buildDataAverageHydrationNutritionForCharts() {
      const datesArray = Object.keys(this.averageRatioNutritionForCharts);
      const fetchValuesForField = (field) => datesArray.map(date => [date, _.get(this.averageRatioNutritionForCharts[date], field, 0)]);

      return [
        {
          name: 'Cibles nutritionnelles (moyenne)',
          data: fetchValuesForField('avg_hydration_goal').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#DC3912',
        },
        {
          name: 'Apports hydriques servies (moyenne)',
          data: fetchValuesForField('avg_hydration_served').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#FF9900',
        },
        {
          name: 'Apports hydriques ingérées (moyenne)',
          data: fetchValuesForField('avg_hydration_ingested').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
          color: '#469be0',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
