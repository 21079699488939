<template>
  <b-modal v-bind="$attrs" title="Ajouter une supplémentation" class="col-12 text-center">
    <b-container fluid>
      <b-form>
        <b-row>
            <b-col cols="2">
              <label for="inline-form-input-name" class="pr-4 text-nowrap">Nom : </label>
            </b-col>
            <b-col cols="10">
                <b-form-input
                  id="inline-form-input-name"
                  size="sm"
                  type="text"
                  v-model="newSupplementation.name"
                  :class="{ 'is-invalid': $v.newSupplementation.name.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise (60 caractères max)</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-cal" class="pr-4 text-nowrap">Calories (kcal) : </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  id="inline-form-input-cal"
                  size="sm"
                  type="number"
                  min="0"
                  v-model="newSupplementation.calories"
                  :class="{ 'is-invalid': $v.newSupplementation.calories.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-prot" class="pr-4 text-nowrap">Protéines (g) : </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  size="sm"
                  type="number"
                  min="0"
                  v-model="newSupplementation.proteins"
                  :class="{ 'is-invalid': $v.newSupplementation.proteins.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2">
            <b-col cols="6">
              <label for="inline-form-input-prot" class="pr-4 text-nowrap">Apports hydriques (ml) : </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  size="sm"
                  type="number"
                  min="0"
                  v-model="newSupplementation.hydration"
                  :class="{ 'is-invalid': $v.newSupplementation.hydration.$anyError }"
                />
                <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="pt-2" v-if="currentUser.role == 'administrateur' || currentUser.role == 'intendance'">
            <b-col cols="6">
              <label for="inline-form-input-prot" class="pr-4 text-nowrap">Prix par portion : </label>
            </b-col>
            <b-col cols="6">
                <b-form-input
                  size="sm"
                  type="number"
                  min="0"
                  v-model="newSupplementation.price"
                />
                <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
            </b-col>
          </b-row>

        <div v-if="error" style="color: red;" class="text-center mt-2">
          <p v-for="(error, i) of error.graphQLErrors" :key="i">
            {{ error.message }}
          </p>
        </div>

        <b-button
          class="mt-3 d-flex mx-auto"
          variant="primary"
          size="sm"
          @click.prevent="createSupplementation"
          >Valider</b-button
        >
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      newSupplementation: {
        proteins: null,
        calories: null,
        hydration: null,
        name: '',
        price: 0,
      },
    };
  },
  validations: {
    newSupplementation: {
      name: {
        required,
        maxLength: maxLength(60),
      },
      proteins: {
        required,
      },
      calories: {
        required,
      },
      hydration: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      currentUser: 'user',
    }),
  },
  methods: {
    async createSupplementation() {
      this.$v.newSupplementation.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('createSupplementation', {
        calories: parseInt(this.newSupplementation.calories, 10),
        proteins: parseInt(this.newSupplementation.proteins, 10),
        hydration: parseInt(this.newSupplementation.hydration, 10),
        price: parseFloat(this.newSupplementation.price),
        name: this.newSupplementation.name,
        ehpadId: this.currentEhpad.id,
      }).then((result) => {
        this.error = null;
        this.$v.$reset();
        this.resetData();
        this.$bvModal.hide('new-supplementation-modal');
      }).catch((error) => {
        // Erreur dans la modale :
        this.error = error;
        // Erreur en Flash
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
};
</script>

<style lang="scss" scoped></style>
