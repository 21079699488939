import gql from 'graphql-tag';

export const CREATE_RESIDENT_MEAL_DISH_SUPPLEMENTATION = gql`
  mutation CREATE_RESIDENT_MEAL_DISH_SUPPLEMENTATION($residentMealDishId: ID!, $supplementationId: ID!) {
    createResidentMealDishSupplementation(
      input: { residentMealDishId: $residentMealDishId, supplementationId: $supplementationId }
    ) {
      residentMealDishSupplementation {
        id
      }
    }
  }
`;

export const DESTROY_RESIDENT_MEAL_DISH_SUPPLEMENTATION = gql`
  mutation DESTROY_RESIDENT_MEAL_DISH_SUPPLEMENTATION($id: ID!) {
    destroyResidentMealDishSupplementation(input: {id: $id}) {
      residentMealDishSupplementation {
        id
      }
    }
  }
`;
