import gql from 'graphql-tag';

export const CREATE_GUSTATORY_PERCEPTION = gql`
  mutation CREATE_GUSTATORY_PERCEPTION(
    $value: Int!
    $residentId: ID!
    $gustatoryCategoryId: ID!
  ) {
    createGustatoryPerception(
      input: { value: $value, residentId: $residentId , gustatoryCategoryId: $gustatoryCategoryId }
    ) {
      gustatoryPerception {
        id
        value
        gustatoryCategoryId
      }
    }
  }
`;
