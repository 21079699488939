<template>
  <div>
    <h5 class="text-center" v-if="!noTitle">Protéines</h5>
    <line-chart :data="chartData" class="mb-5" :points=false :library="options" :height="height"></line-chart>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    noTitle: {
      type: Boolean,
      default: false,
    },
    residentMenusForCharts: {
      type: Array,
      required: true,
    },
    nutritionGoals: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: '300px',
    },
  },
  computed: {
    chartData() {
      const proteinsIngested = _.map(this.residentMenusForCharts, (v) => ({
        date: v.date,
        value: v.proteinsIngested,
      }));
      const proteinsTotal = _.map(this.residentMenusForCharts, (v) => ({
        date: v.date,
        value: v.proteinsTotal,
      }));
      const proteinsGoal = _.map(proteinsIngested, (v) => ({
        date: v.date,
        value: this.buildProteinsGoal(v.date),
      }));
      return [
        {
          name: 'Protéines ingérées',
          data: proteinsIngested.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#469be0',
        },
        {
          name: 'Protéines servies',
          data: proteinsTotal.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#FF9900',
        },
        {
          name: 'Cible nutritionnelle (protéines)',
          data: proteinsGoal.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#DC3912',
        },
      ];
    },
  },
  methods: {
    buildProteinsGoal(date) {
      let index = 0;
      if (this.nutritionGoals.length === 1) return this.nutritionGoals[0].proteins;
      do {
        if (moment(date).isBefore(this.nutritionGoals[index + 1].date)) {
          return this.nutritionGoals[index].proteins;
        }
        index++;
      } while (this.nutritionGoals[index + 1] !== undefined);
      return this.nutritionGoals.slice(-1)[0].proteins;
    },
  },
};
</script>

<style scoped></style>
