<template>
  <b-container fluid>
    <custom-date-picker :id="`datepicker-${$options.name}`" @dates-selected="onDatesSelected"></custom-date-picker>

    <b-row class="justify-content-center mt-4">
      <b-col v-if="residentsForSampleLoading" cols="6" class="card p-3 m-1">
        <div class="text-center" style="color: #469AE0;">
          <h4>
            <i class="fa fa-spin fa-circle-notch" />
            Chargement...
          </h4>
        </div>
      </b-col>
      <b-col v-else cols="6" class="card p-3 m-1">
        <div class="d-flex justify-content-center">
          <h6>
            <span>Nombre de résidents sur l'intervalle * :</span>
            <span><strong>{{ residentsForSample.length }}</strong></span>
            <b-button variant="link" size="sm" v-b-modal.residents-for-sample-details-modal>
              <i class="fas fa-info"
                v-b-tooltip.hover.v-primary
                title="Détails des résidents"
              />
            </b-button>
          </h6>
        </div>
        <p class="small text-center">* Ayant eu au moins deux diagnostics pendant la période considérée (Hors "non classé" et "fin de vie")</p>
      </b-col>
    </b-row>

    <b-row class="justify-content-center">
      <b-col cols="4" class="card p-3 m-1">
        <div class="d-flex justify-content-center">
          <h6>
            Moyenne des états nutritionnels
          </h6>
        </div>

        <average-diagnostics
          :start-date="startDate"
          :end-date="endDate"
        ></average-diagnostics>

      </b-col>
      <b-col cols="4" class="card p-3 m-1">
        <div class="d-flex justify-content-center">
          <h6>
            Etats Nutritionnels aux dates de début et de fin  *
          </h6>
        </div>

        <percentage-by-state
          :start-date="startDate"
          :end-date="endDate"
        ></percentage-by-state>

      </b-col>
    </b-row>

    <b-row class="justify-content-center">
      <b-col v-if="calculateResidentStatusChangeLoading || residentsForSampleLoading" cols="8" class="card p-3 m-1">
        <div class="text-center" style="color: #469AE0;">
          <h4>
            <i class="fa fa-spin fa-circle-notch" />
            Chargement...
          </h4>
        </div>
      </b-col>

      <template v-else>
        <b-col cols="4" class="card p-3 m-1">
          <div class="d-flex">
            <h6>
              <span>Résidents avec un gain sur la période * :</span>
              <span><strong>{{ calculateResidentStatusChange.gain }}</strong></span>
            </h6>
          </div>
          <p class="small">* sur les résidents ayant eu au moins deux diagnostics pendant la période considérée :
            un gain est une amélioration de l"état nutritionnel du résident</p>
          <div class="d-flex">
            <h6>
              <span>Résidents avec une perte sur la période * :</span>
              <span><strong>{{ calculateResidentStatusChange.loss }}</strong></span>
            </h6>
          </div>
          <p class="small">* sur les résidents ayant eu au moins deux diagnostics pendant la période considérée :
            une perte est une dégradation de l"état nutritionnel du résident</p>
          <div class="d-flex">
            <h6>
              <span>Résidents stables sur la période * :</span>
              <span><strong>{{ calculateResidentStatusChange.stable }}</strong></span>
            </h6>
          </div>
          <p class="small">* sur les résidents ayant eu au moins deux diagnostics pendant la période considérée</p>
        </b-col>
        <b-col cols="4" class="card p-3 m-1">
          <div class="d-flex justify-content-center">
            <h6>
              <pie-chart
                  height="250px"
                  width="250px"
                  suffix="%"
                  :colors="['#32B796', '#EE5F5B', '#3366CC']"
                  :library="options"
                  :data="[
                    ['Gain', calculateResidentStatusChangeInPercent.gain],
                    ['Perte', calculateResidentStatusChangeInPercent.loss],
                    ['Stable', calculateResidentStatusChangeInPercent.stable]
                  ]"
                ></pie-chart>
            </h6>
          </div>
        </b-col>
      </template>
    </b-row>

    <b-row class="justify-content-center">
      <b-col v-if="calculateTotalGainsAndLossesLevelsLoading" cols="4" class="card p-3 m-1">
        <div class="text-center" style="color: #469AE0;">
          <h4>
            <i class="fa fa-spin fa-circle-notch" />
            Chargement...
          </h4>
        </div>
      </b-col>
      <b-col v-else cols="4" class="card p-3 m-1">
        <div class="d-flex">
          <h6>
            <span>Nombre de niveaux gagnés * :</span>
            <span><strong>{{ calculateTotalGainsAndLossesLevels.total_gains }}</strong></span>
          </h6>
        </div>
        <p class="small">* sur les résidents ayant eu au moins deux diagnostics pendant la période considérée</p>
        <div class="d-flex">
          <h6>
            <span>Nombre de niveaux perdus * :</span>
            <span><strong>{{ calculateTotalGainsAndLossesLevels.total_losses }}</strong></span>
          </h6>
        </div>
        <p class="small">* sur les résidents ayant eu au moins deux diagnostics pendant la période considérée</p>
      </b-col>

      <b-col v-if="calculateAverageGainAndLossDaysLoading" cols="4" class="card p-3 m-1">
        <div class="text-center" style="color: #469AE0;">
          <h4>
            <i class="fa fa-spin fa-circle-notch" />
            Chargement...
          </h4>
        </div>
      </b-col>
      <b-col v-else cols="4" class="card p-3 m-1">
        <div class="d-flex">
          <h6>
            <span>Nombre moyen de jour pour gagner 1 niveau * :</span>
            <span><strong>{{ calculateAverageGainAndLossDays.average_gain_days }}</strong></span>
          </h6>
        </div>
        <p class="small">* sur les résidents ayant eu au moins deux diagnostics pendant la période considérée</p>
        <div class="d-flex">
          <h6>
            <span>Nombre moyen de jour pour perdre 1 niveau * :</span>
            <span><strong>{{ calculateAverageGainAndLossDays.average_loss_days }}</strong></span>
          </h6>
        </div>
        <p class="small">** sur les résidents ayant eu au moins deux diagnostics pendant la période considérée</p>
      </b-col>
    </b-row>

    <b-row class="justify-content-center">
      <b-col cols="6" class="card p-3 m-1">
        <div class="d-flex justify-content-center">
          <h6>
            Progression des diagnostics des nouveaux résidents *
          </h6>
        </div>

        <average-diags-results-for-new-residents
          :start-date="startDate"
          :end-date="endDate"
        ></average-diags-results-for-new-residents>

      </b-col>
    </b-row>

    <app-residents-for-sample-details-modal
      :data="this.residentsForSample"
      id="residents-for-sample-details-modal"
      hide-footer
    />
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { validationMixin } from "vuelidate";
import CustomDatePicker from "../custom/CustomDatePicker.vue";
import AverageDiagnostics from "../stats/AverageDiagnostics.vue";
import AverageDiagsResultsForNewResidents from "../stats/AverageDiagsResultsForNewResidents.vue";
import PercentageByState from "../stats/PercentageByState.vue";
import ResidentsForSampleDetailsModal from "../stats/ResidentsForSampleDetailsModal.vue";

import {
  FETCH_RESIDENTS_FOR_SAMPLE,
  FETCH_CALCULATE_RESIDENT_STATUS_CHANGE,
  FETCH_CALCULATE_TOTAL_GAINS_AND_LOSSES_LEVELS,
  FETCH_CALCULATE_AVERAGE_GAIN_AND_LOSS_DAYS
} from "../../graphql/stats/queries";

export default {
  name: "EhpadAdvancedStats",
  mixins: [validationMixin],
  components: {
    CustomDatePicker,
    AverageDiagnostics,
    AverageDiagsResultsForNewResidents,
    PercentageByState,
    appResidentsForSampleDetailsModal: ResidentsForSampleDetailsModal,
  },
  data() {
    return {
      options: {
        plugins: {
          datalabels: {
            color: "white",
          },
        },
      },
      startDate: null,
      endDate: null,
      residentsForSampleLoading: true,
      calculateResidentStatusChangeLoading: true,
      calculateTotalGainsAndLossesLevelsLoading: true,
      calculateAverageGainAndLossDaysLoading: true,
    };
  },
  created() {
    this.startDate = this.sixMonthsAgo;
    this.endDate = this.today;
  },
  methods: {
    onDatesSelected(dates) {
      this.startDate = dates.start;
      this.endDate = dates.end;
    },
  },
  apollo: {
    residentsForSample: {
      query: FETCH_RESIDENTS_FOR_SAMPLE,
      variables() {
        this.residentsForSampleLoading = true;
        return {
          ehpadId: this.currentEhpad.id,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "network-only",
      result() {
        this.residentsForSampleLoading = false;
      },
      watchQuery: {
        variables(newVariables) {
          return {
            ehpadId: newVariables.currentEhpad.id,
            startDate: newVariables.startDate,
            endDate: newVariables.endDate,
          };
        },
      },
    },
    calculateResidentStatusChange: {
      query: FETCH_CALCULATE_RESIDENT_STATUS_CHANGE,
      variables() {
        this.calculateResidentStatusChangeLoading = true;
        return {
          ehpadId: this.currentEhpad.id,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "network-only",
      result() {
        this.calculateResidentStatusChangeLoading = false;
      },
      watchQuery: {
        variables(newVariables) {
          return {
            ehpadId: newVariables.currentEhpad.id,
            startDate: newVariables.startDate,
            endDate: newVariables.endDate,
          };
        },
      },
    },
    calculateTotalGainsAndLossesLevels: {
      query: FETCH_CALCULATE_TOTAL_GAINS_AND_LOSSES_LEVELS,
      variables() {
        this.calculateTotalGainsAndLossesLevelsLoading = true;
        return {
          ehpadId: this.currentEhpad.id,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "network-only",
      result() {
        this.calculateTotalGainsAndLossesLevelsLoading = false;
      },
      watchQuery: {
        variables(newVariables) {
          return {
            ehpadId: newVariables.currentEhpad.id,
            startDate: newVariables.startDate,
            endDate: newVariables.endDate,
          };
        },
      },
    },
    calculateAverageGainAndLossDays: {
      query: FETCH_CALCULATE_AVERAGE_GAIN_AND_LOSS_DAYS,
      variables() {
        this.calculateAverageGainAndLossDaysLoading = true;
        return {
          ehpadId: this.currentEhpad.id,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "network-only",
      result() {
        this.calculateAverageGainAndLossDaysLoading = false;
      },
      watchQuery: {
        variables(newVariables) {
          return {
            ehpadId: newVariables.currentEhpad.id,
            startDate: newVariables.startDate,
            endDate: newVariables.endDate,
          };
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: "ehpad",
    }),
    today() {
      const now = new Date();
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return moment(date).format("YYYY-MM-DD");
    },
    sixMonthsAgo() {
      const now = new Date();
      const date = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      return moment(date).format("YYYY-MM-DD");
    },
    calculateResidentStatusChangeInPercent() {
      if (this.residentsForSample.length > 0) {
        return {
          gain: parseFloat(((this.calculateResidentStatusChange.gain / this.residentsForSample.length) * 100).toFixed(1)),
          loss: parseFloat(((this.calculateResidentStatusChange.loss / this.residentsForSample.length) * 100).toFixed(1)),
          stable: parseFloat(((this.calculateResidentStatusChange.stable / this.residentsForSample.length) * 100).toFixed(1))
        };
      }
      return {
        gain: 0,
        loss: 0,
        stable: 0
      };
    },
  },
};
</script>

<style lang="scss" scoped>

:target {
  display: block;
  position: relative;
  top: -11.5rem;
  visibility: hidden;
}

.card p {
  color: #BFBFBF;
}

</style>
