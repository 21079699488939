<template>
  <ul>
    <app-resident-dish
      v-for="residentMealDish in sortedDishesAndBuffets"
      :key="residentMealDish.id"
      :residentMealDish="residentMealDish"
      :menuEditState="menuEditState"
    />
    <li v-if="residentMealDishes.length === 0">Aucun plat</li>
  </ul>
</template>

<script>
import _ from 'lodash';

import ResidentDish from './Dish.vue';

export default {
  name: 'ResidentDishes',
  props: ['residentMealDishes', 'menuEditState'],
  components: {
    appResidentDish: ResidentDish,
  },
  methods: {
    sortDishesAndBuffets(type) {
      const sortedDishesAndBuffets = _.sortBy(this.residentMealDishes, (el) => (el.buffet && el.buffet.name) || (el.dish && el.dish.name));
      return sortedDishesAndBuffets.filter((el) => (el.dish && el.dish.dType === type) || (el.buffet && el.buffet.dType === type));
    },
  },
  computed: {
    sortedDishesAndBuffets() {
      return [
        ...this.sortDishesAndBuffets('other'),
        ...this.sortDishesAndBuffets('starter'),
        ...this.sortDishesAndBuffets('main_course'),
        ...this.sortDishesAndBuffets('dairy'),
        ...this.sortDishesAndBuffets('desert'),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
