<template>
  <div>
    <p class="font-weight-bold text-center m-0" id="title">{{ meal.mealCategory.humanType }}
      <span v-if="currentUser.role == 'administrateur' || currentUser.role == 'intendance'" class="font-weight-normal small">
        |
        <span class="price">{{ meal.totalPrice }} €</span>
      </span>
      <a class="float-right" :href="`/ehpads/${this.currentEhpad.shortname}/${this.meal.id}/download.xlsx`" target="_blank"
        v-b-tooltip.hover.v-primary title="Exporter le tableau d'organisation des résidents"
      >
        <i class="far fa-file-excel" />
      </a>
    </p>

    <app-dishes
      :mealDishes="meal.mealDishes"
      v-if="meal.mealDishes"
      :menuEditState="menuEditState"
    />
    <p class="text-center" v-else><i class="fa fa-circle-notch fa-spin" /> Chargement...</p>

    <b-form class="d-flex mt-2" v-if="menuEditState">

      <app-dishes-buffets-multiselect
        :options="activeDishesAndBuffetsByTypeOptions"
        :onChange="setDishOrBuffet"
        :dataToWatch="dishOrBuffet"
      />

      <b-button
        variant="primary"
        @click.prevent="onClick"
        :disabled="loading"
        v-b-tooltip.hover.v-primary
        title="Ajouter un plat"
      >
        <i :class="loading ? 'fa fa-spin fa-circle-notch' : 'fa fa-plus'" />
      </b-button>
    </b-form>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Dishes from './Dishes.vue';
import EventBus from '../../utilities/event_bus';
import { CREATE_MEAL_DISH } from '../../graphql/meal_dishes/mutations';
import DishesBuffetsMultiselect from '../shared/DishesBuffetsMultiselect.vue';

export default {
  name: 'Meal',
  props: ['type', 'meal', 'menuEditState'],
  components: {
    appDishes: Dishes,
    appDishesBuffetsMultiselect: DishesBuffetsMultiselect,
  },
  data() {
    return {
      loading: false,
      dishOrBuffet: null,
    };
  },
  computed: {
    ...mapGetters({
      activeDishesAndBuffetsByType: 'activeDishesAndBuffetsByType',
      currentUser: 'user',
      currentEhpad: 'ehpad',
    }),
    activeDishesAndBuffetsByTypeOptions() {
      return this.activeDishesAndBuffetsByType.map((el) => ({
        text: `${el.name} (${el.humanType})`,
        id: el.id,
        proteins: el.defaultProteins,
        calories: el.defaultCalories,
        hydration: el.defaultHydration,
        type: el.__typename,
      }));
    },
  },
  methods: {
    setDishOrBuffet(value) {
      this.dishOrBuffet = value;
    },
    async onClick() {
      if (this.dishOrBuffet === null) return;
      this.loading = true;
      await this.$apollo.mutate({
        mutation: CREATE_MEAL_DISH,
        variables: {
          mealId: this.meal.id,
          dishOrBuffetId: this.dishOrBuffet.id,
          type: this.dishOrBuffet.type,
        },
      }).then((result) => {
        EventBus.$emit('refetch-menus');
        this.dishOrBuffet = null;
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.is-valid {
  background-image: none !important;
}

.is-invalid {
  background-image: none !important;
}

.price {
  color: gray;
}
</style>
