<template>
  <b-col>
    <span class="d-flex">
      <li class="mb-2">{{ testType.name }}</li>
    </span>
    <dl class="row">
        <dd class="col-8">
          <span style="font-size: smaller;">Expiration (en jours) :</span>
        </dd>
        <dt class="col-4">
          <b-form
            class="d-flex align-items-center"
            @submit.stop.prevent="updateTestTypeSettings"
          >

            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="editableAlertTreshold"
              :class="{ 'is-invalid': $v.editableAlertTreshold.$error }"
            />

            <b-input-group-append>
              <b-button
                variant="primary"
                size="sm"
                @click="updateTestTypeSettings"
                :disabled="updating"
              >
                <i :class="updatingClasses" />
              </b-button>
            </b-input-group-append>
          </b-form>
        </dt>
    </dl>
  </b-col>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, integer } from 'vuelidate/lib/validators';

export default {
  name: 'TestTypeChildSettingsForm',
  props: ['diagnosticState', 'testType'],
  mixins: [validationMixin],
  created() {
    this.editableAlertTreshold = this.testType.allSettings[this.diagnosticState].alert_treshold;
  },
  validations() {
    return {
      editableAlertTreshold: {
        required,
        integer,
      },
    };
  },
  data() {
    return {
      editableAlertTreshold: null,
      updating: false,
    };
  },
  methods: {
    async updateTestTypeSettings() {
      this.$v.editableAlertTreshold.$touch();
      if (this.$v.$anyError) return;
      this.updating = true;
      await this.$store.dispatch('updateTestTypeSettings', {
        id: this.testType.id,
        diagnosticState: this.diagnosticState,
        alertTreshold: parseInt(this.editableAlertTreshold, 10),
      }).then((result) => {
        this.$v.$reset();
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      this.updating = false;
    },
    name(setting) {
      return eval(`this.${setting}.name`);
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    updatingClasses() {
      return this.updating ? 'fa fa-circle-notch fa-spin' : 'fa fa-check';
    },
  },
};
</script>

<style lang="scss" scoped></style>
