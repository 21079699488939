<template>
  <div>
    <b-form>
      <b-row class="mb-1">
        <b-col cols="4" class="my-auto">
          <label for="rating-inline" class="font-size: x-large;"><b>{{ gustatoryCategory.name }}</b></label>
        </b-col>
        <b-col cols="8">
          <b-form-rating
            :id="gustatoryCategory.name"
            inline
            v-model="value"
            disabled
          >
          </b-form-rating>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'Gustatory-Items-Disabled',
  props: ['resident', 'gustatoryCategory'],
  data() {
    return {
      value: 0,
    };
  },
  created() {
    this.value = 0;
    if (this.resident.gustatoryPerceptions !== null &&
        this.resident.gustatoryPerceptions.length > 0) {
      const obj = _.find(this.resident.gustatoryPerceptions, { gustatoryCategoryId: this.gustatoryCategory.id });
      this.value = _.get(obj, 'value', 0);
    }
  },
  watch: {
    resident: {
      handler() {
        this.value = 0;
        if (this.resident.gustatoryPerceptions !== null &&
            this.resident.gustatoryPerceptions.length > 0) {
          const obj = _.find(this.resident.gustatoryPerceptions, { gustatoryCategoryId: this.gustatoryCategory.id });
          this.value = _.get(obj, 'value', 0);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
