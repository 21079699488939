import gql from 'graphql-tag';

export const CREATE_RESIDENT_MEAL_DISH = gql`
  mutation CREATE_RESIDENT_MEAL_DISH($residentMealId: ID!, $dishOrBuffetId: ID!, $type: String!) {
    createResidentMealDish(input: { residentMealId: $residentMealId, dishOrBuffetId: $dishOrBuffetId, type: $type }) {
      residentMealDish {
        id
      }
    }
  }
`;

export const UPDATE_RESIDENT_MEAL_DISH = gql`
  mutation UPDATE_RESIDENT_MEAL_DISH($id: ID!, $ingesta: Float, $comment: String, $prefilled: Boolean!) {
    updateResidentMealDish(input: { id: $id, ingesta: $ingesta, comment: $comment, prefilled: $prefilled }) {
      residentMealDish {
        id
      }
    }
  }
`;

export const DESTROY_RESIDENT_MEAL_DISH = gql`
  mutation DESTROY_RESIDENT_MEAL_DISH($id: ID!) {
    destroyResidentMealDish(input: { id: $id }) {
      residentMealDish {
        id
      }
    }
  }
`;

export const EMPTY_RESIDENT_MEAL = gql`
  mutation EMPTY_RESIDENT_MEAL($id: ID!) {
    emptyResidentMeal(input: { id: $id }) {
      residentMeal {
        id
        filteredResidentMealDishes {
          id
        }
      }
    }
  }
`;

export const CREATE_RESIDENT_MEAL_DISH_FOR_BUFFET_INGESTA = gql`
  mutation CREATE_RESIDENT_MEAL_DISH_FOR_BUFFET_INGESTA($id: ID!, $dishId: ID!, $ingesta: Float!, $prefilled: Boolean!) {
    createResidentMealDishForBuffetIngesta(input: { id: $id, dishId: $dishId, ingesta: $ingesta, prefilled: $prefilled }) {
      residentMealDish {
        id
      }
    }
  }
`;
