<template>
  <div>
    <b-form>
      <dl class="row">
        <dd class="col-6 text-nowrap my-1">Prénom :</dd>
        <dt class="col-6">
          <div v-if="editState">
            <b-form-input
              size="sm"
              v-model="editableResident.firstName"
              :class="{'is-invalid': $v.editableResident.firstName.$error}"
            />
          </div>
          <div v-else>
            <label class="my-1">{{ resident.firstName }}</label>
          </div>
        </dt>

        <dd class="col-6 text-nowrap my-1">Nom :</dd>
        <dt class="col-6">
          <div v-if="editState">
            <b-form-input
              size="sm"
              v-model="editableResident.name"
              :class="{'is-invalid': $v.editableResident.name.$error}"
            />
            <b-form-invalid-feedback>Nom requis</b-form-invalid-feedback>
          </div>
          <div v-else>
            <label class="my-1">{{ resident.name }}</label>
          </div>
        </dt>

        <dd class="col-6 text-nowrap my-1">Sexe :</dd>
        <dt class="col-6">
          <div v-if="editState">
            <b-form-select
              size="sm"
              v-model="editableResident.gender"
              :options="genderOptions"
              :class="{'is-invalid': $v.editableResident.gender.$error}"
            />
            <b-form-invalid-feedback>Sexe requis</b-form-invalid-feedback>
          </div>
          <div v-else>
            <label class="my-1">{{ resident.gender }}</label>
          </div>
        </dt>

        <dd class="col-6 text-nowrap my-1">Taille :</dd>
        <dt class="col-6">
          <div v-if="editState">
            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="editableResident.height"
              :class="{'is-invalid': $v.editableResident.height.$error}"
            />
            <b-form-invalid-feedback>Taille requise</b-form-invalid-feedback>
          </div>
          <div v-else>
            <label class="my-1">{{ resident.height }} cm</label>
          </div>
        </dt>

        <dd class="col-6 text-nowrap my-1">Poids actuel :</dd>
        <dt class="col-6">
          <div>
            <label class="my-1">{{ resident.lastWeight && resident.lastWeight.value }} Kg</label>
          </div>
        </dt>

        <dd class="col-6 text-nowrap my-1">Poids de forme :</dd>
        <dt class="col-6">
          <div v-if="editState">
            <b-form-input
              size="sm"
              type="number"
              min="0"
              v-model="editableResident.idealWeight"
            />
          </div>
          <div v-else>
            <label class="my-1">{{ idealWeightFormatter(resident.idealWeight) }}</label>
          </div>
        </dt>

        <dd class="col-6 text-nowrap m-auto" style="font-size: small;">Poids du matériel :
         <i
            class="fas fa-exclamation-triangle d-print-none"
            style="color: red;"
            v-b-tooltip.hover.v-danger
            title="Renseigner ici le poids du fauteuil roulant et autre matériel avec lequel le résident est pesé.
            Attention : Cette valeur sera automatiquement soustraite lorsqu'un nouveau poids sera enregistré"
          />
        </dd>
        <dt class="col-6">
          <div v-if="editState">
            <b-form-input
              size="sm"
              style="font-size: smaller;"
              type="number"
              min="0"
              v-model="editableResident.equipmentWeight"
            />
          </div>
          <div v-else>
            <label class="my-1" style="font-size: smaller;">{{ equipmentWeightFormatter(resident.equipmentWeight) }}</label>
          </div>
        </dt>

        <dd class="col-6 text-nowrap my-1">Lit :</dd>
        <dt class="col-6">
          <b-form-select size="sm" v-if="editState" v-model="editableResident.roomId" class="rooms-list-select">
            <template #first>
              <b-form-select-option :value="null" disabled>{{ roomsSelectPrompt }}</b-form-select-option>
            </template>
            <b-form-select-option
              v-for="room in rooms"
              :value="room.id"
              :key="room.id"
              :disabled="!room.isFree"
            >N° {{ room.number }}</b-form-select-option>
          </b-form-select>
          <div v-else>
            <label class="my-1">{{ selectedRoomLabel }}</label>
          </div>
        </dt>

        <template v-if="lastRoomId === null && editableResident.roomId !== null && editableResident.lastStatus !== null">
          <dd class="col-6 text-nowrap my-1">Date d'entrée :</dd>
          <dt class="col-6">
            <b-form-datepicker
              id="date-datepicker-status"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              label-help
              label-no-date-selected="Aucune date sélectionnée"
              size="sm"
              :max="today"
              :min="lastStatusDatePlusOneDay"
              v-model="entryDate"
              locale="fr"
            />
          </dt>
        </template>

      <template v-if="this.currentEhpad.allSettings.configuration.sectors.activate_sectors">
        <dd class="col-6 text-nowrap my-1">Secteur :</dd>
        <dt class="col-6">
          <b-form-select size="sm" v-if="editState" v-model="editableResident.sectorId">
            <template #first>
              <b-form-select-option :value="null" default>Pas de secteur affecté</b-form-select-option>
            </template>
            <b-form-select-option
              v-for="sector in sectors"
              :value="sector.id"
              :key="sector.id"
            >{{ sector.name }}</b-form-select-option>
          </b-form-select>
          <div v-else>
            <label class="my-1">{{ sectorFormatter(resident.sector) }}</label>
          </div>
        </dt>
      </template>

      <template v-if="editableResident.lastStatus !== null && editableResident.roomId === null">
        <dd class="col-6 text-nowrap my-1">Status :</dd>
        <dt class="col-6">
          <label class="my-1">{{editableResident.lastStatus.statusCategory.name}}</label>
        </dt>
      </template>

      <template v-if="editableResident.lastStatus !== null && editableResident.roomId === null && editableResident.lastStatus.description !== null">
        <dd class="col-6 text-nowrap my-1">Informations :</dd>
        <dt class="col-6">
          <label class="my-1">{{editableResident.lastStatus.description}}</label>
        </dt>
      </template>

      </dl>

      <b-button variant="danger" size="sm" @click="cancelEdit" v-if="editState">Annuler</b-button>
      <b-button variant="primary" size="sm" @click="updateResident" v-if="editState">Valider</b-button>
      <div class="d-print-none" v-else>
        <div v-if="!readonly">
          <b-button variant="primary" size="sm" @click="editState = true;">
            <i class="fas fa-edit" />
            Editer
          </b-button>
          <b-button variant="danger" size="sm" v-b-modal.resident-status-modal v-if="editableResident.room">
            <i class="fas fa-sign-out-alt" />
            Sortie du résident
          </b-button>
        </div>
      </div>
    </b-form>

    <app-resident-status-modal
      v-if="editableResident.room"
      id="resident-status-modal"
      hide-footer
      :editableResident="editableResident"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import _ from 'lodash';
import moment from 'moment';
import ResidentStatusModal from './ResidentStatusModal.vue';

export default {
  props: ['resident', 'readonly'],
  mixins: [validationMixin],
  components: {
    appResidentStatusModal: ResidentStatusModal,
  },
  data() {
    return {
      editState: false,
      editableResident: null,
      lastRoomId: null,
      entryDate: null,
    };
  },
  watch: {
    resident: {
      handler() {
        this.duplicateResident(this.resident);
      },
      immediate: true,
    },
  },
  created() {
    this.duplicateResident(this.resident);
    this.entryDate = this.today;
    this.lastRoomId = this.editableResident.roomId;
  },
  validations: {
    editableResident: {
      name: {
        required,
      },
      firstName: {
        required,
      },
      gender: {
        required,
      },
      height: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      rooms: 'rooms',
      sectors: 'sectors',
    }),
    genderOptions() {
      return [
        { value: null, text: 'Selectionnez un genre', disabled: true },
        'homme',
        'femme',
      ];
    },
    roomsSelectPrompt() {
      return (_.find(this.rooms, { isFree: true }))
        ? 'Choisir un lit'
        : 'Aucun lit disponible';
    },
    selectedRoomLabel() {
      const { room } = this.resident;
      return room ? `N° ${room.number}` : 'Aucun lit assigné';
    },
    today() {
      const now = new Date();
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return moment(date).format('YYYY-MM-DD');
    },
    lastStatusDatePlusOneDay() {
      return moment(this.editableResident.lastStatus.date).add(1, 'd').format('YYYY-MM-DD');
    },
  },
  methods: {
    ...mapActions(['setRooms']),
    idealWeightFormatter(idealWeight) {
      if (idealWeight === null) { return 'A renseigner !'; }
      return `${idealWeight} Kg`;
    },
    equipmentWeightFormatter(equipmentWeight) {
      if (equipmentWeight === null) { return 'Optionnel'; }
      return `${equipmentWeight} Kg`;
    },
    sectorFormatter(sector) {
      if (sector === null) { return 'Pas de secteur affecté'; }
      return sector.name;
    },
    duplicateResident(resident) {
      const roomId = resident && resident.room && resident.room.id;
      const sectorId = resident && resident.sector && resident.sector.id;
      this.editableResident = { ...resident, roomId, sectorId };
    },
    cancelEdit() {
      this.editState = false;
      this.duplicateResident(this.resident);
    },
    async updateResident() {
      this.$v.editableResident.$touch();
      if (this.$v.$anyError) return;
      this.editState = false;
      await this.$store.dispatch('updateResident', {
        id: this.editableResident.id,
        name: this.editableResident.name,
        firstName: this.editableResident.firstName,
        gender: this.editableResident.gender,
        height: parseInt(this.editableResident.height, 10),
        idealWeight: parseFloat(this.editableResident.idealWeight),
        equipmentWeight: parseFloat(this.editableResident.equipmentWeight),
        nutritionalInfo: this.editableResident.nutritionalInfo,
        diet: this.editableResident.diet,
        requireHelp: this.editableResident.requireHelp,
        roomId: this.editableResident.roomId,
        sectorId: this.editableResident.sectorId,
        entryDate: this.entryDate,
      }).then((result) => {
        this.duplicateResident(result);
        this.$v.$reset();
        this.$emit('updated-resident');
      }).catch((error) => {
        // For resetting form with current values :
        this.duplicateResident(this.resident);
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      await this.setRooms(this.currentEhpad.id);
    },
  },
};
</script>

<style lang="scss" scoped>
  dd {
    font-size: medium;
  }
  .rooms-list-select option {
    color: black;
    font-weight: bold;
  }
  .rooms-list-select option:disabled {
    color: rgb(170, 170, 170) !important;
    font-weight: normal !important;
  }
</style>
