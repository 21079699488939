<template>
  <b-modal v-bind="$attrs" title="Ajouter un commentaire" class="col-12 text-center">
    <b-container fluid>
      <div v-if="resident !== null">RESIDENT : {{ resident.fullName }}</div>
      <div v-if="!$apollo.loading && residentMealDish !== null">
        PLAT : {{ capitalizeString((residentMealDish.dish && residentMealDish.dish.name) || (residentMealDish.buffet && residentMealDish.buffet.name)) }}
      </div>
      <b-form>
        <div class="pt-2">
          <b-form-textarea
            id="comment"
            v-model="comment"
            type="text"
            size="sm"
            rows="4"
            placeholder="Saisissez un commentaire pour ce plat"
          />
        </div>

        <div v-if="error" style="color: red;" class="text-center mt-2">
          <p v-for="(error, i) of error.graphQLErrors" :key="i">
            {{ error.message }}
          </p>
        </div>

        <b-button
          class="mt-3 d-flex mx-auto"
          variant="primary"
          size="sm"
          @click.prevent="addComment"
          >Valider</b-button
        >
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_RESIDENT_MEAL_DISH } from '../../graphql/resident_meal_dishes/mutations';
import { capitalizeString } from '../../utilities/filters';

export default {
  props: ['residentMealDish'],
  data() {
    return {
      error: null,
      comment: '',
    };
  },
  created() {
    this.comment = this.residentMealDish.comment;
  },
  methods: {
    capitalizeString,
    async addComment() {
      await this.$apollo.mutate({
        mutation: UPDATE_RESIDENT_MEAL_DISH,
        variables: {
          id: this.residentMealDish.id,
          ingesta: this.residentMealDish.ingesta,
          prefilled: this.residentMealDish.prefilled,
          comment: this.comment,
        },
      }).then((result) => {
        this.error = null;
        this.$bvModal.hide(`add-comment-modal${this.residentMealDish.id}`);
      }).catch((error) => {
        this.error = error;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      resident: 'resident',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
