<template>
  <div>
    <b-form>
      <b-input-group size="sm" class="mb-2">
        <b-form-input
          v-model="weight.value"
          type="number"
          min="0"
          required
          size="sm"
          placeholder="Saisissez un poids"
          :class="{'is-invalid': $v.weight.value.$error}"
        />

        <b-form-datepicker
          id="date-datepicker-weight"
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
          label-help
          label-no-date-selected="Aucune date sélectionnée"
          :max="today"
          size="sm"
          v-model="weight.date"
          locale="fr"
          :class="{'is-invalid': $v.weight.date.$error}"
        />

        <b-input-group-append>
          <b-button variant="primary" @click="createWeight" :disabled="loading">
            <i :class="loading ? 'fa fa-spin fa-circle-notch' : 'fa fa-plus'" />
          </b-button>
        </b-input-group-append>
          <i
            v-if="(resident.equipmentWeight !== null) && (resident.equipmentWeight !== 0)"
            class="fas fa-exclamation-triangle m-1"
            style="color: red;"
            v-b-tooltip.hover.v-danger
            :title="'Le poids enregistré doit inclure le fauteuil ou autre matériel.'+ resident.equipmentWeight + ' kg seront soustrait au poids renseigné.'"
          />
      </b-input-group>
    </b-form>
    <b-form>
      <b-input-group size="sm" class="mb-2">
        <b-form-input
          v-model="mna.value"
          type="number"
          min="0"
          required
          size="sm"
          placeholder="Saisissez une MNA"
          :class="{'is-invalid': $v.mna.value.$error}"
        />

        <b-form-datepicker
          id="date-datepicker-mna"
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
          label-help
          label-no-date-selected="Aucune date sélectionnée"
          :max="today"
          size="sm"
          v-model="mna.date"
          locale="fr"
          :class="{'is-invalid': $v.mna.date.$error}"
        />

        <b-input-group-append>
          <b-button variant="primary" @click="createMna" :disabled="loading">
            <i :class="loading ? 'fa fa-spin fa-circle-notch' : 'fa fa-plus'" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
    <b-form>
      <b-input-group size="sm" class="mb-2">
        <b-form-input
          v-model="crp.value"
          type="number"
          min="0"
          required
          size="sm"
          placeholder="Saisissez une CRP"
          :class="{'is-invalid': $v.crp.value.$error}"
        />

        <b-form-datepicker
          id="date-datepicker-crp"
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
          label-help
          label-no-date-selected="Aucune date sélectionnée"
          :max="today"
          size="sm"
          v-model="crp.date"
          locale="fr"
          :class="{'is-invalid': $v.crp.date.$error}"
        />

        <b-input-group-append>
          <b-button variant="primary" @click="createCrp" :disabled="loading">
            <i :class="loading ? 'fa fa-spin fa-circle-notch' : 'fa fa-plus'" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
    <b-form>
      <b-input-group size="sm" class="mb-2">
        <b-form-input
          v-model="albumine.value"
          type="number"
          min="0"
          required
          size="sm"
          placeholder="Saisissez une Albumine"
          :class="{'is-invalid': $v.albumine.value.$error}"
        />

        <b-form-datepicker
          id="date-datepicker-albumine"
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
          label-help
          label-no-date-selected="Aucune date sélectionnée"
          :max="today"
          size="sm"
          v-model="albumine.date"
          locale="fr"
          :class="{'is-invalid': $v.albumine.date.$error}"
        />

        <b-input-group-append>
          <b-button variant="primary" @click="createAlbumine" :disabled="loading">
            <i :class="loading ? 'fa fa-spin fa-circle-notch' : 'fa fa-plus'" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
  props: ['resident'],
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      weight: {
        value: null,
        date: null,
      },
      mna: {
        value: null,
        date: null,
      },
      crp: {
        value: null,
        date: null,
      },
      albumine: {
        value: null,
        date: null,
      },
    };
  },
  created() {
    this.weight.date = this.today;
    this.mna.date = this.today;
    this.crp.date = this.today;
    this.albumine.date = this.today;
  },
  validations: {
    weight: {
      value: {
        required,
      },
      date: {
        required,
      },
    },
    crp: {
      value: {
        required,
      },
      date: {
        required,
      },
    },
    mna: {
      value: {
        required,
      },
      date: {
        required,
      },
    },
    albumine: {
      value: {
        required,
      },
      date: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    today() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    },
  },
  methods: {
    async createWeight() {
      this.$v.weight.$touch();
      if (this.$v.$anyError) return;
      this.loading = true;
      await this.$store.dispatch('createWeight', {
        value: parseFloat(this.weight.value),
        // Formatage de base de rails
        date: moment(this.weight.date).format('YYYY-MM-DD'),
        residentId: this.resident.id,
      }).then((result) => {
        this.$v.$reset();
        this.weight.value = null;
        this.$emit('updated-resident');
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      this.loading = false;
    },
    async createMna() {
      this.$v.mna.$touch();
      if (this.$v.$anyError) return;
      this.loading = true;
      await this.$store.dispatch('createMna', {
        value: parseInt(this.mna.value, 10),
        date: moment(this.mna.date).format('YYYY-MM-DD'),
        residentId: this.resident.id,
      }).then((result) => {
        this.$v.$reset();
        this.mna.value = null;
        this.$emit('updated-resident');
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      this.loading = false;
    },
    async createCrp() {
      this.$v.crp.$touch();
      if (this.$v.$anyError) return;
      this.loading = true;
      await this.$store.dispatch('createCrp', {
        value: parseFloat(this.crp.value),
        date: moment(this.crp.date).format('YYYY-MM-DD'),
        residentId: this.resident.id,
      }).then((result) => {
        this.$v.$reset();
        this.crp.value = null;
        this.$emit('updated-resident');
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      this.loading = false;
    },
    async createAlbumine() {
      this.$v.albumine.$touch();
      if (this.$v.$anyError) return;
      this.loading = true;
      await this.$store.dispatch('createAlbumine', {
        value: parseFloat(this.albumine.value),
        date: moment(this.albumine.date).format('YYYY-MM-DD'),
        residentId: this.resident.id,
      }).then((result) => {
        this.$v.$reset();
        this.albumine.value = null;
        this.$emit('updated-resident');
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
