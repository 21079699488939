<template>
  <b-col>
    <span class="d-flex">
    <li class="mb-2"> {{ name(state) }} </li>
      <i
        v-if="message(state) !== undefined"
        class="fas fa-exclamation-triangle mt-1 ml-2"
        style="color: #5bc0de;"
        v-b-tooltip.hover.v-primary
        :title="message(state)"
      />
    </span>
    <dl class="row">
      <template v-for="(value, settingName) in values">
        <dd class="col-8">
          <span style="font-size: smaller;"
            >{{ name(settingName) }}
            <i
              class="fas fa-exclamation-triangle mx-auto mt-2"
              style="color: #5bc0de;"
              v-b-tooltip.hover.v-primary
              v-if="message(settingName) !== undefined"
              :title="message(settingName)"
            />
            :</span>
        </dd>
        <dt class="col-4">
          <b-form
            class="d-flex align-items-center"
            @submit.stop.prevent="
              updateEhpadSetting(settingName, editableSetting[settingName])
            "
          >
            <b-form-group
              v-if="typeof value === 'boolean' || value === 'true' || value === 'false'"
              size="sm"
              style="margin-right: 125px;"
              :class="{ 'is-invalid': $v.editableSetting[settingName].$error }"
            >
              <b-form-radio v-model="editableSetting[settingName]" name="Actif" value="true"
                >Activé</b-form-radio
              >
              <b-form-radio
                v-model="editableSetting[settingName]"
                name="Désactivé"
                value="false"
                >Désactivé</b-form-radio
              >
            </b-form-group>

            <b-input-group
              v-else-if="state === 'default_schedule_time'"
              class="mr-2"
            >
              <b-form-input
                v-model="editableSetting[settingName]"
                type="text"
                placeholder="Heure de passage"
                size="sm"
                @input="editableSetting[settingName] = $event.split(':').slice(0,2).join(':')"
                :class="{ 'is-invalid': $v.editableSetting[settingName].$error }"
              ></b-form-input>
              <b-input-group-append>
                <b-form-timepicker
                  v-model="editableSetting[settingName]"
                  locale="fr"
                  button-only
                  right
                  size="sm"
                  minutes-step="15"
                  @input="editableSetting[settingName] = $event.split(':').slice(0,2).join(':')"
                  :class="{ 'is-invalid': $v.editableSetting[settingName].$error }"
                />
              </b-input-group-append>
            </b-input-group>

            <b-form-input
              v-else
              size="sm"
              v-model="editableSetting[settingName]"
              :class="{ 'is-invalid': $v.editableSetting[settingName].$error }"
            />

            <b-input-group-append>
              <b-button
                variant="primary"
                size="sm"
                @click="updateEhpadSetting(settingName, editableSetting[settingName])"
                :disabled="updating"
              >
                <i :class="updatingClasses" />
              </b-button>
            </b-input-group-append>
          </b-form>
        </dt>
      </template>
    </dl>
  </b-col>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, integer, decimal, helpers } from 'vuelidate/lib/validators';
import { hourCheck } from '../../regex';

const hourRegex = helpers.regex('hourRegex', hourCheck);

export default {
  name: 'ChildSettingsForm',
  props: ['state', 'values', 'rootState'],
  mixins: [validationMixin],
  created() {
    this.editableSetting = this.values;
  },
  validations() {
    if (this.state === 'diagnostic_condition_has_2007') {
      return {
        editableSetting: {
          albumine_treshold: {
            required,
            integer,
          },
          imc_treshold: {
            required,
            integer,
          },
          mna_treshold: {
            required,
            integer,
          },
          weight_loss_one_month_percent: {
            required,
            integer,
          },
          weight_loss_six_month_percent: {
            required,
            integer,
          },
        },
      };
    }
    if (this.state === 'diagnostic_condition_nri') {
      return {
        editableSetting: {
          nri_treshold: {
            required,
            integer,
          },
        },
      };
    }
    if (this.state === 'nutrition_goals') {
      return {
        editableSetting: {
          goals_calories: {
            required,
            integer,
          },
          goals_proteins: {
            required,
            decimal,
          },
          goals_hydration: {
            required,
            integer,
          },
        },
      };
    }
    if (this.state === 'constants_config') {
      return {
        editableSetting: {
          minimum_diagnostic_value_taking_in_days: {
            required,
            integer,
          },
          minimum_weight_value_taking_in_days: {
            required,
            integer,
          },
          minimum_mna_value_taking_in_days: {
            required,
            integer,
          },
          minimum_crp_value_taking_in_days: {
            required,
            integer,
          },
          minimum_albumine_value_taking_in_days: {
            required,
            integer,
          },
        },
      };
    }
    if (this.state === 'nri') {
      return {
        editableSetting: {
          // most_recent_weight_days: {
          //   required,
          //   integer,
          // },
          // most_recent_albumine_days: {
          //   required,
          //   integer,
          // },
          corrected_albumine_factor: {
            required,
            decimal,
          },
          weight_factor: {
            required,
            decimal,
          },
        },
      };
    }
    if (this.state === 'corrected_albumine') {
      return {
        editableSetting: {
          corr_alb_minimum_crp: {
            required,
            integer,
          },
          corr_alb_substract: {
            required,
            integer,
          },
          corr_alb_divider: {
            required,
            integer,
          },
        },
      };
    }
    if (this.state === 'menus_config') {
      return {
        editableSetting: {
          can_edit_in_past: {
            required,
          },
        },
      };
    }
    if (this.state === 'dishes') {
      return {
        editableSetting: {
          use_default_dishes: {
            required,
          },
        },
      };
    }
    if (this.state === 'sectors') {
      return {
        editableSetting: {
          activate_sectors: {
            required,
          },
        },
      };
    }
    if (this.state === 'default_schedule_time') {
      return {
        editableSetting: {
          breakfast: {
            required,
            hourRegex,
          },
          morning_snack: {
            required,
            hourRegex,
          },
          lunch: {
            required,
            hourRegex,
          },
          afternoon_snack: {
            required,
            hourRegex,
          },
          dinner: {
            required,
            hourRegex,
          },
          evening_snack: {
            required,
            hourRegex,
          },
        },
      };
    }
  },
  data() {
    return {
      editableSetting: null,
      updating: false,
      diagnostic_condition_has_2007: {
        name: "Critéres d'état nutritionnel (HAS 2007)",
      },
      diagnostic_condition_nri: {
        name: "Critéres d'état nutritionnel (NRI)",
      },
      nutrition_goals: {
        name: 'Cibles nutritionnelles',
      },
      constants_config: {
        name: 'Durée de validité des mesures',
      },
      moderate_undernutrition: {
        name: 'Dénutrition modérée',
        message: 'Critéres appliqués',
      },
      severe_undernutrition: {
        name: 'Dénutrition sévère',
        message: 'Critéres appliqués',
      },
      high_risk: {
        name: 'A risque élevé',
        message: 'Critéres appliqués',
      },
      default_undernutrition: {
        name: 'Autres états nutritionnels (risques faibles et fin de vie)',
        message: 'Critéres appliqués',
      },
      nri: {
        name: 'NRI',
        message:
          "Formule NRI : Albumine corrigée * Multiplicateur d'albumine) + (poids / poids idéal) * Multiplicateur de poids.",
      },
      corrected_albumine: {
        name: 'Albuminémie corrigée (CRP)',
        message:
          'Formule : Si la derniére CRP > CRP minimum alors Albuminémie corrigée = Albumine + ( (CRP - valeur à soustraire) / diviseur pour le calcul )',
      },
      corr_alb_minimum_crp: {
        name: 'CRP minimum',
        message:
          "Si la CRP est supérieure à cette valeur on corrigera l'albumine",
      },
      corr_alb_divider: {
        name: 'Diviseur pour le calcul',
        message:
          "Diviseur pour le calcul de l'albuminémie corrigée",
      },
      corr_alb_substract: {
        name: 'Valeur à soustraire',
        message:
          "Valeur à soustraire pour le calcul de l'albuminémie corrigée",
      },
      menus_config: {
        name: 'Configuration des menus',
      },
      sectors: {
        name: 'Configuration des secteurs',
      },
      dishes: {
        name: 'Configuration des plats',
      },
      goals_calories: {
        name: 'Cible de calories (en kcal) quotidienne par Kg',
        message:
          'La cible de calories est automatiquement ajustée pour les résidents avec cet état nutritionnel. Les résidents dont la cible a été modifiée à la main ne sont plus ajustés automatiquement.',
      },
      goals_proteins: {
        name: 'Cible de protéines (en g) quotidienne par Kg (Décimal)',
        message:
          'La cible de protéines est automatiquement ajustée pour les résidents avec cet état nutritionnel. Les résidents dont la cible a été modifiée à la main ne sont plus ajustés automatiquement.',
      },
      goals_hydration: {
        name: "Cible de l'apport hydrique (en ml) quotidien par Kg (Décimal)",
        message:
          "La cible de l'apport hydrique est automatiquement ajustée pour les résidents avec cet état nutritionnel. Les résidents dont la cible a été modifiée à la main ne sont plus ajustés automatiquement.",
      },
      albumine_treshold: {
        name: "Seuil pour l'albumine corrigée",
        message:
          "Si la valeur de l'albumine corrigée est en dessous de ce seuil le logiciel proposera cet état nutritionnel.",
      },
      imc_treshold: {
        name: "Seuil pour l'IMC",
        message:
          "Si la valeur de l'IMC est en dessous de ce seuil le logiciel proposera cet état nutritionnel.",
      },
      nri_treshold: {
        name: 'Seuil pour le NRI',
        message:
          'Si la valeur du NRI est en dessous de ce seuil le logiciel proposera cet état nutritionnel.',
      },
      mna_treshold: {
        name: 'Seuil pour le MNA',
        message:
          'Si la valeur du MNA est en dessous de ce seuil le logiciel proposera cet état nutritionnel.',
      },
      minimum_weight_value_taking_in_days: {
        name: 'Expiration du poids (en jours)',
        message:
          'Si le dernier poids enregistré est trop ancien le résident apparaitra en rouge dans le tableau des résidents. Cette valeur est également utilisée pour les indicateurs de controle des mesures.',
      },
      minimum_mna_value_taking_in_days: {
        name: 'Expiration de la MNA (en jours)',
        message:
          'Si la derniére MNA enregistrée est trop ancienne le résident apparaitra en rouge dans le tableau des résidents. Cette valeur est également utilisée pour les indicateurs de controle des mesures.',
      },
      minimum_crp_value_taking_in_days: {
        name: 'Expiration de la CRP (en jours)',
        message:
          'Si la derniére CRP enregistrée est trop ancienne le résident apparaitra en rouge dans le tableau des résidents. Cette valeur est également utilisée pour les indicateurs de controle des mesures.',
      },
      minimum_albumine_value_taking_in_days: {
        name: "Expiration de l'Albumine (en jours)",
        message:
          'Si la derniére Albumine enregistrée est trop ancienne le résident apparaitra en rouge dans le tableau des résidents. Cette valeur est également utilisée pour les indicateurs de controle des mesures.',
      },
      minimum_diagnostic_value_taking_in_days: {
        name: "Expiration des diagnostics (en jours)",
        message:
          'Le diagnostic doit être mis à jour tout les XX jours, sinon la date apparaitra en rouge dans le tableau des résidents. Cette valeur est également utilisée pour les indicateurs de controle des mesures.',
      },
      weight_loss_one_month_percent: {
        name: 'Perte de poids sur un mois (en %)',
        message:
          'Si la perte de poids sur un mois est supérieure à cette valeur (en %) le logiciel proposera cet état nutritionnel.',
      },
      weight_loss_six_month_percent: {
        name: 'Perte de poids sur 6 mois (en %)',
        message:
          'Si la perte de poids sur un mois est supérieure à cette valeur (en %) le logiciel proposera cet état nutritionnel.',
      },
      // most_recent_weight_days: {
      //   name: 'Expiration du poids (en jours)',
      //   message:
      //     'Si le dernier poids enregistré est trop ancien le calcul du NRI ne sera pas possible.',
      // },
      // most_recent_albumine_days: {
      //   name: "Expiration de l'albumine (en jours)",
      //   message:
      //     'Si la derniére albumine enregistrée est trop ancienne le calcul du NRI ne sera pas possible.',
      // },
      corrected_albumine_factor: {
        name: "Multiplicateur d'albumine",
      },
      weight_factor: {
        name: 'Multiplicateur de poids',
      },
      can_edit_in_past: {
        name: 'Modification des menus passés',
        message:
          'Activer ce paramétre permet de modifier les menus passés. Laisser ce parametre désactivé limite la possibilité de modifier les menus passés à 2 semaines maximum',
      },
      use_default_dishes: {
        name: 'Utiliser les plats par défaut du logiciel',
        message:
          'Désactiver ce paramétre et tout les plats intégrés au logiciel ne seront plus visibles dans les listes.',
      },
      activate_sectors: {
        name: "Activer l'option secteur pour les résidents",
        message:
          'Activer cette option vous permettra de créer des secteurs et affecter les résidents de facon à pouvoir les trier plus facilement',
      },
      default_schedule_time: {
        name: "Heure du repas (par défaut)",
        message:
          "Changer l'heure du repas par défaut pour tous les résidents",
      },
      breakfast: {
        name: 'Petit déjeuner',
      },
      morning_snack: {
        name: 'Collation du matin',
      },
      lunch: {
        name: 'Déjeuner',
      },
      afternoon_snack: {
        name: 'Goûter',
      },
      dinner: {
        name: 'Dîner',
      },
      evening_snack: {
        name: 'Collation du soir',
      },
    };
  },
  methods: {
    async updateEhpadSetting(setting, value) {
      this.$v.editableSetting.$touch();
      if (this.$v.$anyError) return;
      this.updating = true;
      await this.$store.dispatch('updateEhpadSetting', {
        id: this.currentEhpad.id,
        rootState: this.rootState,
        state: this.state,
        setting,
        value: String(value),
      }).then((result) => {
        this.$v.$reset();
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      this.updating = false;
    },
    name(setting) {
      return eval(`this.${setting}.name`);
    },
    message(setting) {
      return eval(`this.${setting}.message`);
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    updatingClasses() {
      return this.updating ? 'fa fa-circle-notch fa-spin' : 'fa fa-check';
    },
  },
};
</script>

<style lang="scss" scoped></style>
