<template>
  <div>
    <div class="text-center" style="color: #469AE0;" v-if="averageDiagsResultsForNewResidentsLoading || newResidentsForSampleLoading">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement du graphique...
      </h2>
    </div>
    <div v-else class="mt-3">
      <line-chart :data="dataAverageDiagsResultsForNewResidents" :points=true :library="options"></line-chart>
      <div class="d-flex mt-2">
        <h6>
          <span>Nombre de nouveaux résidents sur l'intervalle :</span>
          <span><strong>{{ newResidentsForSample.length }}</strong></span>
          <b-button variant="link" size="sm" v-b-modal.new-residents-for-sample-details-modal>
              <i class="fas fa-info"
                v-b-tooltip.hover.v-primary
                title="Détails des résidents"
              />
            </b-button>
        </h6>
      </div>
      <p class="small mb-0" style="color: #BFBFBF;">* moyenne par index sur les diags des résidents.</p>
      <p class="small mb-0" style="color: #BFBFBF;">Il faut au moins <b>3 valeurs de diagnostics différents</b> pour faire une moyenne.</p>
      <p class="small" style="color: #BFBFBF;">1 = Dénutrition Sévère, 2 = Dénutrition modérée, 3 = A risque élevé, 4 = A risque faible</p>
      <app-residents-for-sample-details-modal
        :data="this.newResidentsForSample"
        id="new-residents-for-sample-details-modal"
        hide-footer
      />
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { FETCH_AVERAGE_DIAGS_RESULTS_FOR_NEW_RESIDENTS } from "../../graphql/stats/queries";
import { FETCH_NEW_RESIDENTS_FOR_SAMPLE } from "../../graphql/stats/queries";
import ResidentsForSampleDetailsModal from "../stats/ResidentsForSampleDetailsModal.vue";

export default {
  name: "AverageDiagsResultsForNewResidentsChart",
  props: {
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  components: {
    appResidentsForSampleDetailsModal: ResidentsForSampleDetailsModal,
  },
  data() {
    return {
      averageDiagsResultsForNewResidentsLoading: true,
      newResidentsForSampleLoading: true,
      dataAverageDiagsResultsForNewResidents: [],
      options: {
        scales: {
          xAxes: [{
            minimum: 1,
            type: "integer",
            scaleLabel: {
              display: true,
              labelString: "Index du diagnostic",
            },
            ticks: {
              min: 1,
              stepSize: 1,
              precision: 0,
            },
          }],
          yAxes: [{
            ticks: {
              min: 1,
              max: 4,
              stepSize: 1,
              precision: 0,
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  apollo: {
    averageDiagsResultsForNewResidents: {
      query: FETCH_AVERAGE_DIAGS_RESULTS_FOR_NEW_RESIDENTS,
      variables() {
        this.averageDiagsResultsForNewResidentsLoading = true;
        return {
          ehpadId: this.currentEhpad.id,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "network-only",
      result() {
        this.averageDiagsResultsForNewResidentsLoading = false;
      },
      watchQuery: {
        variables(newVariables) {
          return {
            ehpadId: newVariables.currentEhpad.id,
            startDate: newVariables.startDate,
            endDate: newVariables.endDate,
          };
        },
      },
    },
    newResidentsForSample: {
      query: FETCH_NEW_RESIDENTS_FOR_SAMPLE,
      variables() {
        this.newResidentsForSampleLoading = true;
        return {
          ehpadId: this.currentEhpad.id,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "network-only",
      result() {
        this.newResidentsForSampleLoading = false;
      },
      watchQuery: {
        variables(newVariables) {
          return {
            ehpadId: newVariables.currentEhpad.id,
            startDate: newVariables.startDate,
            endDate: newVariables.endDate,
          };
        },
      },
    },
  },
  watch: {
    averageDiagsResultsForNewResidents: {
      handler() {
        if (this.averageDiagsResultsForNewResidents !== undefined) {
          this.dataAverageDiagsResultsForNewResidents = this.buildAverageDiagsResultsForNewResidents;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: "ehpad",
    }),
    buildAverageDiagsResultsForNewResidents() {
      return [
        {
          name: "Moyenne des états nutritionnels",
          data: this.averageDiagsResultsForNewResidents.data,
          color: "#ff6347",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
