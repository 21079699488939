<template>
  <div>
    <b-row v-if="ehpadIngestaControlForCharts !== undefined">
      <b-col class="col-2">Intervalle </b-col>
      <b-col class="col-2">
        <div>
          <b-form-select
            v-model="rangeForIngestaControlCharts"
            :options="chartRangeOptions"
            @change="ehpadIngestaControlForCharts = undefined"
          />
        </div>
      </b-col>
    </b-row>
    <div v-if="ehpadIngestaControlForCharts !== undefined" class="mt-3">
      <line-chart :data="dataEhpadIngestaControlForCharts" suffix="%" :points=false :library="options"></line-chart>
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="ehpadIngestaControlForCharts === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { FETCH_EHPAD_INGESTA_CONTROL_FOR_CHARTS } from '../../graphql/dashboard/queries';

export default {
  data() {
    return {
      dataEhpadIngestaControlForCharts: [],
      rangeForIngestaControlCharts: { months: null, year: null },
      options: {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              displayFormats: {
                day: 'DD MMM YYYY',
              },
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        onClick: this.handleClick,
      },
    };
  },
  apollo: {
    ehpadIngestaControlForCharts: {
      query: FETCH_EHPAD_INGESTA_CONTROL_FOR_CHARTS,
      variables() {
        return {
          ehpadId: this.currentEhpad.id,
          year: parseInt(this.rangeForIngestaControlCharts.year, 10),
          months: parseInt(this.rangeForIngestaControlCharts.months, 10),
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  watch: {
    ehpadIngestaControlForCharts: {
      handler() {
        if (this.ehpadIngestaControlForCharts !== undefined) {
          this.dataEhpadIngestaControlForCharts = this.buildDataEhpadIngestaControlForCharts;
        }
      },
      immediate: true,
    },
  },
  methods: {
  	handleClick(point, event) {
      const item = event[0];
      if (item === undefined) { return; }
      const date = this.dataEhpadIngestaControlForCharts[0].data[item._index][0];
      const formattedDate = moment(date, 'YYYY/MM/DD').format('DD-MM-YYYY');
      this.$router.push({ path: `/ehpads/${this.currentEhpad.shortname}/${formattedDate}/ingesta` });
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    chartRangeOptions() {
      return [
        { value: { months: null, year: null }, text: '3 mois glissants' },
        { value: { months: 6, year: null }, text: '6 mois glissants' },
        { value: { months: 12, year: null }, text: '12 mois glissants' },
        { value: { months: null, year: moment().year() - 2 }, text: moment().year() - 2 },
        { value: { months: null, year: moment().year() - 1 }, text: moment().year() - 1 },
        { value: { months: null, year: moment().year() }, text: moment().year() },
      ];
    },
    buildDataEhpadIngestaControlForCharts() {
      const datesArray = Object.keys(this.ehpadIngestaControlForCharts);
      const fetchValuesForField = (field) => datesArray.map(date => [date, _.get(this.ehpadIngestaControlForCharts[date], field, 0)]);

      return [
        {
          name: 'Taux de remplissage des ingesta',
          data: fetchValuesForField('ingesta_control').map((el) => [moment(el[0]).format('YYYY/MM/DD'), el[1]]),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
