<template>
  <b-container fluid :style="ehpadColor">
    <b-row class="justify-content-center my-5">
      <b-col cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            tag="img"
            src="/assets/images/residentsC.png"
            :to="{path: `/ehpads/${ehpad.shortname}/residents`}"
            class="custom-icon"
            style="cursor: pointer"
          ></router-link>
        </div>
        <p class="text-center mt-2">Résidents</p>
      </b-col>
      <b-col cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            tag="img"
            src="/assets/images/alimentsC.png"
            :to="{path: `/ehpads/${ehpad.shortname}/dishes/`}"
            class="custom-icon"
            style="cursor: pointer"
          ></router-link>
        </div>
        <p class="text-center mt-2">Plats</p>
      </b-col>
      <b-col  cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            tag="img"
            src="/assets/images/supplementsC.png"
            :to="{path: `/ehpads/${ehpad.shortname}/supplementations/`}"
            class="custom-icon"
            style="cursor: pointer"
          ></router-link>
        </div>
        <p class="text-center mt-2">Supplémentations</p>
      </b-col>
      <b-col cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            tag="img"
            src="/assets/images/menusC.png"
            :to="{path: `/ehpads/${ehpad.shortname}/menus/${currentYear}/${currentWeek}`}"
            class="custom-icon"
            style="cursor: pointer"
          ></router-link>
        </div>
        <p class="text-center mt-2">Menus</p>
      </b-col>
    </b-row>
  <b-row class="justify-content-center my-5">
      <b-col cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            tag="img"
            src="/assets/images/ingestaC.png"
            :to="{path: `/ehpads/${ehpad.shortname}/${currentDate}/ingesta`}"
            class="custom-icon"
            style="cursor: pointer"
          ></router-link>
        </div>
        <p class="text-center mt-2">Ingesta</p>
      </b-col>
      <b-col  cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            tag="img"
            src="/assets/images/tableaudebordC.png"
            :to="{path: `/ehpads/${ehpad.shortname}/dashboard/`}"
            class="custom-icon"
            style="cursor: pointer"
          ></router-link>
        </div>
        <p class="text-center mt-2">Tableau de bord</p>
      </b-col>
      <b-col  cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            tag="img"
            src="/assets/images/infosC.png"
            :to="{path: `/ehpads/${ehpad.shortname}/infos/`}"
            class="custom-icon"
            style="cursor: pointer"
          ></router-link>
        </div>
        <p class="text-center mt-2">Informations</p>
      </b-col>
      <b-col  cols="6" lg="2">
        <div class="menu-link text-center">
          <img
            src="/assets/images/deconnexionC.png"
            @click="signOut"
            class="custom-icon"
            style="cursor: pointer">
          </img>
        </div>
        <p class="text-center mt-2">Déconnexion</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'EhpadMainMenu',
  methods: {
    signOut() {
      this.$store.dispatch('signoutUser');
      this.$router.push('/signin');
    },
  },
  computed: {
    ...mapGetters({
      ehpad: 'ehpad',
    }),
    currentYear() {
      return (moment().year());
    },
    currentWeek() {
      return (moment().isoWeek());
    },
    currentDate() {
      return moment().format('DD-MM-YYYY');
    },
    ehpadColor() {
      return { '--ehpad-color': this.ehpad.color };
    },
  },
};
</script>

<style lang="scss" scoped>

.custom-icon {
  width: 200px;
  border-radius: 9px;
}

.custom-icon:hover {
  background-color: var(--ehpad-color);
}

.container-fluid {
  min-height: calc(100vh - 280px);
}

@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .custom-icon {
    width: 150px;
  }
}

@media only screen and (min-width: 1600px) {
  .custom-icon {
    width: 220px;
  }
}

</style>
