<template>
  <b-container fluid>
    <span class="d-flex justify-content-center">
      <h5 class="font-weight-bold">Programme Individuel</h5>
    </span>

    <b-form>
      <b-row class="pt-4 d-flex justify-content-center">
        <b-col cols="10">
          <GoalsComponent
            ref="goalsComponent"
            :goals="editableIndividualProgram.goals"
            :currentDate="currentDate"
            @add-goal="addGoal"
            @delete-goal="deleteGoal"
            :readonly=false
          />
        </b-col>
      </b-row>

      <b-row class="pt-4 d-flex justify-content-center">
        <b-col cols="6">
          <TherapeuticActionsComponent
            ref="therapeuticActionsComponent"
            :actions="therapeuticActions"
            :currentDate="currentDate"
            @add-action="addAction"
            @delete-action="deleteAction"
            :readonly=false
          />
        </b-col>

        <b-col cols="6">
          <NutritionalActionsComponent
            ref="nutritionalActionsComponent"
            :actions="nutritionalActions"
            :currentDate="currentDate"
            @add-action="addAction"
            @delete-action="deleteAction"
            :readonly=false
          />
        </b-col>
      </b-row>

      <div v-if="error" class="text-center mt-2" style="color: red;">
        <p v-for="(error, i) in error.graphQLErrors" :key="i">
          {{ error.message }}
        </p>
      </div>

      <b-button class="d-flex mx-auto mt-4" variant="primary" size="xl" @click.prevent="createIndividualProgram">
        Valider
      </b-button>
    </b-form>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { dateFormatterShort } from '../../../utilities/filters';
import GoalsComponent from './GoalsComponent.vue';
import TherapeuticActionsComponent from './TherapeuticActionsComponent.vue';
import NutritionalActionsComponent from './NutritionalActionsComponent.vue';

export default {
  components: {
    GoalsComponent,
    TherapeuticActionsComponent,
    NutritionalActionsComponent
  },
  props: ["resident"],
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      editableIndividualProgram: {
        goals: [],
        actions: [],
      },
      currentDate: new Date().toISOString().slice(0, 10),
    };
  },
  created() {
    this.duplicateIndividualProgram(this.resident.individualProgram);
  },
  watch: {
    resident: {
      handler() {
        this.duplicateIndividualProgram(this.resident.individualProgram);
      },
      immediate: true,
    },
  },
  validations: {
    editableIndividualProgram: {
      goals: {
        $each: {
          description: { required },
        },
      },
      actions: {
        $each: {
          description: { required },
          actionType: { required },
          date: { required },
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: "ehpad",
    }),
    therapeuticActions() {
      return this.editableIndividualProgram.actions.filter(action => action.actionType === 'therapeutic');
    },
    nutritionalActions() {
      return this.editableIndividualProgram.actions.filter(action => action.actionType === 'nutritional');
    },
  },
  methods: {
    dateFormatterShort,
    duplicateIndividualProgram(individualProgram) {
      this.editableIndividualProgram = individualProgram
        ? { ...individualProgram, actions: [...individualProgram.actions], goals: [...individualProgram.goals] }
        : { goals: [], actions: [] };
    },
    addGoal() {
      this.editableIndividualProgram.goals.push({ description: "", date: this.currentDate });
    },
    deleteGoal(index) {
      this.editableIndividualProgram.goals.splice(index, 1);
    },
    addAction(type) {
      this.editableIndividualProgram.actions.push({ description: "", actionType: type, date: this.currentDate });
    },
    deleteAction(index, type) {
      const actions = type === 'therapeutic' ? this.therapeuticActions : this.nutritionalActions;
      const actionIndex = this.editableIndividualProgram.actions.findIndex(action => action === actions[index]);
      if (actionIndex > -1) {
        this.editableIndividualProgram.actions.splice(actionIndex, 1);
      }
    },
    async createIndividualProgram() {
      this.editableIndividualProgram.actions.forEach(action => delete action.__typename);
      this.editableIndividualProgram.goals.forEach(goal => delete goal.__typename);

      this.touchAllValidations();

      if (this.hasValidationErrors()) return;

      try {
        await this.$store.dispatch("createIndividualProgram", {
          id: this.editableIndividualProgram.id,
          residentId: this.resident.id,
          goals: this.editableIndividualProgram.goals,
          actions: this.editableIndividualProgram.actions,
        });
        this.resetAllValidations();
      } catch (error) {
        this.error = error;
        this.flashMessage.show({
          status: "error",
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      }
    },
    touchAllValidations() {
      this.$refs.goalsComponent?.$v?.$touch();
      this.$refs.therapeuticActionsComponent?.$v?.$touch();
      this.$refs.nutritionalActionsComponent?.$v?.$touch();
      this.$v.editableIndividualProgram.$touch();
    },
    hasValidationErrors() {
      return this.$v.$anyError ||
             this.$refs.goalsComponent?.$v?.$anyError ||
             this.$refs.therapeuticActionsComponent?.$v?.$anyError ||
             this.$refs.nutritionalActionsComponent?.$v?.$anyError;
    },
    resetAllValidations() {
      this.$v.$reset();
      this.$refs.goalsComponent?.$v?.$reset();
      this.$refs.therapeuticActionsComponent?.$v?.$reset();
      this.$refs.nutritionalActionsComponent?.$v?.$reset();
    }
  }
};
</script>
