<template>
  <div>
    <span class="d-flex justify-content-center">
      <h5>NRI</h5>
      <i
        class="fas fa-exclamation-triangle mt-1 ml-2"
        style="color: #5bc0de;"
        v-b-tooltip.hover.v-primary
        title="Attention : Pour chaque date, le NRI est calculé avec les valeurs de poids et d'albumine antérieures les plus récentes"
      />
    </span>
    <line-chart :data="chartData" :colors="['#469be0']" class="mb-5" :library="options"></line-chart>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    residentAlbumines: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    currentEhpad: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chartData() {
      const computedAlbumines = _.map(this.residentAlbumines, (v) => {
        return {
          date: v.date,
          value: v.nri,
        };
      });
      const sortedComputedAlbumines = _.orderBy(computedAlbumines, (albumine) => moment(albumine.date), ['asc']);
      const lowTreshold = _.map(sortedComputedAlbumines, (v) => {
        return {
          date: v.date,
          value: this.currentEhpad.allSettings.moderate_undernutrition.diagnostic_condition_nri.nri_treshold,
        };
      });
      const criticalTreshold = _.map(sortedComputedAlbumines, (v) => {
        return {
          date: v.date,
          value: this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_nri.nri_treshold,
        };
      });
      return [
        {
          name: 'Courbe du NRI',
          data: sortedComputedAlbumines.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
        },
        {
          name: 'Seuil bas',
          data: lowTreshold.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#FF9900',
        },
        {
          name: 'Seuil Critique',
          data: criticalTreshold.map((el) => [moment(el.date).format('YYYY/MM/DD'), el.value]),
          color: '#DC3912',
        },
      ];
    },
  },
};
</script>

<style scoped>
</style>
