<template>
  <b-container fluid>
    <span class="d-flex justify-content-center">
      <h5 class="font-weight-bold">États nutritionnels (En fonction des protocoles)</h5>
      <i
        class="fas fa-exclamation-triangle mt-1 ml-2"
        style="color: #5bc0de;"
        v-b-tooltip.hover.v-primary
        title="Attention : seul un professionnel de santé est habilité à établir un diagnostic"
      />
    </span>
    <div class="mx-5 d-print-none">
      <b-row class="justify-content-around">
        <b-col cols="12" lg="5" class="mt-2">
        <h6 class="text-center">État nutritionnel (prérempli HAS 2021)</h6>
          <b-form>
            <b-row class="mt-3">
              <b-col cols="12" md="6" class="mb-3">
                <b-form-select
                  size="sm"
                  v-model="diagnostic.state"
                  :options="diagnosticStates"
                  :class="{'is-invalid': $v.diagnostic.state.$anyError}"
                />
              </b-col>
              <b-col cols="12" md="6" class="mb-3">
                <b-form-datepicker
                  id="date-datepicker-diag"
                  :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                  label-help
                  label-no-date-selected="Aucune date sélectionnée"
                  :max="today"
                  size="sm"
                  v-model="diagnostic.date"
                  locale="fr"
                  :class="{'is-invalid': $v.diagnostic.date.$error}"
                />
              </b-col>
              <b-form-textarea
                id="comment-diag"
                v-model="diagnostic.comment"
                type="text"
                rows="6"
                required
                size="sm"
                placeholder="Saisissez un commentaire attaché à l'état nutritionnel"
              />
              <b-button class="mt-3 mx-auto d-flex align-items-center" variant="primary" size="sm" type="submit" @click="createDiagnostic()" :disabled="loading">
                <i class="mr-2" :class="loading ? 'fa fa-spin fa-circle-notch' : 'fas fa-plus'" />
                  Ajouter cet état nutritionnel
              </b-button>
            </b-row>
          </b-form>
        </b-col>

        <b-col cols="12" lg="7" class="mt-2">
          <div class="card bg-light h-100">
            <div class="card-header text-center">
              <span>État nutritionnel proposé (HAS 2021)</span>
            </div>
            <div class="card-body">
              <div class="card-text text-center mb-1">
                <a href="/assets/documentation/HAS2021.pdf" target="_blank">Documentation HAS 2021</a>
              </div>

              <div class="has2021-table">

                <b-row>
                  <b-col cols="4"><b>≥ 1 critère phénotypique</b></b-col>
                  <b-col cols="4"><b>≥ 1 critère étiologique</b></b-col>
                  <b-col cols="4"><b>Sévérité</b></b-col>
                </b-row>

                <b-row>
                  <b-col cols="4">
                    <span v-if="this.weightLossOneMonth < -5" style="color:red;"><b>Perte de poids supérieure à 5% sur un mois</b></span>
                    <span v-if="this.weightLossSixMonth < -10" style="color:red;"><b>Perte de poids supérieure à 10% sur 6 mois</b></span>
                    <span v-if="(this.weightLossOneMonth > -5) && (this.weightLossSixMonth > -10)">Pas de perte de poids détectée</span>
                  </b-col>
                  <b-col cols="4">
                    <span v-if="this.resident.recentNutritionReduction" style="color:red;"><b>Réduction de la prise alimentaire détectée</b></span>
                    <span v-else>Pas de réduction de la prise alimentaire détectée</span>
                  </b-col>
                  <b-col cols="4">
                    <span v-if="this.weightLossOneMonth < -10" style="color:red;"><b>Perte de poids supérieure à 10% sur un mois</b></span>
                    <span v-if="this.weightLossSixMonth < -15" style="color:red;"><b>Perte de poids supérieure à 15% sur 6 mois</b></span>
                    <span v-if="(this.weightLossOneMonth > -10) && (this.weightLossSixMonth > -15)">Pas de sévérité liée à perte de poids</span>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="4">
                    <span v-if="this.lastImc < 22" style="color:red;"><b>IMC inférieur au seuil recommandé</b></span>
                    <span v-else>IMC Normal</span>
                  </b-col>
                  <b-col cols="4">
                     <p class="text-center mb-0" v-if="resident.absorptionStatus.error"><a href="#resident-tests">Passer un test</a></p>
                    <span style="color:orange;font-size:smaller" v-if="resident.absorptionStatus.error"><b> {{ resident.absorptionStatus.error }} </b></span>
                    <span style="color:red" v-if="resident.absorptionStatus.status === true"><b>Absorption réduite</b></span>
                    <span v-if="resident.absorptionStatus.status === false">Pas de réduction d'absorption</span>
                  </b-col>
                  <b-col cols="4">
                    <span v-if="this.lastImc < 20" style="color:red;"><b>IMC inférieur au seuil de sévérité</b></span>
                    <span v-else>Pas de sévérité liée à l'IMC</span>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="4">
                    <p class="text-center mb-0" v-if="resident.sarcopeniaStatus.error"><a href="#resident-tests">Passer un test</a></p>
                    <p class="m-1 text-center" style="color:orange;font-size:smaller" v-if="resident.sarcopeniaStatus.error"><b> {{ resident.sarcopeniaStatus.error}} </b></p>
                    <p class="m-1 text-center" style="color:red" v-if="resident.sarcopeniaStatus.status === true"><b> Résident sarcopénique </b></p>
                    <p class="m-1 text-center" v-if="resident.sarcopeniaStatus.status === false"><b> Résident non sarcopénique </b></p>
                    <p class="m-1 text-center" v-if="resident.sarcopeniaStatus.muscular_mass_test_date"><i style="color: darkgray;font-size: smaller;">Test de masse (Le : {{ resident.sarcopeniaStatus.muscular_mass_test_date }})</i></p>
                    <p class="m-1 text-center" v-if="resident.sarcopeniaStatus.muscular_force_test_date"><i style="color: darkgray;font-size: smaller;">Test de force (Le : {{ resident.sarcopeniaStatus.muscular_force_test_date }})</i></p>
                  </b-col>
                  <b-col cols="4">
                    <p class="text-center mb-0" v-if="resident.aggressionStatus.error"><a href="#resident-tests">Passer un test</a></p>
                    <span style="color:orange;font-size:smaller" v-if="resident.aggressionStatus.error"><b> {{ resident.aggressionStatus.error }} </b></span>
                    <span style="color:red" v-if="resident.aggressionStatus.status === true"><b>Situation d’agression</b></span>
                    <span v-if="resident.aggressionStatus.status === false">Pas de situation d’agression</span>
                  </b-col>
                  <b-col cols="4">
                    <span v-if="resident.lastAlbumine && resident.lastAlbumine.value < 30" style="color:red;">Albumine inférieure au seuil de sévérité</span>
                    <span v-else>Pas de sévérité liée à l'Albumine</span>
                  </b-col>
                </b-row>

              </div>
                  <!-- <div class="text-center">
                    <ul style="list-style-type: none; font-size: smaller; padding: 0; margin: 0;">
                      <li v-for="criterion in this.preferedHas2021DiagState.criterions">
                        {{ criterion }}
                      </li>
                    </ul>
                  </div> -->
              <hr>
              <div class="text-center my-auto">
                <h6 v-html="coloredDiagHtml(this.preferedHas2021DiagState.state)"></h6>
                <h6 v-html="this.preferedHas2021DiagState.error"></h6>
              </div>
            </div>
          </div>
        </b-col>

        <b-col cols="12" lg="5" class="mt-3">
          <div class="card bg-light h-100">
            <div class="card-header text-center">
              <span>État nutritionnel proposé (NRI)</span>
            </div>
            <div class="card-body">
              <div class="card-text">
                <div class="mt-1" style="font-size: small;" v-if="!this.resident.lastNri">
                  <p>
                    <i class="fas fa-exclamation-triangle" style="color: red;"/>
                    <i>Les données suivantes sont nécessaires </i>
                    <i class="fas fa-exclamation-triangle" style="color: red;"/>
                  </p>
                  <div>
                    <li><i>Le poid de forme doit être renseigné</i></li>
                    <li><i>Une valeur de poid (récente pour que le calcul soit cohérent)</i></li>
                    <li><i>Une valeur d'albumine (récente pour que le calcul soit cohérent)</i></li>
                  </div>
                </div>
                <div v-else>
                  <div class="text-center">
                    NRI =<span style="color: red;"> {{ this.resident.lastNri }}</span>
                    <ul style="list-style-type: none; font-size: smaller; padding: 0; margin: 0;">
                      <li v-for="criterion in this.preferedNriDiagState.criterions">
                        {{ criterion }}
                      </li>
                    </ul>
                  </div>
                  <hr>
                  <div class="text-center my-auto">
                    <h6 v-html="coloredDiagHtml(this.preferedNriDiagState.state)"></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>

        <b-col cols="12" lg="7" class="mt-3">
          <div class="card bg-light h-100">
            <div class="card-header text-center">
              <span>État nutritionnel proposé (HAS 2007)</span>
            </div>
            <div class="card-body">
              <div class="card-text">
                <div>
                  <div class="text-center">
                    <ul style="list-style-type: none; font-size: smaller; padding: 0; margin: 0;">
                      <li v-for="criterion in this.preferedHas2007DiagState.criterions">
                        {{ criterion }}
                      </li>
                    </ul>
                  </div>
                  <hr>
                  <div class="text-center my-auto">
                    <h6 v-html="coloredDiagHtml(this.preferedHas2007DiagState.state)"></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>

      </b-row>

    </div>

    <app-resident-test-modal
      id="resident-test-modal"
      hide-footer
      :resident="resident"
    />
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { coloredDiagHtml } from '../../utilities/filters';
import _ from 'lodash';
import ResidentTestModal from './ResidentTestModal.vue';

export default {
  props: ['resident'],
  mixins: [validationMixin],
  components: {
    appResidentTestModal: ResidentTestModal,
  },
  data() {
    return {
      lastImc: null,
      loading: false,
      weightLossOneMonth: 0,
      weightLossSixMonth: 0,
      residentWeights: null,
      residentCrps: null,
      residentMnas: null,
      residentAlbumines: null,
      residentDiagnostics: null,
      diagnostic: {
        state: null,
        comment: '',
        date: null,
      },
      nriCriterion: null,
    };
  },
  watch: {
    resident: {
      handler() {
        this.residentWeights = this.resident && this.resident.weights;
        this.residentCrps = this.resident && this.resident.crps;
        this.residentMnas = this.resident && this.resident.mnas;
        this.residentAlbumines = this.resident && this.resident.albumines;
        this.residentDiagnostics = this.resident && this.resident.diagnostics;
        this.lastImc = this.resident && (this.resident.lastWeight.value / ((this.resident.height / 100) * (this.resident.height / 100))).toFixed(2);
        if (this.resident) { this.weightWarnings(); }
        this.diagnostic.state = this.resident && this.preferedHas2021DiagState.state;
      },
      immediate: true,
    },
    residentWeights() {
      this.weightWarnings();
      this.lastImc = (this.resident.lastWeight.value / ((this.resident.height / 100) * (this.resident.height / 100))).toFixed(2);
      this.diagnostic.state = this.preferedHas2021DiagState.state;
    },
    residentMnas() {
      this.diagnostic.state = this.preferedHas2021DiagState.state;
    },
    residentAlbumines() {
      this.diagnostic.state = this.preferedHas2021DiagState.state;
    },
    residentDiagnostics() {
      this.diagnostic.state = this.preferedHas2021DiagState.state;
    },
  },
  created() {
    this.diagnostic.date = this.today;
  },
  validations: {
    diagnostic: {
      state: {
        required,
      },
      date: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    today() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    },
    diagnosticStates() {
      return [
        { value: null, text: 'Choisissez un état nutritionnel', disabled: true },
        'Non classé',
        'A risque faible',
        'A risque élevé',
        'Dénutrition modérée',
        'Dénutrition sévère',
        'Fin de vie',
      ];
    },
    preferedHas2021DiagState() {
      const ph1 = (this.weightLossOneMonth < -5 || this.weightLossSixMonth < -10);
      const ph2 = (this.lastImc < 22);
      const ph3 = this.resident.sarcopeniaStatus.status;
      const has2021PhenotypicCriterion = ph1 || ph2 || ph3;
      const etio1 = this.resident.recentNutritionReduction;
      const etio2 = this.resident.absorptionStatus.status;
      const etio3 = this.resident.aggressionStatus.status;
      const has2021EtiologicalCriterion = etio1 || etio2 || etio3;
      const sev1 = (this.weightLossOneMonth < -10 || this.weightLossSixMonth < -15);
      const sev2 = (this.lastImc < 20);
      const sev3 = this.resident.lastAlbumine && this.resident.lastAlbumine.value < 30;
      const has2021Severity = sev1 || sev2 || sev3;
      if ((has2021PhenotypicCriterion === undefined) || (has2021EtiologicalCriterion === undefined)) {
        return { state: "Non classé", error: "Des données sont manquantes pour établir un diagnostic" };
      }
      if (has2021PhenotypicCriterion && has2021EtiologicalCriterion) {
        if (has2021Severity) { return { state: "Dénutrition sévère" }; }
        return { state: "Dénutrition modérée" };
      }
      return { state: "A risque faible" };
    },
    preferedHas2007DiagState() {
      const criterions = [];
      // Dénutrition Sévère
      const albSev = (_.get(this.resident.lastAlbumine, 'value', undefined) < this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_has_2007.albumine_treshold);
      if (albSev) { criterions.push(`Albumine corrigée (${_.get(this.resident.lastAlbumine, 'correctedAlbumine', undefined)}) inférieure au seuil de dénutrition sévére (${this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_has_2007.albumine_treshold})`); }
      const imcSev = (this.lastImc < this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_has_2007.imc_treshold);
      if (imcSev) { criterions.push(`IMC (${this.lastImc}) inférieure au seuil de dénutrition sévére (${this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_has_2007.imc_treshold})`); }
      const w1Sev = (this.weightLossOneMonth < -this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_has_2007.weight_loss_one_month_percent);
      if (w1Sev) { criterions.push(`Perte de poids 1 mois (${this.weightLossOneMonth.toFixed(1)}%) supérieure au seuil de dénutrition sévére (${this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_has_2007.weight_loss_one_month_percent}%)`); }
      const w6Sev = (this.weightLossSixMonth < -this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_has_2007.weight_loss_six_month_percent);
      if (w6Sev) { criterions.push(`Perte de poids 6 mois (${this.weightLossSixMonth.toFixed(1)}%) supérieure au seuil de dénutrition sévére (${this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_has_2007.weight_loss_six_month_percent}%)`); }
      if (
        albSev || imcSev || w1Sev || w6Sev
      ) {
        return {
          criterions,
          state: 'Dénutrition sévère',
        };
      }
      // Dénutrition Modérée
      const albMod = (_.get(this.resident.lastAlbumine, 'value', undefined) < this.currentEhpad.allSettings.moderate_undernutrition.diagnostic_condition_has_2007.albumine_treshold);
      if (albMod) { criterions.push(`Albumine corrigée (${_.get(this.resident.lastAlbumine, 'correctedAlbumine', undefined)}) inférieure au seuil de dénutrition modérée (${this.currentEhpad.allSettings.moderate_undernutrition.diagnostic_condition_has_2007.albumine_treshold})`); }
      const imcMod = (this.lastImc < this.currentEhpad.allSettings.moderate_undernutrition.diagnostic_condition_has_2007.imc_treshold);
      if (imcMod) { criterions.push(`IMC (${this.lastImc}) inférieure au seuil de dénutrition modérée (${this.currentEhpad.allSettings.moderate_undernutrition.diagnostic_condition_has_2007.imc_treshold})`); }
      const w1Mod = (this.weightLossOneMonth < -this.currentEhpad.allSettings.moderate_undernutrition.diagnostic_condition_has_2007.weight_loss_one_month_percent);
      if (w1Mod) { criterions.push(`Perte de poids 1 mois (${this.weightLossOneMonth.toFixed(1)}%) supérieure au seuil de dénutrition modérée (${this.currentEhpad.allSettings.moderate_undernutrition.diagnostic_condition_has_2007.weight_loss_one_month_percent}%)`); }
      const w6Mod = (this.weightLossSixMonth < -this.currentEhpad.allSettings.moderate_undernutrition.diagnostic_condition_has_2007.weight_loss_six_month_percent);
      if (w6Mod) { criterions.push(`Perte de poids 6 (${this.weightLossSixMonth.toFixed(1)}%) mois supérieure au seuil de dénutrition modérée (${this.currentEhpad.allSettings.moderate_undernutrition.diagnostic_condition_has_2007.weight_loss_six_month_percent}%)`); }
      if (
        albMod || imcMod || w1Mod || w6Mod
      ) {
        return {
          criterions,
          state: 'Dénutrition modérée',
        };
      }
      // A risque élevé
      const albHigh = (_.get(this.resident.lastAlbumine, 'value', undefined) === this.currentEhpad.allSettings.high_risk.diagnostic_condition_has_2007.albumine_treshold);
      if (albHigh) { criterions.push(`Albumine corrigée (${_.get(this.resident.lastAlbumine, 'correctedAlbumine', undefined)}) égale au seuil de risque élevé (${this.currentEhpad.allSettings.high_risk.diagnostic_condition_has_2007.albumine_treshold})`); }
      const imcHigh = (this.lastImc === this.currentEhpad.allSettings.high_risk.diagnostic_condition_has_2007.imc_treshold);
      if (imcHigh) { criterions.push(`IMC (${this.lastImc}) égal au seuil de risque élevé (${this.currentEhpad.allSettings.high_risk.diagnostic_condition_has_2007.imc_treshold})`); }
      const w1High = ((this.weightLossOneMonth < 0) && (this.weightLossOneMonth > -this.currentEhpad.allSettings.high_risk.diagnostic_condition_has_2007.weight_loss_one_month_percent));
      if (w1High) { criterions.push(`Perte de poids 1 mois (${this.weightLossOneMonth.toFixed(1)}%) inférieure au seuil de risque élevé (${this.currentEhpad.allSettings.high_risk.diagnostic_condition_has_2007.weight_loss_one_month_percent}%)`); }
      const w6High = ((this.weightLossSixMonth < 0) && (this.weightLossSixMonth > -this.currentEhpad.allSettings.high_risk.diagnostic_condition_has_2007.weight_loss_six_month_percent));
      if (w6High) { criterions.push(`Perte de poids 6 (${this.weightLossSixMonth.toFixed(1)}%) mois inférieure au seuil de risque élevé (${this.currentEhpad.allSettings.high_risk.diagnostic_condition_has_2007.weight_loss_six_month_percent}%)`); }
      const mnaHigh = (_.get(this.resident.lastMna, 'value', undefined) < this.currentEhpad.allSettings.high_risk.diagnostic_condition_has_2007.mna_treshold);
      if (mnaHigh) { criterions.push(`MNA (${_.get(this.resident.lastMna, 'value', undefined)}) inférieure au seuil de risque élevé (${this.currentEhpad.allSettings.high_risk.diagnostic_condition_has_2007.mna_treshold})`); }
      if (
        albHigh || imcHigh || w1High || w6High || mnaHigh
      ) {
        return {
          criterions,
          state: 'A risque élevé',
        };
      }
      return {
        criterions: ['Aucun critére retenu'],
        state: 'A risque faible',
      };
    },
    preferedNriDiagState() {
      if (this.resident.lastNri === null) {
        return {
          criterions: [],
          state: null,
        };
      }
      if (this.resident.lastNri <= this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_nri.nri_treshold) {
        return {
          criterions: [`NRI inférieur ou égal au seuil de dénutrition sévére (${this.currentEhpad.allSettings.severe_undernutrition.diagnostic_condition_nri.nri_treshold})`],
          state: 'Dénutrition sévère',
        };
      }
      if (this.resident.lastNri <= this.currentEhpad.allSettings.moderate_undernutrition.diagnostic_condition_nri.nri_treshold) {
        return {
          criterions: [`NRI inférieur ou égal au seuil de dénutrition modérée (${this.currentEhpad.allSettings.moderate_undernutrition.diagnostic_condition_nri.nri_treshold})`],
          state: 'Dénutrition modérée',
        };
      }
      if (this.resident.lastNri <= this.currentEhpad.allSettings.high_risk.diagnostic_condition_nri.nri_treshold) {
        return {
          criterions: [`NRI inférieur ou égal au seuil de risque élevé (${this.currentEhpad.allSettings.high_risk.diagnostic_condition_nri.nri_treshold})`],
          state: 'A risque élevé',
        };
      }
      return {
        criterions: ['Aucun critére retenu'],
        state: 'A risque faible',
      };
    },
  },
  methods: {
    coloredDiagHtml,
    weightWarnings() {
      this.weightLossSixMonth = 0;
      this.weightLossOneMonth = 0;
      const sortedWeights = _.orderBy(this.residentWeights, (el) => new moment(el.date), ['desc']);
      const lastWeightDate = _.get(_.first(sortedWeights), 'date', null);
      const lastWeightValue = _.get(_.first(sortedWeights), 'value', null);
      const sixMonth = moment(lastWeightDate).subtract(6, 'M');
      const oneMonth = moment(lastWeightDate).subtract(1, 'M');
      const weightSixMonthAgo = _.find(sortedWeights, (weight) => {
        return moment(weight.date).isBefore(sixMonth);
      });
      const weightOneMonthAgo = _.find(sortedWeights, (weight) => {
        return moment(weight.date).isBefore(oneMonth);
      });
      if (weightSixMonthAgo) this.weightLossSixMonth = ((lastWeightValue - weightSixMonthAgo.value) / weightSixMonthAgo.value) * 100;
      if (weightOneMonthAgo) this.weightLossOneMonth = ((lastWeightValue - weightOneMonthAgo.value) / weightOneMonthAgo.value) * 100;
    },
    async createDiagnostic() {
      this.$v.diagnostic.$touch();
      if (this.$v.$anyError) return;
      this.loading = true;
      await this.$store.dispatch('createDiagnostic', {
        state: this.diagnostic.state,
        nriState: this.preferedNriDiagState.state,
        has2007State: this.preferedHas2007DiagState.state,
        has2021State: this.preferedHas2021DiagState.state,
        comment: this.diagnostic.comment,
        date: moment(this.diagnostic.date).format('YYYY-MM-DD'),
        residentId: this.resident.id,
        nri: parseFloat(this.resident.lastNri),
      }).then((result) => {
        this.$v.$reset();
        this.diagnostic.comment = '';
        this.diagnostic.date = this.today;
        this.$emit('updated-resident');
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      this.loading = false;
    },
  },
};

</script>

<style lang="scss" scoped>

.has2021-table > .row > .col-4 {
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: smaller;
}

</style>
