import moment from 'moment';
import _ from 'lodash';

export const addNumPadding = (int) => (int < 10 ? `0${int}` : int);

export const dateFormatter = (str) => {
  const date = moment(str);
  return date.locale('fr').format('ddd DD MMM YYYY');
};

export const dateFormatterFull = (str) => {
  const date = moment(str);
  return date.locale('fr').format('dddd DD MMM YYYY');
};

export const dateFormatterShort = (str) => {
  const date = moment(str);
  return date.locale('fr').format('DD/MM/YYYY');
};

export const colorNumberPercent = (int) => {
  if (int === null) {
    return;
  }
  if (int < 0) {
    return `<b style="color: tomato; font-size: medium;">${int}%</b>`;
  }
  return `<b style="color: green; font-size: medium;">${int}%</b>`;
};

export const colorNumberNutrition = (int, base) => {
  if (int === null) {
    return;
  }
  if (int < base) {
    return `<b style="color: tomato; font-size: medium;">${int}</b>`;
  }
  return `<b style="color: green; font-size: medium;">${int}</b>`;
};

export const capitalizeString = (str) => `${str[0].toUpperCase()}${str.slice(1)}`;

export const coloredDiagHtml = (state) => {
  switch (state) {
    case 'Non classé':
      return `<b style="color: darkgray;">${state}</b>`;
    case 'A risque faible':
      return `<b>${state}</b>`;
    case 'A risque élevé':
      return `<b style="color: orange;">${state}</b>`;
    case 'Dénutrition modérée':
      return `<b style="color: orange;">${state}</b>`;
    case 'Dénutrition sévère':
      return `<b style="color: tomato;">${state}</b>`;
    case 'Fin de vie':
      return `<b style="color: tomato;">${state}</b>`;
    default:
      return state;
  }
};

export const coloredDiagHtmlWithDate = (obj, days) => {
  const lastDate = _.get(obj, 'date', null);
  const localizedDate = moment(lastDate).format('DD/MM/YYYY');
  const daysAgo = moment().subtract(days, 'd');

  const state = _.get(obj, 'humanState', null);
  let formattedState = null;
  switch (state) {
    case 'Non classé':
      formattedState = `<b style="color: darkgray;">${state}</b>`;
      break;
    case 'A risque faible':
      formattedState = `<b>${state}</b>`;
      break;
    case 'A risque élevé':
      formattedState = `<b style="color: orange;">${state}</b>`;
      break;
    case 'Dénutrition modérée':
      formattedState = `<b style="color: orange;">${state}</b>`;
      break;
    case 'Dénutrition sévère':
      formattedState = `<b style="color: tomato;">${state}</b>`;
      break;
    case 'Fin de vie':
      formattedState = `<b style="color: tomato;">${state}</b>`;
      break;
    default:
      formattedState = state;
  }

  if (moment(lastDate).isBefore(daysAgo)) return `<span><b>${formattedState}</b> <b><i style="color: tomato;font-size: smaller;">(Le : ${localizedDate})</i></b>`;
  return `<b>${formattedState}</b> <i style="color: darkgray;font-size: smaller;">(Le : ${localizedDate})</i>`;
};

export const coloredDataHtml = (obj, days) => {
  const lastValue = _.get(obj, 'value', null);
  const lastDate = _.get(obj, 'date', null);
  const localizedDate = moment(lastDate).format('DD/MM/YYYY');
  if (lastValue === null) return '<i style="color: tomato;font-size: smaller;">Pas de donnée</i>';
  const daysAgo = moment().subtract(days, 'd');
  if (moment(lastDate).isBefore(daysAgo)) return `<span><b>${parseFloat(lastValue)}</b> <b><i style="color: tomato;font-size: smaller;">(Le : ${localizedDate})</i></b>`;
  return `<b>${parseFloat(lastValue)}</b> <i style="color: darkgray;font-size: smaller;">(Le : ${localizedDate})</i>`;
};

export const dataWithDate = (value, date) => {
  const localizedDate = moment(date).format('DD/MM/YYYY');
  return `<b>${value}</b> <i style="color: darkgray;font-size: smaller;">(Le : ${localizedDate})</i>`;
};
