<template>
  <b-col cols="12">
    <span class="d-flex">
      <h5 v-html="coloredDiagHtml(name(diagnosticState))"></h5>
    </span>

    <app-child-settings-form
      v-for="(testType, index) in testTypes"
      :testType="testType"
      :diagnosticState="diagnosticState"
      :key="index"
    />
  </b-col>
</template>

<script>

import { mapGetters } from 'vuex';
import ChildSettingsForm from './ChildSettingsForm.vue';
import { coloredDiagHtml } from '../../utilities/filters';

export default {
  name: 'TestTypesRootSettings',
  props: ['diagnosticState'],
  components: {
    appChildSettingsForm: ChildSettingsForm,
  },
  data() {
    return {
      moderate_undernutrition: {
        name: 'Dénutrition modérée',
      },
      severe_undernutrition: {
        name: 'Dénutrition sévère',
      },
      high_risk: {
        name: 'A risque élevé',
      },
      default_undernutrition: {
        name: 'Autres états nutritionnels (risques faibles et fin de vie)',
      },
    };
  },
  methods: {
    coloredDiagHtml,
    name(setting) {
      return eval(`this.${setting}.name`);
    },
  },
  computed: {
    ...mapGetters({
      testTypes: 'testTypes',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
