<template>
  <div class="card p-4">
    <div class="pt-4 mb-4 d-flex align-items-center">
      <h6 class="font-weight-bold">Objectifs individuels</h6>
      <b-button class="mb-2 ml-4" variant="primary" size="sm" @click="addGoal" v-if="!readonly">
        <i class="fas fa-plus"></i>
      </b-button>
    </div>
    <b-row v-for="(goal, index) in goals" :key="`goal-${index}`" class="mb-2 align-items-center">
      <b-col cols="1">
        <label>{{ index + 1 }}.</label>
      </b-col>
      <b-col cols="8">
        <b-form-textarea
          :id="`goal-${index}`"
          size="sm"
          type="text"
          rows="2"
          v-model="goal.description"
          :disabled="readonly"
          :class="{ 'is-invalid': $v.goals.$each[index].description.$anyError }"
        />
      </b-col>
      <b-col cols="2" class="d-flex align-items-center">
        <label class="text-muted small mb-0">
          Ajouté le : {{ dateFormatterShort(goal.date || currentDate) }}
        </label>
      </b-col>
      <b-col cols="1" class="d-flex justify-content-start">
        <b-button variant="link" size="sm" @click="deleteGoal(index)" v-if="!readonly">
          <i class="text-danger fa fa-trash-alt" v-b-tooltip.hover.v-danger title="Supprimer"></i>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { dateFormatterShort } from '../../../utilities/filters';

export default {
  mixins: [validationMixin],
  props: {
    goals: Array,
    currentDate: String,
    readonly: Boolean
  },
  validations: {
    goals: {
      $each: {
        description: { required }
      }
    }
  },
  methods: {
    dateFormatterShort,
    addGoal() {
      this.$emit('add-goal');
    },
    deleteGoal(index) {
      this.$emit('delete-goal', index);
    }
  }
};
</script>
