<template>
    <div>
        <app-residents-ingesta-table
          :date="date"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ResidentsIngestaTable from '../../components/residents/IngestaTable.vue';

export default {
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  components: {
    appResidentsIngestaTable: ResidentsIngestaTable,
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
  methods: {
    ...mapActions(['findEhpadWithShortname']),
  },
  created() {
    this.findEhpadWithShortname(this.currentEhpad.shortname);
  },
};
</script>

<style lang="scss" scoped>
</style>
