<template>
  <div>
    <div
      style="transform: scale(0.8);margin-top: -30px;"
    >
      <p class="m-0">
        <span style="font-size: x-large;">
          {{ capitalizeString(buffetResidentMealDishIngested.dish.name)  }}
        </span>

        <span class="d-print-only">
          INGESTA :
          <span v-for="(ingestaObject, index) in ingestaObjects" class="mx-2" :key="index">
            <span :class="ingestaClassForPrint(ingestaObject)">{{ ingestaObject.value * 100 }}%</span>
          </span>
        </span>
      </p>

      <b-row class="mt-2 d-print-none d-flex justify-content-around mr-4">
        <b-col cols="1" v-for="ingestaObject in ingestaObjects" :key="ingestaObject.value">
          <div :class="ingestaClass(ingestaObject)" @click="updateIngesta(ingestaObject)">
            <span style="color: white;">{{ ingestaObject.value * 100 }}%</span>
          </div>
        </b-col>
      </b-row>

      <hr class="w-50 d-print-none" />
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { capitalizeString } from '../../utilities/filters';
import _ from 'lodash';
import EventBus from '../../utilities/event_bus';
import { UPDATE_RESIDENT_MEAL_DISH } from '../../graphql/resident_meal_dishes/mutations';

export default {
  name: 'DishChosenForBuffet',
  props: ['buffetResidentMealDishIngested', 'mealCategory', 'ingestaPrefilled'],
  data() {
    return {
      ingestaObjects: [
        { value: 0, class: 'redCircle', isSelected: false, isPrefilled: false },
        { value: 0.25, class: 'redCircle', isSelected: false, isPrefilled: false },
        { value: 0.5, class: 'orangeCircle', isSelected: false, isPrefilled: false },
        { value: 0.75, class: 'orangeCircle', isSelected: false, isPrefilled: false },
        { value: 1.0, class: 'greenCircle', isSelected: false, isPrefilled: false },
        { value: 1.25, class: 'greenCircle', isSelected: false, isPrefilled: false },
        { value: 1.5, class: 'greenCircle', isSelected: false, isPrefilled: false },
        { value: 1.75, class: 'greenCircle', isSelected: false, isPrefilled: false },
        { value: 2, class: 'greenCircle', isSelected: false, isPrefilled: false },
      ],
      currentSurvey: null,
    };
  },
  created() {
    this.setIngestValue();
    if (this.currentSurveys.length > 0) {
      // Get matching survey if exist for meal_category
      const filtered_survey = this.currentSurveys.filter(survey => survey.mealCategories.find(mc => mc.name === this.mealCategory.name) );
      this.currentSurvey = filtered_survey[0] || null;
    }
  },
  computed: {
    ...mapGetters({
      currentSurveys: 'currentSurveys',
    }),
  },
  methods: {
    capitalizeString,
    setIngestValue() {
      if (this.buffetResidentMealDishIngested.ingesta !== null) {
        const obj = _.find(this.ingestaObjects, { value: this.buffetResidentMealDishIngested.ingesta });
        obj.isSelected = true;
      }
      if (this.buffetResidentMealDishIngested.prefilled === true) {
        const obj = _.find(this.ingestaObjects, { value: this.buffetResidentMealDishIngested.ingesta });
        obj.isPrefilled = true;
      }
    },
    updateIngesta(ingestaObject) {
      this.ingestaObjects.forEach((el) => {
        el.isSelected = false;
        el.isPrefilled = false;
      });
      ingestaObject.isSelected = true;
      if (this.ingestaPrefilled === true) {
        ingestaObject.isPrefilled = true;
      }
      this.updating(ingestaObject);
    },
    ingestaClass(item) {
      if (item.isSelected && item.isPrefilled === true) {
        return `selected-prefilled ${item.class}`;
      }
      if (item.isSelected && item.isPrefilled === false) {
        return `selected ${item.class}`;
      }
      return item.class;
    },
    ingestaClassForPrint(item) {
      if (item.isSelected) {
        return `selected-print ${item.class}`;
      }
      return item.class;
    },
    async updating(ingestaObject) {
      await this.$apollo.mutate({
        mutation: UPDATE_RESIDENT_MEAL_DISH,
        variables: {
          id: this.buffetResidentMealDishIngested.id,
          ingesta: ingestaObject.value,
          prefilled: ingestaObject.isPrefilled,
        },
      }).then((result) => {
        // For Survey Modal with RMD Id:
        if (this.currentSurvey !== null) {
          if (ingestaObject.value >= this.currentSurvey.ingestaMin
            && ingestaObject.value <= this.currentSurvey.ingestaMax
          ) {
            EventBus.$emit('trigger-survey', result.data.updateResidentMealDish.residentMealDish.id, this.currentSurvey);
          }
        }
      }).catch((error) => {
        ingestaObject.isSelected = false;
        ingestaObject.isPrefilled = false;
        this.setIngestValue();
        if (error.message === 'Network error: Failed to fetch') {
          alert('Erreur réseau, veuillez rééssayer');
        } else {
          this.flashMessage.show({
            status: 'error',
            title: error.graphQLErrors[0].extensions.code,
            message: error.graphQLErrors[0].message,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.selected {
  box-shadow: 0px 0px 5px 5px #469ae0;
}

.selected-prefilled {
  box-shadow: 0px 0px 5px 5px red;
}

.selected-print {
  color: red;
  text-decoration: underline;
}

.redCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background: rgb(184, 31, 31);
  cursor: pointer;
}
.orangeCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background: orange;
  cursor: pointer;
}
.greenCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background: rgb(138, 210, 149);
  cursor: pointer;
}
</style>
